/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
export class Parameters {
  public static readonly MANUAL_DATA_PLATFORM = 'INTERNAL';
  public static readonly KEY_ACCESS_TOKEN = 'accessToken';
  public static readonly LOGOUT_URL = 'logoutUrl';
  public static readonly MAX_QUOTA_ALLOWED = 10000;
  public static readonly MSG_TIMEOUT_MILLIS = 4000;
  public static readonly STATISTIC_SERVICE_TIMEOUT_MILLIS = 300000; // 5 minutes
  public static readonly TARIFF_IMPORT_TIMEOUT_MILLIS = 120000; // 2 minutes

  public static readonly LOGIN_MIN_LENGTH = 5;
  public static readonly LOGIN_MAX_LENGTH = 50;
  public static readonly LOGIN_FILTER_UPPERCASE: RegExp = /[A-Z]/;

  public static readonly PASS_MIN_LENGTH = 8;
  public static readonly PASS_MAX_LENGTH = 256;

  public static readonly PHONE_MIN_LENGTH = 5;
  public static readonly PHONE_MAX_LENGTH = 20;
  public static readonly INTERNATIONAL_PHONE_PATTERN = /\+[0-9+/-]/;

  public static readonly VIN_MIN_LENGTH = 17;
  public static readonly VIN_MAX_LENGTH = 17;

  public static readonly NUMERIC_PATTERN = /[0-9]/;
  public static readonly ADDRESS_FILTER_MAX_LENGTH = 120;
  public static readonly DEFAULT_MAX_LENGTH = 50;
  public static readonly EVSEID_PATTERN: RegExp = /[a-zA-Z0-9 *+%-]/;

  public static readonly CP_ATTRIBUTE_MAX_LENGTH = 350;

  public static readonly CUSTOM_FIELD_NAME_MAX_LENGTH = 50;
  public static readonly CUSTOM_FIELD_DATA_MAX_LENGTH = 300;
  public static readonly MAX_CUSTOM_FIELDS = 20;

  public static readonly BACKEND_DEFAULT_DATE_FORMAT = 'yyyy-LL-dd';
  public static readonly BACKEND_IMPORT_DATE_FORMAT = 'dd.LL.yyyy';
  public static readonly BACKEND_DEFAULT_TIME_FORMAT = 'HH:mm:ss';
  public static readonly BACKEND_DATE_TIME_FORMAT2 = 'yyyy-LL-ddTHH:mm:ss.SSSZ';
  public static readonly BACKEND_DATE_TIME_FORMAT3 = 'yyyy-LL-ddTHH_mm_ss_SSSZ';

  public static readonly MODE_PARAM = 'mode';

  public static readonly GROUP_NAME_MAX_LENGTH = 50;

  public static readonly TAX_RATE_MAX_LENGTH = 6;
  public static readonly PAGE_SIZE_OPTIONS = [10, 20, 50];
  public static readonly PAGE_SIZE_OPTIONS_MEDIUM = [20, 50, 100];
  public static readonly PAGE_SIZE_OPTIONS_EXTENDED = [10, 20, 50, 100];

  public static readonly HEADER_NO_ERROR_HANDLE = 'noErrorHandling';
  public static readonly HEADER_ERROR_MSG_BASE = 'errorMsgBase';

  public static readonly MAX_FILE_SIZE = 5242880;
  public static readonly ONE_MB = 1048576;
}
