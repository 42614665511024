<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="scrollable-table-container">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container matColumnDef="{{col}}">
        <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell [styledColumn]="col" *matCellDef="let element">
          {{element[col]}}
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
<mat-paginator [pageSize]="pageSizeOptions[0]" [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
