/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { ChangeRequestDto } from '../datatypes/audit-flow/change-request-dto';
import { TranslateService } from '@ngx-translate/core';
import { ChangeRequestSubscriberType } from '../datatypes/audit-flow/change-request-subscriber-type';
import { ChangeRequestSubscriberDto } from '../datatypes/audit-flow/change-request-subscriber-dto';
import { ChangeRequestMessageDto } from '../datatypes/audit-flow/change-request-message-dto';
import { ChangeRequestMessageType } from '../datatypes/audit-flow/change-request-message-type';
import { ChangeRequestStatus } from '../datatypes/inbox/change-request-status';
import { firstValueFrom } from 'rxjs';
import { ChangeRequestPayloadType } from '../datatypes/inbox/change-request-payload-type';
import { ChargepointService } from './http/chargepoint.service';
import { ChangeRequestService } from './http/audit/change-request.service';
import { ChangeRequestSharedDataService } from './change-request-shared-data.service';
import { NotificationStore } from 'pcs-commons/notification';
import { Message, Notification } from 'pcs-commons/datatypes';
import { ChangeRequestAction } from '../datatypes/audit-flow/change-request-action';
import { CDRService } from './http/cdr.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeRequestDetailsService {
  constructor(
    private translateService: TranslateService,
    private chargepointService: ChargepointService,
    private cdrService: CDRService,
    private changeRequestService: ChangeRequestService,
    private changeRequestSharedDataService: ChangeRequestSharedDataService
  ) {}

  public getInitiator(changeRequest: ChangeRequestDto): ChangeRequestSubscriberDto {
    return changeRequest?.subscribers?.find((subscriber) => subscriber.type === ChangeRequestSubscriberType.INITIATOR);
  }

  public adaptMessages(changeRequest: ChangeRequestDto): ChangeRequestMessageDto[] {
    const messages = [...changeRequest.messages];
    const userId = this.getInitiator(changeRequest)?.userId;
    const login = this.getInitiator(changeRequest)?.login;
    const initiatedComment = this.translateService.instant('changeRequest.history.initiated');
    const executedComment = this.translateService.instant('changeRequest.history.done');
    const initialMsg = this.createMsg(changeRequest?.created, userId, login, initiatedComment);
    let newMessages = [];
    newMessages.push(initialMsg);
    newMessages = newMessages.concat(messages);
    if (changeRequest.status === ChangeRequestStatus.DONE) {
      const doneMsg = this.createMsg(changeRequest?.updated, '', '', executedComment);
      newMessages = newMessages.concat(doneMsg);
    }
    return newMessages;
  }

  private createMsg(created: string, senderUserId: string, senderLogin: string, comment: string): ChangeRequestMessageDto {
    const message = new ChangeRequestMessageDto();
    message.created = created;
    message.messageType = ChangeRequestMessageType.COMMENT;
    message.senderUserId = senderUserId;
    message.senderLogin = senderLogin;
    message.comment = comment;
    return message;
  }

  public getAllowedMessageTypesForUser(changeRequest: ChangeRequestDto, currentUserId: string): ChangeRequestMessageType[] {
    const msgTypeList: ChangeRequestMessageType[] = [];
    if (changeRequest.status === ChangeRequestStatus.CANCELED || changeRequest.status === ChangeRequestStatus.DONE) {
      return msgTypeList;
    }

    msgTypeList.push(ChangeRequestMessageType.COMMENT);
    if (changeRequest.status === ChangeRequestStatus.ACCEPTED) {
      return msgTypeList;
    }

    if (this.getInitiator(changeRequest)?.userId === currentUserId) {
      msgTypeList.push(ChangeRequestMessageType.RESTART);
      msgTypeList.push(ChangeRequestMessageType.CLOSE);
      return msgTypeList;
    }

    msgTypeList.push(ChangeRequestMessageType.APPROVAL);
    msgTypeList.push(ChangeRequestMessageType.REJECTION);
    return msgTypeList;
  }

  public async getChangeRequestAndAdditionalData(changeRequestId: string): Promise<void> {
    const changeRequest = await firstValueFrom(this.changeRequestService.findChangeRequestById(changeRequestId));
    await this.handleChangeRequestAnswer(changeRequest, changeRequestId);
  }

  public async getMyChangeRequestAndAdditionalData(changeRequestId: string): Promise<void> {
    const changeRequest = await firstValueFrom(this.changeRequestService.findMyChangeRequestById(changeRequestId));
    await this.handleChangeRequestAnswer(changeRequest, changeRequestId);
  }

  private async handleChangeRequestAnswer(changeRequest: ChangeRequestDto, changeRequestId: string): Promise<void> {
    if (!changeRequest) {
      console.log('No change request with id found: ', changeRequestId);
      NotificationStore.instance.notify(Notification.error(new Message('changeRequest.notFound')));
      return;
    }

    changeRequest.messages = this.adaptMessages(changeRequest);
    this.changeRequestSharedDataService.updateChangeRequestData(changeRequest);
    if (changeRequest?.payloadObjectType === ChangeRequestPayloadType.CPO) {
      const operator = await firstValueFrom(this.chargepointService.getOperator(changeRequest.payloadObjectId));
      this.changeRequestSharedDataService.updateCurrentCpo(operator);
    }
    if (changeRequest?.action === ChangeRequestAction.REMOVE_CDR_FROM_BILLRUN) {
      const cdr = await firstValueFrom(this.cdrService.getCdr(changeRequest.payloadObjectId));
      this.changeRequestSharedDataService.updateCurrentCdr(cdr);
    }
  }
}
