/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {CDRService} from '../../services/http/cdr.service';
import {BehaviorSubject} from 'rxjs';
import {CDRClearing, CDRClearingColumns} from '../../datatypes/cdrclearing';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {PaginationHandlerComponent} from '../../pagination-handler.component';
import {PageEvent} from '@angular/material/paginator';
import {Parameters} from '../../global/parameters';
import {MatDialog} from '@angular/material/dialog';
import {CdrClearingEntryDialogComponent} from '../cdr-clearing-entry-dialog/cdr-clearing-entry-dialog.component';
import {Message} from '../../datatypes/message';
import {SelectConfig} from 'pcs-commons/datatypes';
import {Platform} from '../../datatypes/platform.enum';

@Component({
  selector: 'cdr-clearing-pool',
  templateUrl: './cdr-clearing-pool.component.html',
  styleUrls: ['./cdr-clearing-pool.component.css']
})
export class CdrClearingPoolComponent extends PaginationHandlerComponent implements OnInit, PermissionAware {
  public readonly reqEditPermission: AccessRights[] = [AccessRights.CDR_EDIT_WEB];
  public readonly translateColumns = [CDRClearingColumns.CLEARABLE];
  public readonly nonSearchableColumns = [CDRClearingColumns.CLEARABLE];
  public selectColumnConfigs = new Map<string, SelectConfig>();

  public pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS_MEDIUM;
  public loading = false;
  public rejectedCdrsSource = new BehaviorSubject<CDRClearing[]>([]);
  public rejectedCdrs$ = this.rejectedCdrsSource.asObservable();

  public rejectedCdrsColumns: string[] = Object.values(CDRClearingColumns);
  public currFilter = null;

  constructor(
    private dialog: MatDialog,
    private cdrService: CDRService) {
    super();
    const selectConf = new SelectConfig();
    selectConf.options = Object.values(Platform);
    this.selectColumnConfigs.set(CDRClearingColumns.PLATFORM, selectConf);
  }

  public ngOnInit(): void {
    this.getRejectedCdrs(0, this.pageSizeOptions[0]);
  }

  public handleEmptyResult(): void {
  }

  public handlePageChange(pageEvent: PageEvent): void {
    console.log('handling page event: ', pageEvent);
    this.getRejectedCdrs(pageEvent.pageIndex, pageEvent.pageSize);
  }

  private getRejectedCdrs(pageIndex: number, pageSize: number): void {
    console.log(`Fetching rejected cdrs: page ${pageIndex}, pageSize: ${pageSize}`);
    this.loading = true;
    const offset = pageIndex * pageSize;
    this.cdrService.getCdrClearingEntries(offset, pageSize + 1, this.currFilter).subscribe({
      next: (result) => {
        this.loading = false;
        this.handleNewResults(pageIndex, pageSize, result, this.rejectedCdrsSource);
      },
      error: () => this.loading = false
    });

  }

  public showClearingEntry(toShow: CDRClearing): void {
    this.loading = true;
    console.log('Fetching details for clearing entry: ', toShow);
    this.cdrService.getCdrClearingDetails(toShow.id).subscribe({
      next: (entryDetails) => {
        this.loading = false;
        console.log('clearing entry details: ', entryDetails);
        this.showCDRClearingDetails(toShow, entryDetails);

      },
      error: () => this.loading = false
    });
  }

  private showCDRClearingDetails(toShow: CDRClearing, entryDetails: string): void {
    const dialogRef = this.dialog.open(CdrClearingEntryDialogComponent,
      {
        width: '750px',
        data: {
          id: toShow.id,
          clearable: toShow.clearable,
          clearingEntry: entryDetails
        }
      });

    dialogRef.afterClosed().subscribe((success) => {
      if (success) {
        this.showInfo(new Message('cdrClearing.retryClearing.success'));
        this.getRejectedCdrs(this.paginator.pageIndex, this.paginator.pageSize);
      }
    });
  }

  public onFilterChange(filter: any): void {
    this.currFilter = filter;
    this.paginator.pageIndex = 0;
    this.getRejectedCdrs(this.paginator.pageIndex, this.paginator.pageSize);
  }
}
