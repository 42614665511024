<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-form-field class="column-filter-form column-filter-select-form">
  <mat-select [formControl]="selectControl"
              [value]="defaultOption"
              class="font-14">
    <mat-option *ngIf="withEmptyOption" [value]="" class="font-14"></mat-option>
    <div *ngIf="options">
      <mat-option *ngFor="let option of options" [value]="option" class="font-14">
        {{translationKey ? ((translationKey + option)| translate | conditionalUppercase:withUppercaseOptions) : option}}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
