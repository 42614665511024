/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {ChangeRequestStatus} from "./change-request-status";
import {ChangeRequestTaskStatus} from "./change-request-task-status";
import {ChangeRequestPayloadType} from "./change-request-payload-type";
import {Identifiable} from "pcs-commons/datatypes";

export class ChangeRequestOverviewDto extends Identifiable {
  public payloadObjectType: ChangeRequestPayloadType;
  public payloadObjectId: number;
  public status: ChangeRequestStatus;
  public title: string;
  public taskStatus: ChangeRequestTaskStatus;
  public created: string;
  public updated: string;
  public hasUnreadChanges: boolean;
}

export enum ChangeRequestDtoColumns {
  UNREAD_CHANGES = 'unreadChanges',
  TITLE = 'title',
  UPDATE = 'updated',
}

export enum ChangeRequestsHistoryColumns {
  TITLE = 'title',
  UPDATE = 'updated',
  TYPE = 'payloadObjectType',
  STATUS = 'status'
}
