<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <div class="contentDiv">
    <mat-icon class="warningIcon"> notification_important </mat-icon>
    <div class="warningText">{{message.message | translate:message}}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button id="deleteBtn" class="dialogueButton" [mat-dialog-close]="true">{{'button.delete' | translate}}</button>
  <button mat-stroked-button id="cancelBtn" class="dialogueButton" [mat-dialog-close]="false">{{'button.cancel' | translate}}</button>
</mat-dialog-actions>