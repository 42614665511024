<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="fleetId">
  <mat-toolbar class="actionsToolbar">
    <span class="headerSpan">{{'fleet.actions' | translate }}</span>
  </mat-toolbar>
  <div class="buttonDiv">
    <button mat-raised-button class="actionButton" (click)="toIdCardsView()">
      {{'fleet.actions.cards'| translate}}
    </button>
    <button mat-raised-button class="actionButton" (click)="onSelectInvoices()">
      {{'fleet.actions.invoices'| translate}}
    </button>
    <button mat-raised-button class="actionButton" *ngIf="!fleetInactive" (click)="onDeactivate()">
      {{'fleet.actions.deactivate'| translate}}
    </button>
    <button mat-raised-button class="actionButton" *ngIf="fleetInactive" (click)="onActivate()">
      {{'fleet.actions.activate'| translate}}
    </button>
    <button mat-raised-button class="actionButton" (click)="onDelete()" [disabled]="!fleetInactive">
      {{'fleet.actions.delete'| translate}}
    </button>
  </div>
</div>
