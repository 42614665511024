/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Input, OnDestroy, Renderer2 } from '@angular/core';
import { TableBaseComponent } from '../table-base/table-base.component';
import { AttributeRow } from './attribute-row';
import { Parameters } from '../../global/parameters';
import { AttributeTableAction } from '../../datatypes/attribute-table-action.enum';
import { FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { IopPlatformDto } from '../../datatypes/iop-platform-dto';
import { CountryCode } from 'pcs-commons/datatypes';
import { ChargepointVisibility } from '../../datatypes/chargepoint-visibility.enum';
import { AttributeValueDto } from '../../datatypes/location/attribute-value-dto';
import { Utils } from '../../utils/utils';
import { LocationDtoAttributeName } from '../../datatypes/location/location-dto-attribute-name.enum';
import { ChargepointDtoAttributeName } from '../../datatypes/location/chargepoint/chargepoint-attribute-name.enum';
import { InputValidationService } from '../../services/input-validation.service';
import { Observable, Subscription } from 'rxjs';
import { ChargepointValidatorList } from '../../validation/chargepoint-validator-list';
import { AttributeTranslationHelperService } from '../../services/attribute-translation-helper.service';
import { TranslationHelperService } from 'pcs-commons/http';

@Component({
  selector: 'pcs-attribute-table',
  template: ''
})
export class AttributeTableBaseComponent extends TableBaseComponent implements OnDestroy {
  // read only
  public static readonly multipleValueColumns: string[] = [
    ChargepointDtoAttributeName.AUTH_MODES,
    ChargepointDtoAttributeName.PLUGS
  ];
  public readonly SEPARATOR = ';';
  public readonly attributeNameColumn = AttributeRow.ATTRIBUTE_NAME;
  public readonly manualDataColumn = Parameters.MANUAL_DATA_PLATFORM;

  @Input() public sortedPlatformList: IopPlatformDto[];

  public commentRow: AttributeRow;
  public updatedByRow: AttributeRow;
  public lastUpdateDateRow: AttributeRow;

  public platformsIncludedInData: string[] = [];
  public dataColumns: string[] = [];
  public i18nBase: string;
  public currentCommand: AttributeTableAction = undefined;
  public manualDataFromGroup: UntypedFormGroup;
  public countryCodeChoiceList: string[] = [];
  public visibilityChoiceList: string[] = [];
  public toUnsubscribe: Subscription[] = [];

  constructor(
    public renderer: Renderer2,
    public inputValidationService: InputValidationService,
    public attrTranslateHelper: AttributeTranslationHelperService,
    public translateService: TranslationHelperService,
    public formBuilder: FormBuilder
  ) {
    super(renderer);
    this.manualDataFromGroup = this.formBuilder.group({});
    this.manualDataFromGroup.disable();
    this.countryCodeChoiceList = Object.values(CountryCode);
    this.visibilityChoiceList = Object.values(ChargepointVisibility);
    // add empty entry at the start so that the user can remove the manual visibilityAttribute
    this.visibilityChoiceList.unshift('');
    this.i18nBase = 'chargepoint.tableHeader';
  }

  protected get inEditMode(): boolean {
    return this.currentCommand && this.currentCommand === AttributeTableAction.ENABLE_EDIT;
  }

  public static toFormControlCompatible(value: string, attributeName: string): string | string[] {
    if (this.multipleValueColumns.includes(attributeName)) {
      return value.split(';');
    }
    return value;
  }

  public resetDataColumns(attributeValueMap: Map<string, Map<string, AttributeValueDto>>, addManualDataColumn: boolean): void {
    this.dataColumns = [];
    this.dataColumns.push(this.attributeNameColumn);
    this.platformsIncludedInData = this.getPlatformsSortedByDataPriority(attributeValueMap);
    if (addManualDataColumn && !this.platformsIncludedInData.includes(this.manualDataColumn)) {
      this.dataColumns.push(this.manualDataColumn);
    }
    this.dataColumns.push(...this.platformsIncludedInData);
  }

  public getCellData(col: string, element: AttributeRow): string | null {
    if (!element) {
      return;
    }
    if (col === this.attributeNameColumn) {
      return this.translateService.instant('chargepoint.attribute.' + element[col]);
    }
    const value = this.getCellValue(element, col);
    return value && this.attributeTranslatable(element.name) ? this.getTranslation(element.name, value) : value;
  }

  public getCellValue(element: AttributeRow, col: string): string | null {
    // since typescript is dumb and does not properly convert http responses to the expected object,
    // using map functions does not work.
    return element.values[col] ? element.values[col].value : null;
  }

  public getManualDataForAttribute(element: AttributeRow): string | string[] {
    if (element.values[this.manualDataColumn]) {
      if (element.name === LocationDtoAttributeName.COUNTRY_CODE) {
        const value: string = CountryCode[element.values[this.manualDataColumn].value];
        return AttributeTableBaseComponent.toFormControlCompatible(value, element.name);
      }
      return AttributeTableBaseComponent.toFormControlCompatible(element.values[this.manualDataColumn].value, element.name);
    }
    return '';
  }

  public elementOnEdit(element: AttributeRow, column: string): boolean {
    return this.inEditMode && this.manualDataColumn === column && this.attributeEditable(element);
  }

  // must be overridden by the actual component classes
  public attributeEditable(element: AttributeRow): boolean {
    return false;
  }

  public getTranslation(attributeName: string, value: string): string {
    if (!value) {
      return '';
    }

    if (
      LocationDtoAttributeName.ADMIN_LAST_UPDATED_DATE === attributeName ||
      ChargepointDtoAttributeName.ADMIN_LAST_UPDATED_DATE === attributeName ||
      ChargepointDtoAttributeName.LAST_CHARGED === attributeName
    ) {
      return this.translateService.translateDateSafe(value);
    }

    if (AttributeTableBaseComponent.multipleValueColumns.includes(attributeName)) {
      return this.handleMultipleValueTranslation(attributeName, value.split(this.SEPARATOR));
    }
    return this.attrTranslateHelper.getInstantTranslationForAttribute(attributeName, value);
  }

  public getError(fc: UntypedFormControl): Observable<any> | null {
    return this.inputValidationService.getError(fc);
  }

  public determineColumnClass(col: string): string {
    return col === this.attributeNameColumn
      ? 'attribute-name-column'
      : col === this.manualDataColumn && this.inEditMode
        ? 'manual-data-column'
        : 'default-attribute-table-column';
  }

  public validateInput(event: KeyboardEvent, attributeName: string): void {
    if (LocationDtoAttributeName.HOTLINE === attributeName) {
      this.inputValidationService.allowPhoneNoOnlyChar(event);
    }
    if (
      LocationDtoAttributeName.LATITUDE === attributeName ||
      LocationDtoAttributeName.LONGITUDE === attributeName ||
      LocationDtoAttributeName.ACCESS_LATITUDE === attributeName ||
      LocationDtoAttributeName.ACCESS_LONGITUDE === attributeName ||
      ChargepointDtoAttributeName.MAX_POWER === attributeName
    ) {
      this.inputValidationService.allowDoubleChars(event);
    }
  }

  public extractRowsNotShownInTable(allAttributeRows: AttributeRow[], rowName: string): AttributeRow {
    const result = allAttributeRows.find((row) => row.name === rowName);
    if (result) {
      Utils.removeArrayItem(allAttributeRows, result);
    }
    return result;
  }

  public getFromControl(element: AttributeRow): FormControl<string | string[]> {
    if (!element) {
      return;
    }
    if (this.manualDataFromGroup.contains(element.name)) {
      return this.manualDataFromGroup.controls[element.name] as FormControl<string | string[]>;
    }

    let validator: ValidatorFn[];
    if (element.name === LocationDtoAttributeName.COUNTRY_CODE) {
      validator = ChargepointValidatorList.COUNTRY_CODE;
    } else {
      validator = ChargepointValidatorList.DEFAULT;
    }

    // for comment, we deactivate it by default as it will always be shown
    const fc = new FormControl<string | string[]>(
      {
        value: this.getManualDataForAttribute(element),
        disabled: element.name === this.commentRow.name
      },
      validator
    );
    this.manualDataFromGroup.addControl(element.name, fc);
    return fc;
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  private getPlatformsSortedByDataPriority(attributeValueMap: Map<string, Map<string, AttributeValueDto>>): string[] {
    console.log('attributes: ', attributeValueMap);
    const unsortedPlatforms: string[] = Utils.findIncludedPlatforms(attributeValueMap);
    if (unsortedPlatforms.length <= 1) {
      return unsortedPlatforms;
    }
    return Utils.sortByReferencePlatformArray(unsortedPlatforms, this.sortedPlatformList);
  }

  private attributeTranslatable(attribute: string): boolean {
    return this.attrTranslateHelper.ATTRIBUTE_BASE.has(attribute);
  }

  private handleMultipleValueTranslation(attributeName: string, values: string[]): string {
    const translatedValues: string[] = [];
    values.forEach((val) =>
      translatedValues.push(this.attrTranslateHelper.getInstantTranslationForAttribute(attributeName, val))
    );
    return translatedValues.join(', ');
  }
}
