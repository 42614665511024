/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {CDRBillingDto} from '../../datatypes/CDRBillingDto';
import {FinanceEnablerStatus} from '../../datatypes/finance-enabler-status';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'cdrs-for-revocation-table',
  templateUrl: './cdrs-for-revocation-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css', '../action-column-style.css', './cdrs-for-revocation-table.component.css']
})
export class CdrsForRevocationTableComponent extends OptimizedTableBaseComponent {

  public update: Date = new Date();

  constructor(renderer: Renderer2,
              private translate: TranslateService) {
    super(renderer);
    /* istanbul ignore next */
    this.translate.onLangChange.subscribe(() => this.update = new Date());
  }

  public revocationPossible(element: CDRBillingDto): boolean {
    return element.financeEnablerStatus === FinanceEnablerStatus.SENT || element.financeEnablerStatus === FinanceEnablerStatus.INITIAL
      || element.financeEnablerStatus === FinanceEnablerStatus.SENT_FAIL;
  }
}
