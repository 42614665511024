<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div id="cdrs-for-revocation" >

  <mat-toolbar class="headerToolbar mat-elevation-z1">
    <span class="headerSpan">{{'cdrs-for-revocation.list' | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>


  <div class="section-container">
    <pcs-cdrs-for-revocation-filter editKeyEvents (filterEvent)="onSearchCdrsForRevocation($event)"></pcs-cdrs-for-revocation-filter>
  </div>

  <div class="section-container" *ngIf="resultLength > 0" id="cdrs-for-revocation-table-area">
    <mat-accordion>
      <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
        <cdrs-for-revocation-table
          [dataColumns]="dataColumns"
          [i18nBase]="'cdrs-for-revocation.tableHeader'"
          [data]="cdrs$"
          [actionButtons]="reqEditPermission |accessAllowed"
          (deleteEvent)="openRevocationRequestDialog($event)">
        </cdrs-for-revocation-table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"
                       (page)="handlePageChange($event)" [length]="resultLength">
        </mat-paginator>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
