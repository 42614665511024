<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data">
  <mat-table #table [dataSource]="tableDataSource">
    <div *ngFor="let col of dataColumns; let columnIndex = index;">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <div *ngIf="nonSearchableColumns && nonSearchableColumns.includes(col)">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>
            {{getI18nBase(col) | translate}}
          </mat-header-cell>
        </div>
        <div *ngIf="!nonSearchableColumns || !nonSearchableColumns.includes(col)">
          <mat-header-cell [styledColumn]="col" class="filter-column-header-cell" *matHeaderCellDef>
            {{getI18nBase(col) | translate}}
            <mat-form-field *ngIf="!selectColumns.includes(col)" class="column-filter-form">
              <input matInput (keyup)="handleFilterInputs($event, col)">
            </mat-form-field>
            <mat-form-field *ngIf="selectColumns.includes(col)" class="column-filter-form column-filter-select-form">
              <mat-select (selectionChange)="handleFilterSelectChange($event, col)"
                          [value]="getDefaultOption(col)"
                          class="font-14">
                <mat-option [value]="" class="font-14"></mat-option>
                <div *ngIf="selectColumnConfigs.get(col) as selectProps">
                  <mat-option *ngFor="let option of selectProps.options" [value]="option" class="font-14">
                    {{selectProps.translationKey ? ((selectProps.translationKey + option)| translate) : option}}
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </mat-header-cell>
        </div>

        <mat-cell [styledColumn]="col" *matCellDef="let element ">
          <div *ngIf="translateColumns && translateColumns.includes(col)">
            {{(element[col]| translateColumn: col:update)}}
          </div>
          <div *ngIf="dateColumns && dateColumns.includes(col)">
            {{translateDate(element[col])}}
          </div>
          <div
            *ngIf="(!dateColumns || !dateColumns.includes(col)) && (!translateColumns || !translateColumns.includes(col))">
            {{element[col]}}
          </div>
        </mat-cell>
      </ng-container>
    </div>
    <ng-container matColumnDef="tableAction">
      <mat-header-cell *matHeaderCellDef class="actionCell"> {{'fleet.tableheader.action' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="actionCell">
        <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                (mouseleave)="changeStyle($event)" (click)="editElement(element)" *ngIf="!readonly && !disableEdit">
          <mat-icon> edit</mat-icon>
        </button>
        <button mat-mini-fab class="delete mat-elevation-z0" (mouseenter)="changeStyle($event)"
                (mouseleave)="changeStyle($event)" (click)="deleteElement(element)" *ngIf="!readonly && !disableDelete">
          <mat-icon> delete_outline</mat-icon>
        </button>
        <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                (mouseleave)="changeStyle($event)" (click)="readElement(element)" *ngIf="readonly">
          <svg-icon src="/assets/images/ic_detail2.svg"></svg-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
