/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {DateRangeValidatorService} from '../../validation/date-range-validator.service';
import {CurrencyFactorService} from '../../services/http/currency-factor.service';
import {CurrencyFactorsDialog} from '../currency-factors-dialog';
import {TranslationHelperService} from 'pcs-commons/http';
import {ValidationMessagePipe} from 'pcs-commons/validation';

@Component({
  selector: 'pcs-add-currency-factor',
  templateUrl: './add-currency-factor.component.html',
  styleUrls: ['../../dialog/dialog-common-styles.css', './add-currency-factor.component.css']
})
export class AddCurrencyFactorComponent extends CurrencyFactorsDialog {

  public currencies: string[];

  public currencyControl: FormControl;

  constructor(formBuilder: FormBuilder,
              currencyFactorService: CurrencyFactorService,
              dialogRef: MatDialogRef<AddCurrencyFactorComponent>,
              validationPipe: ValidationMessagePipe,
              @Inject(MAT_DIALOG_DATA) data: any,
              dateRangeValidator: DateRangeValidatorService,
              translationHelper: TranslationHelperService) {
    super(formBuilder, validationPipe, dateRangeValidator, translationHelper,
      dialogRef, currencyFactorService);
    this.currencies = data.currencies;
    this.currencyControl = new FormControl(null, Validators.required);
  }

  protected defineCurrencyFactorsForm(): void {
    super.defineCurrencyFactorsForm();
    this.editCurrencyFactorForm.addControl('currencyControl', this.currencyControl);
  }

  public onCurrencySelectionChanged($event: MatSelectChange): void {
    this.loading = true;
    this.currency = $event.value;
    this.currencyFactorService.retrieveCurrencyFactors(this.currency, 0, '').subscribe({
      next: (currencyFactors) => {
        this.loading = false;
        this.currencyFactors = currencyFactors;
        if (this.currencyFactors.length > 0) {
          this.setValidUntilInitialEditable();
          this.defineCurrencyFactorsForm();
        } else {
          this.addRecord();
        }
      },
      error: () => this.loading = false
    });
  }
}
