/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {AccessRights} from "../../datatypes/access-rights.enum";
import {CodeModel} from "@ngstack/code-editor";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PermissionAware} from "../../permission-aware";
import {OrderItemDto} from "../../datatypes/invoices/OrderItemDto";

@Component({
  selector: 'pcs-invoice-order-item-update-dialog',
  templateUrl: './invoice-order-item-update-dialog.component.html',
  styleUrls: [
    '../../dialog/dialog-common-styles.css', './invoice-order-item-update-dialog.component.css']
})
export class InvoiceOrderItemUpdateDialogComponent implements PermissionAware {
  public readonly reqEditPermission = [AccessRights.INVOICE_EDIT_WEB];
  public orderItem = '';
  public loading = false;
  public invalidJson = false;
  public clearable = false;
  public ignoreChargeValidation = false;

  private editorCodeModelUri = 'order-item-edit.ts';
  private editorCodeModelLanguage = 'json';
  public codeModel: CodeModel = null;
  public itemId: string;

  constructor(
    private dialogRef: MatDialogRef<InvoiceOrderItemUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('orderItem details: ', data.orderItem);
    this.orderItem = JSON.stringify(data.orderItem, null, '\t');
    this.itemId = data.orderItem.item_id;
    this.codeModel = {
      language: this.editorCodeModelLanguage,
      uri: this.editorCodeModelUri,
      value: this.orderItem
    }
    this.clearable = data.clearable;
  }

  public verifyJson(): void {
    try {
      JSON.parse(this.orderItem);
      this.invalidJson = false;
    } catch (e) {
      console.log(e);
      this.invalidJson = true;
    }
  }

  public saveOrderItemChanges(): void {
    console.log('returning updated orderItem: ', this.orderItem);
    const updatedOrderItem: OrderItemDto = JSON.parse(this.orderItem);
    this.dialogRef.close({update: true, orderItem: updatedOrderItem, itemId: this.itemId});
  }

  public onEditorValueChange(newValue: string): void {
    this.orderItem = newValue;
    this.verifyJson();
  }

  public closeDialog(): void {
    this.dialogRef.close({update: false});
  }
}
