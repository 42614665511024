/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component} from '@angular/core';
import {TokenResponse} from "pcs-commons/datatypes";
import {AuthenticationService} from "../auth/authentication.service";

@Component({
  selector: 'pcs-local-login-page',
  templateUrl: './local-login-page.component.html',
  styleUrls: ['./local-login-page.component.css']
})
export class LocalLoginPageComponent {

  constructor(
    private authService: AuthenticationService) {
  }

  public onTokenReceive(tokenResponse: TokenResponse): void {
    const accessToken = tokenResponse.accessToken;
    console.log('access_token received: ', !!accessToken);
    this.authService.fetchUserAuthDetailsAndNavigateToHomePage(accessToken ? accessToken as string : null);
  }

}
