/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewInit, Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FocusMonitor} from '@angular/cdk/a11y';
import {AssociateTotpDialogComponent} from '../associate-totp/associate-totp-dialog.component';
import {UserDataSourceService} from '../../services/user-data-source.service';
import {Utils} from '../../utils/utils';

@Component({
  selector: 'app-confirm-totp-dialog',
  templateUrl: './confirm-totp-dialog.component.html',
  styleUrls: [
    '../confirmation-dialog/confirmation-dialog.component.css',
    './confirm-totp-dialog.component.css']
})
export class ConfirmTotpDialogComponent implements AfterViewInit {
  public message: string;
  public associationRequired = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmTotpDialogComponent>,
    private user: UserDataSourceService,
    private focusMonitor: FocusMonitor,
    private dialog: MatDialog) {
    if (Utils.arrayEmpty(this.user.getMFADetails().userMFASettingList)) {
      this.associationRequired = true;
    }
    this.message = this.associationRequired ? 'mfa.prefer.SOFTWARE_TOKEN_MFA.confirm.mustAssociate' : 'mfa.prefer.SOFTWARE_TOKEN_MFA.confirm';
  }

  public ngAfterViewInit(): void {
    this.focusMonitor.stopMonitoring(document.getElementById('actionBtn'));
    this.focusMonitor.stopMonitoring(document.getElementById('cancelBtn'));
  }

  public toAssociateTotp(): void {
    this.dialogRef.close();
    this.dialog.open(AssociateTotpDialogComponent, {
      width: '700px'
    });
  }
}
