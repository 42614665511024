/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {TranslationHelperService} from 'pcs-commons/http';
import {PurchaseConditionOverviewElement} from '../../datatypes/purchase-conditions/purchase-condition-overview-element';
import {PurchaseConditionStatus} from '../../datatypes/purchase-conditions/purchase-condition-status.enum';
import {DateUtils} from 'pcs-commons/utils';
import {DateTime} from "luxon";

@Component({
  selector: 'purchase-conditions-table',
  templateUrl: './purchase-conditions-table.component.html',
  styleUrls: [
    '../scrollable-table/scrollable-table.component.css',
    '../action-column-style.css',
    './purchase-conditions-table.component.css']
})
export class PurchaseConditionsTableComponent extends OptimizedTableBaseComponent {

  @Output() public templateEvent = new EventEmitter<PurchaseConditionOverviewElement>();
  @Input() public disableTableBtns: boolean;
  public currentMoment: DateTime;

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
    this.currentMoment = DateTime.now().startOf('day');
  }

  public getDateTranslation(value: string): string {
    return this.translateHelper.translateDateSafe(value);
  }

  public getRowClass(row: PurchaseConditionOverviewElement): string {
    if (!row) {
      return 'normal-row';
    }
    if (PurchaseConditionStatus.ACTIVE === row.status && this.isCurrentlyActive(row)) {
      return 'currently-active-row';
    } else if (PurchaseConditionStatus.ACTIVE === row.status && DateUtils.dateIsInFuture(row.validFrom)) {
      return 'normal-row';
    } else if (PurchaseConditionStatus.INACTIVE === row.status) {
      return 'inactive-row';
    }
    return 'obsolete-row';
  }

  public editAllowed(element: PurchaseConditionOverviewElement): boolean {
    return PurchaseConditionStatus.DELETED !== element.status;
  }

  public detailsAllowed(element: PurchaseConditionOverviewElement): boolean {
    return PurchaseConditionStatus.DELETED === element.status;
  }

  public onUseAsTemplate(element: PurchaseConditionOverviewElement): void {
    this.templateEvent.emit(element);
  }

  public isCurrentlyActive(row: PurchaseConditionOverviewElement): boolean {
    if (PurchaseConditionStatus.ACTIVE !== row.status) {
      return false;
    }

    const validFrom = DateUtils.convertToDateTimeWithUTC(row.validFrom);
    if (!row.validUntil) {
      return this.currentMoment.startOf("day") >= validFrom.startOf('day');
    }
    const validUntil = DateUtils.convertToDateTimeWithUTC(row.validUntil);

    return validFrom <= this.currentMoment && this.currentMoment <= validUntil;
  }

  public deleteAllowed(element: PurchaseConditionOverviewElement): boolean {
    return PurchaseConditionStatus.INACTIVE === element.status;
  }
}
