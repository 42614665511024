/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {FormGroup, ValidationErrors, Validator} from '@angular/forms';
import {ValidationType} from './validation-type.enum';
import {FormUtils} from 'pcs-commons/utils';

@Injectable({
  providedIn: 'root'
})
export class PasswordMatchValidatorService implements Validator {

  public validate(group: FormGroup): ValidationErrors | null {
    const newPassword = FormUtils.getStringValue(group, 'newPassword');
    const confirmNewPassword = FormUtils.getStringValue(group, 'confirmNewPassword');
    if (!newPassword || !confirmNewPassword) {
      return null; // password fields should be mandatory, this error should not appear if the are not present
    }
    const valError = confirmNewPassword === newPassword ? null : {[ValidationType.PASSWORD_MISMATCH]: true};
    console.log('validation result:', valError);
    return valError;
  }
}
