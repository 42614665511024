/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {TranslationHelperService} from 'pcs-commons/http';
import {MatFormFieldAppearance} from '@angular/material/form-field';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'autocomplete-string-select',
  templateUrl: './autocomplete-string-select.component.html',
  styleUrls: ['./autocomplete-string-select.component.css']
})
export class AutocompleteStringSelectComponent implements OnInit {
  @Input() public appearance: MatFormFieldAppearance | undefined;
  @Input() public label: string;
  @Input() public optionI18nBase: string;
  @Input() public inputControl: FormControl;
  @Input() public optionList: string[];
  @Input() public required: boolean;
  @Input() public fullWidth = false;
  public filteredOptions: Observable<string[]> = of([]);

  constructor(private translate: TranslateService,
              private translationHelperService: TranslationHelperService) {
  }

  public ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      const valueBefore = this.inputControl.value;
      this.inputControl.setValue('');
      this.sortSelectionArray();
      this.inputControl.setValue(valueBefore);
    });

    this.sortSelectionArray();
    this.filteredOptions = this.inputControl.valueChanges.pipe(
      startWith(this.inputControl.value ? String(this.inputControl.value) : ''),
      map((value) => {
        console.log('filtering for value: ', value);
        return value ? this._filter(value) : this.optionList;
      })
    );
  }

  private sortSelectionArray(): void {
    this.translationHelperService.sortArrayByTranslation(this.optionList, this.optionI18nBase + 'sort.', this.optionI18nBase);
  }

  private _filter(value: unknown): string[] {
    const filterValue = String(value).toLowerCase();
    return this.optionList.filter((option) => this.getDisplayText(option).toLowerCase().startsWith(filterValue));
  }

  public getDisplayText(option: string): string {
    if (!option) {
      return '';
    }
    return this.optionI18nBase ? this.translate.instant(this.optionI18nBase + option) : option;
  }

}
