/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'translatePowerClasses'
})
export class TranslatePowerClassesPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: string[]): string {
    if (!value || value.length <= 0) {
      return null;
    }
    const translatedPClasses = [];
    value.forEach(pClass => translatedPClasses.push(this.translateService.instant('global.chargeClass.' + pClass.toUpperCase())));
    return translatedPClasses.join(', ');
  }

}
