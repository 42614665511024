/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {UserDataSourceService} from '../services/user-data-source.service';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../auth/authentication.service';
import {LoginService} from 'pcs-commons/http';
import {DateAdapter} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../services/http/user.service';
import {MFARequestHandler} from './mfahandler.abstract';
import {PasswordChangeDialogComponent} from '../dialog/password-change-dialog/password-change-dialog.component';
import {environment} from "../../environments/environment";
import {DateTime} from "luxon";

@Component({
  selector: 'pcs-language-and-user-bar',
  templateUrl: './language-and-user-bar.component.html',
  styleUrls: ['./language-and-user-bar.component.css']
})
export class LanguageAndUserBarComponent extends MFARequestHandler implements OnInit {

  public languageCtrl: FormControl;
  public currentLogin: string | undefined = undefined;
  private cloudWatchUrl = environment.cloudWatchUrl;

  public get isLoggedIn(): boolean {
    return !!this.currentLogin;
  }


  constructor(
    dialog: MatDialog,
    userService: UserService,
    user: UserDataSourceService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private dateAdapter: DateAdapter<DateTime>,
    private loginService: LoginService
  ) {
    super(dialog, user, userService);
  }

  public ngOnInit(): void {
    this.preferredMFA = new FormControl();
    this.user.currentUserAuthDetails$.subscribe(
      currUser => {
        if (currUser) {
          console.log('updating user login for language and user bar');
          this.currentLogin = currUser.login;
        }
      });
    this.user.mfaDetails$.subscribe(mfaDetailsNext => {
      if (mfaDetailsNext) {
        console.log('updating mfa settings for language and user bar');
        this.preferredMFA.setValue(mfaDetailsNext.preferredMfaSetting);
      }
    });
    this.initLanguageConfigurations();
  }

  public logout(): void {
    console.log('logging out');
    this.authenticationService.clearUserAuthDetails();
    this.loginService.logout();
    this.currentLogin = undefined;
    console.log('should be logged out');
  }

  private initLanguageConfigurations(): void {
    const userLanguage = navigator.language.substring(0, 2);
    console.log(userLanguage);
    this.translate.setDefaultLang(userLanguage);
    this.dateAdapter.setLocale(userLanguage);
    this.translate.use(userLanguage);
    this.translate.get('global.title').subscribe(
      value => {
        // value is our translated string
        console.log('Title: ' + value);
      }
    );
    this.languageCtrl = new FormControl(userLanguage);
    this.languageCtrl.valueChanges.subscribe(newLanguage => {
      console.log('handling language change to: ', newLanguage);
      this.switchLanguage(newLanguage);
    });
  }

  public switchLanguage(language: string): void {
    console.log(language);
    this.translate.use(language);
    this.dateAdapter.setLocale(language);
  }

  public getMFAMenuClass(): string {
    return this.languageCtrl.value === 'de' ? 'mfa-menu' : '';
  }

  public openPasswordChangeDialog(): void {
    console.log('opening password change dialog...');
    const dialogRef = this.dialog.open(PasswordChangeDialogComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(value => console.log('password change dialog is closing with info: ', value));
  }

  public onCloudWatchButtonClick(): void {
    window.open(this.cloudWatchUrl);
  }
}
