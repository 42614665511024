/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
export enum LocationDtoAttributeName {
  NAME = 'NAME',
  CPO = 'CPO',
  STREET = 'STREET',
  HOUSE_NO = 'HOUSE_NO',
  POSTAL_CODE = 'POSTAL_CODE',
  CITY = 'CITY',
  COUNTRY_CODE = 'COUNTRY_CODE',
  HOTLINE = 'HOTLINE',
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE',
  GEO_POSITION_STATUS = 'GEO_POSITION_STATUS',
  ACCESS_LATITUDE = 'ACCESS_LATITUDE',
  ACCESS_LONGITUDE = 'ACCESS_LONGITUDE',
  ADMIN_LAST_UPDATED_BY = 'ADMIN_LAST_UPDATED_BY',
  ADMIN_LAST_UPDATED_DATE = 'ADMIN_LAST_UPDATED_DATE',
  ADMIN_COMMENTS = 'ADMIN_COMMENTS',
  STATE = 'STATE'
}
