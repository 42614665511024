<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="cp-details">
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource" *ngIf="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container matColumnDef="{{col}}">
          <mat-header-cell *matHeaderCellDef
                           [ngClass]="determineColumnClass(col)">
            {{ getI18nBase(col) | translate }}
            <button mat-icon-button class="raw-data-button" (click)="retrieveRawData(col)"
                    *ngIf="col!==attributeNameColumn && col!== manualDataColumn">
              <mat-icon>list_alt</mat-icon>
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let element" [ngClass]="determineColumnClass(col)">
            <div *ngIf="showPlainText(element, col)">
              {{ getCellData(col, element) }}
            </div>
            <div *ngIf="isReliabilityRatingData(element, col) || isCappedRatingData(element, col)">
              <pcs-rating [rating]="getCellData(col, element)"></pcs-rating>
            </div>
            <!-- declaration for editable attributes -->
            <div *ngIf="elementOnEdit(element, col)">
              <mat-form-field *ngIf="shouldShowInputField(element) && getFromControl(element) as fCtrl"
                              floatLabel="never">
                <input matInput [formControl]="fCtrl" (keypress)="validateInput($event, element.name)">
                <mat-error *ngIf="fCtrl.invalid">{{ getError(fCtrl) | async }}</mat-error>
              </mat-form-field>
              <mat-form-field *ngIf="!shouldShowInputField(element)">
                <mat-select *ngIf="element.name === authModesAttribute" [formControl]="getFromControl(element)"
                            multiple>
                  <mat-option *ngFor="let authMode of authModeMultiChoiceList"
                              [value]="authMode">{{ getTranslation(element.name, authMode) }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="element.name === plugsAttribute" [formControl]="getFromControl(element)"
                            multiple>
                  <mat-option *ngFor="let plug of plugsMultiChoiceList"
                              [value]="plug">{{ getTranslation(element.name, plug) }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="element.name === chargingCategoryAttribute" [formControl]="getFromControl(element)">
                  <mat-option *ngFor="let chargingCategory of chargingCategoryChoiceList"
                              [value]="chargingCategory">{{ getTranslation(element.name, chargingCategory) }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="element.name === accessAttribute" [formControl]="getFromControl(element)">
                  <mat-option *ngFor="let accessType of accessChoiceList"
                              [value]="accessType">{{ getTranslation(element.name, accessType) }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="element.name === cpClassAttribute" [formControl]="getFromControl(element)">
                  <mat-option *ngFor="let cpClass of cpClassChoiceList"
                              [value]="cpClass">{{ getTranslation(element.name, cpClass) }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="element.name === freeChargingAttribute" [formControl]="getFromControl(element)">
                  <mat-option *ngFor="let choice of yesNoChoiceList"
                              [value]="choice">{{ getTranslation(element.name, choice) }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="element.name === adhocCapableAttribute" [formControl]="getFromControl(element)">
                  <mat-option *ngFor="let choice of yesNoChoiceList"
                              [value]="choice">{{ getTranslation(element.name, choice) }}
                  </mat-option>
                </mat-select>
                <mat-select *ngIf="element.name === visibilityAttribute" [formControl]="getFromControl(element)">
                  <mat-option *ngFor="let visibility of visibilityChoiceList" [value]="visibility">
                    {{ getTranslation(element.name, visibility) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
  <div class="occupancy-distribution">
    <mat-label class="comments-label">{{ "chargepoint.occupancy" | translate }}</mat-label>
    <mat-label class="weekday-label">{{ "message.day." + days[now.getDay()] | translate }}</mat-label>
    <ngx-charts-bar-vertical
      [scheme]="colorScheme"
      [results]="chartData"
      [gradient]="false"
      [xAxis]="true"
      [yAxis]="true"
      [legend]="false"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [roundEdges]="false"
      [yScaleMax]="hourlyDistributionMax"
    >
      <ng-template #tooltipTemplate let-model="model">
        <div class="tooltipBar">
          {{ model.name }}
        </div>
        <div class="tooltipBar">{{ model.extra.day }}: {{ model.value }}</div>
      </ng-template>
    </ngx-charts-bar-vertical>
  </div>
  <div class="info-row">
    <mat-label class="comments-label">{{ getCellData(attributeNameColumn, lastUpdateDateRow) }}</mat-label>
    <div>{{ getCellData(manualDataColumn, lastUpdateDateRow) }}</div>
  </div>
  <div class="info-row">
    <mat-label class="comments-label">{{ getCellData(attributeNameColumn, updatedByRow) }}</mat-label>
    <div>{{ getCellData(manualDataColumn, updatedByRow) }}</div>
  </div>
  <div class="comments-row">
    <mat-label class="comments-label">{{ getCellData(attributeNameColumn, commentRow) }}</mat-label>
    <mat-form-field appearance="outline">
      <textarea cdkTextareaAutosize matInput [formControl]="getFromControl(commentRow)"></textarea>
    </mat-form-field>
  </div>
</div>
