/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export class LocationAdminAttributeDto {
  name: string;
  value: string;
  locationGroupId: number;
}
