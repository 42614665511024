/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OcpiPartner } from '../datatypes/ocpi-partner';
import { OcpiPartnerService } from '../services/http/ocpi-partner.service';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatePartnerComponent } from './activate-partner/activate-partner.component';
import { Message } from '../datatypes/message';
import { OcpiPartnerDetailsComponent } from './ocpi-partner-details/ocpi-partner-details.component';
import { PermissionAware } from '../permission-aware';
import { AccessRights } from '../datatypes/access-rights.enum';
import { OcpiPartnerDialogData } from './ocpi-partner-dialog-data';
import { Action } from 'pcs-commons/datatypes';
import { WithLoadingSpinner } from 'pcs-commons/components';
import { arrayIncludesAny } from 'pcs-commons/utils';
import { UserDataSourceService } from '../services/user-data-source.service';

@Component({
  selector: 'pcs-ocpi-partners',
  templateUrl: './ocpi-partners.component.html',
  styleUrls: ['./ocpi-partners.component.css']
})
export class OcpiPartnersComponent extends AbstractNotificationHandler implements OnInit, OnDestroy, PermissionAware {
  public allPartnersSource = new BehaviorSubject<OcpiPartner[]>([]);
  public allPartners$ = this.allPartnersSource.asObservable();

  public dataColumns = OcpiPartner.dataColumns;

  public readonly reqEditPermission = [AccessRights.CPO_AND_PARTNER_EDIT_WEB];

  constructor(
    private partnerService: OcpiPartnerService,
    private dialog: MatDialog,
    private userDataSource: UserDataSourceService
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    await this.getPartners();
  }

  public async pushRfidTokensToPartner(partner: OcpiPartner): Promise<void> {
    await this.partnerService.pushRfidTokensToPartner(partner);
    await this.getPartners();
    this.showInfo(new Message('ocpi.partner.pushRfidTokens.successful', partner.partyId));
  }

  public async onActivatePartner(toActivate: OcpiPartner): Promise<void> {
    const dialogRef = this.dialog.open(ActivatePartnerComponent, {
      width: '600px',
      data: toActivate
    });
    const success = await firstValueFrom(dialogRef.afterClosed());
    if (success) {
      const msg = new Message('ocpi.partner.activate.successful', toActivate.partyId);
      this.showInfo(msg);
      await this.getPartners();
    }
  }

  @WithLoadingSpinner()
  private async getPartners(): Promise<void> {
    const hasPermission = arrayIncludesAny(this.userDataSource.currentUserRights, [
      AccessRights.ADMIN_WEB,
      AccessRights.JOB_EDIT_WEB,
      AccessRights.JOB_READ_WEB
    ]);
    const partners = await this.partnerService.listPartners(hasPermission);
    const sortedPartners = [...partners].sort((a, b) => a.partyId.localeCompare(b.partyId));
    this.allPartnersSource.next(sortedPartners);
  }

  public async onConfigureNewPartner(): Promise<void> {
    const dialogData = new OcpiPartnerDialogData();
    dialogData.mode = Action.CREATE;
    await this.openPartnerDetailsDialog(dialogData);
  }

  public ngOnDestroy(): void {
    this.allPartnersSource.complete();
  }

  public async onEditPartner(partnerToEdit: OcpiPartner): Promise<void> {
    const dialogData = new OcpiPartnerDialogData();
    dialogData.mode = Action.EDIT;
    dialogData.partnerSource = partnerToEdit;
    await this.openPartnerDetailsDialog(dialogData);
  }

  private async openPartnerDetailsDialog(dialogData: OcpiPartnerDialogData): Promise<void> {
    const dialogRef = this.dialog.open(OcpiPartnerDetailsComponent, { width: '600px', data: dialogData });
    const success = await firstValueFrom(dialogRef.afterClosed());
    if (success) {
      await this.getPartners();
    }
  }
}
