/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from '../header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {CreateChangeRequest} from "../../../datatypes/audit-flow/create-change-request";
import {ChangeRequestDto} from "../../../datatypes/audit-flow/change-request-dto";
import {ChangeRequestPayloadType} from "../../../datatypes/inbox/change-request-payload-type";
import {ChangeRequestMessageDto} from "../../../datatypes/audit-flow/change-request-message-dto";

@Injectable({
  providedIn: 'root'
})
export class ChangeRequestService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public createChangeRequest(changeRequest: CreateChangeRequest): Observable<void> {
    return this.http.post<void>(environment.auditChangeRequestServiceUrl, changeRequest, {
        headers: this.headers
      }
    );
  }

  public findActiveChangeRequest(objectType: ChangeRequestPayloadType, objectId: number): Observable<ChangeRequestDto> {
    const url = environment.auditChangeRequestServiceUrl + '/active';
    return this.http.get<ChangeRequestDto>(url, {
        params: {objectType: objectType, objectId: objectId},
        headers: this.headers
      }
    );
  }

  public findAllActiveChangeRequestsByPayloadType(objectType: ChangeRequestPayloadType): Observable<number[]> {
    const url = environment.auditChangeRequestServiceUrl + '/active/all-payload-object-ids';
    return this.http.get<number[]>(url, {
        params: {objectType: objectType},
        headers: this.headers
      }
    );
  }

  public findChangeRequestById(changeRequestId: string): Observable<ChangeRequestDto> {
    const url = environment.auditChangeRequestServiceUrl + '/' + changeRequestId;
    return this.http.get<ChangeRequestDto>(url, {
        headers: this.headers
      }
    );
  }

  public findMyChangeRequestById(changeRequestId: string): Observable<ChangeRequestDto> {
    const url = environment.auditChangeRequestServiceUrl + '/my/' + changeRequestId;
    return this.http.get<ChangeRequestDto>(url, {
        headers: this.headers
      }
    );
  }

  public addMessage(changeRequestId: number, message: ChangeRequestMessageDto): Observable<void> {
    const url = environment.auditChangeRequestServiceUrl + '/' + changeRequestId + '/message';
    return this.http.post<void>(url, message, {
        headers: this.headers
      }
    );
  }
}
