<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="div-container">
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource">

      <ng-container matColumnDef="{{unreadChangesColumn}}">
        <mat-header-cell *matHeaderCellDef
                         class="actionCell center unread-data-column"></mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell center unread-data-column">
          <div>
            <mat-icon *ngIf="element.hasUnreadChanges" class="unreadChanges">
              fiber_manual_record
            </mat-icon>
          </div>
        </mat-cell>
      </ng-container>

      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn && col !== 'unreadChanges'" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'updated'">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'updated'">{{translateDate(element)}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>

      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="inboxActionCell actionCell center details-column"> {{'inbox.tableHeader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="inboxActionCell actionCell center details-column">
          <button mat-mini-fab class="edit tableActionBtn" (click)="editElement(element)"
                  *ngIf="hasTodo(element)">
            <mat-icon>
              task_alt
            </mat-icon>
          </button>
          <button mat-mini-fab class="edit tableActionBtn" (click)="editElement(element)"
                  *ngIf="!hasTodo(element)">
            <i class="fa fa-info" aria-hidden="true"></i>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>
