/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {Role} from "../datatypes/role.enum";
import {UserDataSourceService} from "../services/user-data-source.service";
import {WebConfigRouterService} from "../services/web-config-router.service";
import {Action} from "../datatypes/action.enum";
import {AccessRights} from "../datatypes/access-rights.enum";
import {Domain} from "../datatypes/domain";
import {Parameters} from "../global/parameters";
import {ActivatedRouteSnapshot, Routes} from "@angular/router";
import {NavMenu} from "../datatypes/nav-menu";
import {NavigationMenuStore} from "../navigation-menu-store";
import {routes} from "../app-routing.module";
import {arrayIncludesAny} from 'pcs-commons/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private urlToRequiredRightsMap: Map<string, AccessRights[]>;

  constructor(
    private router: WebConfigRouterService,
    private userDataSource: UserDataSourceService) {
    this.urlToRequiredRightsMap = this.getRequiredRightsForUrlAccessFromRouteData(routes);
  }

  public userHasAccessToUrl(route: ActivatedRouteSnapshot): boolean {
    // check role
    const accessModeString = route.queryParamMap.get(Parameters.MODE_PARAM);
    const userRole = this.userDataSource.currentUserRole;

    if (!userRole || Role.PCS_ADMIN !== Role[userRole.toString()]) {
      console.log('Access not authorized for user with role: ', userRole, ' allowed role: ', Role.PCS_ADMIN);
      this.router.accessNotAuthorized();
      return false;
    }
    const accessMode = accessModeString ? Action[accessModeString] : null;
    console.log('Checking user\'s permission to url: ', route.url, ' access mode: ', accessMode);
    const requiredDomainAccess: Domain[] = route.data.domains;
    return this.userHasAccess(requiredDomainAccess, accessMode);
  }

  public userHasAccess(requiredDomainAccess: Domain[], accessMode?: Action): boolean {
    const usersPermissions = this.userDataSource.currentUserRights;
    if (!usersPermissions) {
      return false;
    }

    if (usersPermissions.includes(AccessRights.ADMIN_WEB)) {
      return true;
    }

    if (!requiredDomainAccess || requiredDomainAccess.length < 1) {
      console.log('no permission restrictions are set for the url, allowing user access');
      return true;
    }
    const allowedForPermissions = this.determineRequiredPermissions(accessMode, requiredDomainAccess);
    const hasPermission = arrayIncludesAny(usersPermissions, allowedForPermissions);
    console.log('User has access to the url: ', hasPermission);
    return hasPermission;
  }

  private determineRequiredPermissions(accessMode: Action, domains: Domain[]): AccessRights[] {
    const allowedForPermissions: AccessRights[] = [];
    if (accessMode && (Action.EDIT === accessMode || Action.CREATE === accessMode)) {
      domains.forEach((domain) =>
        allowedForPermissions.push(domain.editPermission));
    } else {
      domains.forEach((domain) =>
        allowedForPermissions.push(...domain.permissions));
    }
    return allowedForPermissions;
  }

  public findAndStoreAccessibleNavMenusForUser(): void {
    console.log("Fetching nav-menus user has access to..");
    const userAuthDetails = this.userDataSource.getUserAuthDetails();
    if (Role.PCS_ADMIN !== userAuthDetails.role) {
      console.log("Only PCS_ADMIN user can access! No accessible menu for user!");
      return;
    }

    const urlToMenuMap: Map<string, NavMenu> = new Map(NavigationMenuStore.getAllNavMenus().map(menu => [menu.homeUrl, menu]));
    const accessibleMenus: NavMenu[] = [];
    urlToMenuMap.forEach((menu, url) => {
      const requiredAccessRights = this.urlToRequiredRightsMap.get(url);
      if (requiredAccessRights.length < 1 || arrayIncludesAny(userAuthDetails.rights, requiredAccessRights)) {
        accessibleMenus.push(menu);
      }
    })

    console.log("Accessible menus for user: ", accessibleMenus.map(menu => menu.name));
    this.userDataSource.updateAvailableNavMenus(accessibleMenus);
  }

  public getRequiredRightsForUrlAccessFromRouteData(routes: Routes): Map<string, AccessRights[]> {
    return new Map(routes.map(route => {
      const key = route.path;
      let value: AccessRights[];
      if (route.data?.domains) {
        value = [AccessRights.ADMIN_WEB];
        (route.data.domains as Domain[]).forEach(domain => value.push(...domain.permissions));
      } else {
        value = [];
      }
      return [key, value];
    }));
  }

  public hasPermission(permToCheck: AccessRights[]): boolean {
    const userAuthDetails = this.userDataSource.getUserAuthDetails();
    if (Role.PCS_ADMIN !== userAuthDetails.role) {
      return false;
    }

    if (userAuthDetails.rights) {
      return arrayIncludesAny(userAuthDetails.rights, [AccessRights.ADMIN_WEB, ...permToCheck]);
    }
    return false;
  }
}
