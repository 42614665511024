/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {SharedDataService} from '../services/shared-data.service';
import {ChargepointClassPrice, ChargepointClassPriceColumn} from '../chargepoint-class-price';
import {PriceUtils} from '../utils/price-utils';
import {PriceColumnPipeBase} from './price-column-pipe-base';

@Pipe({
  name: 'impureClassPriceColumnValue',
  pure: false
})
export class ImpureClassPriceColumnValuePipe extends PriceColumnPipeBase implements PipeTransform {

  constructor(dataSource: SharedDataService) {
    super(dataSource);
  }

  transform(priceRecord: ChargepointClassPrice, column: string, country: string, taxDateTime?: string): string {
    if (!priceRecord) {
      return null;
    }

    switch (column) {
      case ChargepointClassPriceColumn.PRICE_PER_MIN:
      case ChargepointClassPriceColumn.PRICE_PER_KWH:
        return PriceUtils.formatPrice(priceRecord[column]);
      case ChargepointClassPriceColumn.PRICE_PER_MIN_NETTO:
       return PriceUtils.getNettoPrice(
          priceRecord[ChargepointClassPriceColumn.PRICE_PER_MIN], PriceUtils.getTaxForTime(country, this.taxData, taxDateTime)
        );
      case ChargepointClassPriceColumn.PRICE_PER_KWH_NETTO:
        return PriceUtils.getNettoPrice(
          priceRecord[ChargepointClassPriceColumn.PRICE_PER_KWH], PriceUtils.getTaxForTime(country, this.taxData, taxDateTime)
        );
      default:
        return priceRecord[column];
    }
  }
}
