/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Identifiable} from 'pcs-commons/datatypes';

export class TaxRecord extends Identifiable {
  public static readonly dataColumns = ['country', 'taxRate', 'currency', 'validFrom', 'validUntil'];
  public static readonly editDataColumns = ['taxRate', 'currency', 'validFromDate', 'validFromTime',
    'validUntilDate', 'validUntilTime'];

  public country: string;
  public taxRate: number;
  public currency: string;
  public validFrom: string;
  public validUntil: string;
  public index: number;
}
