<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>{{
    'chargepoint.dialog.header'| translate :{param0: evseId, param1: platform}
    }}
  </h2>
  <div class="raw-data-editor-div">
    <ngs-code-editor *ngIf="codeModel"
                     theme="vs"
                     class="raw-data-editor"
                     [codeModel]="codeModel"
                     readOnly="true">
    </ngs-code-editor>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button id="cancelBtn" class="dialogueButton" [mat-dialog-close]="false">
    {{'button.close'| translate}}
  </button>
</mat-dialog-actions>
