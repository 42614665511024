<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-card class="platform-list-card">
  <mat-card-header *ngIf="titleKey">
    <mat-card-title class="headerToolbarSub">{{titleKey | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="platformList">
    <custom-drag-drop-list [elementList]="platformList"
                           (itemMoveEvent)="handleListItemMove($event)"
                           [hasWriteAccess]="reqEditPermission | accessAllowed"></custom-drag-drop-list>
  </mat-card-content>
</mat-card>
