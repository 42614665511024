<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div editKeyEvents>
  <mat-toolbar class="mat-elevation-z1 header-toolbar" *ngIf="mode">
    <span *ngIf="mode === 'CREATE'"> {{'usermanagement.groups.create.header'|translate}}</span>
    <span *ngIf="mode !== 'CREATE' && groupOnProcess"> {{'usermanagement.groups.edit.header'|translate}}
      : {{groupOnProcess.name}}</span>
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
  </mat-toolbar>
  <mat-divider class="section-divider"></mat-divider>
  <form class="edit-group-form" [formGroup]="userGroupForm" *ngIf="userGroupForm">
    <mat-form-field appearance="outline" class="left-margin group-name-form-field">
      <mat-label>{{'usermanagement.groups.name' | translate}}</mat-label>
      <input matInput required formControlName="name"
             (keydown.space)="$event.preventDefault();">
      <mat-hint align="end" *ngIf="name.value">{{name.value.length}}
        / {{nameMaxLength}}</mat-hint>
      <mat-error *ngIf="name.invalid">
        {{getError(name) | async}}
      </mat-error>
    </mat-form-field>
    <div id="groupRights" class="permissions-div mat-elevation-z1">
      <mat-toolbar class="header-toolbar"> {{'usermanagement.groups.permissions.header'|translate}}</mat-toolbar>
      <mat-grid-list cols="5" rowHeight="50px" *ngFor="let domain of domainList;" class="left-margin">
        <mat-grid-tile colspan="2" class="group-rights-tile">
          <mat-label>{{ domain.key | translate }}</mat-label>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" class="group-rights-tile">
          <mat-form-field>
            <mat-select formControlName="{{domain.name}}">
              <mat-option></mat-option>
              <mat-option *ngFor="let permissionType of domain.permissions;" [value]="permissionType">
                {{permissionType| permissionTranslationKey |translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="2" class="group-rights-tile">
          <div class="menu-list-div"> {{ domain.name |fetchMenus:updated}}</div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </form>
  <div class="button-pane">
    <button okAction mat-raised-button class="actionButton" [disabled]="changeRequestDialogOpen || !isSaveValid()" *ngIf="mode !== 'READ'"
            (click)="onSave()">
      {{'button.save' | translate}}
    </button>
    <button cancelAction mat-raised-button class="actionButton" (click)="onCancel()" [disabled]="changeRequestDialogOpen">
      {{ getCloseButtonText() | translate}}
    </button> 
  </div>
</div>
