/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HeaderEnabled} from './header-enabled.abstract';
import {Observable} from 'rxjs';
import {QueryDto} from '../../datatypes/QueryDto';
import {Parameters} from '../../global/parameters';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
    this.headers = this.headers.append(Parameters.HEADER_ERROR_MSG_BASE, 'database.error.');
  }

  public getQueryResults(queryInput: QueryDto): Observable<Map<string, any>[]> {
    return this.http.post<Map<string, any>[]>(
      environment.databaseEndpoint + 'query',
      queryInput,
      {headers: this.headers}
    );
  }

  public retrieveSavedQueries(): Observable<QueryDto[]> {
    return this.http.get<QueryDto[]>(
      environment.databaseEndpoint + 'queries',
      {headers: this.headers}
    );
  }

  public saveQuery(queryInput: QueryDto): Observable<QueryDto[]> {
    return this.http.post<QueryDto[]>(
      environment.databaseEndpoint + 'create-query',
      queryInput,
      {headers: this.headers}
    );
  }

  public updateQuery(queryInput: QueryDto): Observable<QueryDto[]> {
    return this.http.put<QueryDto[]>(
      environment.databaseEndpoint + 'query',
      queryInput,
      {headers: this.headers}
    );
  }

  public deleteQuery(queryInput: QueryDto): Observable<QueryDto[]> {
    return this.http.delete<QueryDto[]>(
      environment.databaseEndpoint + 'query/' + queryInput.id,
      {headers: this.headers}
    );
  }

}
