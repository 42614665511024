/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {NavigationExtras, Router, UrlTree} from '@angular/router';
import {UserDataSourceService} from './user-data-source.service';
import {BaseUrls} from '../datatypes/base-urls.enum';

@Injectable({
  providedIn: 'root'
})
export class WebConfigRouterService {

  constructor(
    private router: Router,
    private userDataSource: UserDataSourceService) {
  }

  public toUsersHomePage(): void {
    console.log('navigating user to his Homepage');
    // check user has permission for out default start page
    const usersPermissions = this.userDataSource.currentUserRights;
    if (!usersPermissions || usersPermissions.length < 1) {
      this.accessNotAuthorized();
      return;
    }

    this.navigate([this.userDataSource.currentAccessibleNavMenus[0].homeUrl]);
  }

  public accessNotAuthorized(): void {
    this.router.navigate([BaseUrls.ERROR, 403]);
  }

  /*
    This is an instrumented version of router.navigate().
    This method will check if the user has rights to the inputted url
    if not, he will be navigated to his home page.
   */
  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    console.log('Navigating with commands: ', commands.toString());
    return this.router.navigate(commands, extras);
  }

  public navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigateByUrl(url, extras);
  }

  public getCurrentUrl(): string {
    return this.router.url;
  }
}
