<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-grid-list cols="3" rowHeight="50px">
  <mat-grid-tile colspan="2">
    <div>
      <span class="margin-right">{{'jsonDiff.showChangesOnly' | translate}}</span>
      <mat-slide-toggle [formControl]="toggle"></mat-slide-toggle>
      <span class="margin-left">{{'jsonDiff.showFullContext' | translate}}</span>
    </div>
  </mat-grid-tile>
  <mat-grid-tile colspan="1" class="pull-right">
    <span class="box">
      <span>{{'jsonDiff.legend' | translate}}</span>
      <del style="margin-left: 10px">{{'jsonDiff.deleted' | translate}}</del>
      <ins style="margin-left: 10px">{{'jsonDiff.added' | translate}}</ins>
    </span>
  </mat-grid-tile>
</mat-grid-list>

<ngx-object-diff *ngIf="toggle.value" [obj]="diffView"></ngx-object-diff>
<ngx-object-diff *ngIf="!toggle.value" [obj]="diffValueChanges"></ngx-object-diff>
