/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors, Validator} from '@angular/forms';
import {PurchasePriceComponentType} from '../datatypes/purchase-conditions/purchase-price-component-type.enum';
import {Utils} from '../utils/utils';
import {FormUtils} from 'pcs-commons/utils';

@Injectable({
  providedIn: 'root'
})
export class PurchasePriceListValidatorService implements Validator {
  public readonly priceTypes: string[] = Object.values(PurchasePriceComponentType);

  public validate(form: FormGroup): ValidationErrors | null {
    let hasOneCompletePrice = false;
    let allPricesValid = true;
    this.priceTypes.forEach((type) => {
      const requiresStepSize = type !== PurchasePriceComponentType.FLAT;
      const fcPriceName = Utils.purchasePriceFormControlName(type, 'price');
      const fcPrice = FormUtils.getFormControl(form, fcPriceName);
      let fcStepSize = null;
      if (requiresStepSize) {
        const fcStepSizeName = Utils.purchasePriceFormControlName(type, 'stepSize');
        fcStepSize = FormUtils.getFormControl(form, fcStepSizeName);
      }
      hasOneCompletePrice =
        hasOneCompletePrice || this.hasValidNonNullValue(fcPrice) && (!requiresStepSize || this.hasValidNonNullValue(fcStepSize));
      allPricesValid = allPricesValid &&
        (fcPrice && Utils.isEmpty(fcPrice.value) && (!requiresStepSize || fcStepSize && Utils.isEmpty(fcStepSize.value))
          || this.hasValidNonNullValue(fcPrice) && (!requiresStepSize || this.hasValidNonNullValue(fcStepSize)));
    });

    console.log('price component form validation: hasOneCompletePrice: ', hasOneCompletePrice, ' allPricesValid', allPricesValid);
    const error = hasOneCompletePrice ? null : {emptyPurchasePriceList: true};
    return error ? error : (allPricesValid ? null : {incompletePriceComponent: true});
  }

  private hasValidNonNullValue(control: AbstractControl): boolean {
    return control && control.valid && Utils.objectNotEmpty(control.value);
  }
}
