<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span> {{ 'changeRequest.details.title'|translate }}</span>
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
</div>
<div class="section-container" *ngIf="changeRequest">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true" [hideToggle]="true" class="change-request-expansion-panel" disabled="true">
      <mat-expansion-panel-header class="change-request-expansion-panel-header">
        <div id="change-request-header">
          <span class="change-request-expansion-panel-title">{{ changeRequest.title }}</span>
          <br>
          <span>{{
              'changeRequest.initiated.by'| translate :{ param0: getInitiator() }
            }}</span>
        </div>
      </mat-expansion-panel-header>
      <pcs-change-request-info></pcs-change-request-info>
      <pcs-change-request-history-table [data]="messagesResult$"
                                        [dataColumns]="dataColumns"
                                        [actionButtons]="false"
                                        [i18nBase]="'changeRequest.history.tableHeader'"></pcs-change-request-history-table>
      <div class="change-request-status">
      <span>{{
          'changeRequest.status'| translate :{ param0: getStatus() }
        }}</span>
      </div>
      <div *ngIf="editMode()">
        <form class="change-request-msg-form" [formGroup]="changeRequestMsgForm">
          <mat-form-field appearance="outline" class="change-request-msgtype">
            <mat-label>{{ 'changeRequest.message.type' | translate }}</mat-label>
            <mat-select formControlName="msgType">
              <mat-option *ngFor="let type of msgTypeList" [value]="type">
                {{ 'changeRequest.message.type.' + type | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="msgType.invalid">{{ msgType | validationMessage | async }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="change-request-comment">
            <mat-label>{{ 'changeRequest.message.comment' | translate }}</mat-label>
            <mat-error *ngIf="comment.invalid">{{ comment | validationMessage | async }}</mat-error>
            <textarea matInput
                      cdkTextareaAutosize
                      formControlName="comment"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"></textarea>
          </mat-form-field>
        </form>
      </div>
      <mat-action-row>
        <button okAction class="normalButton" mat-stroked-button (click)="onBack()">
          <span>{{ "changeRequest.message.back" | translate }}</span>
        </button>
        <button okAction class="normalButton" mat-stroked-button (click)="sendMessage()"
                [disabled]="loading || !allInputsValid()" *ngIf="editMode()">
          <span>{{ "changeRequest.message.send" | translate }}</span>
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
