/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export class SelfInformationDto {
  public static readonly dataColumns = ['ciamId', 'contractId', 'status', 'vins', 'validFrom', 'validUntil', 'cardUids'];
  id: number;
  ciamId: string;
  contractId: string;
  status: string;
  vins: string[];
  validFrom: string;
  validUntil: string;
  cardUids: string[];
}
