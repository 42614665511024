/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Role} from './role.enum';
import {AccessRights} from './access-rights.enum';
import {MFADetails} from './mfadetails';

export class UserInfo extends MFADetails {
  public id: number;
  public userId: string;
  public login: string;
  public userName: string;
  public role: Role;
  public rights: AccessRights[];
}
