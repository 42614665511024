/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AbstractControl, FormGroup} from '@angular/forms';
import {StringUtils} from 'pcs-commons/utils';

export class FormHelper {

  public static getStringValue(formGroup: FormGroup, formControlName: string): any {
    return StringUtils.trim(this.getValue(formGroup, formControlName));
  }

  public static getStringValueSafe(formGroup: FormGroup, formControlName: string, defaultValue: string): any {
    const val = StringUtils.trim(this.getValue(formGroup, formControlName));
    return val ? val : defaultValue;
  }

  public static getValue(formGroup: FormGroup, formControlName: string): any {
    const ctrl = this.getFormControl(formGroup, formControlName);
    console.log('Got value of form control ', formControlName, ' = ', ctrl.value);
    return ctrl ? ctrl.value : null;
  }

  public static getFormControl(fg: FormGroup, name: string): AbstractControl {
    return fg.controls[name];
  }

  public static allFormsValid(...formGroups: FormGroup[]): boolean {
    let valid = true;
    formGroups.forEach((fg) => valid = valid && fg && fg.valid);
    return valid;
  }
}
