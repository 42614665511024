/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { HeaderEnabled } from './header-enabled.abstract';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OcpiPartner, UpdateLocationDataStatus } from '../../datatypes/ocpi-partner';
import { environment } from '../../../environments/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { OcpiPartnerInitializationData } from '../../datatypes/ocpi-partner-initialization-data';
import { OCPIPartnerConfigResultDto } from '../../datatypes/ocpipartner-config-result-dto';
import { OcpiPartnerUpdateData } from '../../datatypes/ocpi-partner-update-data';
import { OcpiPartnerStatus } from '../../datatypes/ocpi-partner-status.enum';

interface JobInfo {
  name: string;
  status: UpdateLocationDataStatus;
}

@Injectable({
  providedIn: 'root'
})
export class OcpiPartnerService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public async listPartners(hasStatusPermission: boolean): Promise<OcpiPartner[]> {
    const url = environment.ocpiPartnerEndpoint;
    const partners = await firstValueFrom(this.http.get<OcpiPartner[]>(url, { headers: this.headers }));
    if (hasStatusPermission) {
      await this.determineSyncStatus(partners);
    }
    return partners;
  }

  private async determineSyncStatus(partners: OcpiPartner[]): Promise<void> {
    const promises = partners.map(async (partner) => {
      if (OcpiPartnerStatus.ACTIVE === partner.status) {
        const status = await this.getUpdateLocationDataJobStatus(partner);
        partner.updateLocationDataStatus = status;
      }
    });
    await Promise.allSettled(promises);
    const isSynching = partners.some((partner) => partner.updateLocationDataStatus === 'RUNNING');
    if (isSynching) {
      // check again in 1 second
      setTimeout(() => this.determineSyncStatus(partners), 1000);
    }
  }

  public enablePartner(toEnable: OcpiPartner, token: string): Observable<void> {
    const url = environment.ocpiPartnerEndpoint + '/' + toEnable.id;
    const localHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      token: token
    });
    return this.http.post<void>(url, null, { headers: localHeaders });
  }

  public configureNewPartner(newPartner: OcpiPartnerInitializationData): Observable<OCPIPartnerConfigResultDto> {
    return this.http.post<OCPIPartnerConfigResultDto>(environment.ocpiPartnerEndpoint, newPartner, { headers: this.headers });
  }

  public pushRfidTokensToPartner(partner: OcpiPartner): Promise<void> {
    const url = `${environment.ocpiPartnerEndpoint}/${partner.id}/token`;
    return firstValueFrom(this.http.put<void>(url, null, { headers: this.headers }));
  }

  public updatePartner(id: number, partnerUpdateData: OcpiPartnerUpdateData): Observable<void> {
    const url = environment.ocpiPartnerEndpoint + '/' + id;
    return this.http.put<void>(url, partnerUpdateData, { headers: this.headers });
  }

  public async updateLocationDataForPartner(partner: OcpiPartner): Promise<void> {
    if (partner.updateLocationDataStatus === 'RUNNING') {
      return; // if already one partner is on sync, we do not send the event again
    }
    const url = `${environment.ticktockRemoteControlEndpoint}/jobs/PULL_OCPI_FULL_STATIC_DATA?group=${partner.id}&partnerId=${partner.id}`;
    const response = await firstValueFrom(this.http.post(url, null, { headers: this.headers, observe: 'response' }));
    if (response.status !== 200) {
      throw new Error("Couldn't start the job: " + response.status);
    } else {
      partner.updateLocationDataStatus = 'RUNNING';
      setTimeout(() => this.determineSyncStatus([partner]), 1000);
    }
  }

  public isSyncRunning(toCheck: OcpiPartner): boolean {
    return toCheck.updateLocationDataStatus === 'RUNNING';
  }

  public async getUpdateLocationDataJobStatus(partner: OcpiPartner): Promise<UpdateLocationDataStatus | undefined> {
    const url = `${environment.ticktockRemoteControlEndpoint}/jobs/PULL_OCPI_FULL_STATIC_DATA?group=${partner.id}&partnerId=${partner.id}`;
    try {
      const response = await firstValueFrom(this.http.get<JobInfo>(url, { headers: this.headers }));
      return response?.status;
    } catch (e) {
      console.log(e);
    }
  }
}
