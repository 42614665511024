/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { TaxService } from './http/tax.service';
import { SharedDataService } from './shared-data.service';

export function productDetailsResolver(): Observable<boolean> {
  const taxService = inject(TaxService);
  const dataSource = inject(SharedDataService);
  return taxService.getTaxRecords().pipe(
    tap((taxData) => dataSource.updateTaxData(taxData)),
    map(() => true)
  );
}
