/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {CountryPriceRecord} from '../country-price-record';
import {BasicFeeRecord} from './basicfee-record';
import {ProductStatus} from "./product-status.enum";
import {Plan} from "./plan";

export type ProductDataColumnType =
  'code'
  | 'serviceId'
  | 'name'
  | 'term'
  | 'channel'
  | 'status'
  | 'hasActiveChangeRequest';

export class Product {
  public static dataColumns: Array<ProductDataColumnType> =
    ['code', 'serviceId', 'name', 'term', 'channel', 'status', 'hasActiveChangeRequest'];

  public id: number;
  public code: string;
  public serviceId: string;
  public term: number;
  public name: string;
  public status: ProductStatus;
  public channel: string;
  public prices: CountryPriceRecord[];
  public basicFees: BasicFeeRecord[];
  public plans: Plan[];
  public hasActiveChangeRequest: boolean;
}
