<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title *ngIf="clearable">
    {{'cdrClearing.edit.dialog.header'|translate}}
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <h2 mat-dialog-title *ngIf="!clearable">
    {{'cdrClearing.noEdit.dialog.header'|translate}}
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <mat-checkbox
    [(ngModel)]="ignoreChargeValidation">{{'cdrClearing.edit.ignoreChargeValidation' | translate}}
  </mat-checkbox>

  <mat-error *ngIf="invalidJson">{{'cdrClearing.edit.invalidJson' | translate}}</mat-error>
  <div class="clearing-entry-editor-div">
    <ngs-code-editor *ngIf="codeModel" theme="vs" class="clearing-entry-editor"
                     [codeModel]="codeModel"
                     [readOnly]="!(reqEditPermission | accessAllowed) || !clearable"
                     (valueChanged)="onEditorValueChange($event)">
    </ngs-code-editor>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button id="actionBtn" class="retry-clearing-button dialogueButton"
          *ngIf="(reqEditPermission | accessAllowed) && clearable"
          [disabled]="invalidJson"
          (click)="retryClearing()">
    {{ 'cdrClearing.retryClearing' | translate}}
  </button>
  <button mat-stroked-button id="cancelBtn" class="dialogueButton" [mat-dialog-close]="false">
    {{((!(reqEditPermission | accessAllowed) || !clearable) ? 'button.close' : 'button.cancel')| translate}}
  </button>
</mat-dialog-actions>
