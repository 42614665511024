/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export enum CountryCode {
    AFGHANISTAN = 'AF',
    ALAND_ISLANDS = 'AX',
    ALBANIA = 'AL',
    ALGERIA = 'DZ',
    AMERICAN_SAMOA = 'AS',
    ANDORRA = 'AD',
    ANGOLA = 'AO',
    ANGUILLA = 'AI',
    ANTARCTICA = 'AQ',
    ANTIGUA_AND_BARBUDA = 'AG',
    ARGENTINA = 'AR',
    ARMENIA = 'AM',
    ARUBA = 'AW',
    AUSTRALIA = 'AU',
    AUSTRIA = 'AT',
    AZERBAIJAN = 'AZ',
    BAHAMAS = 'BS',
    BAHRAIN = 'BH',
    BANGLADESH = 'BD',
    BARBADOS = 'BB',
    BELARUS = 'BY',
    BELGIUM = 'BE',
    BELIZE = 'BZ',
    BENIN = 'BJ',
    BERMUDA = 'BM',
    BHUTAN = 'BT',
    BOLIVIA_PLURINATIONAL_STATE_OF = 'BO',
    BONAIRE_SINT_EUSTATIUS_AND_SABA = 'BQ',
    BOSNIA_AND_HERZEGOVINA = 'BA',
    BOTSWANA = 'BW',
    BOUVET_ISLAND = 'BV',
    BRAZIL = 'BR',
    BRITISH_INDIAN_OCEAN_TERRITORY = 'IO',
    BRUNEI_DARUSSALAM = 'BN',
    BULGARIA = 'BG',
    BURKINA_FASO = 'BF',
    BURUNDI = 'BI',
    CABO_VERDE = 'CV',
    CAMBODIA = 'KH',
    CAMEROON = 'CM',
    CANADA = 'CA',
    CAYMAN_ISLANDS = 'KY',
    CENTRAL_AFRICAN_REPUBLIC = 'CF',
    CHAD = 'TD',
    CHILE = 'CL',
    CHINA = 'CN',
    CHRISTMAS_ISLAND = 'CX',
    COCOS_KEELING_ISLANDS = 'CC',
    COLOMBIA = 'CO',
    COMOROS = 'KM',
    CONGO = 'CG',
    CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE = 'CD',
    COOK_ISLANDS = 'CK',
    COSTA_RICA = 'CR',
    COTE_DIVOIRE = 'CI',
    CROATIA = 'HR',
    CUBA = 'CU',
    CURACAO = 'CW',
    CYPRUS = 'CY',
    CZECHIA = 'CZ',
    DENMARK = 'DK',
    DJIBOUTI = 'DJ',
    DOMINICA = 'DM',
    DOMINICAN_REPUBLIC = 'DO',
    ECUADOR = 'EC',
    EGYPT = 'EG',
    EL_SALVADOR = 'SV',
    EQUATORIAL_GUINEA = 'GQ',
    ERITREA = 'ER',
    ESTONIA = 'EE',
    ETHIOPIA = 'ET',
    FALKLAND_ISLANDS_MALVINAS = 'FK',
    FAROE_ISLANDS = 'FO',
    FIJI = 'FJ',
    FINLAND = 'FI',
    FRANCE = 'FR',
    FRENCH_GUIANA = 'GF',
    FRENCH_POLYNESIA = 'PF',
    FRENCH_SOUTHERN_TERRITORIES = 'TF',
    GABON = 'GA',
    GAMBIA = 'GM',
    GEORGIA = 'GE',
    GERMANY = 'DE',
    GHANA = 'GH',
    GIBRALTAR = 'GI',
    GREECE = 'GR',
    GREENLAND = 'GL',
    GRENADA = 'GD',
    GUADELOUPE = 'GP',
    GUAM = 'GU',
    GUATEMALA = 'GT',
    GUERNSEY = 'GG',
    GUINEA = 'GN',
    GUINEABISSAU = 'GW',
    GUYANA = 'GY',
    HAITI = 'HT',
    HEARD_ISLAND_AND_MCDONALD_ISLANDS = 'HM',
    HOLY_SEE = 'VA',
    HONDURAS = 'HN',
    HONG_KONG = 'HK',
    HUNGARY = 'HU',
    ICELAND = 'IS',
    INDIA = 'IN',
    INDONESIA = 'ID',
    IRAN_ISLAMIC_REPUBLIC_OF = 'IR',
    IRAQ = 'IQ',
    IRELAND = 'IE',
    ISLE_OF_MAN = 'IM',
    ISRAEL = 'IL',
    ITALY = 'IT',
    JAMAICA = 'JM',
    JAPAN = 'JP',
    JERSEY = 'JE',
    JORDAN = 'JO',
    KAZAKHSTAN = 'KZ',
    KENYA = 'KE',
    KIRIBATI = 'KI',
    KOREADEMOCRATICPEOPLES_REPUBLIC_OF = 'KP',
    KOREA_REPUBLIC_OF = 'KR',
    KUWAIT = 'KW',
    KYRGYZSTAN = 'KG',
    LAO_PEOPLES_DEMOCRATIC_REPUBLIC = 'LA',
    LATVIA = 'LV',
    LEBANON = 'LB',
    LESOTHO = 'LS',
    LIBERIA = 'LR',
    LIBYA = 'LY',
    LIECHTENSTEIN = 'LI',
    LITHUANIA = 'LT',
    LUXEMBOURG = 'LU',
    MACAO = 'MO',
    MACEDONIA_THE_FORMER_YUGOSLAV_REPUBLIC_OF = 'MK',
    MADAGASCAR = 'MG',
    MALAWI = 'MW',
    MALAYSIA = 'MY',
    MALDIVES = 'MV',
    MALI = 'ML',
    MALTA = 'MT',
    MARSHALL_ISLANDS = 'MH',
    MARTINIQUE = 'MQ',
    MAURITANIA = 'MR',
    MAURITIUS = 'MU',
    MAYOTTE = 'YT',
    MEXICO = 'MX',
    MICRONESIA_FEDERATED_STATES_OF = 'FM',
    MOLDOVA_REPUBLIC_OF = 'MD',
    MONACO = 'MC',
    MONGOLIA = 'MN',
    MONTENEGRO = 'ME',
    MONTSERRAT = 'MS',
    MOROCCO = 'MA',
    MOZAMBIQUE = 'MZ',
    MYANMAR = 'MM',
    NAMIBIA = 'NA',
    NAURU = 'NR',
    NEPAL = 'NP',
    NETHERLANDS = 'NL',
    NEW_CALEDONIA = 'NC',
    NEW_ZEALAND = 'NZ',
    NICARAGUA = 'NI',
    NIGER = 'NE',
    NIGERIA = 'NG',
    NIUE = 'NU',
    NORFOLK_ISLAND = 'NF',
    NORTHERN_MARIANA_ISLANDS = 'MP',
    NORWAY = 'NO',
    OMAN = 'OM',
    PAKISTAN = 'PK',
    PALAU = 'PW',
    PALESTINE_STATE_OF = 'PS',
    PANAMA = 'PA',
    PAPUA_NEW_GUINEA = 'PG',
    PARAGUAY = 'PY',
    PERU = 'PE',
    PHILIPPINES = 'PH',
    PITCAIRN = 'PN',
    POLAND = 'PL',
    PORTUGAL = 'PT',
    PUERTO_RICO = 'PR',
    QATAR = 'QA',
    REUNION = 'RE',
    ROMANIA = 'RO',
    RUSSIAN_FEDERATION = 'RU',
    RWANDA = 'RW',
    SAINT_BARTHELEMY = 'BL',
    SAINT_HELENA_ASCENSION_AND_TRISTAN_DA_CUNHA = 'SH',
    SAINT_KITTS_AND_NEVIS = 'KN',
    SAINT_LUCIA = 'LC',
    SAINT_MARTIN_FRENCH_PART = 'MF',
    SAINT_PIERRE_AND_MIQUELON = 'PM',
    SAINT_VINCENT_AND_THE_GRENADINES = 'VC',
    SAMOA = 'WS',
    SAN_MARINO = 'SM',
    SAO_TOME_AND_PRINCIPE = 'ST',
    SAUDI_ARABIA = 'SA',
    SENEGAL = 'SN',
    SERBIA = 'RS',
    SEYCHELLES = 'SC',
    SIERRA_LEONE = 'SL',
    SINGAPORE = 'SG',
    SINT_MAARTEN_DUTCH_PART = 'SX',
    SLOVAKIA = 'SK',
    SLOVENIA = 'SI',
    SOLOMON_ISLANDS = 'SB',
    SOMALIA = 'SO',
    SOUTH_AFRICA = 'ZA',
    SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'GS',
    SOUTH_SUDAN = 'SS',
    SPAIN = 'ES',
    SRI_LANKA = 'LK',
    SUDAN = 'SD',
    SURINAME = 'SR',
    SVALBARD_AND_JAN_MAYEN = 'SJ',
    SWAZILAND = 'SZ',
    SWEDEN = 'SE',
    SWITZERLAND = 'CH',
    SYRIAN_ARAB_REPUBLIC = 'SY',
    TAIWAN_PROVINCE_OF_CHINA = 'TW',
    TAJIKISTAN = 'TJ',
    TANZANIA_UNITED_REPUBLIC_OF = 'TZ',
    THAILAND = 'TH',
    TIMOR_LESTE = 'TL',
    TOGO = 'TG',
    TOKELAU = 'TK',
    TONGA = 'TO',
    TRINIDAD_AND_TOBAGO = 'TT',
    TUNISIA = 'TN',
    TURKEY = 'TR',
    TURKMENISTAN = 'TM',
    TURKS_AND_CAICOS_ISLANDS = 'TC',
    TUVALU = 'TV',
    UGANDA = 'UG',
    UKRAINE = 'UA',
    UNITED_ARAB_EMIRATES = 'AE',
    UNITED_KINGDOM = 'GB',
    UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'UM',
    UNITED_STATES_OF_AMERICA = 'US',
    URUGUAY = 'UY',
    UZBEKISTAN = 'UZ',
    VANUATU = 'VU',
    VENEZUELA_BOLIVARIAN_REPUBLIC_OF = 'VE',
    VIET_NAM = 'VN',
    VIRGIN_ISLANDS_BRITISH = 'VG',
    VIRGIN_ISLANDS_US = 'VI',
    WALLIS_AND_FUTUNA = 'WF',
    WESTERN_SAHARA = 'EH',
    YEMEN = 'YE',
    ZAMBIA = 'ZM',
    ZIMBABWE = 'ZW'
}
