/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Pipe, PipeTransform } from '@angular/core';
/**
 * This pipe will conditionally transform the string to uppercase
 * By default the value of shouldUppercase is true
 *
 */
@Pipe({
  name: 'conditionalUppercase'
})
export class ConditionalUppercasePipe implements PipeTransform {
  public transform(value: string, shouldUppercase: boolean = true): string {
    if (!value || !shouldUppercase) {
      return value;
    }
    return value.toUpperCase();
  }
}
