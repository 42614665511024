/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component } from '@angular/core';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { DatabaseService } from '../services/http/database.service';
import { ExcelService } from 'pcs-commons/statistics';
import { QueryDto } from '../datatypes/QueryDto';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Message } from '../datatypes/message';
import { Utils } from '../utils/utils';
import { UserDataSourceService } from '../services/user-data-source.service';

@Component({
  selector: 'pcs-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.css']
})
export class DatabaseComponent extends AbstractNotificationHandler {
  public loading = false;

  public queryResultComplete: Map<string, unknown>[];
  public queryResultSubject = new BehaviorSubject<Map<string, unknown>[]>([]);

  public queryResultSubject$ = this.queryResultSubject.asObservable();

  public dataColumns = null;

  public resultLength = 0;

  constructor(
    private databaseService: DatabaseService,
    private excelService: ExcelService,
    private userDataSourceService: UserDataSourceService
  ) {
    super();
  }

  private getQueryResult(query: QueryDto): void {
    this.loading = true;
    this.databaseService.getQueryResults(query).subscribe({
      next: (queryResult) => {
        this.queryResultComplete = queryResult;
        this.handleQueryResult(this.queryResultComplete);
        this.loading = false;
      },
      error: () => {
        this.handleOnQueryResultError();
        this.loading = false;
      }
    });
  }

  public async executeQuery(query: QueryDto): Promise<void> {
    if (query.query) {
      query.userLogin = (await firstValueFrom(this.userDataSourceService.currentUserAuthDetails$)).login;
      this.getQueryResult(query);
    }
  }

  private handleOnQueryResultError(): void {
    this.resultLength = 0;
    this.queryResultSubject.next([]);
  }

  private handleQueryResult(queryResult: Map<string, unknown>[]): void {
    this.resultLength = queryResult.length;
    console.log('Found results: ' + queryResult.length);
    if (queryResult.length > 0) {
      this.dataColumns = Object.keys(queryResult[0]);
      if (this.dataColumns.includes('id')) {
        Utils.moveToFirst('id', this.dataColumns);
      }
    }
    this.queryResultSubject.next(queryResult);
    if (queryResult.length <= 0) {
      const msg = new Message();
      msg.message = 'database.noRecordsFound';
      this.showInfo(msg);
    }
  }

  public onDownloadResults(): void {
    this.loading = true;
    console.log('Converting query result to excel. Number of lines: ', this.resultLength);
    const columns = Object.assign([], this.dataColumns);
    const completeResult = Object.assign([], this.queryResultComplete);
    this.excelService.exportAsExcelFile(
      completeResult,
      'database-export',
      'Query-Result' + new Date().toISOString().split('T')[0],
      columns // make a copy since the xlsx lib for some reason can change this array
    );
    this.loading = false;
  }
}
