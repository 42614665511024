/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Parameters} from './global/parameters';
import {AuthenticationService} from './auth/authentication.service';
import {UserDataSourceService} from './services/user-data-source.service';
import {HttpErrorHandlerService} from 'pcs-commons/error-management';
import {HttpRequestHelper} from 'pcs-commons/http';

@Injectable()
export class WebconfigHttpInterceptor implements HttpInterceptor {
  public errorHandlingDisabled: boolean = undefined;
  public customErrorMsgBase: string = null;

  constructor(
    private errorHandlerService: HttpErrorHandlerService,
    private authenticationService: AuthenticationService,
    private userDataSource: UserDataSourceService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercepting http call: ', request.url, ' \nWith initial headers: ', request.headers);
    this.errorHandlingDisabled = undefined;
    this.customErrorMsgBase = null;
    let clonedReq = this.checkIfErrorHandlingDisabled(request);
    clonedReq = this.checkIfCustomErrorMsgBasePresent(clonedReq);
    clonedReq = HttpRequestHelper.setHttpParamEncoder(clonedReq);
    const authEnabledReq = this.addAuthHeader(clonedReq);


    console.log('Headers in actual call: ', authEnabledReq.headers);

    return next.handle(authEnabledReq)
      .pipe(
        tap({
          error: (error) => {
            this.handleError(error, this.errorHandlingDisabled, this.customErrorMsgBase);
          }
        })
      );
  }

  private handleError(error, errorHandlingDisabled: boolean, customErrorMsgBase: string): void {
    console.log('intercepting error. error handling disabled: ', errorHandlingDisabled, ' custom error message base: ', customErrorMsgBase);
    console.log('error status: ', error.status);
    // if the authorization is invalid, remove the auth token and notify the user
    if (error instanceof HttpErrorResponse && error.status === 401) {
      this.authenticationService.clearUserAuthDetails();
    }
    if (errorHandlingDisabled) {
      return;
    }
    this.errorHandlerService.handleError(error, customErrorMsgBase);
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this.userDataSource.currentAccessToken ?
      this.userDataSource.currentAccessToken : sessionStorage.getItem(Parameters.KEY_ACCESS_TOKEN);
    const authToken = 'Bearer ' + accessToken;
    // add authentication token
    return request.clone({
      headers: request.headers.set('Authorization', authToken)
    });
  }

  private checkIfErrorHandlingDisabled(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.headers.has(Parameters.HEADER_NO_ERROR_HANDLE)) {
      return request;
    }
    const noErrorHandling = request.headers.get(Parameters.HEADER_NO_ERROR_HANDLE);
    this.errorHandlingDisabled = noErrorHandling && 'true' === noErrorHandling;
    if (this.errorHandlingDisabled) {
      console.log('Auto error handling is disabled for this call');
    }
    const newHeaders = request.headers.delete(Parameters.HEADER_NO_ERROR_HANDLE);
    return request.clone({headers: newHeaders});
  }

  private checkIfCustomErrorMsgBasePresent(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.headers.has(Parameters.HEADER_ERROR_MSG_BASE)) {
      return request;
    }

    this.customErrorMsgBase = request.headers.get(Parameters.HEADER_ERROR_MSG_BASE);
    console.log('Request contains custom error message base as header: ', this.customErrorMsgBase);
    const newHeaders = request.headers.delete(Parameters.HEADER_ERROR_MSG_BASE);
    return request.clone({headers: newHeaders});
  }
}
