/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelper} from '../../utils/form-helper';
import {InvoiceFilterDto} from '../../datatypes/invoices/InvoiceFilterDto';

@Component({
  selector: 'invoices-filter',
  templateUrl: './invoices-filter.component.html',
  styleUrls: ['./invoices-filter.component.css']
})
export class InvoicesFilterComponent implements OnInit {
  filterFormGroup: FormGroup;
  vin: FormControl;
  contractId: FormControl;
  invoiceIdent: FormControl;
  ciamId: FormControl;
  porscheId: FormControl;

  @Output() filterEvent = new EventEmitter<InvoiceFilterDto>();

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.vin = new FormControl('', Validators.maxLength(100));
    this.contractId = new FormControl('', Validators.maxLength(100));
    this.invoiceIdent = new FormControl('', Validators.maxLength(200));
    this.ciamId = new FormControl('', Validators.maxLength(100));
    this.porscheId = new FormControl('', Validators.maxLength(100));
    this.filterFormGroup = this.formBuilder.group({
      vin: this.vin,
      contractId: this.contractId,
      invoiceIdent: this.invoiceIdent,
      ciamId: this.ciamId,
      porscheId: this.porscheId
    });
  }

  triggerFetchInvoices() {
    const filter = new InvoiceFilterDto();
    this.fillFilterData(filter);
    this.filterEvent.emit(filter);
  }

  private fillFilterData(filter: InvoiceFilterDto) {
    filter.ciamId = FormHelper.getStringValue(this.filterFormGroup, 'ciamId');
    filter.vin = FormHelper.getStringValue(this.filterFormGroup, 'vin');
    filter.invoiceIdent = FormHelper.getStringValue(this.filterFormGroup, 'invoiceIdent');
    filter.contractId = FormHelper.getStringValue(this.filterFormGroup, 'contractId');
    filter.porscheId = FormHelper.getStringValue(this.filterFormGroup, 'porscheId');
  }

  noFieldIsSet() {
    return !(this.ciamId.value || this.vin.value || this.contractId.value || this.porscheId.value || this.invoiceIdent.value);
  }

}
