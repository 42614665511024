/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { HeaderEnabled } from './header-enabled.abstract';
import { HttpClient } from '@angular/common/http';
import { ChargepointFilter } from '../../datatypes/chargepoint-filter';
import { Observable } from 'rxjs';
import { ChargepointOverviewAdminDto } from '../../datatypes/location/chargepoint/chargepoint-overview-admin-dto';
import { environment } from '../../../environments/environment';
import { LocationAdminDto } from '../../datatypes/location/location-admin-dto';
import { LocationAndChargepointAdminAttributeDto } from '../../datatypes/location/location-and-chargepoint-admin-attribute-dto';
import { UnusedChargepointFilter } from '../../datatypes/unused-chargepoint-filter';
import { GeoLocationDto } from '../../datatypes/location/chargepoint/geo-location-dto';
import { Parameters } from '../../global/parameters';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public getChargepointsByFilter(chargepointFilter: ChargepointFilter): Observable<ChargepointOverviewAdminDto[]> {
    return this.http.put<ChargepointOverviewAdminDto[]>(
      environment.locationServiceUrl + '/chargepoints/byfilter',
      chargepointFilter,
      { headers: this.headers }
    );
  }

  public getUnusedChargepointsByFilter(chargepointFilter: UnusedChargepointFilter): Observable<ChargepointOverviewAdminDto[]> {
    return this.http.put<ChargepointOverviewAdminDto[]>(
      environment.locationServiceUrl + '/unusedchargepoints/byfilter',
      chargepointFilter,
      { headers: this.headers }
    );
  }

  public getLocationByGroupId(locationGroupId: string): Observable<LocationAdminDto> {
    const url = environment.locationServiceUrl + '/' + locationGroupId;
    return this.http.get<LocationAdminDto>(url, { headers: this.headers });
  }

  public getGeoLocationsByGroupId(locationGroupId: string): Observable<GeoLocationDto> {
    const url = environment.locationServiceUrl + '/geo/' + locationGroupId;
    return this.http.get<GeoLocationDto>(url, { headers: this.headers });
  }

  public updateChargepointAttributes(attributes: LocationAndChargepointAdminAttributeDto): Observable<void> {
    const url = environment.locationServiceUrl + '/attributes';
    return this.http.put<void>(url, attributes, { headers: this.headers });
  }

  public validatePutChargepointAttributes(attributes: LocationAndChargepointAdminAttributeDto): Observable<void> {
    const url = environment.locationServiceUrl + '/attributes/validate';
    return this.http.post<void>(url, attributes, { headers: this.headers });
  }

  public retrieveChargepointRawData(cpId: string): Observable<string> {
    this.headers = this.headers.append(Parameters.HEADER_ERROR_MSG_BASE, 'chargepoint.rawdata.error.');
    const url = environment.locationServiceUrl + '/chargepoints/raw/' + cpId;
    return this.http.get<string>(url, { headers: this.headers });
  }
}
