/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {FormErrorHandler} from '../common/form-error-handler.abstract';
import {ValidationMessagePipe} from 'pcs-commons/validation';
import {Utils} from '../utils/utils';
import {Parameters} from '../global/parameters';

@Injectable({
  providedIn: 'root'
})
export class InputValidationService extends FormErrorHandler {

  constructor(validationPipe: ValidationMessagePipe) {
    super(validationPipe);
  }

  public numericOnly(event: any): void {
    Utils.validateNumeric(event);
  }

  public allowPhoneNoOnlyChar(event: any): void {
    Utils.validatePhoneNumber(event);
  }

  public allowDoubleChars(event: any): void {
    this.allowIfPatternMatch(event, Parameters.DOUBLE_CHARS);
  }

  public allowIfPatternMatch(event: any, pattern: RegExp): void {
    // allow paste
    if (event.ctrlKey
      && (event.key === 'v' || event.key === 'V')) {
      return;
    }
    const inputChar = event.key;
    if (event.code && (event.code.toUpperCase() !== ('Backspace').toUpperCase()) && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
