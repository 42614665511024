<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents class="edit-currencyFactors-dialog">
  <h2 mat-dialog-title>{{
      'currencyfactor.edit.dialogue.header'| translate :{currency: currency}
    }}
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
    <button mat-raised-button class="headerActionBtn addCurrencyFactor icon-only" (click)="addRecord()">
      <mat-icon> add</mat-icon>
    </button>
  </h2>
  <mat-toolbar class="error error-toolbar" *ngIf="showDateRangeError()">
    <span class="error-span">{{ getError(editCurrencyFactorForm) | async }}</span>
  </mat-toolbar>
  <div>
    <form [formGroup]="editCurrencyFactorForm">
      <pcs-edit-currency-factors-table (deleteEvent)="deleteRecord($event)"
                                       *ngIf="editCurrencyFactorForm"
                                       [actionButtons]="true"
                                       [dataColumns]="dataColumns"
                                       [data]="currencyFactorsToEdit$"
                                       [editCurrencyFactorForm]="editCurrencyFactorForm"
                                       [i18nBase]="'currencyfactor'">
      </pcs-edit-currency-factors-table>
    </form>

    <mat-dialog-actions>
      <button okAction mat-raised-button class="dialogueButton dialogueButtonRight"
              [disabled]="!allInputsValid()"
              (click)="onSave()">
        {{ 'button.save' | translate }}
      </button>
      <button cancelAction mat-raised-button class="dialogueButton"
              (click)="onCancel()">
        {{ 'button.cancel' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
