/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import { ActivatedRoute } from '@angular/router';
import { AttributeRow } from '../data-table/attribute-table/attribute-row';
import { TableConfigurations } from '../data-table/table-configurations';
import { Action } from '../datatypes/action.enum';
import { IdentifiableTableElement } from '../datatypes/identifiable-table-element';
import { IopPlatformDto } from '../datatypes/iop-platform-dto';
import { AttributeValueDto } from '../datatypes/location/attribute-value-dto';
import { LocationDtoAttributeName } from '../datatypes/location/location-dto-attribute-name.enum';
import { Message } from '../datatypes/message';
import { Parameters } from '../global/parameters';

export class Utils {
  private constructor() {
    // keep me
  }

  /**
   * this method will use time in millis plus a random number at the end
   * this is to help tables identify elements if no other identifier isn't present
   * @param prefix the prefix
   */
  public static generateStringId(prefix: string): string {
    const uniqueNumber = (Date.now().toString(36) + Math.random().toString(36).substring(2, 7)).toUpperCase();
    return prefix ? prefix + uniqueNumber : uniqueNumber;
  }

  public static removeArrayItem<T>(sourceArray: T[], item: T): void {
    const index = sourceArray.indexOf(item, 0);
    if (index > -1) {
      sourceArray.splice(index, 1);
    }
  }

  public static removeArrayItemByTableIdentifier(sourceArray: IdentifiableTableElement[], item: IdentifiableTableElement): void {
    const index = sourceArray.findIndex((value) => value.tElemId === item.tElemId);
    if (index > -1) {
      sourceArray.splice(index, 1);
    }
  }

  public static addArrayItem<T>(sourceArray: T[], item: T): void {
    let i: number;
    for (i = 0; i < sourceArray.length; i++) {
      if (sourceArray[i] === item) {
        // avoid duplicates
        return;
      }
      if (sourceArray[i] > item) {
        // add here in sorted order
        sourceArray.splice(i, 0, item);
        return;
      }
    }
    sourceArray.push(item);
  }

  /**
   * validates if the input contains any character the matches 0-9, +, /, -
   */
  public static validatePhoneNumber(event: KeyboardEvent): void {
    // allow paste
    if (event.ctrlKey
      && (event.key === 'v' || event.key === 'V')) {
      return;
    }
    const inputChar = event.key;
    if (event.code && (event.code.toUpperCase() !== ('Backspace').toUpperCase()) && !Parameters.PHONE_PATTERN.test(inputChar)) {
      event.preventDefault();
    }
  }

  public static validateNumeric(event: KeyboardEvent): void {
    const inputChar = event.key;
    if (event.code && (event.code.toUpperCase() !== ('Backspace').toUpperCase()) && !Parameters.NUMERIC_PATTERN.test(inputChar)) {
      event.preventDefault();
    }
  }

  public static deletePurchaseElementConfirmMessage(): Message {
    const msg = new Message();
    msg.message = 'purchaseCondition.element.delete.confirm';
    return msg;
  }

  public static activatePurchaseConditionConfirmMessage(): Message {
    const msg = new Message();
    msg.message = 'purchaseCondition.activate.confirm';
    return msg;
  }

  public static changePurchaseConValidUntilConfirmMessage(): Message {
    return new Message('purchaseCondition.change.validUntil.confirm');
  }

  public static deactivatePurchaseConditionConfirmMessage(): Message {
    const msg = new Message();
    msg.message = 'purchaseCondition.deactivate.confirm';
    return msg;
  }

  public static deletedStatusOfPurchaseConditionConfirmMessage(): Message {
    const msg = new Message();
    msg.message = 'purchaseCondition.deleteStatus.confirm';
    return msg;
  }

  public static deleteManagerConfirmMessage(login: string): Message {
    const msg = new Message();
    msg.message = 'fleet.manager.delete.confirm';
    msg.param0 = login;
    return msg;
  }

  public static deleteProductConfirmMessage(code: string): Message {
    const msg = new Message();
    msg.message = 'fleet.product.delete.confirm';
    msg.param0 = code;
    return msg;
  }

  public static deleteGroupConfirmMessage(name: string): Message {
    return new Message('usermanagement.groups.delete.confirm', name);
  }

  public static deleteUserConfirmMessage(name: string): Message {
    return new Message('usermanagement.users.delete.confirm', name);
  }

  public static deleteQueryConfirmMessage(name: string): Message {
    return new Message('database.query.delete.confirm', name);
  }

  public static deleteFleetConfirmMessage(code: string): Message {
    const msg = new Message();
    msg.message = 'fleet.delete.confirm';
    msg.param0 = code;
    return msg;
  }

  public static deactivateFleetConfirmMessage(code: string): Message {
    const msg = new Message();
    msg.message = 'fleet.deactivate.confirm';
    msg.param0 = code;
    return msg;
  }

  public static activateFleetConfirmMessage(code: string): Message {
    const msg = new Message();
    msg.message = 'fleet.activate.confirm';
    msg.param0 = code;
    return msg;
  }

  public static deletePurchaseConditionConfirmMessage(cpo: string): Message {
    const msg = new Message();
    msg.message = 'purchaseCondition.delete.confirm';
    msg.param0 = cpo;
    return msg;
  }

  public static findIncludedPlatforms(attributeValueMap: Map<string, Map<string, AttributeValueDto>>): string[] {
    console.log('fetching platforms for attributeValueMap: ', attributeValueMap);
    const finalPlatformList: string[] = [];
    const attributes = Object.keys(attributeValueMap);
    // filter out the attributes that are not considered a direct location/chargepoint attribute
    attributes.filter((attribute) => !TableConfigurations.platformCommonAttributes.includes(attribute))
      .forEach((attribute) => {
        const platformValueMap = attributeValueMap[attribute];
        if (attribute === LocationDtoAttributeName.ADMIN_COMMENTS) {
          console.log('admin comment values: ', platformValueMap);
        }
        const platforms = Object.keys(platformValueMap);
        platforms.forEach((p) => {
          if (!finalPlatformList.includes(p)) {
            finalPlatformList.push(p);
          }
        });
      });
    console.log('platform set extracted from attribute value map: ', finalPlatformList);
    return finalPlatformList;
  }

  public static sortByReferencePlatformArray(unsortedPlatforms: string[], referencePlatformArray: IopPlatformDto[]): string[] {
    console.log('included platform before sorting: ', unsortedPlatforms);
    let newSortedList: string[] = [];
    // MANUAL_DATA_PLATFORM should always be the first one
    if (unsortedPlatforms.includes(Parameters.MANUAL_DATA_PLATFORM)) {
      newSortedList.push(Parameters.MANUAL_DATA_PLATFORM);
      this.removeArrayItem(unsortedPlatforms, Parameters.MANUAL_DATA_PLATFORM);
    }
    newSortedList = newSortedList.concat(
      unsortedPlatforms.sort(
        (a, b) =>
          referencePlatformArray.findIndex((p) => p.name === a) - referencePlatformArray.findIndex((p) => p.name === b)
      ));
    console.log('sorted platform list: ', newSortedList);
    return newSortedList;
  }

  public static sortRowByReferencedArray(unsortedRows: AttributeRow[], referenceArray: string[]): AttributeRow[] {
    let newSortedList: AttributeRow[] = [];
    newSortedList = newSortedList.concat(
      unsortedRows.sort(
        (a, b) => {
          const positionOfA = referenceArray.findIndex((s) => s === a.name);
          const positionOfB = referenceArray.findIndex((s) => s === b.name);
          if (positionOfA < 0 || positionOfB < 0) {
            return 0;
          }
          return positionOfA - positionOfB;
        }
      ));
    console.log('sorted rows: ', newSortedList);
    return newSortedList;
  }

  public static sortArrayByStringProperty(arrayToSort: any[], property: string): void {
    arrayToSort.sort((a, b) => {
      if ((!a[property] && b[property]) || (b[property] && a[property].toLowerCase() < b[property].toLowerCase())) {
        return -1;
      }
      if ((!b[property] && a[property]) || (a[property] && a[property].toLowerCase() > b[property].toLowerCase())) {
        return 1;
      }
      return 0;
    });
  }

  public static moveToFirst<T>(elToMove: T, sourceArray: T[]): void {
    if (sourceArray.includes(elToMove)) {
      sourceArray.splice(sourceArray.indexOf(elToMove), 1);
      sourceArray.unshift(elToMove);
    }
  }

  public static calcClassPriceFormControlName(chargepointClass: string, postfix: string): string {
    return chargepointClass + '_' + postfix;
  }

  public static purchasePriceFormControlName(priceType: string, propName: string): string {
    return priceType + '_' + propName;
  }

  public static getFormattedFormControlName(propName: string, index: number): string {
    return propName + '-' + index;
  }
  public static getPageMode(route: ActivatedRoute): Action {
    const mode = route.snapshot.queryParamMap.get('mode');
    switch (mode) {
      case Action.CREATE:
        return Action.CREATE;
      case Action.EDIT:
        return Action.EDIT;
      default:
        return Action.READ;
    }
  }

  public static isNotEmpty(s: string): boolean {
    return s !== undefined && s !== null && s !== '';
  }

  public static isEmpty(val: any): boolean {
    const empty = val === undefined || val === null;
    return empty || (String(val)).trim() === '';
  }

  public static objectNotEmpty(obj: any): boolean {
    return !this.isEmpty(obj);
  }

  public static arrayEmpty(array: any[]): boolean {
    return array === undefined || array === null || array.length < 1;
  }

  public static randomString(len, charSet?): string {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }
}
