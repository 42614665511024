/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import { Pipe, PipeTransform } from '@angular/core';
import { TableConfigurations } from '../../data-table/table-configurations';
/**
 * This pipe will truncate the string to the limit mentioned
 * By default it is 30  chars (27 + 3 for '...')
 */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  private ellipsis = '...';

  public transform(value: string, colName: string): any {
    if (!value) {
      return value;
    }

    const limit = TableConfigurations.fieldLimit.get(colName);
    if (!limit || value.length <= (limit + this.ellipsis.length)) {
      return value;
    }
    console.log(`truncating field ${colName} to ${limit}`);

    return `${value.substring(0, limit)}${this.ellipsis}`;
  }

}
