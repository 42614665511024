/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AddressType, CountryCode, FleetAddress} from 'pcs-commons/datatypes';
import {FormUtils} from 'pcs-commons/utils';
import {ValidatorList} from 'pcs-commons/validation';
import {Output, EventEmitter, Directive} from '@angular/core';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AddressFormManager {
  public ciamId: FormControl;
  public title: FormControl;
  public salutation: FormControl;
  public firstName: FormControl;
  public middleName: FormControl;
  public lastName: FormControl;
  public email: FormControl;
  // this is exclusive for billing address
  public invoiceLanguage: FormControl;
  public vatId: FormControl;
  public country: FormControl;
  public district: FormControl;
  public region: FormControl;
  public city: FormControl;
  public postalCode: FormControl;
  public postalCodeRegionValue: FormControl;
  public street1: FormControl;
  public street2: FormControl;
  public street3: FormControl;
  public houseNumber: FormControl;
  public buildingCode: FormControl;
  public floor: FormControl;
  public roomNumber: FormControl;
  public coName: FormControl;
  public companyName1: FormControl;
  public companyName2: FormControl;
  public poBox: FormControl;
  public poBoxCity: FormControl;
  public poBoxPostalCode: FormControl;
  public supplement: FormControl;

  public addressForm: FormGroup;

  public isRegionRequired = false;
  public isVatIdRequired = false;
  public isCompanyRequired = false;

  @Output() public formInitEvent = new EventEmitter<FormGroup>(null);

  public static fetchAddressData(addressForm: FormGroup): FleetAddress {
    if (!addressForm) {
      return null;
    }
    const result = new FleetAddress();
    result.ciamId = FormUtils.getStringValue(addressForm, 'ciamId');
    result.title = FormUtils.getStringValue(addressForm, 'title');
    result.salutation = FormUtils.getStringValue(addressForm, 'salutation');
    result.firstName = FormUtils.getStringValue(addressForm, 'firstName');
    result.middleName = FormUtils.getStringValue(addressForm, 'middleName');
    result.lastName = FormUtils.getStringValue(addressForm, 'lastName');
    result.email = FormUtils.getStringValue(addressForm, 'email');
    result.invoiceLanguage = FormUtils.getStringValue(addressForm, 'invoiceLanguage');
    result.vatId = FormUtils.getStringValue(addressForm, 'vatId');
    result.country = FormUtils.getStringValue(addressForm, 'country');
    result.district = FormUtils.getStringValue(addressForm, 'district');
    result.region = FormUtils.getStringValue(addressForm, 'region');
    result.city = FormUtils.getStringValue(addressForm, 'city');
    result.postalCode = FormUtils.getStringValue(addressForm, 'postalCode');
    result.postalCodeRegionValue = FormUtils.getStringValue(addressForm, 'postalCodeRegionValue');
    result.street1 = FormUtils.getStringValue(addressForm, 'street1');
    result.street2 = FormUtils.getStringValue(addressForm, 'street2');
    result.street3 = FormUtils.getStringValue(addressForm, 'street3');
    result.houseNumber = FormUtils.getStringValue(addressForm, 'houseNumber');
    result.buildingCode = FormUtils.getStringValue(addressForm, 'buildingCode');
    result.floor = FormUtils.getStringValue(addressForm, 'floor');
    result.roomNumber = FormUtils.getStringValue(addressForm, 'roomNumber');
    result.coName = FormUtils.getStringValue(addressForm, 'coName');
    result.companyName1 = FormUtils.getStringValue(addressForm, 'companyName1');
    result.companyName2 = FormUtils.getStringValue(addressForm, 'companyName2');
    result.poBox = FormUtils.getStringValue(addressForm, 'poBox');
    result.poBoxCity = FormUtils.getStringValue(addressForm, 'poBoxCity');
    result.poBoxPostalCode = FormUtils.getStringValue(addressForm, 'poBoxPostalCode');
    result.supplement = FormUtils.getStringValue(addressForm, 'supplement');
    return result;
  }

  private static getDefaultFormControl(): FormControl {
    return new FormControl('', ValidatorList.ADDRESS_DEFAULT);
  }

  private static getDefaultRequiredFormControl(): FormControl {
    return new FormControl('', ValidatorList.ADDRESS_DEFAULT_REQUIRED);
  }

  protected constructor(
    public formBuilder: FormBuilder) {
  }

  protected defineFormControls(addressType: AddressType): void {
    this.ciamId = AddressFormManager.getDefaultRequiredFormControl();
    this.title = new FormControl('', ValidatorList.ADDRESS_TITLE);
    this.salutation = AddressFormManager.getDefaultFormControl();
    this.firstName = AddressFormManager.getDefaultFormControl();
    this.middleName = AddressFormManager.getDefaultFormControl();
    this.lastName = AddressFormManager.getDefaultFormControl();
    this.email = new FormControl('', AddressType.BILLING === addressType ? ValidatorList.ADDRESS_EMAIL : []);
    this.invoiceLanguage = new FormControl('', AddressType.BILLING === addressType ? ValidatorList.ADDRESS_LANGUAGE : []);
    this.vatId = new FormControl('',
      AddressType.BILLING === addressType ? ValidatorList.ADDRESS_DEFAULT_REQUIRED : ValidatorList.ADDRESS_DEFAULT);
    this.country = new FormControl('', ValidatorList.ADDRESS_COUNTRY);
    this.district = AddressFormManager.getDefaultFormControl();
    this.region = AddressFormManager.getDefaultFormControl();
    this.city = AddressFormManager.getDefaultRequiredFormControl();
    this.postalCode = AddressFormManager.getDefaultRequiredFormControl();
    this.postalCodeRegionValue = AddressFormManager.getDefaultFormControl();
    this.street1 = AddressFormManager.getDefaultRequiredFormControl();
    this.street2 = AddressFormManager.getDefaultFormControl();
    this.street3 = AddressFormManager.getDefaultFormControl();
    this.houseNumber = AddressFormManager.getDefaultRequiredFormControl();
    this.buildingCode = AddressFormManager.getDefaultFormControl();
    this.floor = AddressFormManager.getDefaultFormControl();
    this.roomNumber = AddressFormManager.getDefaultFormControl();
    this.coName = AddressFormManager.getDefaultFormControl();
    this.companyName1 = new FormControl('',
      AddressType.BILLING === addressType ? ValidatorList.ADDRESS_DEFAULT_REQUIRED : ValidatorList.ADDRESS_DEFAULT);
    this.companyName2 = AddressFormManager.getDefaultFormControl();
    this.poBox = AddressFormManager.getDefaultFormControl();
    this.poBoxCity = AddressFormManager.getDefaultFormControl();
    this.poBoxPostalCode = AddressFormManager.getDefaultFormControl();
    this.supplement = new FormControl('', ValidatorList.ADDRESS_SUPPLEMENT);

    this.addressForm = this.formBuilder.group({
      title: this.title,
      salutation: this.salutation,
      firstName: this.firstName,
      middleName: this.middleName,
      lastName: this.lastName,
      buildingCode: this.buildingCode,
      coName: this.coName,
      companyName1: this.companyName1,
      companyName2: this.companyName2,
      country: this.country,
      district: this.district,
      region: this.region,
      city: this.city,
      floor: this.floor,
      houseNumber: this.houseNumber,
      poBox: this.poBox,
      poBoxCity: this.poBoxCity,
      poBoxPostalCode: this.poBoxPostalCode,
      postalCode: this.postalCode,
      postalCodeRegionValue: this.postalCodeRegionValue,
      roomNumber: this.roomNumber,
      street1: this.street1,
      street2: this.street2,
      street3: this.street3,
      supplement: this.supplement,
      vatId: this.vatId,
      ciamId: this.ciamId,
      email: this.email,
      invoiceLanguage: this.invoiceLanguage
    });

    if (AddressType.BILLING === addressType) {
      this.isVatIdRequired = true;
      this.isCompanyRequired = true;
    }

    this.country.valueChanges.subscribe((newValue) => {
      if (CountryCode.UNITED_STATES_OF_AMERICA === newValue || CountryCode.CANADA === newValue) {
        console.log('setting region as required');
        this.region.setValidators([Validators.required]);
        this.isRegionRequired = true;
      } else {
        this.region.clearValidators();
        this.isRegionRequired = false;
      }
    });
    // emit the FormGroup for the parent
    this.formInitEvent.emit(this.addressForm);
  }

  protected updateFormValues(address: FleetAddress): void {
    this.ciamId.setValue(address.ciamId);
    this.title.setValue(address.title);
    this.salutation.setValue(address.salutation);
    this.firstName.setValue(address.firstName);
    this.middleName.setValue(address.middleName);
    this.lastName.setValue(address.lastName);
    this.email.setValue(address.email);
    // this is exclusive for billing address
    this.invoiceLanguage.setValue(address.invoiceLanguage);
    this.vatId.setValue(address.vatId);
    this.country.setValue(address.country);
    this.district.setValue(address.district);
    this.region.setValue(address.region);
    this.city.setValue(address.city);
    this.postalCode.setValue(address.postalCode);
    this.postalCodeRegionValue.setValue(address.postalCodeRegionValue);
    this.street1.setValue(address.street1);
    this.street2.setValue(address.street2);
    this.street3.setValue(address.street3);
    this.houseNumber.setValue(address.houseNumber);
    this.buildingCode.setValue(address.buildingCode);
    this.floor.setValue(address.floor);
    this.roomNumber.setValue(address.roomNumber);
    this.coName.setValue(address.coName);
    this.companyName1.setValue(address.companyName1);
    this.companyName2.setValue(address.companyName2);
    this.poBox.setValue(address.poBox);
    this.poBoxCity.setValue(address.poBoxCity);
    this.poBoxPostalCode.setValue(address.poBoxPostalCode);
    this.supplement.setValue(address.supplement);
  }

}
