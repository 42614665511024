/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {StringIdentifiable} from './string-identifiable';

export class IopPlatformDto implements StringIdentifiable {
  constructor(public id: number,
              public name: string,
              public dataPriority: number,
              public chargingPriority: number) {
  }

  /**
   * Because Typescript is dumb, and it's HTTP library is dumb
   * The Response object conversion does not really convert it to the proper expected Object
   * @param platforms
   */
  public static toIopPlatformDto(platforms): IopPlatformDto[] {
    const platformList: IopPlatformDto[] = [];
    platforms.forEach(p => {
      platformList.push(new IopPlatformDto(p.id, p.name, p.dataPriority, p.chargingPriority));
    });
    return platformList;
  }

  public toIdentifiableString(): string {
    return this.name;
  }

}
