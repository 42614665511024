/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {FormControl, FormGroup} from '@angular/forms';
import {Utils} from '../../utils/utils';
import {FormUtils} from 'pcs-commons/utils';
import {TranslateService} from '@ngx-translate/core';
import {ValidationMessagePipe} from 'pcs-commons/validation';
import {Observable} from 'rxjs';

@Component({
  selector: 'edit-taxes-table',
  templateUrl: './edit-taxes-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css',
    '../data-table.component.css',
    './edit-taxes-table.component.css']
})
export class EditTaxesTableComponent extends OptimizedTableBaseComponent {
  @Input() public editTaxesForm: FormGroup;
  @Input() public currencies: string[];

  constructor(
    renderer: Renderer2,
    private translateService: TranslateService,
    private validationPipe: ValidationMessagePipe) {
    super(renderer);
  }

  public getFormControl(col: string, index: number): FormControl {
    const name = Utils.getFormattedFormControlName(col, index);
    const control = FormUtils.getFormControl(this.editTaxesForm, name);
    if (control instanceof FormControl) {
      return control;
    }
    return null;
  }

  public isMaxLengthError(col: string, index: number): boolean {
    const name = Utils.getFormattedFormControlName(col, index);
    const control = FormUtils.getFormControl(this.editTaxesForm, name);
    return control && control.hasError('maxlength');
  }

  public getDatePlaceholder(): any {
    return this.translateService.instant('global.choosedate.placeholder');
  }

  public getError(control: FormControl): Observable<any> {
    return this.validationPipe.transform(control);
  }
}
