/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {BehaviorSubject, Observable} from 'rxjs';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {SharedDataService} from '../../services/shared-data.service';
import {Message} from '../../datatypes/message';
import {BaseUrls} from '../../datatypes/base-urls.enum';
import {ManualChargepointDataImportResultDto} from '../../datatypes/manual-chargepoint-data-import-result-dto';
import {ActivatedRoute} from '@angular/router';
import {StringUtils} from 'pcs-commons/utils';
import {
  ManualChargepointDataImportErrorColumns,
  ManualChargepointDataImportErrorDto
} from '../../datatypes/manual-chargepoint-data-import-error-dto';
import {ChargepointAttributeImportType} from '../../datatypes/ChargepointAttributeImportType';

@Component({
  selector: 'pcs-chargepoint-attribute-import-result',
  templateUrl: './chargepoint-attribute-import-result.component.html',
  styleUrls: ['./chargepoint-attribute-import-result.component.css']
})
export class ChargepointAttributeImportResultComponent extends AbstractNotificationHandler implements OnInit {

  public cpAttributeImportResult$: Observable<ManualChargepointDataImportResultDto>;
  public cpAttributeImportResultTableColumns: string[] = Object.values(ManualChargepointDataImportErrorColumns);
  public selectedImportType: ChargepointAttributeImportType;
  public allRecordsCount: number;
  public successfullyImportedRecordsCount: number;
  private tableDataSource = new BehaviorSubject<ManualChargepointDataImportErrorDto[]>([]);
  public tableData$ = this.tableDataSource.asObservable();

  constructor(
    private router: WebConfigRouterService,
    private dataService: SharedDataService,
    private route: ActivatedRoute) {
    super();
  }

  public ngOnInit(): void {
    this.setImportType();
    this.cpAttributeImportResult$ = this.dataService.getCPAttributeImportResultObservable();
    this.cpAttributeImportResult$.subscribe((result) => {
      const msg = new Message();
      if (result.importErrors?.length > 0) {
        this.updateResultData(result);
        this.tableDataSource.next(result.importErrors);
        msg.message = 'cpAttributeImport.import.failed';
        this.showError(msg);
      } else {
        this.updateResultData(result);
        this.tableDataSource.next([]);
        msg.message = 'cpAttributeImport.import.success';
        msg.param0 = '' + result.successfullyImportedRecordsCount;
        msg.param1 = '' + result.allRecordsCount;
        this.showInfo(msg);
      }
    });
  }

  private setImportType(): void {
    const importType = this.route.snapshot.paramMap.get('importType');
    console.log('importType', importType);
    this.selectedImportType = StringUtils.stringToEnumValue(ChargepointAttributeImportType, importType);
    console.log('this.selectedImportType', this.selectedImportType);
    if (!this.selectedImportType) {
      this.selectedImportType = ChargepointAttributeImportType.IMPORT;
    }
  }

  private updateResultData(result: ManualChargepointDataImportResultDto): void {
    this.allRecordsCount = result.allRecordsCount;
    this.successfullyImportedRecordsCount = result.successfullyImportedRecordsCount;
  }

  public onBack(): void {
    this.router.navigate([BaseUrls.CHARGEPOINT_ATTRIBUTE_IMPORT]);
  }

  public resetExecuted(): boolean {
    return this.selectedImportType === ChargepointAttributeImportType.RESET;
  }
}
