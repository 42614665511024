<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents>
  <h2 mat-dialog-title>{{'ocpi.partner.activate.dialogue.header'| translate}}: {{ocpiPartner.partyId}}
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <mat-form-field appearance="outline">
    <mat-label>{{'ocpi.partner.charmToken' | translate}}</mat-label>
    <input matInput required [formControl]="token">
  </mat-form-field>

  <mat-dialog-actions>
    <button cancelAction mat-raised-button class="dialogueButton dialogueButtonRight"
            (click)="onCancel()">
      {{'button.cancel' | translate}}
    </button>
    <button okAction mat-raised-button class="dialogueButton"
            [disabled]="!allInputsValid()"
            (click)="onActivate()">
      {{'button.activate' | translate}}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
