<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="change-request-history">
  <span> {{'changeRequest.history'|translate}}</span>
  <div *ngIf="data" class="scrollable-table-container change-request-history-table">
    <mat-table #table [dataSource]="tableDataSource" matSort>
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <ng-container *ngIf="col==='created' || col=== 'sender'">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{getI18nBase(col) | translate}}</mat-header-cell>
          </ng-container>
          <ng-container *ngIf="col!=='created' && col!== 'sender'">
            <mat-header-cell *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          </ng-container>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'created' && col !== 'messageType' ">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'created' ">{{getDateTimeTranslation(element[col])}}</ng-container>
            <ng-container
              *ngIf="col === 'messageType' ">{{'changeRequest.message.type.' + element[col]|translate}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="dataColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>
