<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" id="db-query-result" class="div-container">
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource" matSort>
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container matColumnDef="{{col}}">
          <mat-header-cell *matHeaderCellDef mat-sort-header
                           [ngClass]="getColumnClass(col)">{{col}}</mat-header-cell>
          <mat-cell *matCellDef="let element "
                    [ngClass]="getColumnClass(col)"> {{element[col]}}</mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true">
  </mat-paginator>
</div>
