<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div style="display: flex; margin-top: 10px" *ngIf="getProductChoiceList().length > 0">
  <form [formGroup]="productForm" class="productForm">
    <mat-form-field *ngIf="productCode" appearance="outline" class="productCode">
      <mat-label>{{ 'fleet.product.productCode' | translate }}</mat-label>
      <mat-select required formControlName="productCode">
        <mat-option id="selectProductCode" *ngFor="let productCode of getProductChoiceList()" [value]="productCode">
          {{ productCode }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="productCode.invalid">{{ getProductCodeError() | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="maxAmount" class="maxAmount">
      <mat-label>{{ "fleet.product.maxAmount" | translate }}</mat-label>
      <input (keypress)="validateNumeric($event)" formControlName="maxAmount" matInput required style="height: 15px;">
      <mat-error *ngIf="maxAmount.invalid">{{ getMaxAmountError() | translate }}</mat-error>
    </mat-form-field>
  </form>
  <div editKeyEvents>
    <button okAction mat-raised-button class="actionBtn"
            (click)="onSave()" [disabled]="productForm.invalid || productForm.pristine">
      <mat-icon> done</mat-icon>
    </button>
    <button cancelAction mat-raised-button class="actionBtn"
            (click)="onClose()">
      <mat-icon> close</mat-icon>
    </button>
  </div>
</div>
