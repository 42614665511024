<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content *ngIf="!showUserCred" editKeyEvents>
    <h2 mat-dialog-title style="margin-bottom: 0;">
        <span>{{'usermanagement.users.create.header'|translate}}</span>
        <mat-spinner *ngIf="loading" [diameter]="30" [strokeWidth]="5"></mat-spinner>
    </h2>
    <form [formGroup]="addManagerForm" class="add-user-form">
        <mat-form-field appearance="outline">
            <mat-label>{{'fleet.manager.login' | translate}}</mat-label>
            <input formControlName="login" matInput required>
            <mat-error *ngIf="login.invalid">{{getError(login) | async}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'fleet.manager.password' | translate}}</mat-label>
            <input [type]="hidePass ? 'password' : 'text'" formControlName="password" matInput>
            <mat-error *ngIf="password.invalid">{{getError(password) | async}}</mat-error>
            <mat-icon (click)="hidePass = !hidePass" matSuffix>{{hidePass ? 'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{'fleet.manager.phone' | translate}}</mat-label>
            <input (keypress)="validatePhoneNumber($event)" formControlName="phone" matInput required>
            <mat-error *ngIf="phone.invalid">{{getError(phone) | async}}</mat-error>
        </mat-form-field>
    </form>

    <mat-dialog-actions>
        <button (click)="onAdd()" [disabled]="!allInputsValid()" class="dialogueButton dialogueButtonRight"
                mat-raised-button
                okAction>
            {{'button.add' | translate}}
        </button>
        <button (click)="onCancel()" cancelAction class="dialogueButton"
                mat-raised-button>
            {{'button.cancel' | translate}}
        </button>
    </mat-dialog-actions>
</mat-dialog-content>

<!--
  confirmation shown after creation: list of users just created
-->
<mat-dialog-content *ngIf="showUserCred" editKeyEvents>
    <user-cred-table [users]="userResponse"></user-cred-table>
    <mat-dialog-actions>
        <button (click)="onOk()" class="dialogueButton dialogueButtonRight" mat-raised-button
                okCancelAction>
            {{'button.ok' | translate}}
        </button>
    </mat-dialog-actions>
</mat-dialog-content>
