/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { inject } from '@angular/core';
import { ChangeRequestDetailsService } from './change-request-details.service';
import { ChangeRequestSharedDataService } from './change-request-shared-data.service';
import { ChangeRequestDetailsMode } from '../datatypes/inbox/change-request-details-mode';
import { ActivatedRouteSnapshot } from '@angular/router';

export async function changeRequestDetailsDataResolver(route: ActivatedRouteSnapshot): Promise<void> {
  const changeRequestDetailsService = inject(ChangeRequestDetailsService);
  const changeRequestSharedDataService = inject(ChangeRequestSharedDataService);
  const changeRequestId = route.queryParamMap.get('changeRequestId');
  const mode = route.queryParamMap.get('mode');
  changeRequestSharedDataService.clearChangeRequestSharedDataService();
  if (mode === ChangeRequestDetailsMode.EDIT) {
    await changeRequestDetailsService.getMyChangeRequestAndAdditionalData(changeRequestId);
  } else {
    await changeRequestDetailsService.getChangeRequestAndAdditionalData(changeRequestId);
  }
}
