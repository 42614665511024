/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {ChangeRequestOverviewDto, ChangeRequestDtoColumns} from "../../datatypes/inbox/change-request-overview-dto";
import {ChangeRequestTaskStatus} from "../../datatypes/inbox/change-request-task-status";
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'pcs-inbox-table',
  templateUrl: './inbox-table.component.html',
  styleUrls: [
    '../scrollable-table/scrollable-table.component.css',
    '../action-column-style.css',
    './inbox-table.component.css']
})
export class InboxTableComponent extends OptimizedTableBaseComponent {
  public unreadChangesColumn = ChangeRequestDtoColumns.UNREAD_CHANGES;

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public translateDate(element: ChangeRequestOverviewDto): any {
    const date = element.updated || element.created;
    return this.translateHelper.translateDateTimeToLocal(date);
  }

  public hasTodo(element: ChangeRequestOverviewDto): boolean {
    return element?.taskStatus === ChangeRequestTaskStatus.TODO;
  }
}

