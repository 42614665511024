/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Message, Notification } from 'pcs-commons/datatypes';
import { BehaviorSubject } from 'rxjs';

/**
 * Singleton holding the last recent error notification (error, info, warning) shown to user
 */
export class NotificationStore {
  public static instance = new NotificationStore();
  public notification = new BehaviorSubject<Notification>(undefined);

  /**
   * clears message and cleanup-timer (if running)
   */
  public clear(): void {
    this.notification.next(undefined);
  }

  /**
   * Stores notification and notifies subscribers.
   * Starts timer to auto-remove notification after 4 secs for notification level INFO and WARN
   * @param notification notification to be shown
   */
  public notify(notification: Notification): void {
    console.log('received notification ', notification);
    this.notification.next(notification);
  }
}

export function showInfo(msg: Message): void {
  NotificationStore.instance.notify(Notification.info(msg));
}

export function showWarning(msg: Message): void {
  NotificationStore.instance.notify(Notification.warn(msg));
}

export function showError(msg: Message): void {
  NotificationStore.instance.notify(Notification.error(msg));
}
