<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents>
    <h2
            mat-dialog-title>{{(isCreateMode() ? 'ocpi.partner.dialogue.header' : 'ocpi.partner.edit.dialogue.header')| translate}}
        <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
    </h2>
    <div *ngIf="!charmToken">
        <form *ngIf="partnerForm" [formGroup]="partnerForm" class="dialog-form">
            <mat-form-field appearance="outline">
                <mat-label>{{'ocpi.partner.partyId' | translate}}</mat-label>
                <input matInput required formControlName="partyId">
                <mat-error *ngIf="partyId?.invalid">{{getError(partyId) | async}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="url" appearance="outline">
                <mat-label>{{'ocpi.partner.url' | translate}}</mat-label>
                <input matInput required formControlName="url">
                <mat-error *ngIf="url?.invalid">{{getError(url) | async}}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="token" appearance="outline">
                <mat-label>{{'ocpi.partner.token' | translate}}</mat-label>
                <input matInput required formControlName="token">
                <mat-error *ngIf="token?.invalid">{{getError(token) | async}}</mat-error>
            </mat-form-field>
            <autocomplete-string-select [appearance]="'outline'"
                                        [label]="'ocpi.partner.countryCode'"
                                        [inputControl]="countryCode" [optionI18nBase]="'global.country.'"
                                        [optionList]="countryList"
                                        required>
            </autocomplete-string-select>
            <autocomplete-string-select [appearance]="'outline'"
                                        [inputControl]="platform"
                                        [label]="'ocpi.partner.platform'"
                                        [optionList]="platformList">
            </autocomplete-string-select>

            <mat-form-field *ngIf="certCn" appearance="outline">
                <mat-label>{{'ocpi.partner.certCn' | translate}}</mat-label>
                <input matInput formControlName="certCn">
                <mat-error *ngIf="certCn?.invalid">{{getError(certCn) | async}}</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{ "ocpi.partner.pullStaticData" | translate }}</mat-label>
                <mat-select formControlName="pullStaticDataOption">
                    <mat-option [value]="i"
                                *ngFor="let i of pullStaticDataOptionList">{{ 'ocpi.partner.pullStaticDataOption.' + i | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox
                    [formControl]="authCheckLocation">{{'ocpi.partner.authCheckLocation' | translate}}</mat-checkbox>
            <mat-checkbox
                    [formControl]="requestAuthorizationId">{{'ocpi.partner.requestAuthorizationId' | translate}}</mat-checkbox>
            <mat-checkbox
                    [formControl]="allowOfflineAuthentication">{{'ocpi.partner.allowOfflineAuthentication' | translate}}</mat-checkbox>
 
        </form>
        <mat-dialog-actions *ngIf="partnerForm">
            <button id="cancelBtn" cancelAction mat-raised-button class="dialogueButton dialogueButtonRight"
                    (click)="onCancel()">
                {{'button.cancel' | translate}}
            </button>
            <button id="saveBtn" okAction mat-raised-button class="dialogueButton"
                    (click)="onSave()"
                    [disabled]="!isSaveAllowed()">
                {{'button.save' | translate}}
            </button>
        </mat-dialog-actions>
    </div>
    <div *ngIf="charmToken">
        <div class="charm-token-area">
    <span class="charm-token">{{'ocpi.partner.charmToken' | translate }}*: {{charmToken}}
    </span>
            <button mat-mini-fab class="copy-token-btn edit mat-elevation-z0"
                    (click)="copyTokenToClipboard(charmToken)">
                <mat-icon> file_copy</mat-icon>
            </button>
        </div>
        <mat-hint class="charm-token-hint">* {{'ocpi.partner.charmToken.footnote' | translate}}</mat-hint>
        <mat-dialog-actions>
            <button okAction mat-raised-button class="dialogueButton dialogueButtonRight"
                    (click)="onOk()">
                {{'button.ok' | translate}}
            </button>
        </mat-dialog-actions>
    </div>
</mat-dialog-content>
