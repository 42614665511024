/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {SharedDataService} from '../services/shared-data.service';
import {Operator} from '../datatypes/operator';

@Injectable({
  providedIn: 'root'
})
export class CpoValidatorService implements Validator {
  private cpoList: Operator[] = [];

  constructor(dataSource: SharedDataService) {
    dataSource.getCurrentCpoListObservable().subscribe(nextCpoList => this.cpoList = nextCpoList);
  }

  // the control should have Operator object as value
  public validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const value = control.value;
    console.log('validating cpo: ', value, ' value Type: ', typeof value);
    let isValid = typeof value === 'object';
    if (isValid) {
      const match = this.cpoList.find(cpo => {
        const selectedCpoCode = value.cpoCode;
        const selectedCpoName = value.name;
        return selectedCpoCode ? cpo.cpoCode === selectedCpoCode : cpo.name === selectedCpoName;
      });
      isValid = !!match;
    }
    return isValid ? null : {invalidCpo: true};
  }
}
