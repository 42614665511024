/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Inject, Injectable} from '@angular/core';
import {ServiceErrors} from './service-errors.enum';
import {NotificationService, NotificationStore} from 'pcs-commons/notification';
import {ErrorRecord} from './errorrecord';
import {Observable, TimeoutError} from 'rxjs';
import {Message, Notification} from 'pcs-commons/datatypes';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Parameters} from "pcs-commons/global";

@Injectable({
  providedIn: 'root'
})
export class HttpErrorHandlerService {
  private static readonly SERVICE_ERROR_LIST: string[] = Object.keys(ServiceErrors);
  private static readonly HEADER_MAX_ALLOWED_SIZE = 'x-maximum-allowed-size';

  constructor(
    @Inject('ERROR_MSG_BASE') private defaultErrorMsgBase,
    private router: Router,
    private notificationService: NotificationService,
    private translateService: TranslateService) {
    console.log('[HttpErrorHandlerService] default error msg base: ', defaultErrorMsgBase);
  }

  private static timeoutError(): ErrorRecord {
    const errorRecord = new ErrorRecord();
    errorRecord.i18nError = 'message.error.TIMEOUT';
    return errorRecord;
  }

  private static parseSpecialErrors(status: number, headers: any): ErrorRecord {
    let errorMsg = '';
    let errorParam = '';
    if (status === 504) {
      return this.timeoutError();
    } else if (status === 0 || status.toString().startsWith('5')) {
      errorMsg = 'ErrorCompNotAvailable';
    } else if (status === 403) {
      errorMsg = 'message.error.forbidden';
    } else if (status === 404) {
      errorMsg = 'ErrorCompURLnotExists';
    } else if (status === 413) {
      const maxSize = headers ? headers.get(HttpErrorHandlerService.HEADER_MAX_ALLOWED_SIZE) : undefined;
      if (maxSize) {
        errorMsg = 'ErrorPayloadTooLargeWithParam';
        const maxSizeNumber = Number(maxSize);
        if (!isNaN(maxSizeNumber)) {
          errorParam = maxSizeNumber / Parameters.ONE_MB + ' MB';
        } else {
          errorParam = maxSize;
        }
      } else {
        errorMsg = 'ErrorPayloadTooLarge';
      }
    } else {
      errorMsg = 'ErrorCompUnexpected';
    }
    const errorRecord = new ErrorRecord();
    errorRecord.i18nError = errorMsg;
    errorRecord.param0 = errorParam;
    return errorRecord;
  }

  public handleError(errorResponse: any, errorMessageBase?: string): void {
    console.log('handling http call error');
    if (errorResponse.error instanceof Blob) {
      this.parseBlobError(errorResponse).subscribe({
        next: () => {
        },
        error: (error) => this.manageErrorResponse(error, errorMessageBase)
      });
      return;
    }
    this.manageErrorResponse(errorResponse, errorMessageBase);
  }

  private manageErrorResponse(errorResponse: any, errorMessageBase: string): void {
    const errorI18n = this.getPrettyError(errorResponse, errorMessageBase);
    if (errorI18n) {
      this.showError(errorI18n.toMessage());
    }
  }

  public getPrettyError(errorResponse: any, errorMessageBase?: string): ErrorRecord {
    console.log('error response: ', errorResponse);
    if (errorResponse instanceof TimeoutError) {
      console.log('handling timeout error');
      return HttpErrorHandlerService.timeoutError();
    }

    // if error code is 401, show snackbar and then redirect to login page
    if (errorResponse.status === 401) {
      this.notificationService.notifyInvalidAuth();
      return null;
    }

    console.log('this.router.getCurrentUrl()', this.router.url);
    const isStatisticsView = this.router.url?.startsWith('/statistic');
    if (isStatisticsView && errorResponse.status === 504) {
      const errorRecord = new ErrorRecord();
      errorRecord.i18nError = 'statistic.error.timeout';
      return errorRecord;
    }

    if ((400 <= errorResponse.status && errorResponse.status < 500)
      && errorResponse.status !== 403
      && errorResponse.status !== 404
      && errorResponse.status !== 413) {
      return this.handleServiceError(errorResponse, errorMessageBase);
    }
    return HttpErrorHandlerService.parseSpecialErrors(errorResponse.status, errorResponse.headers);
  }

  private handleServiceError(errorResponse: any, errorMessageBase?: string): ErrorRecord {
    const serviceError = errorResponse.error;
    const errorRecord = new ErrorRecord();

    const expectedServiceErrors = HttpErrorHandlerService.SERVICE_ERROR_LIST || new Array<string>();
    if (expectedServiceErrors.includes(serviceError.error)) {
      errorRecord.i18nError = this.determineErrorMessageKey(errorMessageBase, serviceError);

      // check if translation exist
      if (serviceError.args.length > 0) {
        errorRecord.param0 = serviceError.args[0] || "" + serviceError.args[0];
      }
      if (serviceError.args.length > 1) {
        errorRecord.param1 = serviceError.args[1] || "" + serviceError.args[1];
      }
      if (serviceError.args.length > 2) {
        errorRecord.param2 = serviceError.args[2] || "" + serviceError.args[2];
      }
    } else {
      errorRecord.i18nError = 'message.error.FALLBACK';
      errorRecord.param0 = serviceError.error;
      errorRecord.param1 = JSON.stringify(errorResponse.error);
    }
    return errorRecord;
  }

  private parseBlobError(errorResponse: HttpErrorResponse): Observable<any> {
    console.log('handling blob type error: ', errorResponse);
    const reader: FileReader = new FileReader();
    const obs = new Observable((subscriber) => {
      reader.onloadend = () => {
        subscriber.error(new HttpErrorResponse({
          error: JSON.parse(reader.result as string),
          status: errorResponse.status
        }));
      };
    });
    reader.readAsText(errorResponse.error);
    return obs;
  }

  private determineErrorMessageKey(errorMessageBase: string, serviceError): string {
    let errMsgKey = '';
    if (!errorMessageBase) {
      return this.defaultErrorMsgBase + serviceError.error;
    } else {
      // check if translation exist
      errMsgKey = errorMessageBase + serviceError.error;
      if (this.translateService.instant(errMsgKey) === errMsgKey) {
        // means translation does not exist, try using default msg base
        errMsgKey = this.defaultErrorMsgBase + serviceError.error;
      }
    }
    return errMsgKey;
  }

  public showError(msg: Message): void {
    this.showNotification(Notification.error(msg));
  }

  public showNotification(notification: Notification): void {
    NotificationStore.instance.notify(notification);
  }
}
