/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {TranslationHelperService} from 'pcs-commons/http';
import {DateTime} from "luxon";

@Pipe({
  name: 'translateDateFromDateTime'
})
export class TranslateDateFromDateTimePipe implements PipeTransform {

  constructor(private translateService: TranslationHelperService) {
  }

  public transform(value: string, update?: Date): string {
    return value ? this.translateService.translateDateFromDateTimeSafe(value) : '';
  }

}
