/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {AccessRights} from '../datatypes/access-rights.enum';
import {AuthorizationService} from "../auth/authorization.service";

@Pipe({
  name: 'accessAllowed'
})
export class AccessAllowedPipe implements PipeTransform {

  constructor(private authorizationService: AuthorizationService) {
  }

  public transform(requiredPermission: AccessRights[]): boolean {
    return this.authorizationService.hasPermission(requiredPermission);
  }
}
