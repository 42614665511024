/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {InvoiceOverview, InvoiceOverviewColumns} from 'pcs-commons/datatypes';
import {MatSort} from "@angular/material/sort";
import {Subscription} from "rxjs";
import {FormBuilder, FormControl, UntypedFormGroup} from "@angular/forms";
import {TranslationHelperService} from 'pcs-commons/http';
import {Utils} from 'pcs-commons/utils';
import {PaginatedTableBaseComponent} from 'pcs-commons/data-tables';

@Component({
  selector: 'pcs-fleet-invoice-table',
  templateUrl: './fleet-invoices-table.component.html',
  styleUrls: ['../data-tables-style/action-column-style.css', '../data-tables-style/scrollable-table.component.css', './fleet-invoices-table.component.css']
})
export class FleetInvoicesTableComponent extends PaginatedTableBaseComponent implements AfterViewInit, OnDestroy, OnInit {
  public readonly invoiceDateTimeCol: string = InvoiceOverviewColumns.INVOICE_DATE_TIME;
  public readonly nameCol: string = InvoiceOverviewColumns.INVOICE_NAME;
  public readonly syncStatusProp = 'syncStatus';
  public readonly downloadColumns: string[] = [InvoiceOverviewColumns.DOWNLOAD_INVOICE, InvoiceOverviewColumns.DOWNLOAD_BILLEDITEMS_FE, InvoiceOverviewColumns.DOWNLOAD_BILLEDITEMS_PCS];
  public readonly downloadInvoice: string = InvoiceOverviewColumns.DOWNLOAD_INVOICE;
  public readonly downloadBilleditemsFE: string = InvoiceOverviewColumns.DOWNLOAD_BILLEDITEMS_FE;
  public readonly downloadBilledItemsPCS: string = InvoiceOverviewColumns.DOWNLOAD_BILLEDITEMS_PCS;

  @ViewChild(MatSort, {static: false}) public sort: MatSort;
  @Input() public downloadButtons: boolean;
  @Output() private downloadInvoiceEvent = new EventEmitter<InvoiceOverview>();
  @Output() private downloadBilledItemsFromFEEvent = new EventEmitter<InvoiceOverview>();
  @Output() private downloadBilledItemsFromPCSEvent = new EventEmitter<InvoiceOverview>();
  private langChangeSub: Subscription;
  private filterValueChangeSub: Subscription;

  public filterForm: UntypedFormGroup;

  constructor(
    renderer: Renderer2,
    private translateService: TranslationHelperService,
    private formBuilder: FormBuilder
  ) {
    super(renderer);
    this.langChangeSub = this.translateService.onLangChange.subscribe(() => {
      this.tableDataSource.sort = this.sort;
    });
  }

  public ngOnDestroy(): void {
    this.langChangeSub?.unsubscribe();
    this.filterValueChangeSub?.unsubscribe();
  }

  public onDownloadInvoice(element: InvoiceOverview): void {
    this.downloadInvoiceEvent.emit(element);
  }

  public onDownloadBilledItems(element: InvoiceOverview): void {
    this.downloadBilledItemsFromFEEvent.emit(element);
  }

  public onDownloadBilledItemsPCS(element: InvoiceOverview): void {
    this.downloadBilledItemsFromPCSEvent.emit(element);
  }

  public ngAfterViewInit(): void {
    // has to be done in ngAfterViewInit, else sorting would not work, because of ngIf
    this.tableDataSource.sort = this.sort;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setupFilterPredicate(this.dataColumns);
    this.setupFilterForm(this.dataColumns);
  }

  private setupFilterPredicate(dataColumns: string[]): void {
    console.log('setting up filter predicate...');
    this.tableDataSource.filterPredicate = (data, filter) : boolean => {
      let match = true;
      for (const col of dataColumns) {
        if (this.actionColumn === col) {
          continue;
        }

        const colFilter = filter[col];
        let colDataMatch: boolean;
        if (col !== this.invoiceDateTimeCol) {
          colDataMatch = !colFilter || data[col] && data[col].toLowerCase().includes(colFilter.toLowerCase());
        } else {
          colDataMatch = !colFilter || data[col] && this.translateService.translateDateFromDateTimeSafe(data[col]).includes(colFilter);
        }
        match = match && colDataMatch;
      }
      console.log('matched: ', match, ' against data: ', data);
      return match;
    };
  }

  private setupFilterForm(dataColumns: string[]): void {
    this.filterForm = this.formBuilder.group({});
    for (const col of dataColumns) {
      if (!this.downloadColumns.includes(col)) {
        this.filterForm.addControl(col, new FormControl<string | number>(null));
      }
    }
    this.filterValueChangeSub?.unsubscribe();
    this.filterValueChangeSub = this.filterForm.valueChanges.subscribe((newFilter) => {
      console.log('new filter value: ', newFilter);
      this.tableDataSource.filter = newFilter as string;
      this.paginator?.firstPage();
    });
  }

  public invalidInvoice(element: InvoiceOverview): boolean {
    return element.syncStatus === 'INITIAL' || Utils.isEmpty(element.country);
  }
}
