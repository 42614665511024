/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ChargepointAdminDto} from '../../datatypes/location/chargepoint/chargepoint-admin-dto';
import {combineLatest, Subscription} from 'rxjs';
import {LocationService} from '../../services/http/location.service';
import {LocationAdminDto} from '../../datatypes/location/location-admin-dto';
import {Utils} from '../../utils/utils';
import {IopPlatformDto} from '../../datatypes/iop-platform-dto';
import {RoamingPlatformService} from '../../services/http/roaming-platform.service';
import {Message} from '../../datatypes/message';
import {AttributeTableEventService} from '../../services/attribute-table-event.service';
import {AttributeTableAction} from '../../datatypes/attribute-table-action.enum';
import {FormControl} from '@angular/forms';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {TranslateService} from '@ngx-translate/core';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {GeoLocationMapHandler} from "./geo-location-map-handler.abstract";
import {ChangeRequestPayloadType} from "../../datatypes/inbox/change-request-payload-type";
import {AuditService} from "../../services/audit.service";
import {ChargepointUpdateData} from "../../datatypes/location/chargepoint/chargepoint-update-data";
import {
    LocationAndChargepointAdminAttributeDto
} from "../../datatypes/location/location-and-chargepoint-admin-attribute-dto";

@Component({
    selector: 'pcs-location-details',
    templateUrl: './location-details.component.html',
    styleUrls: ['./location-details.component.css']
})
export class LocationDetailsComponent extends GeoLocationMapHandler implements OnInit, OnDestroy, PermissionAware {
    public readonly reqEditPermission = [AccessRights.CHARGEPOINTS_EDIT_WEB];
    public currLocationGroupId: string;
    public locationData: LocationAdminDto;
    public chargepoints: ChargepointAdminDto[] = [];
    public toUnsubscribe: Subscription[] = [];
    public sortedPlatformList: IopPlatformDto[];
    public platformsInLocationData: string[];

    public currentChargepointOnEdit: string;
    public selectedChargepoint: string;
    public selectedTab = new FormControl<number>(0);

    constructor(
        private router: WebConfigRouterService,
        locationService: LocationService,
        translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private platformService: RoamingPlatformService,
        private attributeTableEventService: AttributeTableEventService,
        private auditService: AuditService
    ) {
        super(translateService, locationService);
    }

    public ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.currLocationGroupId = params.locationGroupId;
            console.log('got location group id: ', params.locationGroupId);
            this.selectedChargepoint = params.evseId;
            console.log('got initially selected evseId: ', params.evseId);
            if (!this.currLocationGroupId) {
                console.error('Location group id is missing! Going back to chargepoints view!');
                this.router.navigate(['/chargepoints']);
                return;
            }
            this.fetchLocationRelevantData();
            this.setupGeoDataMap(this.currLocationGroupId);

        });
    }

    private fetchLocationRelevantData(): void {
        const locationObservable = this.locationService.getLocationByGroupId(this.currLocationGroupId);
        const platformsObservable = this.platformService.getRoamingPlatforms();
        const observables = [locationObservable, platformsObservable];
        combineLatest(observables).subscribe((data) => {
                const locationData = data[0] as LocationAdminDto;
                if (!locationData) {
                    // TODO use notification service;
                    console.error('location data not found!');
                    return;
                }
                this.locationData = locationData;
                this.chargepoints = locationData.chargepoints;
                const platformList = data[1] as IopPlatformDto[];
                const temp = IopPlatformDto.toIopPlatformDto(platformList);
                this.sortedPlatformList = temp.sort((p1, p2) => p1.dataPriority - p2.dataPriority);
                console.log('Fetched platform list from backend. List after sorting by priority: ', this.sortedPlatformList);
                this.platformsInLocationData = this.getPlatformsSortedByDataPriority();
                // selected the initially selected chargepoint from the chargepoint list
                this.selectedTab.setValue(this.chargepoints.findIndex((cp) => cp.evseId === this.selectedChargepoint));
            }
        );
    }

    public ngOnDestroy(): void {
        this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
    }

    private getPlatformsSortedByDataPriority(): string[] {
        const unsortedPlatforms: string[] = Utils.findIncludedPlatforms(this.locationData.attributes);
        if (unsortedPlatforms.length <= 1) {
            return unsortedPlatforms;
        }
        return Utils.sortByReferencePlatformArray(unsortedPlatforms, this.sortedPlatformList);
    }

    public async storeManualData(data: ChargepointUpdateData): Promise<void> {
        const manualData = data.updatedValue;
        const containsCostSensitiveAttributeChanges = data.containsCostSensitiveAttributeChanges;
        console.log('got manual data update: ', manualData);
        if (!containsCostSensitiveAttributeChanges) {
            this.updateChargepointAttributes(manualData);
            return;
        }
        await this.createChangeRequest(containsCostSensitiveAttributeChanges, data);
    }

    private async createChangeRequest(containsCostSensitiveAttributeChanges: boolean, data: ChargepointUpdateData,): Promise<void> {
        console.log('change request has to be created for manual data update: ', containsCostSensitiveAttributeChanges);
        const chargepointGroupId = data.chargepointGroupId;
        const evseid = data.evseid;
        const manualData = data.updatedValue;
        if (await this.auditService.objectHasActiveChangeRequest(ChangeRequestPayloadType.CHARGEPOINT, chargepointGroupId)) {
            this.showError(new Message('audit.changeRequest.alreadyExists'));
        } else {
            this.locationService.validatePutChargepointAttributes(manualData).subscribe(async () => {
                    const crCreated = await this.auditService.initiateChangeRequestForManualChargepointAttributes(manualData, data.originalValue, chargepointGroupId, evseid);
                    if (crCreated) {
                        this.refreshLocationData();
                    }
                }
            );
        }
    }

    private refreshLocationData(): void {
        this.fetchLocationRelevantData();
        this.attributeTableEventService.triggerLocationRefresh();
        this.currentChargepointOnEdit = undefined;
    }

    private updateChargepointAttributes(manualData: LocationAndChargepointAdminAttributeDto): void {
        this.locationService.updateChargepointAttributes(manualData).subscribe(() => {
                const msg = new Message();
                msg.message = 'chargepoint.manualData.store.success';
                this.showInfo(msg);
                // lets fetch new location data
                this.refreshLocationData();
            }
        );
    }

    public cpInEditMode(chargepoint: ChargepointAdminDto): boolean {
        return this.currentChargepointOnEdit === chargepoint.evseId;
    }

    public async triggerDataEditForChargepoint(chargepoint: ChargepointAdminDto): Promise<void> {
        if (await this.auditService.objectHasActiveChangeRequest(ChangeRequestPayloadType.CHARGEPOINT, chargepoint.id)) {
            return;
        }

        // first we cancel if any other chargepoint is already on edit
        if (this.currentChargepointOnEdit && this.currentChargepointOnEdit !== chargepoint.evseId) {
            this.triggerChargepointEditCancel();
        }
        this.currentChargepointOnEdit = chargepoint.evseId;
        this.attributeTableEventService.triggerCommandForChargepoint(this.currentChargepointOnEdit, AttributeTableAction.ENABLE_EDIT);
    }

    public triggerChargepointDataSave(): void {
        this.attributeTableEventService.triggerCommandForChargepoint(this.currentChargepointOnEdit, AttributeTableAction.SAVE);
    }

    public handleTabChange(event: MatTabChangeEvent): void {
        const selectedCp = this.chargepoints[event.index];
        if (this.currentChargepointOnEdit !== selectedCp.evseId) {
            this.triggerChargepointEditCancel();
        }
        this.selectedTab.setValue(event.index);
        this.selectedChargepoint = selectedCp.evseId;
    }

    public triggerChargepointEditCancel(): void {
        this.attributeTableEventService.triggerCommandForChargepoint(this.currentChargepointOnEdit, AttributeTableAction.CANCEL);
        this.currentChargepointOnEdit = undefined;
    }
}
