/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Operator } from '../datatypes/operator';
import { ChargepointService } from '../services/http/chargepoint.service';
import { Observable, of, Subscription } from 'rxjs';
import { PermissionAware } from '../permission-aware';
import { AccessRights } from '../datatypes/access-rights.enum';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SharedDataService } from '../services/shared-data.service';
import { CpoDownloaderService } from '../services/cpo-downloader.service';
import { OperatorFilter } from '../datatypes/operator-filter';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { Message } from '../datatypes/message';

@Component({
  selector: 'pcs-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.css']
})
export class OperatorsComponent extends AbstractNotificationHandler implements OnInit, PermissionAware, OnDestroy {
  public readonly reqEditPermission = [AccessRights.CPO_AND_PARTNER_EDIT_WEB];
  public readonly toUnsubscribe: Subscription[] = [];
  public errormessage: string;
  public operators: Operator[] = [];
  public dataFound: boolean;
  public loading = false;
  public exportingCpos = false;

  public readonly pageSizeOptions = [10, 50, 100, 200];
  public resultLength = 0;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public pageSize = this.pageSizeOptions[0];
  public pageIndex = 0;

  private operatorFilters: OperatorFilter;

  constructor(
    private chargepointService: ChargepointService,
    private cpoDownloader: CpoDownloaderService,
    private dataService: SharedDataService
  ) {
    super();
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  public ngOnInit(): void {
    this.operatorFilters = new OperatorFilter();
    this.getOperators(0, this.pageSizeOptions[0]);
    this.toUnsubscribe.push(this.cpoDownloader.downloadingCpos$.subscribe((next) => (this.exportingCpos = next)));
  }

  public getOperators(pageIndex: number, pageSize: number): void {
    // reset error message:
    this.loading = true;
    this.errormessage = null;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    const offset = pageIndex * pageSize;
    // call the chargepoint service method to load chargepoint operators:
    this.chargepointService.getOperators(offset, pageSize + 1, this.operatorFilters).subscribe({
      next: (operators) => {
        this.handleResponse(operators, pageIndex, pageSize);
        this.loading = false;
      },
      error: () => (this.loading = false)
    });
  }

  public handlePageChange(pageEvent: PageEvent): void {
    this.getOperators(pageEvent.pageIndex, pageEvent.pageSize);
  }

  public get observableOfOperators(): Observable<Operator[]> {
    return of(this.operators);
  }

  public handleResponse(operators: Operator[], pageIndex: number, pageSize: number): void {
    this.resultLength = pageIndex * pageSize + operators.length;
    // since we requested one extra element, we remove it before showing
    if (operators.length > pageSize) {
      operators.splice(operators.length - 1, 1);
    }
    this.operators = operators;
    this.dataFound = this.operators?.length > 0;

    if (this.operators?.length === 0) {
      this.showInfoMsg('operators.noRecordsFound');
    }
  }

  public get cpoDataColumns(): string[] {
    return Operator.dataColumns;
  }

  public onDownloadCPOs(): void {
    this.cpoDownloader.downloadCPOs();
  }

  private updateOperator(toUpdate): void {
    this.dataService.updateOperatorUpdateOnProcessValue(true);
    this.chargepointService.updateOperator(toUpdate).subscribe({
      next: () => this.getOperators(this.pageIndex, this.pageSize),
      error: () => this.getOperators(this.pageIndex, this.pageSize),
      complete: () => this.dataService.updateOperatorUpdateOnProcessValue(false)
    });
  }

  public toggleEnergySource(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.renewableEnergySource = !toUpdate.renewableEnergySource;
    this.updateOperator(toUpdate);
  }

  public togglePlugAndCharge(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.enablePlugAndCharge = !toUpdate.enablePlugAndCharge;
    this.updateOperator(toUpdate);
  }

  public toggleShowStationName(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.showStationName = !toUpdate.showStationName;
    this.updateOperator(toUpdate);
  }

  public updateDisplayName(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    this.updateOperator(toUpdate);
  }

  public toggleRemoteAuth(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.enableRemoteAuth = !toUpdate.enableRemoteAuth;
    this.updateOperator(toUpdate);
  }

  public toggleRfidAuth(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.enableRfidAuth = !toUpdate.enableRfidAuth;
    this.updateOperator(toUpdate);
  }

  public onFilterChange(filter: OperatorFilter): void {
    this.operatorFilters = filter;
    this.paginator.pageIndex = 0;
    this.getOperators(this.paginator.pageIndex, this.paginator.pageSize);
  }

  private showInfoMsg(message: string): void {
    const msg = new Message();
    msg.message = message;
    this.showInfo(msg);
  }
}
