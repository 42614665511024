/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChargepointFilterType } from '../datatypes/chargepoint-filter-type.enum';
import { ChargepointFilter } from '../datatypes/chargepoint-filter';
import { UnusedChargepointFilter } from '../datatypes/unused-chargepoint-filter';
import { AddressMismatchChargepointFilter } from '../datatypes/AddressMismatchChargepointFilter';

@Injectable({
  providedIn: 'root'
})
export class ChargepointFilterEventService {
  private currentFilterTypeSource: BehaviorSubject<ChargepointFilterType>;
  public currFilterType$: Observable<ChargepointFilterType>;

  private defaultFilterEventSource: BehaviorSubject<ChargepointFilter>;
  public defaultCPFilterEvent$: Observable<ChargepointFilter>;

  private unusedCPFilterEventSource: BehaviorSubject<UnusedChargepointFilter>;
  public unusedCPFilterEvent$: Observable<UnusedChargepointFilter>;

  private addMismatchCPFilterEventSource: BehaviorSubject<AddressMismatchChargepointFilter>;
  public addMismatchCPEvent$: Observable<AddressMismatchChargepointFilter>;

  public refreshSources(): void {
    this.currentFilterTypeSource = new BehaviorSubject<ChargepointFilterType>(ChargepointFilterType.DEFAULT);
    this.currFilterType$ = this.currentFilterTypeSource.asObservable();
    this.refreshFilterSources();
  }

  public refreshFilterSources(): void {
    this.defaultFilterEventSource = new BehaviorSubject<ChargepointFilter>(null);
    this.defaultCPFilterEvent$ = this.defaultFilterEventSource.asObservable();

    this.unusedCPFilterEventSource = new BehaviorSubject<UnusedChargepointFilter>(null);
    this.unusedCPFilterEvent$ = this.unusedCPFilterEventSource.asObservable();

    this.addMismatchCPFilterEventSource = new BehaviorSubject<AddressMismatchChargepointFilter>(null);
    this.addMismatchCPEvent$ = this.addMismatchCPFilterEventSource.asObservable();
  }

  public updateCurrentFilterType(newFilter: ChargepointFilterType): void {
    console.log('updating current chargepoint filter selection to: ', newFilter);
    this.currentFilterTypeSource.next(newFilter);
  }

  public triggerDefaultChargepointFilterEvent(newFilter: ChargepointFilter): void {
    if (this.defaultFilterEventSource) {
      console.log('received new chargepoint filter event with filter: ', newFilter);
      this.defaultFilterEventSource.next(newFilter);
    }
  }

  public triggerUnusedChargepointFilterEvent(newFilter: UnusedChargepointFilter): void {
    if (this.unusedCPFilterEventSource) {
      console.log('received new unused chargepoint filter event with filter: ', newFilter);
      this.unusedCPFilterEventSource.next(newFilter);
    }
  }
}
