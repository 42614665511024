/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {UserDataSourceService} from '../../services/user-data-source.service';
import {UserService} from '../../services/http/user.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  MandatoryCrossFieldFormErrorMatcher,
  PasswordMatchValidatorService,
  ValidatorList
} from 'pcs-commons/validation';
import {PasswordChangeRequest} from '../../datatypes/password-change-request';
import {FormUtils} from 'pcs-commons/utils';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from 'pcs-commons/notification';

@Component({
  selector: 'app-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: [
    '../dialog-common-styles.css',
    './password-change-dialog.component.css'
  ]
})
export class PasswordChangeDialogComponent implements OnInit {
  public passChangeForm: FormGroup;
  public currentPassword: FormControl;
  public newPasswordFrom: FormGroup;
  public newPassword: FormControl;
  public confirmNewPassword: FormControl;
  public crossFieldErrorMatcher: ErrorStateMatcher;
  public hideConfirmNewPassword = true;
  public hideNewPassword = true;
  public hideCurrPassword = true;

  constructor(
    private dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    private userDataSource: UserDataSourceService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private passwordMatchValidator: PasswordMatchValidatorService) {
    this.crossFieldErrorMatcher = new MandatoryCrossFieldFormErrorMatcher();
  }

  public ngOnInit(): void {
    this.currentPassword = new FormControl('', [Validators.required]);
    this.newPassword = new FormControl('', [Validators.required, ...ValidatorList.PASSWORD]);
    this.confirmNewPassword = new FormControl('', Validators.required);
    this.passChangeForm = this.formBuilder.group(
      {
        currentPassword: this.currentPassword,
        newPasswordFrom: this.newPasswordFrom
      });
    this.newPasswordFrom = this.formBuilder.group({
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword
      },
      {
        validators: [this.passwordMatchValidator.validate.bind(this.passwordMatchValidator)]
      }
    );
  }

  public requestPasswordChange(): void {
    console.log(`password change requested! current: ${this.currentPassword.value}, new: ${this.newPassword.value}`);
    if (this.passChangeForm.invalid || this.newPasswordFrom.invalid) {
      return;
    }
    const request = new PasswordChangeRequest();
    request.currentPassword = FormUtils.getStringValue(this.passChangeForm, 'currentPassword');
    request.newPassword = FormUtils.getStringValue(this.newPasswordFrom, 'newPassword');
    this.userService.changePassword(request).subscribe(() => {
      console.log('password changed successful');
      this.dialogRef.close(true);
      this.userDataSource.clearAllUserData();
      this.notificationService.notifyPasswordChange();
    });
  }
}
