/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { FormControl } from '@angular/forms';
import { ValidationMessagePipe } from 'pcs-commons/validation';
import { Observable } from 'rxjs';

export abstract class FormErrorHandler {
  protected constructor(public validationPipe: ValidationMessagePipe) {}

  public getError(fc: FormControl): Observable<any> | null {
    return this.validationPipe.transform(fc);
  }
}
