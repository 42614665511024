/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Message, Notification} from 'pcs-commons/datatypes';
import {NotificationStore} from './notification-store';

export abstract class AbstractNotificationHandler {

  public showInfo(msg: Message): void {
    this.showNotification(Notification.info(msg));
  }

  public showWarning(msg: Message): void {
    this.showNotification(Notification.warn(msg));
  }

  public showError(msg: Message): void {
    this.showNotification(Notification.error(msg));
  }

  public showNotification(notification: Notification): void {
    NotificationStore.instance.notify(notification);
  }

}
