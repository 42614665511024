/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {TranslationHelperService} from 'pcs-commons/http';

@Pipe({
  name: 'translateDateTime'
})
export class TranslateDateTimePipe implements PipeTransform {
  constructor(
    private translationService: TranslationHelperService) {
  }

  public transform(value: string, update: Date): string {
    return this.translationService.translateDateTimeSafe(value);
  }
}
