/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {ChargepointClassNames} from './chargepoint-class-names.enum';

export class ChargepointClass {
  constructor(
    private _name: ChargepointClassNames,
    private _chargingPower: number,
    private _ac: boolean) {
  }

  get name() {
    return this._name;
  }

  get power() {
    return this._chargingPower;
  }

  get ac() {
    return this._ac;
  }
}
