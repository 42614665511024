<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="scrollable-table-container active-pc-table">
  <mat-table #table [dataSource]="tableDataSource">
    <div *ngFor="let col of dataColumns; let columnIndex = index">

      <ng-container matColumnDef="{{col}}" *ngIf="col !== actionColumn &&  col !== pricesColumn">
        <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell [styledColumn]="col" *matCellDef="let element">

          <div *ngIf="!columnsNeedConversion.includes(col) && !isElementCol(col)">
            {{element[col]}}
          </div>

          <div *ngIf="!columnsNeedConversion.includes(col) && isElementCol(col)">
            <span *ngFor="let pc_element of element.elements">{{pc_element[col]}}</span>
          </div>

          <div *ngIf="col === validFromColumn || col === validUntilColumn">{{translateDate(element[col])}}</div>

          <div *ngIf="col === countryColumn && element[col]">{{'global.country.' + element[col] | translate}}</div>

          <div
            *ngIf="col === statusColumn && element[col]">{{'purchaseConditions.status.' + element[col] | translate}}</div>

          <div *ngIf="col === daysOfWeekColumn">
            <span *ngFor="let pc_element of element.elements">{{pc_element[col]| translateWeekdays}}</span>
          </div>

          <div *ngIf="col === powerClassColumn">
            <span *ngFor="let pc_element of element.elements">{{pc_element[col]| translatePowerClasses}}</span>
          </div>

          <div *ngIf="col === startDateColumn || col === endDateColumn">
            <span *ngFor="let pc_element of element.elements">{{translateDate(pc_element[col])}}</span>
          </div>

        </mat-cell>
      </ng-container>

      <ng-container *ngIf="col !== actionColumn && col === pricesColumn" matColumnDef="{{col}}">
        <mat-header-cell class="prices-cell" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell class="prices-cell" *matCellDef="let element">
          <price-component-table
            class="price-component-table"
            [i18nBase]="'purchaseCondition.element'"
            *ngIf="hasPrices(element.elements[0])"
            [data]="observableOfPrices(element.elements[0])"
            [dataColumns]="pricesTableColumns">
          </price-component-table>
        </mat-cell>
      </ng-container>

    </div>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
<mat-paginator [pageSize]="pageSizeOptions[0]" [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>
