/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {User} from '../../datatypes/user';
import {HttpClient} from '@angular/common/http';
import {HeaderEnabled} from './header-enabled.abstract';
import {Observable} from 'rxjs';
import {FleetSummary} from '../../datatypes/fleet-summary';
import {FleetCreateResponse} from '../../datatypes/fleet-create-response';
import {Fleet} from '../../datatypes/fleet';
import {UpdateFleet} from '../../datatypes/update-fleet';
import {CardInventory} from '../../datatypes/card-inventory';
import {CardInventoryImportResult} from '../../datatypes/card-inventory-import-result';
import {FleetAddressList} from 'pcs-commons/datatypes';

@Injectable()
export class FleetService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public getFleets(): Observable<FleetSummary[]> {
    return this.http.get<FleetSummary[]>(environment.fleetsEndpoint, {headers: this.headers});
  }

  public createFleet(fleetInput: Fleet): Observable<FleetCreateResponse> {
    return this.http.post<FleetCreateResponse>(
      environment.fleetsEndpoint,
      fleetInput,
      {headers: this.headers}
    );
  }

  public cardInventory(fleetId: string): Observable<CardInventory[]> {
    const uri = environment.fleetsEndpoint + fleetId + '/card-inventory';
    return this.http.get<CardInventory[]>(uri, {headers: this.headers});
  }

  public addCardsToInventory(fleetId: string, body: any): Observable<CardInventoryImportResult> {
    const newHeader = this.headers.set('Content-Type', 'application/octet-stream');
    const uri = environment.fleetsEndpoint + fleetId + '/card-inventory';
    return this.http.post<CardInventoryImportResult>(
      uri,
      body,
      {headers: newHeader}
    );
  }

  public addFleetManager(fleetId: string, manager: User): Observable<User> {
    const uri = environment.fleetsEndpoint + fleetId + '/accounts';
    return this.http.post<User>(
      uri,
      manager,
      {headers: this.headers}
    );
  }

  public deleteFleetManager(fleetId: string, fleetManagerId: number): Observable<any> {
    const uri = environment.fleetsEndpoint + fleetId + '/accounts' + '/' + fleetManagerId;
    return this.http.delete(uri, {headers: this.headers});
  }

  public getFleetById(fleetId: string): Observable<Fleet> {
    const uri = environment.fleetsEndpoint + fleetId;
    return this.http.get<Fleet>(uri);
  }

  public updateFleet(currentFleetId: string, updateFleet: UpdateFleet): Observable<Fleet> {
    const uri = environment.fleetsEndpoint + currentFleetId;
    return this.http.put<Fleet>(uri, updateFleet, {headers: this.headers});
  }

  public activateFleet(fleetId: string): Observable<Fleet> {
    const uri = environment.fleetsEndpoint + fleetId + '/activate';
    return this.http.put<Fleet>(uri, {headers: this.headers});
  }

  public deactivateFleet(fleetId: string): Observable<Fleet> {
    const uri = environment.fleetsEndpoint + fleetId + '/deactivate';
    return this.http.put<Fleet>(uri, {headers: this.headers});
  }

  public deleteFleet(fleetId: string): Observable<any> {
    const uri = environment.fleetsEndpoint + fleetId;
    return this.http.delete(uri, {headers: this.headers});
  }

  public addAddresses(fleetId: string, addresses: FleetAddressList): Observable<FleetAddressList> {
    const uri = environment.fleetsEndpoint + fleetId + '/addresses';
    return this.http.post<FleetAddressList>(uri, addresses, {headers: this.headers});
  }

  public updateAddresses(fleetId: string, addresses: FleetAddressList): Observable<FleetAddressList> {
    const uri = environment.fleetsEndpoint + fleetId + '/addresses';
    return this.http.put<FleetAddressList>(uri, addresses, {headers: this.headers});
  }

  public getFleetTypes(): Observable<string[]> {
    return this.http.get<string[]>(environment.fleetsEndpoint + 'types', {headers: this.headers});
  }

}
