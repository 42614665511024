/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {WebConfigRouterService} from '../services/web-config-router.service';
import {AuthenticationService} from './authentication.service';
import {LoginService} from 'pcs-commons/http';
import {AuthorizationService} from "./authorization.service";

@Injectable()
export class AuthGuardService  {

  constructor(
    private router: WebConfigRouterService,
    private loginService: LoginService,
    private authService: AuthenticationService,
    private accessControlService: AuthorizationService) {
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('Running auth guard');
    console.log('url: ', state.url);
    console.log('route', route);
    console.log('state: ', state);
    const usersAuthDataPresent = await this.authService.verifyUserAuthDetailsPresent();
    if (!usersAuthDataPresent) {
      return false;
    }

    // check user's access to the url
    if (!this.accessControlService.userHasAccessToUrl(route)) {
      this.router.accessNotAuthorized();
      return false;
    }
    return true;
  }
}

