import {Directive, Inject, Input, OnDestroy} from "@angular/core";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {TranslateService} from "@ngx-translate/core";

interface DateParse {
  parse: {
    dateInput: string;
  },
  display: {
    dateInput: string;
  }
}

/**
 * Add this directive to the <input> of a material datepicker to display only the month and year.
 * Note that this implementation only works if there's only one date picker on the page.
 * This is a bit hacky; a cleaner version is here:
 * https://stackblitz.com/edit/angular-material-create-a-separate-format-for-each-date-picker
 */
@Directive({
  selector: "[monthFormat]"
})
export class DatePickerMonthFormatDirective implements OnDestroy {
  private originalDateFormat: DateParse;

  @Input()
  public monthFormat: boolean;

  constructor(
    @Inject(MAT_DATE_FORMATS) public dateFormat: DateParse,
    private translateService: TranslateService
  ) {
    this.originalDateFormat = JSON.parse(JSON.stringify(dateFormat));
    const format = this.translateService.instant('global.monthFormat');
    this.dateFormat.display.dateInput = format;
    this.dateFormat.parse.dateInput = format;

  }

  public ngOnDestroy(): void {
    this.dateFormat.display = this.originalDateFormat.display;
    this.dateFormat.parse = this.originalDateFormat.parse;
  }
}
