/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {ProductQuota} from './product-quota';

export class UpdateFleet {
  public products: ProductQuota[];
  public name: string;
  public fleetId: string;
  public sapDebtorNumber: string;
  public doNotSendToFinanceEnabler: boolean;
}
