/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit, Renderer2} from '@angular/core';
import {FilterableTableBaseComponent} from 'pcs-commons/data-tables';
import {TranslationHelperService} from 'pcs-commons/http';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'pcs-rfidcards-table',
  templateUrl: './rfidcards-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css',
    './rfidcards-table.component.css']
})
export class RFIDCardsTableComponent extends FilterableTableBaseComponent {
  constructor(
    renderer: Renderer2,
    translateService: TranslationHelperService,
    formBuilder: FormBuilder) {
    super(renderer, translateService, formBuilder);
  }
}
