<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<form class="address-form" [formGroup]="addressForm" *ngIf="addressForm">
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.ciamId' | translate}}</mat-label>
        <input matInput formControlName="ciamId" required>
        <mat-error *ngIf="ciamId.invalid">
          {{ciamId | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" *ngIf="addressType==='BILLING'">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.email' | translate}}</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="email.invalid">
          {{email | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="addressType==='BILLING'" colspan="1">
      <autocomplete-string-select [inputControl]="invoiceLanguage" class="autocomplete-select" [required]="true"
                                  [label]="'address.invoiceLanguage'"
                                  [optionList]="languageList" [optionI18nBase]="'global.language.'">
      </autocomplete-string-select>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.vatId' | translate}}</mat-label>
        <input matInput formControlName="vatId" [required]="isVatIdRequired">
        <mat-error *ngIf="vatId.invalid">
          {{vatId | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.companyName1' | translate}}</mat-label>
        <input matInput formControlName="companyName1" [required]="isCompanyRequired">
        <mat-error *ngIf="companyName1.invalid">
          {{companyName1 | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.companyName2' | translate}}</mat-label>
        <input matInput formControlName="companyName2">
        <mat-error *ngIf="companyName2.invalid">
          {{companyName2 | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.salutation' | translate}}</mat-label>
        <input matInput formControlName="salutation">
        <mat-error *ngIf="salutation.invalid">
          {{salutation | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.title' | translate}}</mat-label>
        <input matInput formControlName="title">
        <mat-error *ngIf="title.invalid">
          {{title | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.firstName' | translate}}</mat-label>
        <input matInput formControlName="firstName">
        <mat-error *ngIf="firstName.invalid">
          {{firstName | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.middleName' | translate}}</mat-label>
        <input matInput formControlName="middleName">
        <mat-error *ngIf="middleName.invalid">
          {{middleName | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.lastName' | translate}}</mat-label>
        <input matInput formControlName="lastName">
        <mat-error *ngIf="lastName.invalid">
          {{lastName | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.coName' | translate}}</mat-label>
        <input matInput formControlName="coName">
        <mat-error *ngIf="coName.invalid">
          {{coName | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.street1' | translate}}</mat-label>
        <input matInput formControlName="street1" required>
        <mat-error *ngIf="street1.invalid">
          {{street1 | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.street2' | translate}}</mat-label>
        <input matInput formControlName="street2">
        <mat-error *ngIf="street2.invalid">
          {{street2 | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.street3' | translate}}</mat-label>
        <input matInput formControlName="street3">
        <mat-error *ngIf="street3.invalid">
          {{street3 | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.houseNumber' | translate}}</mat-label>
        <input matInput formControlName="houseNumber" required>
        <mat-error *ngIf="houseNumber.invalid">
          {{houseNumber | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.buildingCode' | translate}}</mat-label>
        <input matInput formControlName="buildingCode">
        <mat-error *ngIf="buildingCode.invalid">
          {{buildingCode | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.floor' | translate}}</mat-label>
        <input matInput formControlName="floor">
        <mat-error *ngIf="floor.invalid">
          {{floor | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.roomNumber' | translate}}</mat-label>
        <input matInput formControlName="roomNumber">
        <mat-error *ngIf="roomNumber.invalid">
          {{roomNumber | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.city' | translate}}</mat-label>
        <input matInput formControlName="city" required>
        <mat-error *ngIf="city.invalid">
          {{city | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.postalCode' | translate}}</mat-label>
        <input matInput formControlName="postalCode" required>
        <mat-error *ngIf="postalCode.invalid">
          {{postalCode | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.region' | translate}}</mat-label>
        <input matInput formControlName="region" [required]="isRegionRequired">
        <mat-error *ngIf="region.invalid">
          {{region | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.postalCodeRegionValue' | translate}}</mat-label>
        <input matInput formControlName="postalCodeRegionValue">
        <mat-error *ngIf="postalCodeRegionValue.invalid">
          {{postalCodeRegionValue | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.district' | translate}}</mat-label>
        <input matInput formControlName="district">
        <mat-error *ngIf="district.invalid">
          {{district | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <autocomplete-string-select [inputControl]="country" class="autocomplete-select"
                                  [label]="'address.country'" [required]="true"
                                  [optionList]="countryList" [optionI18nBase]="'global.country.'">
      </autocomplete-string-select>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="4" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.poBox' | translate}}</mat-label>
        <input matInput formControlName="poBox">
        <mat-error *ngIf="poBox.invalid">
          {{poBox | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.poBoxCity' | translate}}</mat-label>
        <input matInput formControlName="poBoxCity">
        <mat-error *ngIf="poBoxCity.invalid">
          {{poBoxCity | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.poBoxPostalCode' | translate}}</mat-label>
        <input matInput formControlName="poBoxPostalCode">
        <mat-error *ngIf="poBoxPostalCode.invalid">
          {{poBoxPostalCode | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="1" rowHeight="100px">
    <mat-grid-tile colspan="1">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>{{'address.supplement' | translate}}</mat-label>
        <input matInput formControlName="supplement">
        <mat-error *ngIf="supplement.invalid">
          {{supplement | validationMessage | async}}
        </mat-error>
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
</form>
