/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
/**
 * this class defines the basic validation error types for the form inputs
 * and the message it should show in the UI
 */

export abstract class ValidationError {
  public message: string;
  public param0: string;

  protected constructor() {
  }
}

export class RequiredError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.required';
  }
}

export class MaxlengthError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.maxlength';
  }
}

export class MinlengthError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.minlength';
  }
}

export class MinValueError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.minValue';
  }
}

export class MaxValueError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.maxValue';
  }
}

export class InvalidEmailError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.invalidemail';
  }
}

export class InvalidPatternError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.invalidpattern';
  }
}

export class NoUppercaseError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.noUppercase';
  }
}

export class UrlError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.invalidurl';
  }
}

export class DateInvalidError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.matDatepickerParse';
  }
}

export class DateInPastError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.matDatepickerMin';
  }
}

export class NameNotUniqueError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.notUniqueName';
  }
}

export class TaxRateError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.invalidtaxrate';
  }
}

export class InvalidMinMaxCombination extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.invalidMinMaxCombination';
  }
}

export class DateRangeError extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.invalidDateRange';
  }
}

export class PositiveInteger extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.positiveInteger';
  }
}

export class PasswordMismatch extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.passwordMismatch';
  }
}

export class NoWhitespace extends ValidationError {
  constructor() {
    super();
    this.message = 'validation.input.noWhitespace';
  }
}
