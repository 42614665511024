/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { Observable, of } from 'rxjs';
import { Message } from '../datatypes/message';
import { QueryHistoryService } from '../services/http/query-history.service';
import { QueryHistoryEntryDto } from '../datatypes/query-history/query-history-entry-dto';
import { HistoryFilter } from '../datatypes/query-history/query-history-filter';
import { Parameters } from '../global/parameters';

@Component({
  selector: 'pcs-query-history-overview',
  templateUrl: './query-history-overview.component.html',
  styleUrl: './query-history-overview.component.css'
})
export class QueryHistoryOverviewComponent extends AbstractNotificationHandler implements OnInit {
  public historyEntries: QueryHistoryEntryDto[] = [];
  public lastHistoryEntryUserId = '';
  public lastHistoryEntryTimestamp = '';

  public readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  public resultLength = 0;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public pageSize = this.pageSizeOptions[0];
  public pageIndex = 0;

  public allRequestedHistoryEntries: QueryHistoryEntryDto[] = [];

  public filter: HistoryFilter = new HistoryFilter();

  constructor(private queryHistoryService: QueryHistoryService) {
    super();
  }

  public ngOnInit(): void {
    this.clearData();
    this.filter = new HistoryFilter();
    this.retrieveQueryHistory('', '', this.filter, this.pageSizeOptions[0]);
  }

  public retrieveQueryHistory(
    lastHistoryEntryUserId: string,
    lastHistoryEntryTimestamp: string,
    filter: HistoryFilter,
    pageSize: number
  ): void {
    this.pageSize = pageSize;

    this.queryHistoryService
      .retrieveQueryHistory(lastHistoryEntryUserId, lastHistoryEntryTimestamp, filter, pageSize + 1)
      .subscribe({
        next: (entries) => {
          this.handleResponse(entries, pageSize);
        }
      });
  }

  public handlePageChange(pageEvent: PageEvent): void {
    if (pageEvent.pageIndex == 0) {
      this.clearData();
      this.retrieveQueryHistory(this.lastHistoryEntryUserId, this.lastHistoryEntryTimestamp, this.filter, pageEvent.pageSize);
      return;
    }
    if (this.newPageAlreadyRequested(pageEvent)) {
      const start = pageEvent.pageIndex * pageEvent.pageSize;
      this.historyEntries = this.allRequestedHistoryEntries.slice(start, start + pageEvent.pageSize);
      return;
    }
    this.retrieveQueryHistory(this.lastHistoryEntryUserId, this.lastHistoryEntryTimestamp, this.filter, pageEvent.pageSize);
  }

  public get observableOfHistoryEntries(): Observable<QueryHistoryEntryDto[]> {
    return of(this.historyEntries);
  }

  public handleResponse(entries: QueryHistoryEntryDto[], pageSize: number): void {
    this.resultLength = this.paginator.pageIndex * pageSize + entries.length;

    // since we requested one extra element, we remove it before showing
    if (entries.length > pageSize) {
      entries.splice(entries.length - 1, 1);
    }
    this.historyEntries = entries;
    this.allRequestedHistoryEntries = this.allRequestedHistoryEntries.concat(this.historyEntries);

    if (this.historyEntries?.length === 0) {
      this.lastHistoryEntryUserId = '';
      this.lastHistoryEntryTimestamp = '';
      this.showInfoMsg('query-history.noRecordsFound');
      return;
    }

    this.lastHistoryEntryUserId = this.historyEntries[this.historyEntries.length - 1].userId;
    this.lastHistoryEntryTimestamp = this.historyEntries[this.historyEntries.length - 1].timestamp;
  }

  public get queryHistoryColumns(): string[] {
    return QueryHistoryEntryDto.dataColumns;
  }

  public onFilterChange(filter: HistoryFilter): void {
    this.paginator.pageIndex = 0;
    this.clearData();
    this.filter = filter;
    this.retrieveQueryHistory('', '', filter, this.paginator.pageSize);
  }

  private clearData(): void {
    this.lastHistoryEntryUserId = '';
    this.lastHistoryEntryTimestamp = '';
    this.allRequestedHistoryEntries = [];
  }

  private showInfoMsg(message: string): void {
    const msg = new Message();
    msg.message = message;
    this.showInfo(msg);
  }

  private newPageAlreadyRequested(pageEvent: PageEvent): boolean {
    return this.allRequestedHistoryEntries.length / pageEvent.pageSize > pageEvent.pageIndex;
  }
}
