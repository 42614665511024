/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {ChargingCardFilter} from '../../datatypes/charging-card-filter';
import {ChargingCard} from '../../datatypes/charging-card';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChargingCardService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public getCardsByFilter(filter: ChargingCardFilter): Observable<ChargingCard[]> {
    const uri = environment.chargingCardServiceUrl + '/filter';
    return this.http.post<ChargingCard[]>(uri, filter, {headers: this.headers});
  }
}
