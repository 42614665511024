<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && pricesForm">
  <form [formGroup]="pricesForm" class="price-component-form">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}
          </mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <div *ngIf="col===PRICE_TYPE_COLUMN">{{'global.label.' + element | translate}}</div>
            <mat-form-field *ngIf="col!==PRICE_TYPE_COLUMN && !(col=== STEP_SIZE_COLUMN && element === FLAT_PRICE)"
                            class="price-component-form">
              <input matInput [formControlName]="getFromControlName(element, col)">
            </mat-form-field>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </form>
</div>
