/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AdditionDto} from "./AdditionDto";
import {DetailAdditionDto} from "./DetailAdditionDto";
import {DeductionDto} from "./DeductionDto";

export class OrderItemDto {
  name: string;
  product_number: string;
  amount: number;
  net_price: number;
  gross_price: number;
  currency: string;
  service_country: string;
  type: string;
  unit: string;
  start_period_date_time: string;
  end_period_date_time: string;
  tax_amount: string;
  tax_amount_gst: number;
  tax_amount_hst: number;
  tax_amount_pst: number;
  tax_amount_qst: number;
  item_id: string;
  vat_rate: string;
  timezone: string;
  additions: AdditionDto[];
  known_additions: AdditionDto[];
  detail_additions: DetailAdditionDto[];
  deductions: DeductionDto[];
  billing_status: string;
  vehicle_number: string;
  reference_invoice_ident: string;
}

export enum OrderItemColumns {
  ITEM_ID = 'item_id',
  PRODUCT_NUMBER = 'product_number',
  NET_PRICE = 'net_price',
  GROSS_PRICE = 'gross_price',
  CURRENCY = 'currency',
  SERVICE_COUNTRY = 'service_country',
  START_PERIOD_DATE_TIME = 'start_period_date_time'
}
