/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SelfInformationFilterDto} from '../../datatypes/self-information-filter-dto';

@Component({
  selector: 'pcs-customerinfo-filter',
  templateUrl: './customerinfo-filter.component.html',
  styleUrls: ['./customerinfo-filter.component.css']
})
export class CustomerinfoFilterComponent implements OnInit {

  @Output() public filterEvent = new EventEmitter<SelfInformationFilterDto>();
  protected filterFormGroup: FormGroup;
  protected ciamId: FormControl;
  protected vin: FormControl;
  protected contractId: FormControl;
  protected evcoId: FormControl;

  constructor(private formBuilder: FormBuilder) {
  }

  public ngOnInit(): void {
    this.defineFormControls();
  }

  protected triggerFetchCustomerinfo(): void {
    this.filterEvent.emit(this.filterFormGroup.value);
  }

  protected noFieldIsSet(): boolean {
    return Object.values(this.filterFormGroup.value).every(value => !value || typeof value === "string" && value.trim().length === 0);
  }

  private defineFormControls(): void {
    this.ciamId = new FormControl('');
    this.vin = new FormControl('');
    this.contractId = new FormControl('');
    this.filterFormGroup = this.formBuilder.group({
      ciamId: this.ciamId,
      vin: this.vin,
      contractId: this.contractId,
      evcoId: this.evcoId
    });
  }
}
