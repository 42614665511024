/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {EventEmitter, Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DateUtils} from 'pcs-commons/utils';
import {Observable} from 'rxjs';
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class TranslationHelperService {
  private readonly COUNTRY_PREFIX = 'global.country.';

  constructor(private translateService: TranslateService) {
  }

  public get(key: string): Observable<string | any> {
    return this.translateService.get(key);
  }

  public instant(key: string): any {
    return this.translateService.instant(key);
  }

  /**
   * Converts date to current locales format, returns the input date if the format is invalid
   */
  public translateDateSafe(date: string | null): string | null {
    const parsedMoment = DateUtils.convertToDateTimeWithUTC(date);
    return parsedMoment?.isValid ? parsedMoment.toFormat(this.translateService.instant('global.dateFormat')) : date;
  }

  public translateDateTimeSafe(date: string | DateTime): string {
    if (!date) {
      return '';
    }
    const dateTime = date instanceof DateTime ? date : DateUtils.parseToDateTime(date);
    return dateTime?.isValid ? dateTime.toFormat(this.translateService.instant('global.dateTimeFormat')) : String(date);
  }

  public translateDateTimeToLocal(date: string): string {
    const utcDateTime = DateUtils.parseToDateTime(date);
    if (utcDateTime?.isValid) {
      const localDateTime = utcDateTime.setZone('local');
      return localDateTime.toFormat(this.translateService.instant('global.dateTimeFormat'));
    }
    return date;
  }

  public translateDateFromDateTimeSafe(date: string | DateTime): string {
    let dateTime: DateTime;
    if (date instanceof DateTime) {
      dateTime = date;
    } else {
      dateTime = DateUtils.parseToDateTime(date);
    }
    if (dateTime?.isValid) {
      return dateTime.toFormat(this.translateService.instant('global.dateFormat'))
    }
    return String(date);
  }

  public translateMonthNameYearFromDateTimeSafe(date: string): string {
    const parsedMoment = DateUtils.parseToDateTime(date);
    return parsedMoment?.isValid ? parsedMoment.toFormat('MMMM y') : date;
  }

  /**
   * Converts date and time to current locales format, returns the input date if the format is invalid
   */
  public translateDateTimeUTCSafe(date: string): string {
    if (!date) {
      return date;
    }
    const dateTime = DateTime.fromISO(date, {zone: 'utc'});
    return dateTime?.isValid ? dateTime.toFormat(this.translateService.instant('global.dateTimeFormatWithSec')) : date;
  }

  public getCountryTranslation(alpha2Code: string): any {
    return this.translateService.instant(this.COUNTRY_PREFIX + alpha2Code);
  }

  public translateCountryNotFromBE(country: any): any {
    const countryTranslation = this.getCountryTranslation(country);
    if (countryTranslation.startsWith(this.COUNTRY_PREFIX)) {
      return country;
    }
    return countryTranslation;
  }

  public sortArrayByTranslation(array: string[], sortBaseKey: string, baseKey: string): string[] {
    console.log('sorting array by translation: ', array);
    return array.sort(
      (a, b) => {
        let translationA = '';
        let translationB = '';

        if (sortBaseKey) {
          translationA = this.translateService.instant(sortBaseKey + a);
          translationB = this.translateService.instant(sortBaseKey + b);
        }

        if (!sortBaseKey || translationA.startsWith(sortBaseKey)) {
          translationA = this.translateService.instant(baseKey ? (baseKey + a) : a);
        }

        if (!sortBaseKey || translationB.startsWith(sortBaseKey)) {
          translationB = this.translateService.instant(baseKey ? (baseKey + b) : b);
        }
        if (translationA > translationB) {
          return 1;
        } else if (translationA < translationB) {
          return -1;
        }
        return 0;
      });
  }

  public get onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

}
