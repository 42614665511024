<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn && col !== PRICE_DATA_COLUMN" matColumnDef="{{col}}">
        <mat-header-cell priceTableCell [column]="col" *matHeaderCellDef>
          {{getI18nBase(col) | translate}}
        </mat-header-cell>
        <mat-cell priceTableCell [column]="col" *matCellDef="let element">
          <div *ngIf="col===COUNTRY_COLUMN">{{'global.country.' + element[col] | translate}}</div>
          <div *ngIf="!requiresTranslation(col)">{{element | countryPriceColumnValue: col}}</div>
          <div *ngIf="col === VALID_FROM || col === VALID_UNTIL">{{getDateTimeTranslation(element[col])}}</div>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="col !== actionColumn && col === PRICE_DATA_COLUMN" matColumnDef="{{col}}">
        <mat-header-cell class="class-price-header-cell" *matHeaderCellDef>
          {{'product-detail.label.priceData' | translate}}
        </mat-header-cell>
        <mat-cell class="class-price-cell" *matCellDef="let element ">
          <class-prices-table class="class-price-table"
                              *ngIf="hasClassPrices(element)"
                              [data]="observableOfClassData(element)"
                              [disableEdit]="true"
                              [dataColumns]="classPriceTableColumns"
                              [country]="element[COUNTRY_COLUMN]"
                              [taxDateTime]="element[VALID_FROM]? element[VALID_FROM] : element[VALID_UNTIL]"
                              [i18nBase]="'product-detail.label.chargeClass'">
          </class-prices-table>
        </mat-cell>
      </ng-container>
    </div>

    <ng-container matColumnDef="tableAction">
      <mat-header-cell *matHeaderCellDef class="actionCell"> {{'fleet.tableheader.action' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="actionCell">
        <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                (mouseleave)="changeStyle($event)" (click)="editElement(element)" *ngIf="!disableEdit">
          <mat-icon> edit</mat-icon>
        </button>
        <button mat-mini-fab class="delete mat-elevation-z0" (mouseenter)="changeStyle($event)"
                (mouseleave)="changeStyle($event)" (click)="deleteElement(element)" *ngIf="!disableDelete">
          <mat-icon> delete_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
