/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {CardInventoryStatus} from './card-inventory-status.enum';
import {Identifiable} from 'pcs-commons/datatypes';

export enum CardInventoryAttributes {
  UID = 'uid',
  NAME = 'name',
  STATUS = 'status',
  CONTRACT_ID = 'contractId'
}

export class CardInventory extends Identifiable {
  public static readonly tableColumns: string[] = [
    CardInventoryAttributes.UID,
    CardInventoryAttributes.NAME,
    CardInventoryAttributes.STATUS
  ];

  public uid: string;
  public name: string;
  public status: CardInventoryStatus;
  public contractId: string;
}
