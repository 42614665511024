/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { DateTime } from 'luxon';
import { Parameters } from 'pcs-commons/global';

export class DateUtils {
  private static readonly DATE_TIME_FORMATS = [Parameters.BACKEND_DATE_TIME_FORMAT2, Parameters.BACKEND_DATE_TIME_FORMAT3];

  public static convertToDateTimeWithUTC(date: DateTime | string | null): DateTime {
    if (!date) {
      return null;
    }

    let dateTime: DateTime;
    if (date instanceof DateTime) {
      dateTime = date;
    } else {
      dateTime = DateTime.fromISO(date, { zone: 'utc' });
      const formats = [
        Parameters.BACKEND_IMPORT_DATE_FORMAT, // dd.LL.yyyy
        'dd.MM.yy',
        'LL/dd/yyyy',
        'dd.MM.yy',
        Parameters.BACKEND_DEFAULT_DATE_FORMAT // yyyy-LL-dd
      ];
      for (const format of formats) {
        if (dateTime?.isValid) {
          break;
        }
        dateTime = DateTime.fromFormat(date, format, { zone: 'utc' });
      }
    }
    if (dateTime.isValid) {
      return dateTime;
    }
    return null;
  }

  public static parseToDateTime(date: string): DateTime | null {
    if (!date) {
      return null;
    }
    const isoDateTime = DateTime.fromISO(date, { zone: 'utc' });
    if (isoDateTime?.isValid) {
      return isoDateTime;
    }
    for (const format of DateUtils.DATE_TIME_FORMATS) {
      const dateTime = DateTime.fromFormat(date, format, { zone: 'utc' });
      if (dateTime?.isValid) {
        return dateTime;
      }
    }
    return null;
  }

  public static nextDay(currDate: string | DateTime): DateTime {
    const date = currDate instanceof DateTime ? currDate : DateTime.fromISO(currDate, { zone: 'utc' });
    return date.plus({ days: 1 });
  }

  public static previousDay(currDate: string | DateTime): DateTime {
    const date = currDate instanceof DateTime ? currDate : DateTime.fromISO(currDate, { zone: 'utc' });
    return date.minus({ days: 1 });
  }

  public static dateIsInFuture(dateAsString: string): boolean {
    if (!dateAsString) {
      return false;
    }
    return DateUtils.convertToDateTimeWithUTC(dateAsString) > DateTime.now();
  }

  public static toBackendDate(date: DateTime | string | null): string {
    const dateTime = DateUtils.convertToDateTimeWithUTC(date);
    return dateTime?.isValid ? dateTime.toFormat(Parameters.BACKEND_DEFAULT_DATE_FORMAT) : null;
  }

  public static dateTimeIsInFuture(dateAsString: string): boolean {
    if (!dateAsString) {
      return false;
    }
    return DateUtils.convertToDateTimeWithUTC(dateAsString) > DateTime.now();
  }

  public static isDateAfter(toCheck: string, refDate: string): boolean {
    if (!toCheck || !refDate) {
      return false;
    }
    const refDateTime = DateUtils.convertToDateTimeWithUTC(refDate);
    const dateTimeToCheck = DateUtils.convertToDateTimeWithUTC(toCheck);
    return dateTimeToCheck > refDateTime;
  }

  public static buildDateTimeFromSeparateFields(date: DateTime, time: string, endOfSecond: boolean): DateTime | null {
    if (!date?.isValid) {
      return null;
    }
    if (!time) {
      return date;
    }
    const luxonTime = DateTime.fromFormat(time, 'HH:mm:ss', { zone: 'utc' });
    if (luxonTime?.isValid) {
      return date.set({
        hour: luxonTime.hour,
        minute: luxonTime.minute,
        second: luxonTime.second,
        millisecond: endOfSecond ? 999 : 0
      });
    }
    return null;
  }
}
