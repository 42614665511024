/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {NotificationService} from 'pcs-commons/notification';
import {WebConfigRouterService} from '../services/web-config-router.service';
import {UserService} from '../services/http/user.service';
import {SharedDataService} from '../services/shared-data.service';
import {UserDataSourceService} from '../services/user-data-source.service';
import {Parameters} from '../global/parameters';
import {UserAuthDetails} from '../datatypes/user-auth-details';
import {UserInfo} from '../datatypes/user-info';
import {MFADetails} from '../datatypes/mfadetails';
import {firstValueFrom} from "rxjs";
import {LoginService} from "pcs-commons/http";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private router: WebConfigRouterService,
    private notificationService: NotificationService,
    private userService: UserService,
    private dataSource: SharedDataService,
    private userDataSource: UserDataSourceService,
    private loginService: LoginService
  ) {
  }

  public async verifyUserAuthDetailsPresent(): Promise<boolean> {
    let accessToken = this.userDataSource.currentAccessToken;
    if (accessToken) {
      return true;
    }

    accessToken = sessionStorage.getItem(Parameters.KEY_ACCESS_TOKEN);
    if (!accessToken) {
      this.loginService.toCognitoLogin();
      return false;
    }

    // at this stage it means that there was a page refresh, so we need to update usher's authorization data again
    return await this.fetchAndUpdateUserInformation(accessToken);
  }

  public fetchUserAuthDetailsAndNavigateToHomePage(accessToken: string): void {
    if (!accessToken) {
      this.notificationService.notifyInvalidAuth();
      return;
    }
    sessionStorage.setItem(Parameters.KEY_ACCESS_TOKEN, accessToken);
    this.fetchAndUpdateUserInformation(accessToken).then(success => {
      if (success) {
        console.log("Successfully fetched user's information");
        this.router.toUsersHomePage();
      }
    });
  }


  private async fetchAndUpdateUserInformation(accessToken: string): Promise<boolean> {
    console.log('fetching user info');
    const userInfo = await firstValueFrom(this.userService.getUserInfo());
    if (!userInfo) {
      this.notificationService.notifyInvalidAuth();
      return false;
    }
    this.updateAuthDetailsInDataSource(userInfo, accessToken);
    return true;
  }

  private updateAuthDetailsInDataSource(user: UserInfo, accessToken: string): void {
    const authDetails = new UserAuthDetails();
    authDetails.id = user.id;
    authDetails.userId = user.userId;
    authDetails.accessToken = accessToken;
    authDetails.userName = user.userName;
    authDetails.role = user.role;
    authDetails.rights = user.rights;
    authDetails.login = user.login;
    this.userDataSource.updateUserAuthDetails(authDetails);
    const mfaDetails = new MFADetails();
    mfaDetails.preferredMfaSetting = user.preferredMfaSetting;
    mfaDetails.userMFASettingList = user.userMFASettingList;
    this.userDataSource.updateMFADetails(mfaDetails);
  }

  public clearUserAuthDetails(): void {
    this.userDataSource.clearAllUserData();
  }
}
