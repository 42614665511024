/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewInit, Component, Renderer2, ViewChild} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {TranslationHelperService} from 'pcs-commons/http';
import {
  PurchaseConditionElement,
  PurchaseConditionElementAttributes
} from '../../datatypes/purchase-conditions/purchase-condition-element';
import {Observable, of} from 'rxjs';
import {
  PurchasePriceComponent,
  PurchasePriceComponentAttributes
} from '../../datatypes/purchase-conditions/purchase-price-component';
import {MatPaginator} from '@angular/material/paginator';
import {Parameters} from '../../global/parameters';

@Component({
  selector: 'active-purchase-conditions-table',
  templateUrl: './active-purchase-conditions-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css',
    '../data-table.component.css',
    './active-purchase-conditions-table.component.css']
})
export class ActivePurchaseConditionsTableComponent extends OptimizedTableBaseComponent implements PermissionAware, AfterViewInit {

  readonly reqEditPermission: AccessRights[] = [AccessRights.PRODUCT_EDIT_WEB];

  readonly elementColumns: string[] = Object.values(PurchaseConditionElementAttributes);

  readonly pricesColumn = PurchaseConditionElementAttributes.PRICES;
  readonly daysOfWeekColumn = PurchaseConditionElementAttributes.DAYS_OF_WEEK;
  readonly startDateColumn = PurchaseConditionElementAttributes.START_DATE;
  readonly endDateColumn = PurchaseConditionElementAttributes.END_DATE;
  readonly powerClassColumn = PurchaseConditionElementAttributes.POWER_CLASSES;
  readonly validFromColumn = 'validFrom';
  readonly validUntilColumn = 'validUntil';
  readonly statusColumn = 'status';
  readonly countryColumn = 'country';
  readonly pricesTableColumns: string[] = Object.values(PurchasePriceComponentAttributes);
  readonly columnsNeedConversion: string[] = [this.powerClassColumn, this.daysOfWeekColumn, this.startDateColumn, this.endDateColumn,
    this.validFromColumn, this.validUntilColumn, this.statusColumn, this.countryColumn];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  ngAfterViewInit() {
    this.tableDataSource.paginator = this.paginator;
  }

  isElementCol(col: string) {
    return this.elementColumns.includes(col);
  }

  hasPrices(element: PurchaseConditionElement): boolean {
    return element && element.prices && element.prices.length > 0;
  }

  observableOfPrices(element: PurchaseConditionElement): Observable<PurchasePriceComponent[]> {
    return of(element.prices);
  }

  translateDate(date: any) {
    return this.translateHelper.translateDateSafe(date);
  }
}
