<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents>
  <h2 mat-dialog-title>{{ 'currencyfactor.configure.dialogue.header'|translate }}
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
    <button (click)="addRecord()" *ngIf="currency" class="headerActionBtn addCurrencyFactor icon-only"
            mat-raised-button>
      <mat-icon>add</mat-icon>
    </button>
  </h2>
  <mat-toolbar class="error error-toolbar"
               *ngIf="currency && (currencyFactorsToEdit$ | async)?.length > 0  && showDateRangeError()">
    <span class="error-span">{{ editCurrencyFactorForm.errors.invalidDateRange.message }}</span>
  </mat-toolbar>
  <form class="dialog-form" [formGroup]="editCurrencyFactorForm" *ngIf="editCurrencyFactorForm">
    <mat-form-field appearance="outline" class="currency-selection">
      <mat-label>{{ 'currencyfactor.currency' | translate }}</mat-label>
      <mat-select required [formControl]="currencyControl" (selectionChange)="onCurrencySelectionChanged($event)">
        <mat-option *ngFor="let currency of currencies"
                    [value]="currency">{{ currency }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="currencyControl.invalid">{{ getErrorFC(currencyControl) | async }}</mat-error>
    </mat-form-field>
    <pcs-edit-currency-factors-table (deleteEvent)="deleteRecord($event)"
                                     *ngIf="currency"
                                     [actionButtons]="true"
                                     [dataColumns]="dataColumns"
                                     [data]="currencyFactorsToEdit$"
                                     [editCurrencyFactorForm]="editCurrencyFactorForm"
                                     [i18nBase]="'currencyfactor'">
    </pcs-edit-currency-factors-table>
    <mat-dialog-actions>
      <button okAction mat-raised-button class="dialogueButton dialogueButtonRight"
              [disabled]="!allInputsValid()"
              (click)="onSave()">
        {{ 'button.save' | translate }}
      </button>
      <button cancelAction mat-raised-button class="dialogueButton"
              (click)="onCancel()">
        {{ 'button.cancel' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
