/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {ChargepointFilter} from '../datatypes/chargepoint-filter';
import {ChargepointOverviewAdminDto} from '../datatypes/location/chargepoint/chargepoint-overview-admin-dto';
import {LocationService} from '../services/http/location.service';
import {ChargepointFilterEventService} from '../services/chargepoint-filter-event.service';
import {ChargepointFilterType} from '../datatypes/chargepoint-filter-type.enum';
import {AbstractChargepointFilterManager} from './abstract-chargepoint-filter-manager';
import {UnusedChargepointFilter} from '../datatypes/unused-chargepoint-filter';
import {WebConfigRouterService} from '../services/web-config-router.service';

@Component({
  selector: 'chargepoints',
  templateUrl: './chargepoints.component.html',
  styleUrls: ['./chargepoints.component.css']
})
export class ChargepointsComponent extends AbstractChargepointFilterManager implements OnInit {

  constructor(
    private router: WebConfigRouterService,
    filterEventService: ChargepointFilterEventService,
    locationService: LocationService
  ) {
    super(locationService, filterEventService);
  }

  protected get cpDataColumns(): string[] {
    return this.currentFilterType === ChargepointFilterType.UNUSED_CHARGEPOINTS ? ChargepointOverviewAdminDto.dataColumnsUnusedCP :
      ChargepointOverviewAdminDto.dataColumns;
  }

  public ngOnInit(): void {
    this.subscribeToFilterEvents();
  }

  public applyFilter(filter: ChargepointFilter | UnusedChargepointFilter): void {
    if (!filter || this.filterOnProcess) {
      return;
    }
    this.currentFilter = filter;
    if (filter instanceof ChargepointFilter) {
      this.applyDefaultFilter(filter);
    } else if (filter instanceof UnusedChargepointFilter) {
      this.applyUnusedChargepointFilter(filter);
    }
  }

  protected navigateToDetailsView(cp: ChargepointOverviewAdminDto): void {
    console.log('navigating to details view of chargepoint: ', cp);
    this.router.navigate(['location-details'], {
      queryParams: {
        locationGroupId: cp.locationGroupId,
        evseId: cp.evseId
      }
    })
    ;
  }

  private applyDefaultFilter(filter: ChargepointFilter): void {
    console.log('processing default chargepoint search event: ', filter);
    this.currentFilter = JSON.parse(JSON.stringify(filter)); // clone it to avoid mutation
    // set offset and pageSize
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.filterOnProcess = true;
    this.getDefaultChargepointsByFilter(filter, 0, this.pageSizeOptions[1]);
  }

  private subscribeToFilterEvents(): void {
    this.filterEventService.refreshSources();
    this.subscriptions.push(
      this.filterEventService.currFilterType$.subscribe(
        filterType => {
          if (this.currentFilterType !== filterType) {
            this.handleFilterChange(filterType);
          }
        }
      ));
    this.filterEventSubscription = this.filterEventService.defaultCPFilterEvent$.subscribe(filter => this.applyFilter(filter));
  }
}
