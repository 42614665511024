<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>
    {{ 'product-edit.title.plan'|translate }}
  </h2>
  <div class="content-div">
    <form [formGroup]="planFormGroup">
      <mat-form-field appearance="outline">
        <mat-label>{{ "product-detail.label.partner" | translate }}</mat-label>
        <mat-select formControlName="partner">
          <mat-option *ngFor="let partner of partnerList"
                      [value]="partner">{{ partner }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'product-detail.label.partnerPlanId' | translate }}</mat-label>
        <input formControlName="partnerPlanId" matInput required>
      </mat-form-field>
      <mat-checkbox
        formControlName="inclusivePlan">{{'product-detail.label.inclusivePlan' | translate}}</mat-checkbox>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="onSave()" [disabled]="!planFormGroup.valid" class="dialogueButton" id="actionBtn"
          mat-stroked-button>
    {{ 'button.save' | translate }}
  </button>
  <button [mat-dialog-close]="undefined" class="dialogueButton" id="cancelBtn" mat-stroked-button>
    {{ 'button.cancel' | translate }}
  </button>
</mat-dialog-actions>
