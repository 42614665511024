/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable, OnDestroy} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {SharedDataService} from '../services/shared-data.service';
import {Subscription} from 'rxjs';
import {UserGroup} from '../datatypes/user-group';

@Injectable({providedIn: 'root'})
export class UniqueGroupNameValidator implements Validator, OnDestroy {
  public toUnsubscribe: Subscription[] = [];
  private existingGroupNames: string[] = [];
  private currGroupOnEdit: UserGroup;

  constructor(private dataService: SharedDataService) {
    this.toUnsubscribe.push(this.dataService.currentUserGroups$.subscribe(
      (groups) => {
        console.log('setting group names: ', groups);
        this.existingGroupNames = groups.map((gr) => gr.name);
      }
    ));
    this.toUnsubscribe.push(this.dataService.currentGroupToEdit$.subscribe((grp) => this.currGroupOnEdit = grp));
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    console.log('validating name: ', control);
    return this.foundMatch(control) ? {notUniqueGroupName: true} : null;
  }

  public foundMatch(control: AbstractControl): boolean {
    let inputValue = control.value;
    if (!inputValue) {
      return false;
    }
    inputValue = inputValue.toString();
    const match = this.existingGroupNames.find((grName) => grName.toLowerCase() === (inputValue as string).toLowerCase());

    let isUnique: boolean = !match;
    if (!isUnique && this.currGroupOnEdit) {
      isUnique = match.toLowerCase() === this.currGroupOnEdit.name.toLowerCase();
    }
    console.log('name is unique: ', isUnique,
      ' \nmatched name: ', match,
      ' \ninput value: ', inputValue,
      ' \ncurr group on edit: ', this.currGroupOnEdit);
    return !isUnique;
  }

}
