/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {SharedDataService} from '../../../services/shared-data.service';
import {Product} from '../../../datatypes/product';
import {combineLatest, Observable, of} from 'rxjs';
import {TaxData} from '../../../datatypes/taxdata';
import {TaxRecord} from '../../../datatypes/taxrecord';
import {Utils} from '../../../utils/utils';
import {DateUtils, FormUtils} from 'pcs-commons/utils';
import {ChargepointClassPrice, ChargepointClassPriceColumn} from '../../../chargepoint-class-price';
import {EditCountryPriceFormManager} from './edit-country-price-form-manager.abstract';
import {TranslationHelperService} from 'pcs-commons/http';
import {DateRangeValidator2Service} from '../../../validation/date-range-validator2.service';
import {PriceUtils} from '../../../utils/price-utils';
import {
  CountryPriceRecord,
  CountryPriceRecordColumns,
  EditCountryPriceRecordColumns
} from '../../../country-price-record';
import {ValidationMessagePipe, ValidatorList} from 'pcs-commons/validation';

@Component({
  selector: 'pcs-edit-country-price',
  templateUrl: './edit-country-price.component.html',
  styleUrls: [
    '../../../dialog/dialog-common-styles.css',
    '../../../common/tab-styles.css',
    './edit-country-price.component.css']
})
export class EditCountryPriceComponent extends EditCountryPriceFormManager implements OnInit {

  public readonly classPriceTableColumns: string[] = Object.values(ChargepointClassPriceColumn);

  public countries: string[];
  public currencies: string[];

  constructor(
    formBuilder: FormBuilder,
    dateRangeValidator2Service: DateRangeValidator2Service,
    private dataSource: SharedDataService,
    private dialogRef: MatDialogRef<EditCountryPriceComponent>,
    private validationPipe: ValidationMessagePipe,
    private translationHelper: TranslationHelperService
  ) {
    super(formBuilder, dateRangeValidator2Service);
  }

  public ngOnInit(): void {
    this.defineBasicInfoForm();
    this.subscribeToDataSources();
  }

  public defineBasicInfoForm(): void {
    this.country = new FormControl({
      value: null,
      disabled: this.countryPriceOnProcess
    }, ValidatorList.ALPHA2_COUNTRY_REQUIRED);
    this.currency = new FormControl(null, [Validators.required]);

    this.basicInfoForm = this.formBuilder.group({
        country: this.country,
        currency: this.currency
      }
    );
    this.toUnsubscribe.push(
      this.country.valueChanges.subscribe(
        (newCountry) => {
          this.checkAndUpdateFormsForExistingPrices(newCountry);
          this.updateCurrencyAndVat(newCountry);
        }
      )
    );
  }

  public subscribeToDataSources(): void {
    const observables = [
      this.dataSource.productToEdit$,
      this.dataSource.countryPriceToEdit$,
      this.dataSource.currentTaxData$,
    ];
    this.dataSourceSubscription = combineLatest(observables).subscribe((data) => {
      this.currentProduct = data[0] as Product;
      this.countryPriceOnProcess = data[1] as CountryPriceRecord;
      this.taxData = data[2] as TaxData;
      if (!this.countryPriceOnProcess) {
        console.log('creating empty country price');
        this.countryPriceOnProcess = new CountryPriceRecord();
      }
      this.fetchAllPricesForCountry();
      this.updateFormValues();
      this.defineClassPricesForms();
      this.countries = this.getCountryList();
      this.currencies = this.getCurrencyList();
    });
  }

  private fetchAllPricesForCountry(): void {
    const currCountry = this.countryPriceOnProcess.country;

    // this means its a new price config
    if (!currCountry) {
      this.currCountryPrices = [this.toCountryPriceWithAllClassPrices(this.countryPriceOnProcess)];
      console.log('country not present, adding empty class price data -> ', this.currCountryPrices);
      return;
    }

    this.currCountryPrices = this.currentProduct.prices
      .filter((cPrice) => cPrice.country === currCountry)
      .map((cPrice) => this.toCountryPriceWithAllClassPrices(cPrice));
    const userSelectedPriceIndex = this.currCountryPrices.findIndex(
      (cPrice) => cPrice.validFrom === this.countryPriceOnProcess.validFrom && cPrice.validUntil === this.countryPriceOnProcess.validUntil
    );

    this.selectedPriceTab = userSelectedPriceIndex;
    console.log('all prices for country: ', currCountry, ' -> ', this.currCountryPrices);
    console.log('Selected price index: ', userSelectedPriceIndex);
    console.log('selected tab index: ', this.selectedPriceTab);
  }

  public updateFormValues(): void {
    if (this.countryPriceOnProcess) {
      this.country.setValue(this.countryPriceOnProcess.country);
      this.currency.setValue(this.countryPriceOnProcess.currency);
    }
  }

  public updateCurrencyAndVat(country: string): void {
    if (!this.taxData || !country) {
      return;
    }
    PriceUtils.updateCurrencyForCountry(country, this.taxData, this.currency);
    this.updateVat(country);
  }

  public updateVat(country: string): void {
    this.currCountryPricesFormList.forEach((priceForm) => {
      let taxDateTime = DateUtils.buildDateTimeFromSeparateFields(priceForm.value.validFromTime,
          priceForm.value.validUntilFromTime, false);
      if (!taxDateTime) {
        taxDateTime = DateUtils.buildDateTimeFromSeparateFields(priceForm.value.validUntilDate,
            priceForm.value.validUntilTime, false);
      }

      const vat = PriceUtils.getTaxForTime(country, this.taxData, taxDateTime?.toISO());
      priceForm.controls[CountryPriceRecordColumns.TAX].setValue(vat * 100);
    });
  }

  public getErrorMsgBase(): string {
    return 'validation.error.';
  }

  public isSaveAllowed(): boolean {
    return (!this.basicInfoForm.pristine || !this.parentClassPricesForm.pristine)
      && this.basicInfoForm.valid && this.parentClassPricesForm.valid;
  }

  public onSave(): void {
    if (this.basicInfoForm.pristine && this.parentClassPricesForm.pristine) {
      // nothing changed, just return
      this.onOk();
      return;
    }

    // 1. fetch and create countryPricesRecord list from the Forms
    const newCountryPrices: CountryPriceRecord[] = [];
    this.currCountryPricesFormList.forEach((cPriceForm) => {
      const cPrice = this.prepareCountryPrice(cPriceForm);
      newCountryPrices.push(cPrice);
    });

    // 2. get all Prices from product and remove the existing prices for the country and replace them with the new list
    const currCountry = this.country.value;
    const updatedCountryPrices = this.currentProduct.prices
      .filter((cPrice) => cPrice.country !== currCountry);
    updatedCountryPrices.push(...newCountryPrices);

    this.currentProduct.prices = updatedCountryPrices;

    // detach data source change dependencies first
    this.dataSourceSubscription.unsubscribe();
    this.dataSource.updateCurrentProductOnEdit(this.currentProduct);
    this.onOk();
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onOk(): void {
    this.dialogRef.close(true);
  }

  public getCountryList(): string[] {
    const countries = [];
    let j: number;
    const taxes: TaxRecord[] = this.taxData.data;
    for (j = taxes.length - 1; j >= 0; j--) {
      Utils.addArrayItem(countries, taxes[j].country);
    }
    return countries;
  }

  public getCurrencyList(): string[] {
    const currencies = [];
    let j: number;
    const taxes: TaxRecord[] = this.taxData.data;
    for (j = taxes.length - 1; j >= 0; j--) {
      Utils.addArrayItem(currencies, taxes[j].currency);
    }
    return currencies;
  }

  public getCountry(): any {
    return this.country.value;
  }

  public observableOfClassPrices(priceIndex: number): Observable<ChargepointClassPrice[]> {
    const selectedPrice = this.currCountryPrices[priceIndex];
    return of(selectedPrice ? selectedPrice.priceDtos : []);
  }

  public getTabLabel(index: number): string {
    const pricesForm = this.currCountryPricesFormList[index];
    const validUntil = this.translationHelper.translateDateSafe(
        FormUtils.getValue(pricesForm, EditCountryPriceRecordColumns.VALID_UNTIL_DATE));
    const validFrom = this.translationHelper.translateDateSafe(
        FormUtils.getValue(pricesForm, EditCountryPriceRecordColumns.VALID_FROM_DATE));
    return (validFrom ? validFrom : '') + ' - ' + (validUntil ? validUntil : '');
  }

  public onAddNewPriceConfig(): void {
    const emptyPrice = this.toCountryPriceWithAllClassPrices(new CountryPriceRecord());
    this.currCountryPrices.push(emptyPrice);
    this.defineAndPushNewFormForPrice(emptyPrice);
    this.refreshParentPricesForm(true);
    // mark parentClassPricesForm as dirty to make it possible to save with the default unknown class prices
    this.parentClassPricesForm.markAsDirty();
    console.log('added new price config! all price configs: ', this.currCountryPrices);
    setTimeout(() => this.selectedPriceTab = this.currCountryPrices.length - 1);
  }

  public onRemovePriceConfig(index: number): void {
    // first navigate to previous tab, if current tab's price is being removed
    console.log('removing price config of tab: ', index);
    console.log('current tab: ', this.selectedPriceTab);
    const nextTab = index === 0 ? 0 : (this.selectedPriceTab >= index ? this.selectedPriceTab - 1 : this.selectedPriceTab);
    console.log('navigating to tab: ', nextTab);
    setTimeout(() => this.selectedPriceTab = nextTab);

    // Remove from form group list
    this.currCountryPricesFormList.splice(index, 1);
    this.currCountryPrices.splice(index, 1);
    this.refreshParentPricesForm(true);
  }

  private checkAndUpdateFormsForExistingPrices(newCountry: string): void {
    console.log('updating forms for country selection: ', newCountry);
    this.currCountryPrices = this.currentProduct.prices
      .filter((cPrice) => cPrice.country === newCountry)
      .map((cPrice) => this.toCountryPriceWithAllClassPrices(cPrice));
    console.log('current country prices for country: ', newCountry, ' :', this.currCountryPrices);
    this.defineClassPricesForms();
    this.selectedPriceTab = 0;
    this.basicInfoForm.markAsPristine();
    this.parentClassPricesForm.markAsPristine();
  }
}
