/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Role} from './role.enum';

export class User {
  public static readonly dataTableColumns = ['login', 'role', 'userGroup'];
  public static readonly fleetManagerColumns = ['login', 'userGroup'];

  public static ADMIN_GROUP_NAME = 'Administrator';
  public id: number;
  public userId: string;
  public login: string;
  public phone: string;
  public password: string;
  public role: Role;
  public userGroup: string;
  public hasActiveChangeRequest: boolean;

  public clone(from: User): void {
    this.id = from.id;
    this.userId = from.userId;
    this.login = from.login;
    this.phone = from.phone;
    this.password = from.password;
    this.role = from.role;
    this.userGroup = from.userGroup;
    this.hasActiveChangeRequest = from.hasActiveChangeRequest;
  }
}
