/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Plan, PlanFormGroupType} from "../../../datatypes/plan";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PlanDialogData} from "../plan-dialog-data";


@Component({
  selector: 'pcs-edit-plan',
  templateUrl: './edit-plan.component.html',
  styleUrls: [
    '../../../dialog/dialog-common-styles.css',
    './edit-plan.component.css'
  ]
})
export class EditPlanComponent {
  protected planFormGroup: FormGroup<PlanFormGroupType> = undefined;
  protected readonly partnerList: string[];
  private readonly planToEdit: Plan;

  constructor(@Inject(MAT_DIALOG_DATA) private readonly dialogData: PlanDialogData,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<EditPlanComponent>
  ) {
    if (!this.dialogData?.plan) {
      this.planToEdit = {
        id: undefined,
        partnerPlanId: undefined,
        partner: undefined,
        inclusivePlan: false
      };
    } else {
      this.planToEdit = this.dialogData.plan;
    }

    this.partnerList = dialogData.partnerChoice;
    this.initializeFormGroup(this.planToEdit);
  }

  protected onSave(): void {
    const partialPlan = this.planFormGroup.value;
    const changedPlan: Plan = {...this.planToEdit, ...partialPlan};
    this.dialogRef.close(changedPlan);
  }

  private initializeFormGroup(planToEdit: Plan): void {
    this.planFormGroup = this.formBuilder.group({
      partner: [planToEdit?.partner, [Validators.required]],
      partnerPlanId: [planToEdit?.partnerPlanId, [Validators.required]],
      inclusivePlan: [planToEdit?.inclusivePlan, [Validators.required]]
    });
  }

}
