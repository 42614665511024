/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export enum ProductQuotaColumns {
  PRODUCT_CODE = 'productCode',
  MAX_AMOUNT = 'maxAmount',
  USED_AMOUNT = 'usedAmount',
  ACTIVE_CONTRACTS_TAYCAN = 'activeContractsTaycan',
  ACTIVE_CONTRACTS_OTHER = 'activeContractsOther',
  NEW_CONTRACTS_LAST_MONTH_TAYCAN = 'newContractsLastMonthTaycan',
  NEW_CONTRACTS_LAST_MONTH_OTHER = 'newContractsLastMonthOther',
  EXTENSIONS_LAST_MONTH = 'extensionsLastMonth',
  EXTENSIONS_CURRENT_MONTH = 'extensionsCurrentMonth',
  EXTENSIONS_NEXT_MONTH = 'extensionsNextMonth'
}

export enum ProductQuotaColumnGroups {
  PRODUCT_CODE = 'group.productCode',
  MAX_AMOUNT = 'group.maxAmount',
  USED_AMOUNT = 'group.usedAmount',
  ACTIVE_CONTRACTS = 'group.activeContracts',
  NEW_CONTRACTS_LAST_MONTH = 'group.newContractsLastMonth',
  EXTENSIONS_LAST_MONTH = 'group.extensionsLastMonth',
  EXTENSIONS_CURRENT_MONTH = 'group.extensionsCurrentMonth',
  EXTENSIONS_NEXT_MONTH = 'group.extensionsNextMonth'
}

export class ProductQuota {
  public static readonly dataTableColumns = ['productCode', 'maxAmount', 'usedAmount'];
  public static readonly createFleetTableColumns = [ProductQuotaColumns.PRODUCT_CODE, ProductQuotaColumns.MAX_AMOUNT];
  public static readonly columnGroupMap: Map<string, string[]> = new Map([
      [ProductQuotaColumnGroups.PRODUCT_CODE, [ProductQuotaColumns.PRODUCT_CODE]],
      [ProductQuotaColumnGroups.MAX_AMOUNT, [ProductQuotaColumns.MAX_AMOUNT]],
      [ProductQuotaColumnGroups.USED_AMOUNT, [ProductQuotaColumns.USED_AMOUNT]],
      // eslint-disable-next-line no-trailing-spaces, max-len
      [ProductQuotaColumnGroups.ACTIVE_CONTRACTS, [ProductQuotaColumns.ACTIVE_CONTRACTS_TAYCAN, ProductQuotaColumns.ACTIVE_CONTRACTS_OTHER]],
      // eslint-disable-next-line no-trailing-spaces, max-len
      [ProductQuotaColumnGroups.NEW_CONTRACTS_LAST_MONTH, [ProductQuotaColumns.NEW_CONTRACTS_LAST_MONTH_TAYCAN, ProductQuotaColumns.NEW_CONTRACTS_LAST_MONTH_OTHER]],
      [ProductQuotaColumnGroups.EXTENSIONS_LAST_MONTH, [ProductQuotaColumns.EXTENSIONS_LAST_MONTH]],
      [ProductQuotaColumnGroups.EXTENSIONS_CURRENT_MONTH, [ProductQuotaColumns.EXTENSIONS_CURRENT_MONTH]],
      [ProductQuotaColumnGroups.EXTENSIONS_NEXT_MONTH, [ProductQuotaColumns.EXTENSIONS_NEXT_MONTH]]
    ]
  );
  productCode: string;
  maxAmount: number;
  usedAmount: number;
  activeContractsTaycan: number;
  activeContractsOther: number;
  newContractsLastMonthTaycan: number;
  newContractsLastMonthOther: number;
  extensionsLastMonth: number;
  extensionsCurrentMonth: number;
  extensionsNextMonth: number;
}
