/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {HeaderEnabled} from "./header-enabled.abstract";

export interface ContractHistoryItem {
  contractId?: string,
  status: string,
  enrollmentStatus: string,
  lastUpdated: Date,
  contractCountry: string,
  oldPlan: string,
  newPlan: string
}

interface ContractHistory {
  historyElements: Array<ContractHistoryItem>
}
@Injectable({
  providedIn: 'root'
})
export class ContractHistoryService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public async readHistory(vin: string): Promise<Array<ContractHistoryItem>> {
    const headers = {
      headers: this.headers
    };
    const url = environment.contractHistory.replace("#{vin}", vin);
    const wrapper = await firstValueFrom(this.http.get<ContractHistory>(url, headers));
    return wrapper.historyElements;
  }


}
