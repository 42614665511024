/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AddressFormManager} from './address-form-manager.abstract';
import {FormBuilder} from '@angular/forms';
import {AddressType, CountryCode, FleetAddress, LanguageCode} from 'pcs-commons/datatypes';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fleet-address-editor',
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.css']
})
export class AddressEditorComponent extends AddressFormManager implements OnChanges {
  @Input() public address: FleetAddress;
  @Input() public addressType: AddressType;
  public countryList: string[] = [];
  public languageList: string[] = [];

  constructor(
    formBuilder: FormBuilder
  ) {
    super(formBuilder);
    this.countryList = Object.values(CountryCode);
    this.languageList = Object.values(LanguageCode);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.addressType && changes.addressType.currentValue) {
      this.defineFormControls(this.addressType);
    }
    if (changes.address && this.addressType) {
      this.updateFormValues(changes.address.currentValue ? this.address : new FleetAddress());
    }
  }
}
