/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Output, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {SharedDataService} from '../../services/shared-data.service';
import {TranslationHelperService} from 'pcs-commons/http';
import {take} from 'rxjs/operators';
import {Action} from '../../datatypes/action.enum';
import {FormControl, Validators} from '@angular/forms';
import {ElectricVehicle} from '../../datatypes/electric-vehicle';
import {ValidationMessagePipe} from 'pcs-commons/validation';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {ConfirmationDialogComponent} from '../../dialog/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Message} from '../../datatypes/message';
import {ElectricVehicleStatus} from '../../datatypes/electric-vehicle-status.enum';
import {Observable} from 'rxjs';

@Component({
  selector: 'electric-vehicle-table',
  templateUrl: './electric-vehicle-table.component.html',
  styleUrls: [
    '../scrollable-table/scrollable-table.component.css',
    '../action-column-style.css',
    './electric-vehicle-table.component.css']
})
export class ElectricVehicleTableComponent extends OptimizedTableBaseComponent {

  public readonly reqEditPermission: AccessRights[] = [AccessRights.VEHICLE_EDIT_WEB];
  public loading = false;

  public mode: Action = null;
  public evInEdit: number = null;
  public pcidControl = new FormControl('', [Validators.maxLength(100)]);
  public pcidMaxLength = 100;

  @Output() public revokeCertEvent = new EventEmitter<any>();

  constructor(renderer: Renderer2,
              private sharedDataService: SharedDataService,
              private translateHelper: TranslationHelperService,
              private validationPipe: ValidationMessagePipe,
              public dialog: MatDialog) {
    super(renderer);
  }

  public translateDate(date: any): any {
    return this.translateHelper.translateDateTimeSafe(date);
  }

  public revokePnCCert(ev: ElectricVehicle): void {
    const msg = new Message();
    msg.message = 'electricVehicle.message.revokeCert';
    msg.param0 = ev.vin;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {
        data: {
          message: msg,
          actionKey: 'global.button.yes'
        },
        maxWidth: 800
      }
    );
    dialogRef.afterClosed().subscribe((positive) => {
      if (positive) {
        this.revokeCertEvent.emit(ev);
      }
    });
  }

  public revokeNotAllowed(ev: ElectricVehicle): boolean {
    return !this.actionButtons || this.loading || ev.status === ElectricVehicleStatus.DELETED ||
      (ev.jobStatus && (ev.jobStatus.includes('QUEUED') || ev.jobStatus.includes('PENDING'))) || !ev.pncCertificateValidity;
  }

  public updatePcid(evToEdit: ElectricVehicle): void {
    if (evToEdit.pcid === this.pcidControl.value) {
      // we dont need to make any server call as no changes has been made, treat it as a cancel event
      this.cancelUpdatePcid();
      return;
    }
    this.loading = true;
    const updatedEv: ElectricVehicle = JSON.parse(JSON.stringify(evToEdit)); // clone it to avoid mutation;
    updatedEv.pcid = this.pcidControl.value;
    this.editElement(updatedEv);
    this.sharedDataService.getEvUpdateResultObservable().pipe(take(2)).subscribe({
      next: (success) => {
        if (success) {
          this.loading = false;
          this.cancelUpdatePcid();
          this.sharedDataService.updateEvUpdateResult(undefined);
        }
      },
      error: () => {
      },
      complete: () => this.loading = false
    });
  }

  public cancelUpdatePcid(): void {
    this.mode = null;
    this.evInEdit = null;
  }

  public enableEditForEv(toEdit: ElectricVehicle): void {
    this.mode = Action.EDIT;
    this.evInEdit = toEdit.id;
    this.pcidControl.setValue(toEdit.pcid);
  }

  public evIsInEdit(ev: ElectricVehicle): boolean {
    return this.editMode && this.evInEdit && this.evInEdit === ev.id;
  }

  get editMode(): boolean {
    return this.mode && Action.EDIT === this.mode;
  }

  public getError(control: FormControl): Observable<any> {
    return this.validationPipe.transform(control);
  }

  public updateEvOngoing(ev: ElectricVehicle): boolean {
    return this.loading && ev.id === this.evInEdit;
  }

  public disableEditPcid(): boolean {
    return !this.actionButtons || this.loading;
  }

  public pcidUpdateAllowed(ev: ElectricVehicle): boolean {
    return ElectricVehicleStatus.ACTIVE === ev.status;
  }

  public isjobPending(ev: ElectricVehicle): boolean {
    return ev.jobStatus?.endsWith('PENDING');
  }

  public editButtonTooltip(ev: ElectricVehicle): string {
    if (this.isjobPending(ev)) {
      return 'electricVehicle.editButton.wait';
    }
    return undefined;
  }
}
