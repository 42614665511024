/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, ViewChild} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {AccessRights} from '../datatypes/access-rights.enum';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {InvoiceService} from '../services/http/invoice.service';
import {InvoiceAdminColumns, InvoiceAdminDto} from '../datatypes/invoices/InvoiceAdminDto';
import {InvoiceFilterDto} from '../datatypes/invoices/InvoiceFilterDto';
import {InvoiceTransactionDialogComponent} from './invoice-transaction-dialog/invoice-transaction-dialog.component';
import {Parameters} from "pcs-commons/global";
import {Message} from "pcs-commons/datatypes";
import {
  InvoiceOrderItemsDialogComponent
} from "./invoice-order-items-dialog/invoice-order-items-dialog.component";
import {SharedDataService} from "../services/shared-data.service";

@Component({
  selector: 'invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent extends AbstractNotificationHandler implements OnDestroy {
  public readonly reqEditPermission = [AccessRights.INVOICE_EDIT_WEB];
  public loading = false;

  public pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  public resultLength = 0;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public invoicesSource = new BehaviorSubject<InvoiceAdminDto[]>([]);
  public invoices$ = this.invoicesSource.asObservable();

  public dataColumns: string[] = Object.values(InvoiceAdminColumns);
  public selectedPageSize = this.pageSizeOptions[0];
  public toUnsubscribe: Subscription[] = [];

  public selectedFilter: InvoiceFilterDto;

  constructor(
    private invoiceService: InvoiceService,
    private dialog: MatDialog,
    private sharedDataService: SharedDataService
  ) {
    super();
  }

  private retrieveInvoices(filter: InvoiceFilterDto, pageIndex: number, pageSize: number): void {
    const offset = pageIndex * pageSize;
    // send one more so see if we have more records than requested
    this.loading = true;

    this.invoiceService.retrieveInvoices(filter, offset, pageSize + 1).subscribe({
      next: (invoices) => {
        this.loading = false;

        this.handleResult(pageIndex, pageSize, invoices);
      },
      error: () => this.loading = false
    });
  }

  private handleResult(pageIndex: number, pageSize: number, invoices: InvoiceAdminDto[]): void {
    this.resultLength = pageIndex * pageSize + invoices.length;

    // remove extra element before showing
    if (invoices.length > pageSize) {
      invoices.splice(invoices.length - 1, 1);
    }

    this.invoicesSource.next(invoices);

    if (invoices.length <= 0) {
      const msg = new Message();
      msg.message = 'invoices.noRecordsFound';
      this.showInfo(msg);
    }
  }

  public onSearchInvoices(filter: InvoiceFilterDto): void {
    this.selectedFilter = filter;
    this.retrieveInvoices(filter, 0, this.selectedPageSize);
  }

  public handlePageChange(pageEvent: PageEvent): void {
    this.selectedPageSize = pageEvent.pageSize;
    this.retrieveInvoices(this.selectedFilter, pageEvent.pageIndex, pageEvent.pageSize);
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  public openDetails(selectedInvoice: InvoiceAdminDto): void {
    this.loading = true;
    const invoiceIdent = selectedInvoice.invoiceIdent;
    const id = selectedInvoice.id;
    const contractId = selectedInvoice.contractId;
    const fleetId = selectedInvoice.fleetId;

    this.retrieveTransactionsAndOpenDialog(contractId, fleetId, invoiceIdent, id);
  }

  private retrieveTransactionsAndOpenDialog(contractId: string, fleetId: string, invoiceIdent: string, id: number): void {
    this.invoiceService.retrieveTransactionsForInvoice(contractId || fleetId, invoiceIdent).subscribe({
      next: (transactions) => {
        console.log('received transactions for the invoice: ', transactions);
        this.loading = false;

        if (transactions.length <= 0) {
          const msg = new Message();
          msg.message = 'invoices.noTransactionsFound';
          this.showInfo(msg);
          return;
        }

        this.dialog.open(InvoiceTransactionDialogComponent, {
          data: {
            id,
            invoiceIdent,
            contractId,
            fleetId,
            transactions
          }
        });
      },
      error: () => this.loading = false
    });
  }

  public onRetrieveOrderItems(selectedInvoice: InvoiceAdminDto): void {
    this.loading = true;
    this.retrieveOrderItemsAndOpenDialog(selectedInvoice);
  }

  private retrieveOrderItemsAndOpenDialog(selectedInvoice: InvoiceAdminDto): void {
    this.invoiceService.retrieveOrderItems(selectedInvoice.contractId || selectedInvoice.fleetId, selectedInvoice.invoiceIdent).subscribe({
      next: (orderItems) => {
        console.log('received orderItems for the invoice: ', orderItems);
        this.loading = false;
        this.sharedDataService.updateOrderItemList(orderItems);
        if (orderItems.length <= 0) {
          const msg = new Message();
          msg.message = 'invoices.noOrderItemsFound';
          this.showInfo(msg);
          return;
        }

        this.dialog.open(InvoiceOrderItemsDialogComponent, {
          data: {
            selectedInvoice,
            originalOrderItems: orderItems
          }
        });
      },
      error: () => this.loading = false
    });
  }
}
