/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export enum Platform {
  HUBJECT = 'HUBJECT',
  INTERNAL = 'INTERNAL',
  NOBIL = 'NOBIL',
  GIREVE = 'GIREVE',
  SMARTLAB = 'SMARTLAB',
  OCPI_DIRECT = 'OCPI_DIRECT',
  STATIC = 'STATIC',
  OCPI_EA = 'OCPI_EA',
  OCPI_EC = 'OCPI_EC',
  CP_INC = 'CP_INC'
}
