/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {Operator} from "../datatypes/operator";
import {AccessRights} from "../datatypes/access-rights.enum";
import {
  CreateChangeRequestDialogComponent
} from "../dialog/create-change-request-dialog/create-change-request-dialog.component";
import {User} from "../datatypes/user";
import {CreateChangeRequestDialogData} from "../datatypes/audit-flow/create-change-request-dialog-data";
import {ChangeRequestAction} from "../datatypes/audit-flow/change-request-action";
import {UserService} from "./http/user.service";
import {UserDataSourceService} from "./user-data-source.service";
import {MatDialog} from "@angular/material/dialog";
import {ChangeRequestService} from "./http/audit/change-request.service";
import {firstValueFrom} from "rxjs";
import {Message, Notification} from "pcs-commons/datatypes";
import {NotificationStore} from "pcs-commons/notification";
import {ChangeRequestPayloadType} from "../datatypes/inbox/change-request-payload-type";
import {Product} from "../datatypes/product";
import {CDRBillingDto} from "../datatypes/CDRBillingDto";
import {OrderItemDto} from "../datatypes/invoices/OrderItemDto";
import {InvoiceAdminDto} from "../datatypes/invoices/InvoiceAdminDto";
import {ReimbursementRequestDto} from "../datatypes/invoices/ReimbursementRequestDto";
import {UserGroup} from "../datatypes/user-group";
import {
  LocationAndChargepointAdminAttributeDto
} from "../datatypes/location/location-and-chargepoint-admin-attribute-dto";

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(
    private dialog: MatDialog,
    private userService: UserService,
    private changeRequestService: ChangeRequestService,
    private userDataSource: UserDataSourceService) {
  }

  public async initiateChangeRequest(objectId: number,
                                     updatedValue: unknown,
                                     originalValue: unknown,
                                     requiredAccessRight: AccessRights,
                                     payloadType: ChangeRequestPayloadType,
                                     action: ChangeRequestAction,
                                     messageParameters?: Array<string>,
                                     additionalMessageKeyPart?: string,
                                     excludedReviewers?: User[]): Promise<boolean> {
    if (objectId && (await this.objectHasActiveChangeRequest(payloadType, objectId))) {
      return false;
    }

    const message = this.getChangeRequestMsg(payloadType, action, messageParameters, additionalMessageKeyPart);
    const dialogData = await this.prepareCreateChangeRequestDialogData(
      objectId, requiredAccessRight, updatedValue, originalValue, payloadType, action, message, excludedReviewers);
    console.log("Opening create change request dialog with data: ", dialogData);
    const matDialogRef = this.dialog.open(CreateChangeRequestDialogComponent,
      {
        height: '550px',
        data: dialogData
      }
    );
    return await firstValueFrom(matDialogRef.afterClosed());
  }

  public async initiateChangeRequestForUser(action: ChangeRequestAction,
                                            originalUserData: User,
                                            updatedUserData?: User): Promise<boolean> {
    const id = originalUserData.id;
    const messageParameters = [originalUserData.login];
    return await this.initiateChangeRequest(id, updatedUserData, originalUserData, AccessRights.USERMANAGEMENT_EDIT_WEB, ChangeRequestPayloadType.USER,
      action, messageParameters, undefined, [originalUserData]);
  }

  public async initiateChangeRequestForCDRBillRunRevocation(cdr: CDRBillingDto): Promise<boolean> {
    const messageParameters = [cdr.sessionId, cdr.contractId];
    return await this.initiateChangeRequest(cdr.id, undefined, undefined, AccessRights.INVOICE_EDIT_WEB, ChangeRequestPayloadType.CDR,
      ChangeRequestAction.REMOVE_CDR_FROM_BILLRUN, messageParameters);
  }

  public async initiateChangeRequestForProduct(updatedProduct: Product, originalProduct: Product): Promise<boolean> {
    return await this.initiateChangeRequest(
      updatedProduct?.id ?? originalProduct?.id,
      updatedProduct,
      originalProduct,
      AccessRights.PRODUCT_EDIT_WEB,
      ChangeRequestPayloadType.PRODUCT,
      ChangeRequestAction.MODIFY,
      [originalProduct.name, originalProduct.code]);
  }

  public async initiateChangeRequestForReimbursement(updatedProduct: ReimbursementRequestDto,
                                                     id: number,
                                                     invoiceIdent: string): Promise<boolean> {

    const messageParameters = [invoiceIdent, updatedProduct.contractId ?? updatedProduct.fleetId];
    const addition = updatedProduct.contractId ? undefined : 'fleet';
    return this.initiateChangeRequest(id, updatedProduct, undefined, AccessRights.INVOICE_EDIT_WEB, ChangeRequestPayloadType.INVOICE,
      ChangeRequestAction.REIMBURSEMENT, messageParameters, addition);
  }

  public async initiateChangeRequestForUserGroup(action: ChangeRequestAction,
                                                 oldUserGroup?: UserGroup,
                                                 newUserGroup?: UserGroup): Promise<boolean> {
    const id = newUserGroup?.id ?? oldUserGroup?.id;

    const messageParameters = [oldUserGroup?.name ?? newUserGroup?.name];
    return this.initiateChangeRequest(id, newUserGroup, oldUserGroup, AccessRights.USERMANAGEMENT_EDIT_WEB, ChangeRequestPayloadType.USER_GROUP,
      action, messageParameters, undefined);
  }

  public async initiateChangeRequestForCPOActivationOrDeactivation(operator: Operator): Promise<void> {
    const action = operator.active ? ChangeRequestAction.DEACTIVATE : ChangeRequestAction.ACTIVATE;
    const messageParameters = [operator.cpoCode ? operator.cpoCode : this.getOperatorName(operator)];
    await this.initiateChangeRequest(operator.id, undefined, undefined, AccessRights.CPO_AND_PARTNER_EDIT_WEB, ChangeRequestPayloadType.CPO,
      action, messageParameters);
  }

  public async initiateChangeRequestForCPOToSetPreferredAttribute(operator: Operator,
                                                                  previousPreferredAttribute: boolean,
                                                                  newPreferredAttribute: boolean): Promise<void> {
    const action = ChangeRequestAction.SET_PREFERRED_ATTRIBUTE;
    const originalValueAsJson = {preferred: previousPreferredAttribute};
    const updatedValueAsJson = {preferred: newPreferredAttribute};
    const messageParameters = [operator.cpoCode ? operator.cpoCode : this.getOperatorName(operator)];
    await this.initiateChangeRequest(operator.id, updatedValueAsJson, originalValueAsJson,
      AccessRights.CPO_AND_PARTNER_EDIT_WEB, ChangeRequestPayloadType.CPO, action, messageParameters);
  }


  public async initiateChangeRequestForInvoiceCorrection(invoice: InvoiceAdminDto, originalOrderItemsData: OrderItemDto[], updatedOrderItemsData: OrderItemDto[]): Promise<boolean> {
    const addition = invoice.contractId ? "orderitems" : 'orderitems.fleet';
    const messageParameters = [invoice.invoiceIdent, invoice.contractId ?? invoice.fleetId];
    return await this.initiateChangeRequest(invoice.id, updatedOrderItemsData, originalOrderItemsData, AccessRights.INVOICE_EDIT_WEB, ChangeRequestPayloadType.INVOICE,
      ChangeRequestAction.CORRECTION, messageParameters, addition);
  }

  public async initiateChangeRequestForManualChargepointAttributes(updatedValue: LocationAndChargepointAdminAttributeDto, originalValue: LocationAndChargepointAdminAttributeDto, chargepointGroupId: number, evseid: string): Promise<boolean> {
    const messageParameters = [evseid];
    return await this.initiateChangeRequest(chargepointGroupId, updatedValue, originalValue, AccessRights.CHARGEPOINTS_EDIT_WEB, ChangeRequestPayloadType.CHARGEPOINT,
      ChangeRequestAction.MODIFY, messageParameters, "manualdata");
  }

  public async objectHasActiveChangeRequest(objectType: ChangeRequestPayloadType,
                                            objectId: number,
                                            silent = false): Promise<boolean> {
    const existingChangeRequest = await firstValueFrom(this.changeRequestService.findActiveChangeRequest(objectType, objectId));
    if (existingChangeRequest && !silent) {
      console.log("Found existing change request for object with id: ", objectId);
      NotificationStore.instance.notify(Notification.warn(new Message('audit.changeRequest.alreadyExists')))
    }
    return !!existingChangeRequest;
  }

  public getDialog(): MatDialog {
    return this.dialog;
  }

  private async prepareCreateChangeRequestDialogData(objectId: number,
                                                     requiredAccessRight: AccessRights,
                                                     updatedValue: unknown | undefined,
                                                     originalValue: unknown | undefined,
                                                     payloadType: ChangeRequestPayloadType,
                                                     action: ChangeRequestAction,
                                                     message: Message,
                                                     excludedReviewers?: User[]): Promise<CreateChangeRequestDialogData> {
    const dialogData = new CreateChangeRequestDialogData();
    const reviewerChoices = await this.getUsersWithRequiredRights(requiredAccessRight);
    dialogData.reviewerChoices = excludedReviewers ?
      reviewerChoices.filter(user => !excludedReviewers.find(excluded => excluded.userId === user.userId)) : reviewerChoices;
    dialogData.message = message;
    dialogData.objectType = payloadType;
    dialogData.objectId = objectId;
    dialogData.action = action;
    if (originalValue) {
      dialogData.originalValueAsJson = JSON.stringify(originalValue);
    }
    if (updatedValue) {
      dialogData.updatedValueAsJson = JSON.stringify(updatedValue);
    }
    return dialogData;
  }

  private getChangeRequestMsg(payloadType: ChangeRequestPayloadType,
                              action: ChangeRequestAction,
                              params?: Array<string>,
                              additionalMessageKeyPart?: string): Message {
    const addition = additionalMessageKeyPart ? additionalMessageKeyPart + "." : "";
    const msg = new Message(`audit.${payloadType.toLowerCase()}.${action.toLowerCase()}.${addition}message`);
    if (params) {
      msg.param0 = params[0];
      msg.param1 = params[1];
      msg.param2 = params[2];
    }
    return msg;
  }

  private async getUsersWithRequiredRights(requiredAccessRight: AccessRights): Promise<User[]> {
    return (await firstValueFrom(this.userService.getUsersByRight(requiredAccessRight)))
      .filter(user => user.userId !== this.userDataSource.currentUserId);
  }

  private getOperatorName(operator: Operator): string {
    return operator.name ?? '';
  }
}
