/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { NgModule } from '@angular/core';
import { AutocompleteStringSelectComponent } from './src/string-select/autocomplete-string-select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FleetAddressOverviewComponent } from './src/fleet-address-overview/fleet-address-overview.component';
import { EditHandlerComponent } from './src/edit-handler/edit-handler.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { ValidationModule } from 'pcs-commons/validation';
import { AddressEditorComponent } from './src/fleet-address/address-editor.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { LocalLoginComponent } from './src/local-login/local-login.component';
import { FleetInvoicesTableComponent } from './src/fleet-invoices-table/fleet-invoices-table.component';
import { DatatypesModule } from 'pcs-commons/datatypes';
import { DataTablesModule } from 'pcs-commons/data-tables';
import { FleetInvoicesComponent } from './src/fleet-invoices/fleet-invoices.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from 'pcs-commons/pipes';
import { MatSortModule } from '@angular/material/sort';
import { UtilsModule } from 'pcs-commons/utils';
import { LoadingSpinnerComponent } from './src/loading-spinner/loading-spinner.component';

@NgModule({
  declarations: [
    AutocompleteStringSelectComponent,
    FleetAddressOverviewComponent,
    EditHandlerComponent,
    AddressEditorComponent,
    LocalLoginComponent,
    FleetInvoicesComponent,
    FleetInvoicesTableComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    ValidationModule,
    MatGridListModule,
    DatatypesModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    PipesModule,
    MatSortModule,
    UtilsModule
  ],
  providers: [],
  exports: [
    AutocompleteStringSelectComponent,
    FleetAddressOverviewComponent,
    EditHandlerComponent,
    AddressEditorComponent,
    LocalLoginComponent,
    FleetInvoicesComponent,
    FleetInvoicesTableComponent,
    LoadingSpinnerComponent
  ]
})
export class ComponentsModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
