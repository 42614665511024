/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { StringUtils } from './string-utils';
import { Utils } from './utils';
import { DateUtils } from './date-utils';
import { Parameters } from 'pcs-commons/global';
import { DateTime } from 'luxon';

export class FormUtils {
  /**
   * @description instead of using FormGroup.get(form_control_name),
   * we use FormGroup.controls[form_control_name] since the get method sometimes failed to find the FormControl
   * please refer to bug #PAGCM-2572
   * @param fg the form group
   * @param name the name of the form control
   */

  public static getFormControl = (fg: UntypedFormGroup, name: string): AbstractControl => fg.controls[name];

  public static getValue(formGroup: UntypedFormGroup, formControlName: string): any {
    const ctrl = this.getFormControl(formGroup, formControlName);
    return ctrl ? ctrl.value : null;
  }

  public static getNullableNumber(formGroup: UntypedFormGroup, formControlName: string): number {
    const value = this.getValue(formGroup, formControlName);
    return Utils.isNotEmpty(value) ? Number(value) : null;
  }

  public static getValueAsBackendDate(formGroup: UntypedFormGroup, formControlName: string): string {
    const value = this.getValue(formGroup, formControlName);
    return value ? DateUtils.toBackendDate(value) : null;
  }

  public static getValueAsBackendTime(formGroup: UntypedFormGroup, formControlName: string): string | null {
    const value = this.getValue(formGroup, formControlName);
    if (!value) {
      return null;
    }
    // the value outputted from time input should already have the following format 'H:mm:ss'
    // we redo the conversion to make sure the seconds part is not omitted
    const timeAsDateTime = DateTime.fromFormat(value, Parameters.BACKEND_DEFAULT_TIME_FORMAT, { zone: 'utc' });
    return timeAsDateTime?.isValid ? timeAsDateTime.toFormat(Parameters.BACKEND_DEFAULT_TIME_FORMAT) : null;
  }

  public static getStringValue(formGroup: UntypedFormGroup, formControlName: string): string {
    return StringUtils.trim(this.getValue(formGroup, formControlName));
  }

  public static getValueAsDateTimeString(formGroup: UntypedFormGroup, formControlName: string, endDate: boolean): string {
    const value = this.getValue(formGroup, formControlName);
    if (value) {
      const dateTime = DateUtils.convertToDateTimeWithUTC(value);
      if (dateTime?.isValid) {
        const formattedDate = dateTime.set({ millisecond: 0 }).toISO();
        if (endDate) {
          return formattedDate.replace('.000Z', '.999Z');
        }
        return formattedDate;
      }
    }
    return null;
  }
}
