/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {FormControl} from '@angular/forms';
import {MFATypes} from '../datatypes/mfatypes.enum';
import {ConfirmationDialogComponent} from '../dialog/confirmation-dialog/confirmation-dialog.component';
import {Message} from '../datatypes/message';
import {AssociateTotpDialogComponent} from '../dialog/associate-totp/associate-totp-dialog.component';
import {SetPreferredMFARequest} from '../datatypes/set-preferred-mfarequest';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../services/http/user.service';
import {take} from 'rxjs/operators';
import {ConfirmTotpDialogComponent} from '../dialog/confirm-totp-dialog/confirm-totp-dialog.component';
import {MatRadioChange} from '@angular/material/radio';
import {UserDataSourceService} from '../services/user-data-source.service';
import {AbstractNotificationHandler} from 'pcs-commons/notification';

export abstract class MFARequestHandler extends AbstractNotificationHandler {
  public preferredMFA: FormControl;
  public mfaTypes = Object.values(MFATypes);

  protected constructor(
    public dialog: MatDialog,
    public user: UserDataSourceService,
    public userService: UserService) {
    super();
  }

  public associateTotpMFA(): void {
    const confirmDialog = this.dialog.open(ConfirmationDialogComponent, {
      maxWidth: 600,
      data: {
        message: new Message('mfa.associateTotp.confirm'),
        actionKey: 'global.button.yes'
      }
    });

    confirmDialog.afterClosed().pipe(take(1)).subscribe((positive) => {
      if (positive) {
        this.dialog.open(AssociateTotpDialogComponent, {
          width: '700px'
        });
      }
    });
  }

  public updatePreferredMFA(newValue: string): void {
    console.log(`requesting server to set preferred MFA to ${newValue}`);
    const req = new SetPreferredMFARequest();
    req.preferredMfaSetting = newValue;
    this.userService.setPreferredMFA(req).subscribe({
      next: () => {
        console.log('preferred MFA is set to: ', newValue);
        const mfaDetails = this.user.getMFADetails();
        mfaDetails.preferredMfaSetting = MFATypes[newValue];
        this.user.updateMFADetails(mfaDetails);
        const msg = MFATypes.SOFTWARE_TOKEN_MFA === mfaDetails.preferredMfaSetting ? 'mfa.associateTotp.setPreferred.success' : 'mfa.smsMFA.setPreferred.success';
        this.showInfo(new Message(msg));
      },
      error: () => this.revertToPreviousValue()
    });
  }

  private revertToPreviousValue(): void {
    const valueBefore = this.user.getMFADetails().preferredMfaSetting;
    console.log('reverting to previous value: ', valueBefore);
    this.preferredMFA.setValue(valueBefore);
  }

  private confirmAndSetTOTPAsPreferred(): void {
    const confirmDialog = this.dialog.open(ConfirmTotpDialogComponent, {
      width: '850px'
    });

    confirmDialog.afterClosed().pipe(take(1)).subscribe((positive) => {
      if (positive) {
        this.updatePreferredMFA(MFATypes.SOFTWARE_TOKEN_MFA);
      } else {
        this.revertToPreviousValue();
      }
    });
  }

  public handlePreferredMFASelectionChange($event: MatRadioChange): void {
    console.log('reacting to preferred MFA selection change: ', $event);
    const newValue = $event.value;
    if (MFATypes.SOFTWARE_TOKEN_MFA === newValue) {
      this.confirmAndSetTOTPAsPreferred();
    } else {
      this.updatePreferredMFA(newValue);
    }
  }
}
