/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {PurchasePriceComponentAttributes} from '../../datatypes/purchase-conditions/purchase-price-component';

@Component({
  selector: 'price-component-table',
  templateUrl: './price-component-table.component.html',
  styleUrls: [
    './price-component-table.component.css',
    '../class-prices-table/class-prices-table.component.css'
  ]
})
export class PriceComponentTableComponent extends OptimizedTableBaseComponent {
  PRICE_TYPE_COLUMN: string = PurchasePriceComponentAttributes.TYPE;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

}
