/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {TableBaseComponent} from '../table-base/table-base.component';
import {UserDataSourceService} from '../../services/user-data-source.service';
import {UserGroup} from '../../datatypes/user-group';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {arrayIncludesAny} from 'pcs-commons/utils';

@Component({
  selector: 'pcs-user-group-table',
  templateUrl: './user-group-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css'
  ]
})
export class UserGroupTableComponent extends TableBaseComponent implements PermissionAware {
  public readonly reqEditPermission: AccessRights[] = [AccessRights.USERMANAGEMENT_EDIT_WEB];

  constructor(
    renderer: Renderer2,
    private userDataSource: UserDataSourceService) {
    super(renderer);
  }

  public editNotAllowed(group: UserGroup): boolean {
    if (group.hasActiveChangeRequest) {
      return true;
    }
    //  if user is in the group, they should not be able to edit too
    const myGroup = !!group.users.find(user => user.login === this.userDataSource.currentUserLogin);
    return !arrayIncludesAny(this.userDataSource.currentUserRights, [this.reqEditPermission, AccessRights.ADMIN_WEB])
      || group.rights.includes(AccessRights.ADMIN_WEB) || myGroup;
  }

  public tooltip(group: UserGroup): string | undefined {
    if (group?.hasActiveChangeRequest) {
      return "usermanagement.groups.tooltip.blocked-by-change-request";
    }
    const myGroup = !!group.users.find(user => user.login === this.userDataSource.currentUserLogin);
    if (myGroup) {
      return "usermanagement.groups.tooltip.cannot_edit_own_group";
    }
    return undefined;
  }
}
