/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { FleetService } from '../../services/http/fleet.service';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { User } from '../../datatypes/user';
import { Role } from '../../datatypes/role.enum';
import { Utils } from '../../utils/utils';
import { ValidationMessagePipe, ValidatorList } from 'pcs-commons/validation';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action } from '../../datatypes/action.enum';
import { firstValueFrom, Observable } from 'rxjs';
import { WithLoadingSpinner } from 'pcs-commons/components';

@Component({
  selector: 'pcs-add-fleet-manager',
  templateUrl: './add-fleet-manager.component.html',
  styleUrls: ['./add-fleet-manager.component.css']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AddFleetManagerComponent extends AbstractNotificationHandler implements OnInit {
  public addManagerForm: UntypedFormGroup;
  public managerResponse: User[] = [];
  public hidePass = true;
  public loading = false;

  public login = new FormControl<string>('', ValidatorList.LOGIN);
  public phone = new FormControl<string>('', ValidatorList.FLEETMANAGER_PHONE_WITH_COUNTRY_CODE);
  public password = new FormControl<string>('', ValidatorList.PASSWORD);
  public fleetId: string;
  public mode: Action;

  constructor(
    public dialogRef: MatDialogRef<AddFleetManagerComponent>,
    private formBuilder: FormBuilder,
    private fleetService: FleetService,
    private validationPipe: ValidationMessagePipe,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.fleetId = data.fleetId;
    this.mode = data.mode;
    console.log('fleet id:' + this.fleetId);
    dialogRef.disableClose = true;
  }

  public get showUserCred(): boolean {
    return this.managerResponse.length > 0;
  }

  public ngOnInit(): void {
    this.addManagerForm = this.formBuilder.group({
      login: this.login,
      phone: this.phone,
      password: this.password
    });
  }

  public getError(fc: FormControl<string>): Observable<any> {
    return this.validationPipe.transform(fc);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public validatePhoneNumber(event: KeyboardEvent): void {
    Utils.validatePhoneNumber(event);
  }

  @WithLoadingSpinner()
  public async onAdd(): Promise<void> {
    console.log(this.addManagerForm.value.login);
    const manager = new User();
    manager.role = Role.FLEET_MANAGER;
    manager.login = this.addManagerForm.value.login;
    manager.password = this.addManagerForm.value.password;
    manager.phone = this.addManagerForm.value.phone;
    // if phone number contains any / or - , remove them
    manager.phone = manager.phone.replace(/[/-]/g, '');
    manager.userGroup = User.ADMIN_GROUP_NAME;
    if (this.mode === Action.CREATE) {
      this.dialogRef.close(manager);
    } else {
      debugger;
      const response = await firstValueFrom(this.fleetService.addFleetManager(this.fleetId, manager));
      this.managerResponse.push(response);
    }
  }

  public onOk(): void {
    /*send true as an indication to refresh*/
    this.dialogRef.close(true);
  }

  public allInputsValid(): boolean {
    return this.addManagerForm.valid;
  }
}
