/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/http/user.service';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {environment} from '../../../environments/environment';
import {UserDataSourceService} from '../../services/user-data-source.service';
import {FormControl} from '@angular/forms';
import {VerifySoftwareTokenRequest} from '../../datatypes/verify-software-token-request';
import {SetPreferredMFARequest} from '../../datatypes/set-preferred-mfarequest';
import {MFATypes} from '../../datatypes/mfatypes.enum';
import {Message} from '../../datatypes/message';
import {Utils} from '../../utils/utils';

@Component({
  selector: 'app-associate-totp',
  templateUrl: './associate-totp-dialog.component.html',
  styleUrls: [
    '../dialog-common-styles.css',
    './associate-totp-dialog.component.css']
})
export class AssociateTotpDialogComponent extends AbstractNotificationHandler implements OnInit {

  public associating = false;
  public processing = false;
  public secretCode: string;
  public secretCodeUri: string;
  public userCode: FormControl;
  public showSecretCode: boolean;
  public verified = false;

  constructor(
    private userDataSource: UserDataSourceService,
    private userService: UserService) {
    super();
  }

  public ngOnInit(): void {
    this.associateTotpMFA();
    this.userCode = new FormControl();
  }

  public associateTotpMFA(): void {
    this.associating = true;
    console.log('requesting to enable totp');
    this.userService.associateTotpMFA().subscribe({
      next: (result) => {
        console.log('totp association secret code: ', result);
        // show qrcode
        this.associating = false;
        this.secretCode = result.secretCode;
        this.secretCodeUri = this.buildSecretCodeUri(this.secretCode);
        console.log('secret code uri: ', this.secretCodeUri);
        this.userCode.reset(null);
      },
      error: () => this.associating = false
    });
  }

  public buildSecretCodeUri(code: string): string {
    const issuer = environment.appName;
    const accountName = this.userDataSource.currentUserLogin;
    const label = issuer + ':' + accountName;
    console.log('building secrect code uri with label: ', label);
    const uri = `otpauth://totp/${encodeURIComponent(label)}?secret=${code}`;
    console.log('secret code uri: ', uri);
    return uri;
  }

  public verifyUserCode(): void {
    this.processing = true;
    const request = new VerifySoftwareTokenRequest();
    request.userCode = this.userCode.value;
    this.userService.verifyTotpToken(request).subscribe({
      next: () => {
        this.processing = false;
        this.verified = true;
        console.log('verification successful');
        this.showInfo(new Message('mfa.associateTotp.verify.success'));
        if (Utils.arrayEmpty(this.userDataSource.getMFADetails().userMFASettingList)) {
          const mfaDetails = this.userDataSource.getMFADetails();
          mfaDetails.userMFASettingList = [MFATypes.SMS_MFA, MFATypes.SOFTWARE_TOKEN_MFA];
          this.userDataSource.updateMFADetails(mfaDetails);
        }
      },
      error: () => this.processing = false
    });
  }

  public setTotpAsPreferred(): void {
    this.processing = true;
    const req = new SetPreferredMFARequest();
    req.preferredMfaSetting = MFATypes.SOFTWARE_TOKEN_MFA;
    console.log(`requesting server to set preferred MFA to ${req.preferredMfaSetting}`);
    this.userService.setPreferredMFA(req).subscribe({
      next: () => {
        console.log('preferred MFA is set to: ', req.preferredMfaSetting);
        this.processing = false;
        this.showInfo(new Message('mfa.associateTotp.setPreferred.success'));
        const mfaDetails = this.userDataSource.getMFADetails();
        mfaDetails.preferredMfaSetting = MFATypes[req.preferredMfaSetting];
        this.userDataSource.updateMFADetails(mfaDetails);
      },
      error: () => this.processing = false
    });
  }
}
