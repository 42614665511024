/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Identifiable} from './identifiable';

export class FleetAddress extends Identifiable {
  public title: string;
  public salutation: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public buildingCode: string;
  public coName: string;
  public companyName1: string;
  public companyName2: string;
  public country: string;
  public district: string;
  public region: string;
  public city: string;
  public floor: string;
  public houseNumber: string;
  public poBox: string;
  public poBoxCity: string;
  public poBoxPostalCode: string;
  public postalCode: string;
  public postalCodeRegionValue: string;
  public roomNumber: string;
  public street1: string;
  public street2: string;
  public street3: string;
  public supplement: string;
  public vatId: string;
  // the following are exclusive for billing address
  public ciamId: string;
  public email: string;
  public invoiceLanguage: string;

}
