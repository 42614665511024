/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorList } from 'pcs-commons/validation';
import { Action } from '../../datatypes/action.enum';
import { Product } from '../../datatypes/product';
import { SalesChannel } from '../../datatypes/sales-channel.enum';
import { ProductStatus } from '../../datatypes/product-status.enum';

export abstract class ProductDetailFormManager extends AbstractNotificationHandler {
  public channelList: string[] = Object.values(SalesChannel);
  public priceConfigChanged = false;
  public selectedProductCode: string;
  public mode: Action;
  public currProductData: Product;
  public originalProductData: Product;
  public loading = false;

  public productInfoForm: FormGroup;
  public status: FormControl<ProductStatus>;
  public code: FormControl<string>;
  public serviceId: FormControl<string>;
  public name: FormControl<string>;
  public term: FormControl<number>;
  public channel: FormControl<string>;

  protected constructor(private formBuilder: FormBuilder) {
    super();
  }

  public defineFormControls(): void {
    console.log('defining form controls');
    this.status = new FormControl(ProductStatus.DRAFT);
    this.status.disable();
    this.code = new FormControl(undefined);
    this.code.disable();

    this.name = new FormControl('', ValidatorList.PRODUCT_NAME);
    this.term = new FormControl(undefined, ValidatorList.PRODUCT_TERM);
    this.channel = new FormControl('', Validators.required);
    this.serviceId = new FormControl('', Validators.maxLength(100));

    this.defineProductForm();
  }

  public defineProductForm(): void {
    console.log('defining product form');
    this.productInfoForm = this.formBuilder.group({
      status: this.status,
      code: this.code,
      name: this.name,
      term: this.term,
      channel: this.channel,
      serviceId: this.serviceId
    });
  }

  public updateFormValuesForProduct(): void {
    if (this.isReadOrEditMode() && this.currProductData) {
      this.status.setValue(this.currProductData.status);
      this.code.setValue(this.currProductData.code);
      this.name.setValue(this.currProductData.name);
      this.term.setValue(this.currProductData.term);
      this.channel.setValue(this.currProductData.channel);
      this.serviceId.setValue(this.currProductData.serviceId);
    }
    if (Action.READ === this.mode || (Action.EDIT === this.mode && ProductStatus.ACTIVE === this.currProductData.status)) {
      this.productInfoForm.disable();
    }
  }

  public isReadOrEditMode(): boolean {
    return Action.EDIT === this.mode || Action.READ === this.mode;
  }

  public isCreateOrEditMode(): boolean {
    return Action.EDIT === this.mode || Action.CREATE === this.mode;
  }

  public priceAndPlanEditAllowed(): boolean {
    return this.isCreateOrEditMode() && this.currProductData && ProductStatus.DORMANT !== this.currProductData.status;
  }

  public saveAllowed(): boolean {
    if (this.productInfoForm.invalid) {
      return false;
    }

    const plansHaveChanged = JSON.stringify(this.currProductData?.plans) != JSON.stringify(this.originalProductData?.plans);
    if (plansHaveChanged) {
      return true;
    }

    return (
      (this.priceConfigChanged && (this.productInfoForm.disabled || this.productInfoForm.pristine)) ||
      !this.productInfoForm.pristine
    );
  }
}
