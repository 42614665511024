<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <div class="expansion-panel-header">
        <span class="headerSpan headerToolbarSub">{{'customerinfo.filter.header' | translate}}</span>
      </div>
    </mat-expansion-panel-header>
    <form class="ci-filter-form" [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
      <div class="multi-column-div">
        <mat-form-field class="filter-form-field">
          <mat-label>{{'customerinfo.ciamId' | translate}}</mat-label>
          <input [maxlength]="20" formControlName="ciamId" matInput>
        </mat-form-field>

        <mat-form-field class="filter-form-field">
          <mat-label>{{'customerinfo.vin' | translate}}</mat-label>
          <input [maxlength]="17" formControlName="vin" matInput>
        </mat-form-field>

        <mat-form-field class="filter-form-field">
          <mat-label>{{'customerinfo.contractId' | translate}}</mat-label>
          <input [maxlength]="30" formControlName="contractId" matInput>
        </mat-form-field>

        <mat-form-field class="filter-form-field">
          <mat-label>{{'customerinfo.evcoId' | translate}}</mat-label>
          <input [maxlength]="30" formControlName="evcoId" matInput>
        </mat-form-field>
      </div>
    </form>
    <mat-action-row>
      <button okAction class="normalButton" mat-stroked-button (click)="triggerFetchCustomerinfo()"
              [disabled]="noFieldIsSet()">
        <span class="filter-button-span">{{ "customerinfo.button.filter" | translate }}</span>
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
