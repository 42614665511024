/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {UserGroupService} from '../../services/http/user-group.service';
import {USER_GROUP_DATA_TABLE_COLUMNS, UserGroup} from '../../datatypes/user-group';
import {firstValueFrom, Observable} from 'rxjs';
import {SharedDataService} from '../../services/shared-data.service';
import {Action, Message} from 'pcs-commons/datatypes';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {Utils} from '../../utils/utils';
import {MatDialog} from '@angular/material/dialog';
import {UserDataSourceService} from '../../services/user-data-source.service';
import {BaseUrls} from '../../datatypes/base-urls.enum';
import {WithLoadingSpinner} from 'pcs-commons/components';
import {AuditService} from "../../services/audit.service";
import {ChangeRequestAction} from "../../datatypes/audit-flow/change-request-action";
import {ChangeRequestPayloadType} from "../../datatypes/inbox/change-request-payload-type";
import {ChangeRequestService} from "../../services/http/audit/change-request.service";


@Component({
  selector: 'pcs-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.css']
})
export class UserGroupsComponent extends AbstractNotificationHandler implements OnInit, PermissionAware {
  public userGroupList$!: Observable<UserGroup[]>;

  constructor(
    private router: WebConfigRouterService,
    private userGroupService: UserGroupService,
    private userDataSource: UserDataSourceService,
    private dataService: SharedDataService,
    private auditService: AuditService,
    private changeRequestService: ChangeRequestService,
    public dialog: MatDialog
  ) {
    super();
    this.userGroupList$ = this.dataService.currentUserGroups$;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadUserGroups();
  }

  @WithLoadingSpinner()
  public async loadUserGroups(): Promise<void> {
    const groups = await firstValueFrom(this.userGroupService.getAllUserGroups());
    this.sortGroups(groups);
    this.dataService.updateUserGroups(groups);
    await this.checkForChangeRequests(groups);

    console.log('finished loading groups');
  }

  private async checkForChangeRequests(groups: UserGroup[]): Promise<void> {
    const userGroupsWithActiveChangeRequests = await firstValueFrom(this.changeRequestService.findAllActiveChangeRequestsByPayloadType(ChangeRequestPayloadType.USER_GROUP));
    groups.forEach(g => g.hasActiveChangeRequest = userGroupsWithActiveChangeRequests.includes(g.id));
  }

  public async onCreateButtonClick(): Promise<void> {
    await this.router.navigate([BaseUrls.USER_GROUPS], {queryParams: {mode: Action.CREATE}});
  }

  public get userGroupTableColumns(): Array<string> {
    return USER_GROUP_DATA_TABLE_COLUMNS;
  }

  public async onEditGroup(groupToEdit: UserGroup): Promise<void> {
    console.log('got edit request of group: ', groupToEdit);
    this.dataService.updateCurrentUserGroupOnEdit(groupToEdit);
    await this.router.navigate([BaseUrls.USER_GROUPS, groupToEdit.name], {queryParams: {mode: Action.EDIT}});
  }

  public async onGroupDelete(groupToDelete: UserGroup): Promise<void> {
    if (groupToDelete.users?.length === 0) {
      await this.doGroupDelete(groupToDelete);
    } else {
      this.showError(new Message('usermanagement.groups.delete.not-empty'));
    }
  }

  @WithLoadingSpinner()
  public async doGroupDelete(groupToDelete: UserGroup): Promise<void> {
    console.log('got delete request of group: ', groupToDelete);
    delete groupToDelete.hasActiveChangeRequest; // we do not want this field to appear in the change request
    if (await this.auditService.initiateChangeRequestForUserGroup(
      ChangeRequestAction.DELETE,
      groupToDelete,
      undefined)) {
      // re-fetch data
      await this.loadUserGroups();
    }
  }

  public get reqEditPermission(): Array<AccessRights> {
    return [AccessRights.USERMANAGEMENT_EDIT_WEB];
  }

  public async onReadGroupDetails(groupToRead: UserGroup): Promise<void> {
    console.log('got read request of group: ', groupToRead);
    this.dataService.updateCurrentUserGroupOnEdit(groupToRead);
    await this.router.navigate([BaseUrls.USER_GROUPS, groupToRead.name], {queryParams: {mode: Action.READ}});
  }

  private sortGroups(groups: UserGroup[]): void {
    // 1. find admin group
    const adminGroup = groups.find(grp => grp.rights.includes(AccessRights.ADMIN_WEB));
    // 2. find current user's group
    const currUser = this.userDataSource.getUserAuthDetails().login;
    const currUsersGroup = groups.find(grp => grp.users.find(user => user.login === currUser));
    console.log('admin group: ', adminGroup, '\n user: ', currUser, '\n group : ', currUsersGroup);
    Utils.sortArrayByStringProperty(groups, 'name');
    if (currUsersGroup.name !== adminGroup.name) {
      Utils.moveToFirst(currUsersGroup, groups);
    }
    Utils.moveToFirst(adminGroup, groups);
  }
}
