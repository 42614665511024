/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from "@angular/core";
import {BehaviorSubject, Subscription} from "rxjs";
import {InvoiceService, TranslationHelperService} from "pcs-commons/http";
import FileSaver from "file-saver";
import {DateUtils} from "pcs-commons/utils";
import {Invoice, InvoiceOverview, InvoiceOverviewColumns} from "pcs-commons/datatypes";
import {StatisticDownloaderService} from "pcs-commons/statistics";


@Component({
  selector: 'pcs-fleet-invoices',
  templateUrl: './fleet-invoices.component.html',
  styleUrls: ['./fleet-invoices.component.css']
})
export class FleetInvoicesComponent implements OnInit, OnDestroy, OnChanges {

  private _currentInvoices: Invoice[] = [];
  private invoiceOverviewsSource = new BehaviorSubject<InvoiceOverview[]>([]);
  public invoices$ = this.invoiceOverviewsSource.asObservable();
  public invoicesColumns: string[] = Object.values(InvoiceOverviewColumns);

  @Input() public downloadButtons: boolean;

  @Input() public fleetId: string;
  @Input() public i18nBase: string;
  public loading = false;

  public toUnsub: Subscription[] = [];

  constructor(
    private invoiceService: InvoiceService,
    private statisticsDownloaderService: StatisticDownloaderService,
    private translateHelperService: TranslationHelperService
  ) {
  }

  public ngOnDestroy(): void {
    this.toUnsub.forEach(sub => sub.unsubscribe());
  }

  public ngOnInit(): void {
    this.toUnsub.push(this.translateHelperService.onLangChange.subscribe(() =>
      this.invoiceOverviewsSource.next(this.currentInvoices.map((value) => this.toInvoiceOverview(value)))
    ));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.fleetId && changes.fleetId.currentValue) {
      this.fetchInvoicesOfFleet();
    }
  }

  public fetchInvoicesOfFleet(): void {
    if (!this.fleetId) {
      return;
    }
    this.loading = true;
    console.log('fetching invoices for fleet: ', this.fleetId);
    this.invoiceService.getInvoices(this.fleetId).subscribe((invoices) => {
      this._currentInvoices = invoices;
      this.invoiceOverviewsSource.next(this.currentInvoices.map((value) => this.toInvoiceOverview(value)));
      this.loading = false;
    });
  }

  public onDownloadInvoice(invoice: InvoiceOverview): void {
    this.loading = true;
    console.log('downloading invoice: ', invoice);
    this.invoiceService.downloadInvoice(this.fleetId, invoice.id).subscribe({
      next: (data) => {
        const blob = new Blob([data], {type: 'application/pdf'});
        FileSaver.saveAs(blob, 'invoice_' + invoice.invoiceNumber + '.pdf');
        this.loading = false;
      },
      error: () => this.loading = false
    });
  }

  public onDownloadBilledItemsFromFE(invoice: InvoiceOverview): void {
    this.loading = true;
    console.log('downloading billed items from finance enabler for invoice: ', invoice);
    this.invoiceService.downloadBilledItemsFinanceEnabler(this.fleetId, invoice.id).subscribe({
      next: (data) => {
        const blob = new Blob([data], {type: 'text/csv'});
        FileSaver.saveAs(blob, 'billed_items_finance_enabler_invoice_' + invoice.invoiceNumber + '.csv');
        this.loading = false;
      },
      error: () => this.loading = false
    });
  }

  public async onDownloadBilledItemsFromPCS(invoice: InvoiceOverview): Promise<void> {
    console.log('downloading billed items from PCS for invoice: ', invoice);

    try {
      this.loading = true;
      await this.statisticsDownloaderService.getBilledCDRStatistic(this.fleetId, invoice, 2000);
    } finally {
      this.loading = false;
    }
  }

  private toInvoiceOverview(invoice: Invoice): InvoiceOverview {
    const overview = new InvoiceOverview();
    overview.id = invoice.id;
    overview.syncStatus = invoice.syncStatus;
    overview.invoiceNumber = invoice.invoiceIdent ? invoice.invoiceIdent : '';
    overview.invoiceAmount = (invoice.invoiceAmount ? invoice.invoiceAmount : '') + (invoice.currency ? (' ' + invoice.currency) : '');
    overview.invoiceDateTime = DateUtils.parseToDateTime(invoice.invoiceDateTime);
    overview.country = invoice.country;
    if (!overview.invoiceDateTime?.isValid) {
      overview.invoiceDateTime = null;
    }
    const monthYear = this.translateHelperService.translateMonthNameYearFromDateTimeSafe(invoice.invoiceDateTime);
    overview.invoiceName = (invoice.country ? (this.translateHelperService.getCountryTranslation(invoice.country) + ' ') : '')
      + (monthYear ? monthYear : '');
    return overview;
  }

  public get currentInvoices(): Invoice[] {
    return this._currentInvoices;
  }
}
