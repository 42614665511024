/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PurchaseConditionElement, PurchaseConditionElementAttributes} from '../../datatypes/purchase-conditions/purchase-condition-element';
import {PcElementFormManager} from './edit-pc-element-form-manager.abstract';
import {FormBuilder} from '@angular/forms';
import {PurchasePriceComponent, PurchasePriceComponentAttributes} from '../../datatypes/purchase-conditions/purchase-price-component';
import {Observable, of} from 'rxjs';
import {PurchasePriceListValidatorService} from '../../validation/purchase-price-list-validator.service';
import {Utils} from '../../utils/utils';
import {FormUtils} from 'pcs-commons/utils';
import {PurchasePriceComponentType} from '../../datatypes/purchase-conditions/purchase-price-component-type.enum';

@Component({
  selector: 'edit-pc-element',
  templateUrl: './edit-pc-element.component.html',
  styleUrls: [
    './edit-pc-element.component.css',
    '../../dialog/dialog-common-styles.css'
  ]
})
export class EditPcElementComponent extends PcElementFormManager implements OnInit {

  public readonly purchasePriceComponentColumns: string[] = Object.values(PurchasePriceComponentAttributes);

  constructor(
    private dialogRef: MatDialogRef<EditPcElementComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    formBuilder: FormBuilder,
    purchasePriceListValidator: PurchasePriceListValidatorService
  ) {
    super(data, purchasePriceListValidator, formBuilder);
  }

  public ngOnInit(): void {
    this.setupFromData();
  }

  public onSave(): void {
    const updatedElement = new PurchaseConditionElement();
    updatedElement.id = this.currElement.id;
    updatedElement.tElemId = this.currElement.tElemId;

    updatedElement.maxDuration = FormUtils.getNullableNumber(this.durationDataForm, PurchaseConditionElementAttributes.MAX_DURATION);
    updatedElement.minDuration = FormUtils.getNullableNumber(this.durationDataForm, PurchaseConditionElementAttributes.MIN_DURATION);

    updatedElement.daysOfWeek = this.daysOfWeek.value;
    updatedElement.powerClasses = this.powerClasses.value;

    updatedElement.maxKwhConsumption =
      FormUtils.getNullableNumber(this.consumptionDataForm, PurchaseConditionElementAttributes.MAX_KWH_CONSUMPTION);
    updatedElement.minKwhConsumption =
      FormUtils.getNullableNumber(this.consumptionDataForm, PurchaseConditionElementAttributes.MIN_KWH_CONSUMPTION);

    updatedElement.startTime = FormUtils.getValueAsBackendTime(this.timeDataForm, PurchaseConditionElementAttributes.START_TIME);
    updatedElement.endTime = FormUtils.getValueAsBackendTime(this.timeDataForm, PurchaseConditionElementAttributes.END_TIME);
    updatedElement.startDate = FormUtils.getValueAsBackendDate(this.timeDataForm, PurchaseConditionElementAttributes.START_DATE);
    updatedElement.endDate = FormUtils.getValueAsBackendDate(this.timeDataForm, PurchaseConditionElementAttributes.END_DATE);

    this.fillPrices(updatedElement);
    console.log('updated purchase condition element: ', updatedElement);

    this.dialogRef.close(updatedElement);
  }

  public onCancel(): void {
    this.dialogRef.close(undefined);
  }

  public observableOfPricesTypes(): Observable<string[]> {
    return of(this.priceTypes);
  }

  private fillPrices(updatedElement: PurchaseConditionElement): void {
    const priceList: PurchasePriceComponent[] = [];
    this.priceTypes.forEach((priceType) => {
      const requiresStepSize = PurchasePriceComponentType.FLAT !== priceType;
      const fcPriceName = Utils.purchasePriceFormControlName(priceType, 'price');
      const price = FormUtils.getNullableNumber(this.pricesForm, fcPriceName);
      let stepSize = null;
      if (requiresStepSize) {
        const fcStepSizeName = Utils.purchasePriceFormControlName(priceType, 'stepSize');
        stepSize = FormUtils.getNullableNumber(this.pricesForm, fcStepSizeName);
      }
      if (Utils.objectNotEmpty(price) && (requiresStepSize && stepSize || !requiresStepSize)) {
        const priceComponent = new PurchasePriceComponent();
        const existingPrice = this.currElement.prices.find((pr) => pr.type === priceType);
        priceComponent.id = existingPrice ? existingPrice.id : null;
        priceComponent.type = priceType;
        priceComponent.price = price;
        priceComponent.stepSize = stepSize;
        priceList.push(priceComponent);
      }
    });
    updatedElement.prices = priceList;
  }
}
