<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell [styledColumn]="col" *matCellDef="let element">
          <ng-container *ngIf="!isBoolean(col)">{{getText(col, element[col]) | async}}</ng-container>
          <ng-container *ngIf="isBoolean(col)">
            <mat-icon *ngIf="element[col]===true" matTooltip="{{'global.label.product.changerequest.tooltip' | translate}}">loop</mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>
    </div>
    <ng-container matColumnDef="tableAction">
      <mat-header-cell *matHeaderCellDef class="actionCell"> {{'fleet.tableheader.action' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="actionCell">
        <div *ngIf="isEditable(element)">
          <button mat-mini-fab class="edit mat-elevation-z0"
                  (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)"
                  (click)="editElement(element)"
                  *ngIf="!disableEdit">
            <mat-icon> edit</mat-icon>
          </button>
        </div>
        <div *ngIf="!isEditable(element)">
          <button mat-mini-fab class="info mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="showDetails(element)">
            <svg-icon src="/assets/images/ic_detail2.svg"></svg-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
