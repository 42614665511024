/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import {Component, OnChanges} from '@angular/core';
import {ChangeRequestAction} from "../../datatypes/audit-flow/change-request-action";
import {ChangeRequestSharedDataService} from "../../services/change-request-shared-data.service";
import {Operator} from "../../datatypes/operator";
import {ChangeRequestInfoManagerAbstract} from "../change-request-info-manager.abstract";

@Component({
  selector: 'pcs-change-request-info-cpo',
  templateUrl: './change-request-info-cpo.component.html',
  styleUrls: ['./change-request-info-cpo.component.css']
})
export class ChangeRequestInfoCpoComponent extends ChangeRequestInfoManagerAbstract implements OnChanges {
  public cpo: Operator;
  public actionText = "";
  public currentStatusText = "";
  public newStatusText = "";

  constructor(private changeRequestSharedDataService: ChangeRequestSharedDataService) {
    super();
    changeRequestSharedDataService.getCurrentCpoObservable().subscribe(cpo => this.cpo = cpo);
  }

  public ngOnChanges(/*changes: SimpleChanges*/): void {
    const action = this.changeRequest?.action?.toLowerCase();
    this.actionText = `audit.cpo.${action}.message`;
    if (this.changeRequest?.action === ChangeRequestAction.SET_PREFERRED_ATTRIBUTE) {
      const original = JSON.parse(this.changeRequest.originalValue);
      this.currentStatusText = original.preferred === true ? 'changeRequest.info.preferred.active' : 'changeRequest.info.preferred.inactive';
      const updated = JSON.parse(this.changeRequest.updatedValue);
      this.newStatusText = updated.preferred === true ? 'changeRequest.info.preferred.activate' : 'changeRequest.info.preferred.deactivate';
    } else {
      const currentStatus = this.cpo?.active ? 'active' : 'inactive';
      this.currentStatusText = `changeRequest.info.currentStatus.${currentStatus}`;
      this.newStatusText = this.changeRequest?.action === ChangeRequestAction.ACTIVATE ? 'changeRequest.info.newStatus.activate' : 'changeRequest.info.newStatus.deactivate';
    }
  }
}
