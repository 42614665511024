/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {CountryPriceRecord, CountryPriceRecordColumns} from '../../country-price-record';
import {Observable, of} from 'rxjs';
import {ChargepointClassPrice, ChargepointClassPriceColumn} from '../../chargepoint-class-price';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'country-prices-table',
  templateUrl: './country-prices-table.component.html',
  styleUrls: [
    '../price-table-styles.css',
    '../data-table.component.css',
    './country-prices-table.component.css']
})
export class CountryPricesTableComponent extends OptimizedTableBaseComponent {
  public readonly PRICE_DATA_COLUMN = CountryPriceRecordColumns.PRICE_DTOS;
  public readonly COUNTRY_COLUMN = CountryPriceRecordColumns.COUNTRY;
  public readonly VALID_UNTIL = CountryPriceRecordColumns.VALID_UNTIL;
  public readonly VALID_FROM = CountryPriceRecordColumns.VALID_FROM;
  public readonly columnsRequiredTranslation: string[] = [this.COUNTRY_COLUMN, this.VALID_FROM, this.VALID_UNTIL];
  public readonly classPriceTableColumns: string[] = Object.values(ChargepointClassPriceColumn);

  constructor(renderer: Renderer2, private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public observableOfClassData(element: CountryPriceRecord): Observable<ChargepointClassPrice[]> {
    return of(element.priceDtos);
  }

  public hasClassPrices(element: CountryPriceRecord): boolean {
    return element.priceDtos && element.priceDtos.length > 0;
  }

  public requiresTranslation(col: string): boolean {
    return this.columnsRequiredTranslation.includes(col);
  }

  public getDateTimeTranslation(value: string): string {
    return this.translateHelper.translateDateTimeUTCSafe(value);
  }
}
