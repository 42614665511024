/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CodeModel} from "@ngstack/code-editor";

@Component({
  selector: 'pcs-chargepoint-raw-data-dialog',
  templateUrl: './chargepoint-raw-data-dialog.component.html',
  styleUrls: ['../../../dialog/dialog-common-styles.css', './chargepoint-raw-data-dialog.component.css']
})
export class ChargepointRawDataDialogComponent {
  public evseId = '';
  public platform = '';
  private editorCodeModelUri = 'chargepoint-raw-data.ts';
  private editorCodeModelLanguage = 'json';
  public codeModel: CodeModel = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.codeModel = {
      language: this.editorCodeModelLanguage,
      uri: this.editorCodeModelUri,
      value: JSON.stringify(data.rawData, null, '\t')
    }
    this.evseId = data.evseId;
    this.platform = data.platform;
  }
}
