import {Component, Renderer2} from '@angular/core';
import {TableBaseComponent} from "../table-base/table-base.component";

@Component({
  selector: 'pcs-plan-table',
  templateUrl: './plan-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css', './plan-table.component.css']
})
export class PlanTableComponent extends TableBaseComponent {
  constructor(renderer: Renderer2) {
    super(renderer);
  }
}
