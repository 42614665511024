/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {CDRClearingColumns} from '../datatypes/cdrclearing';
import {TranslateService} from '@ngx-translate/core';
import {VinCheckResultColumns} from '../datatypes/vin-check-result';
import {ChangeRequestsHistoryColumns} from "../datatypes/inbox/change-request-overview-dto";

@Pipe({
  name: 'translateColumn'
})
export class TranslateColumnPipe implements PipeTransform {
  private defaultTranslationKeys: Map<string, string> = new Map([
    [CDRClearingColumns.CLEARABLE.toString(), 'global.label.'],
    [VinCheckResultColumns.HAS_ACTIVE_CONTRACT.toString(), 'global.label.'],
    [ChangeRequestsHistoryColumns.STATUS.toString(), 'changeRequest.status.']
  ]);

  constructor(
    private translateService: TranslateService
  ) {
  }

  public transform(value: string, col: string, update: Date, translationKeyMap?: Map<string, string>): string {
    let key = null;
    if (translationKeyMap) {
      key = translationKeyMap.get(col);
    }

    if (!key) {
      key = this.defaultTranslationKeys.get(col);
    }
    return key ? this.translateService.instant(key + value) : value;
  }

}
