/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component} from '@angular/core';
import {ImportFormBaseComponent} from './import-form-base.component';

/**
 * File Upload form holding text field for file name,
 * plus button showing file selection dialog and import button starting import action
 */
@Component({
  selector: 'import-form',
  templateUrl: './import-form.component.html',
  styleUrls: ['./import-form.component.css']
})
export class ImportFormComponent extends ImportFormBaseComponent {
}
