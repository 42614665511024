/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {StringIdentifiable} from '../../datatypes/string-identifiable';
import {IopPlatformDto} from '../../datatypes/iop-platform-dto';
import {DragDropEventData} from '../../datatypes/drag-drop-event-data';

@Component({
  selector: 'custom-drag-drop-list',
  templateUrl: './drag-drop-list-base.component.html',
  styleUrls: ['./drag-drop-list-base.component.css']
})
export class DragDropListBaseComponent {

  @Input() elementList: StringIdentifiable[];
  @Output() itemMoveEvent = new EventEmitter<DragDropEventData>();
  @Input() hasWriteAccess: boolean;

  constructor() {
  }

  drop(event: CdkDragDrop<StringIdentifiable[]>) {
    const prevIndex = event.previousIndex;
    const currIndex = event.currentIndex;
    moveItemInArray(this.elementList, prevIndex, currIndex);
    if (prevIndex !== currIndex) {
      const eventData = new DragDropEventData(this.elementList[currIndex], prevIndex, currIndex);
      console.log('emitting event data: ', eventData);
      this.itemMoveEvent.emit(eventData);
    }
  }

  getValue(element: StringIdentifiable): string {
    return element.toIdentifiableString();
  }

}
