/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { CountryCode, Identifiable } from 'pcs-commons/datatypes';
import { OcpiPartnerStatus } from './ocpi-partner-status.enum';
import { IopPlatformDto } from './iop-platform-dto';

export type UpdateLocationDataStatus = undefined | 'RUNNING' | 'COMPLETED' | 'ERROR' | 'STALE';

export enum OcpiPartnerTableColumns {
  PARTY_ID = 'partyId',
  NAME = 'name',
  COUNTRY = 'country',
  STATUS = 'status',
  PLATFORM = 'platform',
  CERT_CN = 'certCn',
  AUTH_CHECK_LOCATION = 'authCheckLocation',
  PULL_STATIC_DATA_OPTION = 'pullStaticDataOption',
  REQUEST_AUTHORIZATION_ID = 'requestAuthorizationId',
  ALLOW_OFFLINE_AUTH = 'allowOfflineAuthentication',
  LAST_FULL_TOKEN_SYNC = 'lastFullTokenSync',
  TOKEN_SYNC_STATUS = 'tokenSyncStatus',
  UPDATE_LOCATION_DATA_STATUS = 'updateLocationDataStatus'
}

export class OcpiPartner extends Identifiable {
  public static readonly dataColumns: string[] = [
    OcpiPartnerTableColumns.PARTY_ID,
    OcpiPartnerTableColumns.NAME,
    OcpiPartnerTableColumns.COUNTRY,
    OcpiPartnerTableColumns.STATUS,
    OcpiPartnerTableColumns.PLATFORM,
    OcpiPartnerTableColumns.CERT_CN,
    OcpiPartnerTableColumns.PULL_STATIC_DATA_OPTION,
    OcpiPartnerTableColumns.AUTH_CHECK_LOCATION,
    OcpiPartnerTableColumns.REQUEST_AUTHORIZATION_ID,
    OcpiPartnerTableColumns.ALLOW_OFFLINE_AUTH,
    OcpiPartnerTableColumns.LAST_FULL_TOKEN_SYNC,
    OcpiPartnerTableColumns.TOKEN_SYNC_STATUS,
    OcpiPartnerTableColumns.UPDATE_LOCATION_DATA_STATUS
  ];
  public status: OcpiPartnerStatus;
  public name: string;
  public partyId: string;
  public country: CountryCode;
  public platform: IopPlatformDto;
  public authCheckLocation: boolean;
  public pullStaticDataOption: string;
  public requestAuthorizationId: boolean;
  public allowOfflineAuthentication: boolean;
  public certCn: boolean;
  public lastFullTokenSync: string | null;
  public tokenSyncStatus: null | 'REQUESTED' | 'PENDING' | 'COMPLETED';
  public updateLocationDataStatus: UpdateLocationDataStatus;
}
