<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="div-container">
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns">
        <ng-container *ngIf="belongsToDefaultNgContainer(col)" matColumnDef="{{col}}">
          <mat-header-cell [ngClass]="getColumnClass(col)"
                           *matHeaderCellDef>{{ getI18nBase(col) | translate }}
          </mat-header-cell>
          <mat-cell [ngClass]="getColumnClass(col)"
                    *matCellDef="let element"> {{ getColumnValue(col, element) }}
          </mat-cell>
        </ng-container>
      </div>

      <ng-container matColumnDef="{{visibilityColumn}}">
        <mat-header-cell *matHeaderCellDef
                         class="visibility-column">{{ getI18nBase(visibilityColumn) | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element"
                  class="visibility-column">
          <div *ngIf="getColumnValue(visibilityColumn, element); let columnValue">
           <span>
              <i [ngClass]="determineVisibilityIconClass(columnValue)" aria-hidden="true"></i>
            </span>
            {{ 'chargepoint.visibility.' + columnValue | translate }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{manualDataColumn}}">
        <mat-header-cell *matHeaderCellDef
                         class="actionCell center manual-data-column">{{ getI18nBase(manualDataColumn) | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell center manual-data-column">
          <div>
            <i *ngIf="element.manualChanges && !element.receivedUpdateFromIop"
               title="{{ 'chargepoint.table.manualChanges.patched.title' | translate }}"
               class="fa fa-star-half-full material-icons md-18"></i>
            <i *ngIf="element.manualChanges && element.receivedUpdateFromIop"
               title="{{ 'chargepoint.table.manualChanges.update-available.title' | translate }}"
               class="fa fa-refresh material-icons md-18" aria-hidden="true"></i>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="tableAction" stickyEnd="true">
        <mat-header-cell *matHeaderCellDef
                         class="actionCell center details-column"> {{ 'chargepoint.tableHeader.details' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell center details-column">
          <button mat-mini-fab class="edit tableActionBtn" (click)="editElement(element)" *ngIf="!disableEdit">
            <svg-icon src="/assets/images/ic_detail2.svg"></svg-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>
