<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data">
  <mat-table #table [dataSource]="tableDataSource">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col" matColumnDef="{{col}}">
        <mat-header-cell *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element ">
          <div *ngIf="col===PRICE_TYPE_COLUMN">{{'global.label.' + element[col] | translate}}</div>
          <div *ngIf="col!==PRICE_TYPE_COLUMN">{{element[col]}}</div>
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
