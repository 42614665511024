/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export class DragDropEventData {
  constructor(
    private item: any,
    private previousIndex: number,
    private currentIndex: number) {
  }

  get _item() {
    return this.item;
  }

  get _previousIndex() {
    return this.previousIndex;
  }

  get _currentIndex() {
    return this.currentIndex;
  }
}
