/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {SharedDataService} from '../../services/shared-data.service';
import {VinCheckResult, VinCheckResultColumns} from '../../datatypes/vin-check-result';
import {Location} from '@angular/common';
import {take} from 'rxjs/operators';
import {ExcelService} from 'pcs-commons/statistics';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'pcs-vin-check-result',
  templateUrl: './vin-check-result.component.html',
  styleUrls: [
    '../../dialog/dialog-common-styles.css',
    './vin-check-result.component.css'
  ]
})
export class VinCheckResultComponent implements OnInit {

  public resultList$: Observable<VinCheckResult[]>;
  public resultTableColumns: string[] = Object.values(VinCheckResultColumns);
  public baseFileName = 'vin_check';

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private excelService: ExcelService
  ) {
    this.resultList$ = this.sharedDataService.vinCheckResult$;
  }

  public ngOnInit(): void {
    const fileParam = 'file';
    const nameWithExtension = this.route.snapshot.queryParamMap.get(fileParam);
    if (nameWithExtension) {
      this.baseFileName = nameWithExtension.includes('.') ?
        nameWithExtension.substring(0, nameWithExtension.lastIndexOf('.'))
        : nameWithExtension;
    }
  }

  public goBack(): void {
    this.location.back();
  }

  public onDownloadResult(): void {
    this.resultList$.pipe(take(1)).subscribe((results) => {
      console.log('Downloading vin check result. Result amount: ', results.length);
      this.excelService.exportAsExcelFile(
        this.toExcelRows(results),
        this.baseFileName + '_result_' + new Date().toISOString().split('T')[0],
        'result',
        VinCheckResult.excelHeaders.map((column) => column) // make a copy since the xlsx lib for some reason can change this array
      );
    });
  }

  public toExcelRows(results: VinCheckResult[]): any[] {
    return results
      .map((result) => {
        return {
          vin: result[VinCheckResultColumns.VIN],
          hasActiveContract: result[VinCheckResultColumns.HAS_ACTIVE_CONTRACT]
        };
      });
  }
}
