<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div>
  <div class="mat-elevation-z1">
    <mat-toolbar class="headerToolbar">
      <span class="headerSpan">{{'electricVehicle.check.header' | translate }}</span>
      <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="checkingVins"></mat-spinner>
    </mat-toolbar>
    <import-form (importEvent)="onVinListFileUpload($event)"></import-form>
  </div>
  <div class="section-container">
    <mat-toolbar class="headerToolbar mat-elevation-z1">
      <span class="headerSpan">{{'electricVehicle.header' | translate }}</span>
    </mat-toolbar>
    <div class="vehicle-filter">
      <electric-vehicle-filter [loading]="loading" (filterEvent)="applyFilter($event)"></electric-vehicle-filter>
    </div>
  </div>

  <div class="section-container" *ngIf="resultLength > 0" id="electricVehicles">
    <mat-accordion>
      <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
        <electric-vehicle-table
          [dataColumns]="evColumns"
          [i18nBase]="'electricVehicle.tableHeader'"
          [data]="evSearchResult$"
          [actionButtons]="reqEditPermission |accessAllowed"
          (editEvent)="onUpdatePcid($event)"
          (revokeCertEvent)="onRevokePnCCert($event)">
        </electric-vehicle-table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div>

</div>
