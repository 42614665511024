/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { LocationAdminDto } from '../../datatypes/location/location-admin-dto';
import { Utils } from '../../utils/utils';
import { AttributeTableAction } from '../../datatypes/attribute-table-action.enum';
import { AttributeRow } from '../../data-table/attribute-table/attribute-row';
import { Parameters } from '../../global/parameters';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { LocationDtoAttributeName } from '../../datatypes/location/location-dto-attribute-name.enum';
import { TableConfigurations } from '../../data-table/table-configurations';
import { LocationAndChargepointAdminAttributeDto } from '../../datatypes/location/location-and-chargepoint-admin-attribute-dto';
import { FormHelper } from '../../utils/form-helper';
import { LocationAdminAttributeDto } from '../../datatypes/location/location-admin-attribute-dto';
import { AttributeTableBaseComponent } from '../../data-table/attribute-table/attribute-table-base.component';
import { TranslationHelperService } from 'pcs-commons/http';
import { InputValidationService } from '../../services/input-validation.service';
import { AttributeTableEventService } from '../../services/attribute-table-event.service';
import { PermissionAware } from '../../permission-aware';
import { AccessRights } from '../../datatypes/access-rights.enum';
import { CountryUtil } from '../../utils/country-util';
import { AttributeTranslationHelperService } from '../../services/attribute-translation-helper.service';
import { ChargepointUpdateData } from '../../datatypes/location/chargepoint/chargepoint-update-data';
import { GeoPositionStatus } from '../../datatypes/location/geo-position-status';

@Component({
  selector: 'pcs-location-attributes',
  templateUrl: './location-attributes.component.html',
  styleUrls: ['./location-attributes.component.css']
})
export class LocationAttributesComponent extends AttributeTableBaseComponent implements OnChanges, PermissionAware {
  // read only
  public readonly reqEditPermission = [AccessRights.CHARGEPOINTS_EDIT_WEB];
  public readonly attributeNameColumn = AttributeRow.ATTRIBUTE_NAME;
  public readonly manualDataColumn = Parameters.MANUAL_DATA_PLATFORM;
  protected readonly countryCode = LocationDtoAttributeName.COUNTRY_CODE;
  protected readonly geoPositionStatus = LocationDtoAttributeName.GEO_POSITION_STATUS;
  protected readonly geoPositionStatusChoices: string[];

  @Input() public location: LocationAdminDto;
  @Input() public locationGroupId: string;

  public dataColumns: string[] = [];
  public i18nBase: string;
  public currentCommand: AttributeTableAction = undefined;
  public manualDataFromGroup: FormGroup;

  constructor(
    renderer: Renderer2,
    tableEventSource: AttributeTableEventService,
    attrTranslationService: AttributeTranslationHelperService,
    translateHelper: TranslationHelperService,
    formBuilder: FormBuilder,
    inputValidationService: InputValidationService
  ) {
    super(renderer, inputValidationService, attrTranslationService, translateHelper, formBuilder);
    this.toUnsubscribe.push(
      tableEventSource.locationRefreshEvent.subscribe(() => {
        this.currentCommand = undefined;
        this.manualDataFromGroup.disable();
      })
    );
    this.geoPositionStatusChoices = Object.keys(GeoPositionStatus);
    this.geoPositionStatusChoices.unshift(undefined); // represents no value set
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.location && changes.location.currentValue) {
      this.setLocationData(changes.location.currentValue);
      this.resetDataColumns(changes.location.currentValue.attributes, false);
    }
  }

  private setLocationData(currentLocation: LocationAdminDto): void {
    console.log('Setting location Data', currentLocation);
    let allAttributeRows = AttributeRow.convert(currentLocation.attributes);
    this.commentRow = this.extractRowsNotShownInTable(allAttributeRows, LocationDtoAttributeName.ADMIN_COMMENTS);
    this.updatedByRow = this.extractRowsNotShownInTable(allAttributeRows, LocationDtoAttributeName.ADMIN_LAST_UPDATED_BY);
    this.lastUpdateDateRow = this.extractRowsNotShownInTable(allAttributeRows, LocationDtoAttributeName.ADMIN_LAST_UPDATED_DATE);
    allAttributeRows = Utils.sortRowByReferencedArray(allAttributeRows, Object.keys(LocationDtoAttributeName));
    this.tableDataSource = new MatTableDataSource(allAttributeRows);
  }

  public onSave(): void {
    const manualDataDto = new LocationAndChargepointAdminAttributeDto();
    manualDataDto.locationAttributes = [];
    manualDataDto.locationLastUpdated = this.location.lastUpdated;
    Object.keys(this.manualDataFromGroup.controls).forEach((attribute) => {
      let value;
      if (attribute === this.countryCode) {
        const countryCodeValue = FormHelper.getStringValue(this.manualDataFromGroup, attribute);
        value = CountryUtil.toCountryCodeName(countryCodeValue) || countryCodeValue;
      } else {
        value = FormHelper.getStringValue(this.manualDataFromGroup, attribute);
      }
      const attributeDto = new LocationAdminAttributeDto();
      attributeDto.locationGroupId = Number(this.locationGroupId);
      attributeDto.name = attribute;
      attributeDto.value = value;
      console.log('adding attribute as manual data: ', attributeDto);
      manualDataDto.locationAttributes.push(attributeDto);
    });
    const chargepointUpdateData = new ChargepointUpdateData();
    chargepointUpdateData.originalValue = null;
    chargepointUpdateData.updatedValue = manualDataDto;
    this.editElement(chargepointUpdateData);
  }

  public enableDataEdit(): void {
    this.manualDataFromGroup.enable();
    this.currentCommand = AttributeTableAction.ENABLE_EDIT;
    this.resetDataColumns(this.location.attributes, true);
  }

  public onCancel(): void {
    this.currentCommand = undefined;
    this.resetDataColumns(this.location.attributes, false);
    this.manualDataFromGroup = this.formBuilder.group({});
  }

  public attributeEditable(element: AttributeRow): boolean {
    return TableConfigurations.editableLocationAttributes.includes(element.name);
  }
}
