/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {ReimbursementBilledItemDto} from './ReimbursementBilledItemDto';

export class ReimbursementRequestDto {
  public itemsToReimburse: ReimbursementBilledItemDto[];
  public contractId: string | undefined; // either contractId or fleetId is set
  public fleetId: string | undefined;
}
