/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export enum ChargingPlug {
  SMALL_PADDLE_INDUCTIVE = 'SMALL_PADDLE_INDUCTIVE',
  LARGE_PADDLE_INDUCTIVE = 'LARGE_PADDLE_INDUCTIVE',
  AVCON_CONNECTOR = 'AVCON_CONNECTOR',
  TESLA_CONNECTOR = 'TESLA_CONNECTOR',
  NEMA_5_20 = 'NEMA_5_20',
  TYPE_E_FRENCH_STANDARD = 'TYPE_E_FRENCH_STANDARD',
  TYPE_F_SCHUKO = 'TYPE_F_SCHUKO',
  TYPE_G_BRITISH_STANDARD = 'TYPE_G_BRITISH_STANDARD',
  TYPE_J_SWISS_STANDARD = 'TYPE_J_SWISS_STANDARD',
  TYPE_1_CONNECTOR_CABLE_ATTACHED = 'TYPE_1_CONNECTOR_CABLE_ATTACHED',
  TYPE_2_OUTLET = 'TYPE_2_OUTLET',
  TYPE_2_CONNECTOR_CABLE_ATTACHED = 'TYPE_2_CONNECTOR_CABLE_ATTACHED',
  TYPE_3_OUTLET = 'TYPE_3_OUTLET',
  IEC_60309_SINGLE_PHASE = 'IEC_60309_SINGLE_PHASE',
  IEC_60309_THREE_PHASE = 'IEC_60309_THREE_PHASE',
  CCS_COMBO_2_PLUG_CABLE_ATTACHED = 'CCS_COMBO_2_PLUG_CABLE_ATTACHED',
  CCS_COMBO_1_PLUG_CABLE_ATTACHED = 'CCS_COMBO_1_PLUG_CABLE_ATTACHED',
  CH_ADE_MO = 'CH_ADE_MO',
  UNSPECIFIED = 'UNSPECIFIED'
}
