/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AddressOverview, FleetAddress, Message} from 'pcs-commons/datatypes';
import {StringUtils} from './string-utils';
import {TranslateService} from '@ngx-translate/core';

export class Utils {
  private constructor() {
  }

  public static isNotEmpty(s: string): boolean {
    return s !== undefined && s !== null && s !== '';
  }

  public static isEmpty(val: any): boolean {
    const empty = val === undefined || val === null;
    return empty || (String(val)).trim() === '';
  }

  public static objectNotEmpty(obj: any): boolean {
    return !this.isEmpty(obj);
  }

  public static arrayEmpty(array: any[]): boolean {
    return array === undefined || array === null || array.length < 1;
  }

  public static randomNumberBetween(min: number, max: number): number { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static removeArrayItem<T>(sourceArray: T[], item: T): void {
    const index = sourceArray.indexOf(item, 0);
    if (index > -1) {
      sourceArray.splice(index, 1);
    }
  }

  public static getFormattedFormControlName(propName: string, index: number): string {
    return propName + '-' + index;
  }

  /**
   * please make sure the exclusionList does not contain all numbers between the min and max
   * else there will be an endless loop
   */
  public static randomNumberExclusive(min: number, max: number, exclusionList: number[]): number { // min and max included
    let newNumber;
    do {
      newNumber = Utils.randomNumberBetween(min, max);
    } while (exclusionList.includes(newNumber));
    return newNumber;
  }

  public static printMap(map: Map<any, any>, message?: string): void {
    const jsonObject = {};
    map.forEach((value, key) => {
      jsonObject[key] = value;
    });
    console.log((message ? message : '') + JSON.stringify(jsonObject));
  }

  public static toAddressOverview(address: FleetAddress, translateService: TranslateService): AddressOverview {
    if (!address) {
      return null;
    }
    const addressOverview = new AddressOverview();
    addressOverview.ciamId = address.ciamId;
    addressOverview.email = address.email;
    addressOverview.vatId = address.vatId;
    if (address.invoiceLanguage) {
      addressOverview.invoiceLanguage = translateService.instant('global.language.' + address.invoiceLanguage);
    }
    addressOverview.company = StringUtils.concat(' ', address.companyName1, address.companyName2);
    addressOverview.salutation = address.salutation;
    addressOverview.name = StringUtils.concat(' ', address.title, address.firstName, address.middleName, address.lastName);
    addressOverview.coName = address.coName;
    const street = StringUtils.concat(' ', address.street1, address.street2, address.street3);
    const house = StringUtils.concat(' ', address.houseNumber, address.buildingCode);
    let floorPart = '';
    if (address.floor || address.roomNumber) {
      floorPart = '(';
      floorPart += address.floor ? (translateService.instant('address.floor') + ': ' + address.floor + ', ') : '';
      floorPart += address.roomNumber ? (translateService.instant('address.roomNumber') + ': ' + address.roomNumber) : '';
      floorPart += ')';
    }
    const country = translateService.instant('global.country.' + address.country);
    addressOverview.address = StringUtils.concat(', ', street, house + floorPart, address.city,
      address.postalCode, address.region, address.postalCodeRegionValue, address.district, country);
    addressOverview.poBox = StringUtils.concat(', ', address.poBox, address.poBoxCity, address.poBoxPostalCode);
    addressOverview.supplement = address.supplement;
    return addressOverview;
  }

  public static confirmMessageBillingAddressChange(): Message {
    return new Message('address.confirm.billingAddressChange');
  }
}
