/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {PurchasePriceComponent} from './purchase-price-component';
import {IdentifiableTableElement} from '../identifiable-table-element';

export class PurchaseConditionElement extends IdentifiableTableElement {
  public prices: PurchasePriceComponent[];
  public startTime: string;
  public endTime: string;
  public startDate: string;
  public endDate: string;
  public minKwhConsumption: number;
  public maxKwhConsumption: number;
  public powerClasses: string[];

  // in seconds
  public minDuration: number;
  public maxDuration: number;

  public daysOfWeek: string[];
}

export enum PurchaseConditionElementAttributes {
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  MIN_KWH_CONSUMPTION = 'minKwhConsumption',
  MAX_KWH_CONSUMPTION = 'maxKwhConsumption',
  POWER_CLASSES = 'powerClasses',
  MIN_DURATION = 'minDuration',
  MAX_DURATION = 'maxDuration',
  DAYS_OF_WEEK = 'daysOfWeek',
  PRICES = 'prices'
}
