<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents *ngIf="!showUserCred">
  <h2 mat-dialog-title style="margin-bottom: 0;">{{ 'fleet.manager.dialogue.header'|translate }}
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
  </h2>
  <form class="add-user-form" [formGroup]="addManagerForm">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'fleet.manager.login' | translate }}</mat-label>
      <input matInput required formControlName="login">
      <mat-error *ngIf="login.invalid">{{ getError(login) | async }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'fleet.manager.password' | translate }}</mat-label>
      <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password">
      <mat-error *ngIf="password.invalid">{{ getError(password) | async }}</mat-error>
      <mat-icon matSuffix (click)="hidePass = !hidePass">{{ hidePass ? 'visibility' : 'visibility_off' }}</mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'fleet.manager.phone' | translate }}</mat-label>
      <input matInput (keypress)="validatePhoneNumber($event)" formControlName="phone">
      <mat-error *ngIf="phone.invalid">{{ getError(phone) | async }}</mat-error>
    </mat-form-field>
  </form>

  <mat-dialog-actions>
    <button cancelAction mat-raised-button class="dialogueButton dialogueButtonRight"
            (click)="onCancel()">
      {{ 'button.cancel' | translate }}
    </button>
    <button okAction mat-raised-button class="dialogueButton"
            [disabled]="!allInputsValid()"
            (click)="onAdd()">
      {{ 'button.add' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>

<!--
  confirmation shown after creation: list of users just created
-->
<mat-dialog-content editKeyEvents *ngIf="showUserCred">
  <h2 mat-dialog-title style="margin-bottom: 0;">{{ 'fleet.manager.dialogue.confirmation.header'|translate }}
  </h2>

  <user-cred-table [users]="managerResponse"></user-cred-table>

  <mat-dialog-actions>
    <button okCancelAction mat-raised-button class="dialogueButton dialogueButtonRight"
            (click)="onOk()">
      {{ 'button.ok' | translate }}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
