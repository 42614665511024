/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, ViewChild} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {BehaviorSubject, catchError, finalize, of, tap} from 'rxjs';
import {SelfInformationDto} from '../datatypes/self-information-dto';
import {CustomerinfoService} from '../services/http/customerinfo.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {SelfInformationFilterDto} from '../datatypes/self-information-filter-dto';
import {Message} from '../datatypes/message';
import {saveAs as importedSaveAs} from 'file-saver';
import {PermissionAware} from '../permission-aware';
import {AccessRights} from '../datatypes/access-rights.enum';
import {Parameters} from '../global/parameters';

@Component({
  selector: 'pcs-customerinfo',
  templateUrl: './customerinfo.component.html',
  styleUrls: ['./customerinfo.component.css']
})
export class CustomerinfoComponent extends AbstractNotificationHandler implements PermissionAware {
  public readonly reqEditPermission = [AccessRights.CUSTOMERMANAGEMENT_EDIT_WEB];

  protected readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  protected customerinfoSearchResultSubject = new BehaviorSubject<SelfInformationDto[]>([]);
  protected customerinfoSearchResult$ = this.customerinfoSearchResultSubject.asObservable();

  protected dataColumns = SelfInformationDto.dataColumns;

  protected resultLength = 0;
  protected loading = false;

  @ViewChild(MatPaginator) protected paginator: MatPaginator;

  protected currentFilter: SelfInformationFilterDto;

  constructor(
    private customerinfoService: CustomerinfoService) {
    super();
  }

  protected handlePageChange(pageEvent: PageEvent): void {
    this.getCustomerinfoByFilter(this.currentFilter, pageEvent.pageIndex, pageEvent.pageSize);
  }

  protected applyFilter(filter: SelfInformationFilterDto): void {
    this.currentFilter = filter;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    const pageSize = this.paginator ? this.paginator.pageSize : this.pageSizeOptions[0];
    this.getCustomerinfoByFilter(filter, 0, pageSize);
  }

  protected onDownloadCustomerinfo(customerinfo: SelfInformationDto): void {
    this.loading = true;
    this.customerinfoService.getStatisticCustomerData(customerinfo).pipe(
      tap(blob => importedSaveAs(blob, 'customerinfo-' + customerinfo.ciamId + '.xlsx')),
      catchError(() => of(null)),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  private getCustomerinfoByFilter(filter: SelfInformationFilterDto, pageIndex: number, pageSize: number): void {
    this.loading = true;
    const offset = pageIndex * pageSize;
    // send one more so see if we have more records than requested
    this.customerinfoService.getCustomerInfo(filter, offset, pageSize + 1).pipe(
      tap(customerinfo => this.handleFilterResult(pageIndex, pageSize, customerinfo)),
      catchError(() => of(null)),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  private handleFilterResult(pageIndex: number, pageSize: number, customerinfo: SelfInformationDto[]): void {
    this.resultLength = pageIndex * pageSize + customerinfo.length;
    // since we requested one extra element, we remove it before showing
    if (customerinfo.length > pageSize) {
      customerinfo.splice(customerinfo.length - 1, 1);
    }
    this.customerinfoSearchResultSubject.next(customerinfo);
    if (customerinfo.length <= 0) {
      const msg = new Message();
      msg.message = 'customerinfo.noRecordsFound';
      this.showInfo(msg);
    }
  }
}
