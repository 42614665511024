/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class NonZeroValidatorService implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    const inputValue = control.value;
    if (!inputValue) {
      return null;
    }
    const valueAsNum = Number(inputValue);
    console.log('checking if value is non zero for input: ', valueAsNum);
    return Number(0) === valueAsNum ? {nonZero: false} : null;
  }


}
