/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {InvoiceAdminDto} from '../../datatypes/invoices/InvoiceAdminDto';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'invoices-table',
  templateUrl: './invoices-table.component.html',
  styleUrls: [
    '../scrollable-table/scrollable-table.component.css',
    '../action-column-style.css',
    './invoices-table.component.css']
})
export class InvoicesTableComponent extends OptimizedTableBaseComponent {

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public invalidElementForDetails(element: InvoiceAdminDto): boolean {
    return (!element.contractId && !element.fleetId) || !element.invoiceIdent;
  }

  public translateDate(date: any): any {
    return this.translateHelper.translateDateTimeSafe(date);
  }
}
