<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-form-field class="column-filter-form column-filter-select-form">
  <mat-select [formControl]="selectControl" [value]="defaultOption" class="font-14">
    <mat-select-trigger class="selected-icon">
      <mat-icon
        *ngIf="selectControl.value && getIconTypeForValue(selectControl.value) === 'mat'"
        [ngStyle]="{ color: getIconColorForValue(selectControl.value) }"
      >
        {{ getIconForValue(selectControl.value) }}
      </mat-icon>
      <svg-icon
        *ngIf="selectControl.value && getIconTypeForValue(selectControl.value) === 'svg'"
        [src]="'/assets/images/' + getIconForValue(selectControl.value) + '.svg'"
      ></svg-icon>
    </mat-select-trigger>
    <mat-option *ngIf="withEmptyOption" [value]="" class="font-14"></mat-option>
    <div *ngIf="options">
      <mat-option *ngFor="let option of options" [value]="option.value">
        <mat-icon *ngIf="option && option.iconType === 'mat'" [ngStyle]="{ color: option.iconColor }">{{ option.icon }}</mat-icon>
        <svg-icon *ngIf="option && option.iconType === 'svg'" [src]="'/assets/images/' + option.icon + '.svg'"></svg-icon>
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
