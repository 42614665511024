/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export class InvoiceAdminDto {
  public id: number;
  public contractId: string;
  public fleetId: string;
  public orderNumber: string;
  public invoiceType: string;
  public status: string;
  public orderDateTime: string;
  public invoiceDateTime: string;
  public lastUpdate: string;
  public invoiceIdent: string;
  public recipientIdType: string;
  public invoiceId: string;
  public syncStatus: string;
  public vin: string;
  public ciamid: string;
  public porscheId: string;
}

export enum InvoiceAdminColumns {
  CONTRACT_ID = 'contractId',
  FLEET_ID = 'fleetId',
  ORDER_NUMBER = 'orderNumber',
  INVOICE_IDENT = 'invoiceIdent',
  INVOICE_TYPE = 'invoiceType',
  STATUS = 'status',
  ORDER_DATE_TIME = 'orderDateTime',
  INVOICE_DATE_TIME = 'invoiceDateTime',
  RECIPIENT_ID_TYPE = 'recipientIdType',
  INVOICE_ID = 'invoiceId',
  SYNC_STATUS = 'syncStatus',
  VIN = 'vin',
  CIAMID = 'ciamid',
  PORSCHEID = 'porscheId'
}
