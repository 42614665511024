<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
  <span class="headerSpan">{{'taycanCards.header' | translate }}</span>
</mat-toolbar>

<div class="section-container">
  <card-filter [loading]="loading" (filterEvent)="applyFilter($event)"
               [collapseView]="collapseFilterView$"></card-filter>
</div>

<div id="charging-cards" class="section-container" *ngIf="resultLength > 0">
  <mat-accordion>
    <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
      <div class="expansion-table-header">
        <span class="headerSpan">{{'cards.filterResultHeader' | translate }}</span>
      </div>
      <charging-card-table [isLoadingResults]="pageEventOnProcess" [data]="cardSearchResult$"
                           [dataColumns]="chargingCardColumns" [i18nBase]="'card'"
                           [actionButtons]="false">
      </charging-card-table>
      <mat-paginator [pageSizeOptions]="pageSizeOptions"
                     (page)="handlePageChange($event)"
                     [length]="resultLength"></mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>
</div>
