<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<form class="cp-filter-form" [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
  <div class="multi-column-div">
    <mat-form-field class="default-form-field align-with-dropdown">
      <mat-label>{{'chargepoint.cpoName' | translate}}</mat-label>
      <input matInput maxlength="{{defaultMaxLength}}" formControlName="cpoName" class="align-with-dropdown">
      <mat-hint align="end" *ngIf="cpoName.value">{{cpoName.value.length}}
        / {{defaultMaxLength}}</mat-hint>
    </mat-form-field>
    <autocomplete-string-select [inputControl]="country" class="cp-filter-autocomplete-select"
                                [label]="'chargepoint.country'"
                                [optionList]="countryList" [optionI18nBase]="'global.country.'" [appearance]="'fill'">
    </autocomplete-string-select>
    <mat-form-field class="number-form-field align-with-dropdown">
      <mat-label> {{'chargepoint.notUsedInDays'| translate}}</mat-label>
      <input matInput type="number" formControlName="notUsedInDays" [min]="1">
      <mat-error *ngIf="notUsedInDays.invalid">{{getError(notUsedInDays) | async}}</mat-error>
    </mat-form-field>
  </div>
</form>
