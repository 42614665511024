/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import { ProductQuota } from './product-quota';
import { User } from './user';
import { FleetSummary } from './fleet-summary';
import {FleetAddressList} from 'pcs-commons/datatypes';

export class Fleet extends FleetSummary {
    public products: ProductQuota[];
    public fleetManagers: User[];
    public fleetAddresses: FleetAddressList;
}
