/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, ViewChild} from '@angular/core';
import {LocationService} from '../../services/http/location.service';
import {TranslateService} from '@ngx-translate/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {GoogleMap} from "@angular/google-maps";

@Directive()
export abstract class GeoLocationMapHandler extends AbstractNotificationHandler {
  @ViewChild('map', {static: false}) public map: GoogleMap;
  public lat = 0.0;
  public lng = 0.0;
  public latFromAdd = 0.0;
  public longFromAdd = 0.0;
  public latFromUsersCoordinates = 0.0;
  public longFromUsersCoordinates = 0.0;
  public numOfUserPositions = 0;
  public markerFromCoordinates: google.maps.Marker;
  public markerFromAddress: google.maps.Marker;
  public markerOfUserCoordinates: google.maps.Marker;
  public options: google.maps.MapOptions;
  public markerOptions: google.maps.MarkerOptions = {draggable: false};
  public markers: google.maps.Marker[] = [];

  protected constructor(
    private translateService: TranslateService,
    protected locationService: LocationService) {
    super();
  }

  protected setupGeoDataMap(currLocationGroupId: string): void {
    this.locationService.getGeoLocationsByGroupId(currLocationGroupId).subscribe({
      next: (data) => {
        const geoLocationData = data;
        if (!geoLocationData) {
          console.error('location data not found!');
          // load map without markers
          return;
        }
        this.lat = Number(geoLocationData.latitude);
        this.lng = Number(geoLocationData.longitude);

        this.latFromAdd = Number(geoLocationData.latitudeAsPerGoogle);
        this.longFromAdd = Number(geoLocationData.longitudeAsPerGoogle);

        this.latFromUsersCoordinates = geoLocationData.latitudeAsPerUsers ? geoLocationData.latitudeAsPerUsers : 0;
        this.longFromUsersCoordinates = geoLocationData.longitudeAsPerUsers ? geoLocationData.longitudeAsPerUsers : 0;
        this.numOfUserPositions = geoLocationData.amountOfUserPositions;
        this.configurePoiMarkers();
        this.configureMapBounds();
      },
      error: () => {
        // load empty map
      }
    });
  }

  private configurePoiMarkers(): void {
    this.markers = [];
    if (this.coordinatePresent) {
      this.markerFromCoordinates = GeoLocationMapHandler.createMarker(this.lat, this.lng, `${this.lat},${this.lng}`,
        '../../../assets/images/red_marker.png');
      this.markers.push(this.markerFromCoordinates);
    }
    if (this.addressCoordinatePresent) {
      this.markerFromAddress = GeoLocationMapHandler.createMarker(this.latFromAdd, this.longFromAdd, `${this.latFromAdd},${this.longFromAdd}`,
        '../../../assets/images/purple_marker.png');
      this.markers.push(this.markerFromAddress);
    }
    if (this.userPositionDataPresent) {
      const msg = this.translateService.instant('maps.marker.info.userPositions', {param0: this.numOfUserPositions});
      this.markerOfUserCoordinates = GeoLocationMapHandler.createMarker(this.latFromUsersCoordinates, this.longFromUsersCoordinates,
        this.latFromUsersCoordinates + ',' + this.longFromUsersCoordinates + ' ' + msg, '../../../assets/images/yellow_marker.png');
      this.markers.push(this.markerOfUserCoordinates);
    }
  }

  private static createMarker(lat: number, lng: number, title: string, iconPath: string): google.maps.Marker {
    const marker = new google.maps.Marker();
    marker.setPosition({lat, lng});
    marker.setTitle(title);
    marker.setIcon(iconPath);
    return marker;
  }

  /*
    to show all the markers in the visible area of the map
   */
  private configureMapBounds(): void {
    if (this.markers.length < 1) {
      return;
    } else if (this.markers.length === 1) {
      this.options = {center: this.markers[0].getPosition(), zoom: 12};
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    for (const item of this.markers) {
      bounds.extend(item.getPosition());
    }
    this.map.fitBounds(bounds, 50);
  }

  public get userPositionDataPresent(): boolean {
    return this.latFromUsersCoordinates !== 0 && this.longFromUsersCoordinates !== 0;
  }

  public get addressCoordinatePresent(): boolean {
    return this.latFromAdd !== 0 && this.longFromAdd !== 0;
  }

  public get coordinatePresent(): boolean {
    return this.lat !== 0 && this.lng !== 0;
  }
}
