/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import { BehaviorSubject } from 'rxjs';
import { ChangeRequestInfoManagerAbstract } from '../change-request-info-manager.abstract';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges
  } from '@angular/core';
import { ReimbursementBilledItemColumns, ReimbursementBilledItemDto } from '../../datatypes/invoices/ReimbursementBilledItemDto';
import { ReimbursementRequestDto } from '../../datatypes/invoices/ReimbursementRequestDto';

@Component({
  selector: 'pcs-change-request-info-as-table',
  templateUrl: './change-request-info-as-table.component.html',
  styleUrls: ['./change-request-info-as-table.component.css']
})
export class ChangeRequestInfoAsTableComponent extends ChangeRequestInfoManagerAbstract implements OnChanges {

  /** This parameter is a tuple consisting of [contractId, fleetId] */
  @Input()
  public params: string[];

  public data$ = new BehaviorSubject< ReimbursementBilledItemDto[]>([]);

  public dataColumns = [ReimbursementBilledItemColumns.REFERENCE_ITEM_ID,
    ReimbursementBilledItemColumns.INVOICE_IDENT,
    ReimbursementBilledItemColumns.PRODUCT_NUMBER,
    ReimbursementBilledItemColumns.GROSS_PRICE,
    ReimbursementBilledItemColumns.CURRENCY,
    ReimbursementBilledItemColumns.SERVICE_COUNTRY,
    ReimbursementBilledItemColumns.BILLED_DATE_TIME];
  public getInfoText(): string {
    const [contractId, fleetId]=this.params;
    const type = contractId ? "contract" : "fleet";
    return `changeRequest.info.text.${type}.${this.changeRequest?.action}`;
  }

  public getParam():string {
    return this.params[0] ?? this.params[1];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('changeRequest' in changes) {
      const updatedValue = this.changeRequest?.updatedValue;
      if (updatedValue) {
        const data = JSON.parse(updatedValue) as ReimbursementRequestDto;
        console.log(data.itemsToReimburse);
        this.data$.next(data.itemsToReimburse);
      }
    }
  }
}
