/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input} from '@angular/core';
import {NavMenu} from '../datatypes/nav-menu';

@Component({
  selector: 'menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent {
  @Input() public menu: NavMenu;

  get key(): string {
    return this.menu ? this.menu.key : '';
  }

  get url(): string {
    return this.menu ? this.menu.homeUrl : '';
  }

  get matIcon(): string {
    return this.menu ? this.menu.matIcon : '';
  }

  get faIconClass(): string {
    return this.menu ? this.menu.faIconClass : '';
  }
}
