/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit} from '@angular/core';
import {PurchaseConditionFormManager} from './purchase-condition-form-manager.abstract';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Utils} from '../../utils/utils';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {Action} from '../../datatypes/action.enum';
import {Location} from '@angular/common';
import {Operator} from '../../datatypes/operator';
import {ChargepointService} from '../../services/http/chargepoint.service';
import {CountryCode} from 'pcs-commons/datatypes';
import {
  PurchaseConditionElement,
  PurchaseConditionElementAttributes
} from '../../datatypes/purchase-conditions/purchase-condition-element';
import {MatDialog} from '@angular/material/dialog';
import {EditPcElementComponent} from '../edit-pc-element/edit-pc-element.component';
import {PurchaseConditionService} from '../../services/http/purchase-condition.service';
import {PurchaseCondition} from '../../datatypes/purchase-conditions/purchase-condition';
import {NonZeroValidatorService} from '../../validation/non-zero-validator.service';
import {PurchaseConditionStatus} from '../../datatypes/purchase-conditions/purchase-condition-status.enum';
import {BaseUrls} from '../../datatypes/base-urls.enum';
import {Currency} from '../../datatypes/currency';
import {ConfirmationDialogComponent} from '../../dialog/confirmation-dialog/confirmation-dialog.component';
import {Message} from '../../datatypes/message';
import {DateUtils} from 'pcs-commons/utils';
import {FormUtils} from 'pcs-commons/utils';
import {DatepickerFormErrorMatcher} from '../../common/datepicker-form-error-matcher';
import {CpoValidatorService} from '../../validation/cpo-validator.service';
import {SharedDataService} from '../../services/shared-data.service';
import {CurrencyValidatorService} from '../../validation/currency-validator.service';
import {catchError, finalize, tap} from "rxjs/operators";
import {DateTime} from "luxon";

@Component({
  selector: 'purchase-condition-details',
  templateUrl: './purchase-condition-details.component.html',
  styleUrls: [
    './purchase-condition-details.component.css',
    '../../dialog/dialog-common-styles.css',
    '../../common/tab-styles.css'
  ]
})
export class PurchaseConditionDetailsComponent extends PurchaseConditionFormManager implements OnInit, OnDestroy, PermissionAware {
  public readonly pcElementTableColumns: string[] = Object.values(PurchaseConditionElementAttributes);
  public readonly reqEditPermission = [AccessRights.PURCHASE_EDIT_WEB];

  public countryList: string[] = [];
  public currencyList: string[] = [];
  public pcElementDialogSub: Subscription;

  public datepickerFormErrorMatcher = new DatepickerFormErrorMatcher();

  public operators$: Observable<Operator[]>;

  constructor(
    private router: WebConfigRouterService,
    formBuilder: FormBuilder,
    dialog: MatDialog,
    nonZeroValidator: NonZeroValidatorService,
    cpoValidator: CpoValidatorService,
    currencyValidator: CurrencyValidatorService,
    private dataSource: SharedDataService,
    private location: Location,
    private purchaseConditionService: PurchaseConditionService,
    private cpService: ChargepointService,
    private matDialog: MatDialog,
    private route: ActivatedRoute) {
    super(dialog, nonZeroValidator, cpoValidator, currencyValidator, formBuilder);
  }

  public ngOnInit(): void {
    this.determineMode();
    this.unixEpoch = DateTime.fromMillis(0);
    this.minValidFrom = this.unixEpoch;
    this.minValidUntil = this.unixEpoch;
    this.countryList = Object.values(CountryCode);
    this.defineFormControls();
    this.fetchAndFillPurchaseConditionData();
  }

  private determineMode(): void {
    this.mode = Utils.getPageMode(this.route);
    console.log('purchase condition details page mode:  ', this.mode);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.pcElementDialogSub) {
      this.pcElementDialogSub.unsubscribe();
    }
  }

  public onAddNewElement(): void {
    this.onPCElementEdit(new PurchaseConditionElement());
  }

  public isCreateOrEditMode(): boolean {
    return Action.EDIT === this.mode || Action.CREATE === this.mode;
  }

  public goBack(): void {
    this.location.back();
  }

  public save(): void {
    const updatedPurCon = this.preparePurchaseCondition();
    console.log('purchase condition for store in Backend: ', updatedPurCon);
    this.loading = true;
    if (this.isCreateMode) {
      this.createNewPurchaseCondition(updatedPurCon);
    } else if (this.isEditMode) {
      this.updatePurchaseCondition(updatedPurCon);
    }
  }

  public onPCElementEdit(toEdit: PurchaseConditionElement): void {
    const dialogRef = this.matDialog.open(EditPcElementComponent,
      {
        width: '800px',
        data: {
          validFrom: FormUtils.getValue(this.purchaseConditionForm, 'validFrom'),
          validUntil: FormUtils.getValue(this.purchaseConditionForm, 'validUntil'),
          elemOnEdit: toEdit,
          pcStatus: this.currPurchaseCondition.status
        }
      });

    if (this.pcElementDialogSub) {
      this.pcElementDialogSub.unsubscribe();
    }

    this.pcElementDialogSub = dialogRef.afterClosed().subscribe((updatedPcElement) => {
      console.log('elements dialog result: ', updatedPcElement);
      if (!updatedPcElement) {
        return;
      }

      const currElements = this.currElementsSubject.getValue();
      if (!updatedPcElement.tElemId) {
        updatedPcElement.tElemId = Utils.generateStringId(this._tElemIdPrefix);
      } else {
        // search and replace the existing element from table
        Utils.removeArrayItemByTableIdentifier(currElements, updatedPcElement);
      }
      currElements.push(updatedPcElement);
      this.currElementsSubject.next(currElements);
      this.elementsUpdated = true;
    });
  }

  public onPCElementDelete(toDelete: PurchaseConditionElement): void {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent,
      {
        data: {
          message: Utils.deletePurchaseElementConfirmMessage(),
          actionKey: 'button.ok'
        }
      }
    );
    const dialogSub = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const currElements = this.currElementsSubject.getValue();
        Utils.removeArrayItemByTableIdentifier(currElements, toDelete);
        this.currElementsSubject.next(currElements);
        this.elementsUpdated = true;
      }
      dialogSub.unsubscribe();
    });
  }

  private fetchAndFillPurchaseConditionData(): void {
    this.loading = true;

    this.operators$ = this.dataSource.getCurrentCpoListObservable();
    this.operators$.subscribe((operators) => {
      this.cpoList = operators as Operator[];
      console.log('fetched cpo list: ', this.cpoList);
    });

    const currenciesObservable$ = this.purchaseConditionService.getCurrencies();
    const observableList: Observable<any>[] = [currenciesObservable$];
    if (!this.isCreateMode) {
      const id = this.route.snapshot.paramMap.get('id');
      console.log('fetching details for purchase condition with id: ', id);
      observableList.push(this.purchaseConditionService.getPurchaseConditionById(id));
    } else {
      //  check if any template id is provided
      const templateId = this.route.snapshot.queryParamMap.get('templateId');
      if (templateId) {
        console.log('fetching details for purchase condition with id: ', templateId);
        observableList.push(this.purchaseConditionService.getPurchaseConditionById(templateId));
      }
    }

    forkJoin(observableList).subscribe({
      next: (results) => {
        const currencyList = results[0] as Currency[];
        this.currencyList = currencyList.map((currency) => currency.name);
        this.dataSource.updateCurrentCurrencyList(this.currencyList);

        if (observableList.length > 1) {
          this.currPurchaseCondition = results[1] as PurchaseCondition;
        }

        if (!this.currPurchaseCondition) {
          this.currPurchaseCondition = new PurchaseCondition();
          this.currPurchaseCondition.elements = [];
        }

        if (this.isCreateMode) {
          this.currPurchaseCondition.status = PurchaseConditionStatus.INACTIVE;
          this.currPurchaseCondition.id = undefined;
        }

        this.refreshValues();
        this.loading = false;
      },
      error: (error) => this.loading = false
    });
  }

  private createNewPurchaseCondition(updatedPurCon: PurchaseCondition): void {
    this.purchaseConditionService.create(updatedPurCon).pipe(
      tap((result) => {
        this.currPurchaseCondition = result;
        this.router.navigate([BaseUrls.PURCHASE_CONDITION_OVERVIEW]);
      }),
      catchError(() => of(null)),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  private updatePurchaseCondition(updatedPurCon: PurchaseCondition): void {
    this.purchaseConditionService.update(updatedPurCon).pipe(
      tap((result) => {
        this.currPurchaseCondition = result;
        this.refreshValues();
        const msg = new Message();
        msg.message = 'purchaseCondition.update.success';
        this.showInfo(msg);
      }),
      catchError(() => of(null)),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  public isActivationAllowed(): boolean {
    return this.isEditMode && this.currPurchaseCondition && PurchaseConditionStatus.INACTIVE === this.currPurchaseCondition.status;
  }

  public isDeactivationAllowed(): boolean {
    return this.isEditMode && this.currPurchaseCondition
      && PurchaseConditionStatus.ACTIVE === this.currPurchaseCondition.status
      && DateUtils.dateIsInFuture(this.currPurchaseCondition.validFrom);
  }

  public isDeletedAllowed(): boolean {
    return this.isEditMode && this.currPurchaseCondition
      && PurchaseConditionStatus.ACTIVE === this.currPurchaseCondition.status;
  }

  public onActivate(): void {
    this.doChangeStatus(PurchaseConditionStatus.ACTIVE);
  }

  public onDeactivate(): void {
    this.doChangeStatus(PurchaseConditionStatus.INACTIVE);
  }

  public onMarkAsDeleted(): void {
    this.doChangeStatus(PurchaseConditionStatus.DELETED);
  }

  private doChangeStatus(newStatus: PurchaseConditionStatus): void {
    const message = newStatus === PurchaseConditionStatus.INACTIVE ? Utils.deactivatePurchaseConditionConfirmMessage() :
      newStatus === PurchaseConditionStatus.ACTIVE ? Utils.activatePurchaseConditionConfirmMessage() :
        Utils.deletedStatusOfPurchaseConditionConfirmMessage();
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent,
      {
        maxWidth: 600,
        data: {
          message,
          actionKey: 'button.ok'
        }
      }
    );
    const dialogSub = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        const updatedPurchaseCon = this.preparePurchaseCondition();
        updatedPurchaseCon.status = newStatus;
        this.updatePurchaseCondition(updatedPurchaseCon);
      }
      dialogSub.unsubscribe();
    });
  }

}
