import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from 'pcs-commons/utils';

@Component({
  selector: 'pcs-date-range-column-filter',
  templateUrl: './date-range-column-filter.component.html',
  styleUrl: './date-range-column-filter.component.css'
})
export class DateRangeColumnFilterComponent implements OnInit {
  @Output() public handleInputChange: EventEmitter<object> = new EventEmitter<object>();

  public fromDate: FormControl;
  public toDate: FormControl;
  public dateRangeFormGroup: FormGroup;

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {
  }

  public getDatePlaceholder(): any {
    return this.translateService.instant('global.dateFormat');
  }

  public ngOnInit(): void {
    this.fromDate = new FormControl(null);
    this.toDate = new FormControl(null);
    this.dateRangeFormGroup = this.formBuilder.group({
      fromDate: this.fromDate,
      toDate: this.toDate
    });

    this.dateRangeFormGroup.valueChanges.subscribe((value) => this.processInputChange(value));
  }

  public processInputChange(value: any): void {
    if (this.dateRangeFormGroup.invalid) {
      console.log('processInputChange invalid');
      return;
    }

    if (value.toDate) {
      value.toDate = DateUtils.buildDateTimeFromSeparateFields(value.toDate, '23:59:59', true);
    }

    this.handleInputChange.emit(value);
  }
}
