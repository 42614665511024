/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export enum AccessibilityType {
  UNKNOWN = 'UNKNOWN',
  FREE_PUBLICLY_ACCESSIBLE = 'FREE_PUBLICLY_ACCESSIBLE',
  RESTRICTED_ACCESS = 'RESTRICTED_ACCESS',
  PAYING_PUBLICLY_ACCESSIBLE = 'PAYING_PUBLICLY_ACCESSIBLE'
}
