/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HeaderEnabled } from './header-enabled.abstract';
import { VehicleImportResult } from '../../datatypes/vehicle-import-result';
import { ElectricVehicle } from '../../datatypes/electric-vehicle';
import { ElectricVehicleFilter } from '../../datatypes/electric-vehicle-filter';
import { VinCheckResult } from '../../datatypes/vin-check-result';
import { ReadJobResponseDto } from '../../datatypes/ReadJobResponseDto';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public importMasterData(body: Blob): Observable<VehicleImportResult> {
    const newHeader = this.headers.set('Content-Type', 'application/octet-stream');
    const url = environment.vehicleServiceUrl + 'vehicles/import';
    console.log('IMPORT-VEHICLE-MASTER-DATA: body: ' + body + ' url: ' + url);
    return this.http.post<VehicleImportResult>(url, body, { headers: newHeader });
  }

  public getEVsByFilter(filter: ElectricVehicleFilter): Observable<ElectricVehicle[]> {
    const uri = environment.vehicleServiceUrl + 'ev/filter';
    return this.http.post<ElectricVehicle[]>(uri, filter, { headers: this.headers });
  }

  public updateElectricVehicle(ev: ElectricVehicle): Observable<ElectricVehicle> {
    const uri = environment.vehicleServiceUrl + 'ev/' + ev.id;
    return this.http.put<ElectricVehicle>(uri, ev, { headers: this.headers });
  }

  public revokePnCCert(ev: ElectricVehicle): Observable<void> {
    const uri = environment.vehicleServiceUrl + 'ev/' + ev.id + '/pnc/REVOKE';
    return this.http.post<void>(uri, {}, { headers: this.headers });
  }

  public checkVinsForContract(vinList: unknown[]): Observable<VinCheckResult[]> {
    const uri = environment.vehicleServiceUrl + 'vins/check-contract';
    return this.http.post<VinCheckResult[]>(uri, vinList, { headers: this.headers });
  }

  public retrieveKFISReadState(vin: string, requestId: string): Observable<ReadJobResponseDto> {
    const uri = environment.vehicleServiceUrl + 'read-state/' + vin + '/' + requestId;
    return this.http.get<ReadJobResponseDto>(uri, { headers: this.headers });
  }
}
