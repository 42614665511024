/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input} from '@angular/core';

@Component({
  selector: 'pcs-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent {
  @Input() rating: string;
  readonly ratingMap: Map<string, number[]> = new Map([
    ['ONE', (new Array(1)).fill(1)],
    ['TWO', (new Array(2)).fill(1)],
    ['THREE', (new Array(3)).fill(1)],
    ['FOUR', (new Array(4)).fill(1)],
    ['FIVE', (new Array(5)).fill(1)]
  ]);


  constructor() {
  }

  getArrayForStar() {
    return this.ratingMap.has(this.rating) ? this.ratingMap.get(this.rating) : [];
  }

  getArrayForEmptyStar() {
    const fullStarArray = this.ratingMap.has(this.rating) ?
      this.ratingMap.get(this.rating) : [];
    return (new Array(5 - fullStarArray.length).fill(1));
  }

}
