<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div id="invoices" >

  <mat-toolbar class="headerToolbar mat-elevation-z1">
    <span class="headerSpan">{{'invoices.list' | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>


  <div class="section-container">
    <invoices-filter editKeyEvents (filterEvent)="onSearchInvoices($event)"></invoices-filter>
  </div>

  <div class="section-container" *ngIf="resultLength > 0" id="invoices-table-area">
    <mat-accordion>
      <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
        <invoices-table
          [dataColumns]="dataColumns"
          [i18nBase]="'invoices.tableHeader'"
          [data]="invoices$"
          [actionButtons]="reqEditPermission |accessAllowed"
          (editEvent)="openDetails($event)"
          (searchEvent)="onRetrieveOrderItems($event)">
        </invoices-table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"
                       (page)="handlePageChange($event)" [length]="resultLength">
        </mat-paginator>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
