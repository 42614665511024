/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
export class QueryHistoryEntryDto {
  public userId: string;
  public userLogin: string;
  public timestamp: string;
  public successful: boolean;
  public resultCount: number;
  public query: string;

  public static USER_ID = 'userId';
  public static USER_LOGIN = 'userLogin';
  public static TIMESTAMP = 'timestamp';
  public static SUCCESSFUL = 'successful';
  public static RESULT_COUNT = 'resultCount';
  public static QUERY = 'query';

  public static readonly dataColumns: string[] = [
    QueryHistoryEntryDto.USER_LOGIN,
    QueryHistoryEntryDto.TIMESTAMP,
    QueryHistoryEntryDto.SUCCESSFUL,
    QueryHistoryEntryDto.RESULT_COUNT,
    QueryHistoryEntryDto.QUERY
  ];
}
