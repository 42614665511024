/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AttributeTableAction} from '../datatypes/attribute-table-action.enum';
import {ChargepointCommand} from '../datatypes/chargepoint-command';

@Injectable({
    providedIn: 'root'
})
export class AttributeTableEventService {

    private locationRefreshCommandSource = new BehaviorSubject<any>(undefined);
    public locationRefreshEvent = this.locationRefreshCommandSource.asObservable();

    private chargepointEditCommandSource = new BehaviorSubject<ChargepointCommand>(undefined);
    private chargepointEditCommand = this.chargepointEditCommandSource.asObservable();

    constructor() {
    }

    public triggerLocationRefresh(): void {
        console.log('sending location refresh trigger');
        this.locationRefreshCommandSource.next(Math.random());
    }

    public triggerCommandForChargepoint(evseId: string, command: AttributeTableAction): void {
        const currentCommand = new ChargepointCommand(evseId, command);
        console.log('Updating command for chargepoint: ', currentCommand);
        this.chargepointEditCommandSource.next(currentCommand);
    }

    public getChargepointEditCommand(): Observable<ChargepointCommand> {
        return this.chargepointEditCommand;
    }
}
