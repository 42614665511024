/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';

import { Operator } from '../../datatypes/operator';

import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HeaderEnabled } from './header-enabled.abstract';
import { Observable } from 'rxjs';
import { OperatorFilter } from '../../datatypes/operator-filter';

@Injectable()
export class ChargepointService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public getOperators(offset: number, pageSize: number, filters: OperatorFilter = new OperatorFilter()): Observable<Operator[]> {
    return this.http.post<Operator[]>(environment.chargepointUrl + '/operators', filters.toOperatorFilterDto(), {
      headers: this.headers,
      params: {
        offset: offset,
        pageSize: pageSize
      }
    });
  }

  public getOperator(id: number): Observable<Operator> {
    return this.http.get<Operator>(environment.chargepointUrlWithoutCountryLanguage + '/operator/' + id, {
      headers: this.headers
    });
  }

  public updateOperator(operator: Operator): Observable<Operator> {
    return this.http.put<Operator>(environment.chargepointUrl + '/operators/' + operator.id, operator, { headers: this.headers });
  }
}
