<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div id="currencyFactors" class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'currencyfactor.list' | translate }}</span>
    <button mat-raised-button class="headerActionBtn icon-only" (click)="onConfigureNewCurrencyFactor()"
            *ifAnyPerm="[reqEditPermission]">
      <mat-icon> add</mat-icon>
    </button>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
  <currency-factor-table
    [actionButtons]="reqEditPermission | accessAllowed"
    [data]="currencyFactors$"
    [dataColumns]="dataColumns"
    [i18nBase]="'currencyfactor'"
    (editEvent)="onEditCurrencyFactor($event)">
  </currency-factor-table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions"
                 (page)="handlePageChange($event)" [length]="resultLength">
  </mat-paginator>
</div>
