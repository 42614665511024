<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>
    <span *ngIf="contractId">{{
      'invoice-transaction.dialog.header.contract'| translate :{param0: invoiceIdent, param1: contractId}
      }}</span>
    <span *ngIf="!contractId">{{
      'invoice-transaction.dialog.header.fleet'| translate :{param0: invoiceIdent, param1: fleetId}
      }}</span>
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <invoice-transaction-table
    [dataColumns]="dataColumns"
    [i18nBase]="'invoice-transaction.tableHeader'"
    [data]="observableOfTransactions()"
    [actionButtons]="true">
  </invoice-transaction-table>

  <mat-dialog-actions>
    <button cancelAction mat-raised-button class="dialogueButton dialogueButtonRight"
            (click)="onCancel()">
      {{'button.cancel' | translate}}
    </button>
    <button okAction mat-raised-button class="dialogueButton startReimbursementProcessBtn"
            [disabled]="!allInputsValid()"
            (click)="onStart()">
      {{'transaction.dialog.start.reimbursement' | translate}}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
