/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginatorIntlService extends MatPaginatorIntl implements OnDestroy {
  public static readonly ITEM_PER_PAGE = 'paginator.itemsPerPage';
  public static readonly NEXT_PAGE = 'paginator.nextPage';
  public static readonly PREVIOUS_PAGE = 'paginator.previousPage';
  public static readonly RANGE = 'paginator.range';
  public static readonly LAST_PAGE = 'paginator.lastPage';
  public static readonly FIRST_PAGE = 'paginator.firstPage';

  private rangeLabelIntl: string;
  public langChangeSub: Subscription;
  public translatorSub: Subscription;

  constructor(private translateService: TranslateService, private translateParser: TranslateParser) {
    super();
    this.getTranslations();
    this.langChangeSub = this.translateService.onLangChange.subscribe((e: Event) => {
      console.log('fetching paginator translations for language: ', this.translateService.currentLang);
      this.getTranslations();
    });
  }

  public ngOnDestroy(): void {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  public getTranslations(): void {
    if (this.translatorSub) {
      this.translatorSub.unsubscribe();
    }
    this.translatorSub = this.translateService.get([
      PaginatorIntlService.ITEM_PER_PAGE,
      PaginatorIntlService.FIRST_PAGE,
      PaginatorIntlService.LAST_PAGE,
      PaginatorIntlService.PREVIOUS_PAGE,
      PaginatorIntlService.RANGE,
      PaginatorIntlService.NEXT_PAGE,
    ]).subscribe((translation) => {
      this.itemsPerPageLabel = translation[PaginatorIntlService.ITEM_PER_PAGE];
      this.nextPageLabel = translation[PaginatorIntlService.NEXT_PAGE];
      this.previousPageLabel = translation[PaginatorIntlService.PREVIOUS_PAGE];
      this.rangeLabelIntl = translation[PaginatorIntlService.RANGE];
      this.firstPageLabel = translation[PaginatorIntlService.FIRST_PAGE];
      this.lastPageLabel = translation[PaginatorIntlService.LAST_PAGE];
      this.changes.next();
    });
  }

  public getRangeLabel = (page, pageSize, length) => {
    length = Math.max(length, 0);
    let startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    startIndex = startIndex + 1;
    return this.translateParser.interpolate(this.rangeLabelIntl, {startIndex, endIndex, length});
  }
}
