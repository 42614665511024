/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnDestroy } from '@angular/core';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { PermissionAware } from '../permission-aware';
import { AccessRights } from '../datatypes/access-rights.enum';
import { BehaviorSubject, firstValueFrom, interval, Subscription, switchMap, takeWhile } from 'rxjs';
import { Message } from '../datatypes/message';
import { ElectricVehicle, ElectricVehicleColumns } from '../datatypes/electric-vehicle';
import { VehicleService } from '../services/http/vehicle.service';
import { ElectricVehicleFilter } from '../datatypes/electric-vehicle-filter';
import { SharedDataService } from '../services/shared-data.service';
import * as XLSX from 'xlsx';
import { WebConfigRouterService } from '../services/web-config-router.service';
import { ActivatedRoute } from '@angular/router';
import { VinCheckResult, VinCheckResultColumns } from '../datatypes/vin-check-result';
import { ElectricVehicleStatus } from '../datatypes/electric-vehicle-status.enum';
import { ReadJobResponseDto } from '../datatypes/ReadJobResponseDto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pcs-electric-vehicle',
  templateUrl: './electric-vehicle.component.html',
  styleUrls: ['./electric-vehicle.component.css']
})
export class ElectricVehicleComponent extends AbstractNotificationHandler implements PermissionAware, OnDestroy {
  public readonly reqEditPermission = [AccessRights.VEHICLE_EDIT_WEB];
  public readonly evColumns: string[] = Object.values(ElectricVehicleColumns);
  private readonly MAX_LENGTH = 1000;

  public evsSearchResultSubject = new BehaviorSubject<ElectricVehicle[]>([]);
  public evSearchResult$ = this.evsSearchResultSubject.asObservable();

  public loading = false;
  public checkingVins = false;

  public currentFilter: ElectricVehicleFilter = new ElectricVehicleFilter();
  public resultLength = 0;
  private readonly ROW_NUM: string = '__rowNum__';
  private fileReader: FileReader = new FileReader();
  private vinListFileName = '';
  public readJobStateSub: Subscription;

  constructor(
    private electricVehicleService: VehicleService,
    private dataService: SharedDataService,
    private router: WebConfigRouterService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    super();
  }

  public ngOnDestroy(): void {
    this.unsubscribeReadJobStateSub();
  }

  public async applyFilter(filter: ElectricVehicleFilter): Promise<void> {
    console.log('Received electric vehicle filter: ', filter);
    this.currentFilter = JSON.parse(JSON.stringify(filter)); // clone it to avoid mutation
    await this.getEVsByFilter(filter);
  }

  private async getEVsByFilter(filter: ElectricVehicleFilter): Promise<void> {
    this.loading = true;
    console.log('requesting evs by filter: ', this.currentFilter);
    try {
      const evs = await firstValueFrom(this.electricVehicleService.getEVsByFilter(filter));
      this.handleForFilterResult(evs);
    } finally {
      this.loading = false;
    }
  }

  public checkVins(dataList: unknown[], offset: number, maxLength: number, resultList: VinCheckResult[]): void {
    let end = offset + maxLength;
    if (end >= dataList.length) {
      end = dataList.length;
    }
    const subDataList: unknown[] = dataList.slice(offset, end);
    console.log('Checking vins: start entry: %s, end entry: %s', offset, end - 1);
    console.log('sub data list length: ', subDataList.length);
    this.electricVehicleService.checkVinsForContract(subDataList).subscribe({
      next: (subResultList) => {
        console.log('got %d results', subResultList.length);
        resultList.push(...subResultList);
        if (subDataList.length === maxLength && dataList.length !== end) {
          this.checkVins(dataList, end, maxLength, resultList);
        } else {
          resultList = resultList.sort((a, b) => a.row - b.row);
          this.checkingVins = false;
          this.dataService.updateVinCheckResult(resultList);
          this.router.navigate(['./vin-check-result'], {
            queryParams: { file: this.vinListFileName },
            relativeTo: this.activatedRoute
          });
        }
      },
      error: () => (this.checkingVins = false)
    });
  }

  public async onUpdatePcid(evToUpdate: ElectricVehicle): Promise<void> {
    console.log('Got pcid update request for ev: ', evToUpdate);
    try {
      await firstValueFrom(this.electricVehicleService.updateElectricVehicle(evToUpdate));
      this.dataService.updateEvUpdateResult(true);
      await this.getEVsByFilter(this.currentFilter);
    } catch (exception) {
      this.dataService.updateEvUpdateResult(false);
    } finally {
      await this.getEVsByFilter(this.currentFilter);
    }
  }

  public async onRevokePnCCert(ev: ElectricVehicle): Promise<void> {
    try {
      await firstValueFrom(this.electricVehicleService.revokePnCCert(ev));
      this.dataService.updateEvUpdateResult(true);
      await this.getEVsByFilter(this.currentFilter);
    } catch (exception) {
      this.dataService.updateEvUpdateResult(false);
    } finally {
      await this.getEVsByFilter(this.currentFilter);
    }
  }

  public onVinListFileUpload(file: File): void {
    this.checkingVins = true;
    console.log('Importing vin list from file "%s"', file.name);
    this.vinListFileName = file.name;
    this.setupVinListFileReader();
    this.fileReader.readAsBinaryString(file);
  }

  private handleForFilterResult(evs: ElectricVehicle[]): void {
    this.resultLength = evs.length;
    const evList = evs.map((ev) => {
      if (ev.readJobRequestId) {
        ev.pncCertificateValidity = this.getPendingPncValidityTranslation();
      }
      return ev;
    });
    this.evsSearchResultSubject.next(evList);
    this.checkCertReadJobState();
    if (evs.length <= 0) {
      const msg = new Message();
      msg.message = 'electricVehicle.noRecordsFound';
      this.showInfo(msg);
    }
  }

  private setupVinListFileReader(): void {
    this.fileReader.onload = (): void => {
      const timer = 'parse-excel';
      // eslint-disable-next-line no-console
      console.time(timer);
      const data = this.fileReader.result;
      const workBook = XLSX.read(data, { type: 'binary' });
      const firstSheet = workBook.Sheets[workBook.SheetNames[0]];
      const dataAsJson = XLSX.utils.sheet_to_json(firstSheet);
      const dataList = this.validateAndFetchDataWithRowNumber(dataAsJson);
      console.log('data to send to backend: ', dataList);
      // eslint-disable-next-line no-console
      console.timeEnd(timer);
      if (dataList && dataList.length > 0) {
        this.checkVins(dataList, 0, this.MAX_LENGTH, []);
      } else {
        this.checkingVins = false;
      }
    };
  }

  /**
   * XLSX provides row number as non-enumerable property, so to access it and send it to backend, we need to do a bit of magic!
   * @param dataAsJson the json data directly fetched from the excel file using XLSX.utils.sheet_to_json
   * @private
   */
  public validateAndFetchDataWithRowNumber(dataAsJson: unknown[]): unknown[] {
    const dataList = [];
    if (!dataAsJson || dataAsJson.length < 1) {
      this.showError(new Message('validation.excel.empty'));
      return dataList;
    }
    const props = Object.getOwnPropertyNames(dataAsJson[0]);
    console.log('all properties (including hidden): ', props);

    if (!this.containsVinCol(props)) {
      this.showError(new Message('validation.excel.missingHeader', VinCheckResultColumns.VIN));
      return dataList;
    }

    dataAsJson.forEach((obj) => {
      const temp = {};
      props.forEach((prop) => {
        if (prop === this.ROW_NUM) {
          temp[VinCheckResultColumns.ROW] = obj[prop] + 1; // first row was header
        } else {
          temp[prop.trim()] = obj[prop];
        }
      });
      dataList.push(temp);
    });
    return dataList;
  }

  private containsVinCol(props: string[]): boolean {
    for (const prop of props) {
      if (prop.trim() === VinCheckResultColumns.VIN) {
        return true;
      }
    }
    return false;
  }

  public getFileReader(): FileReader {
    return this.fileReader;
  }

  private checkCertReadJobState(): void {
    console.log('Checking read job state');
    const electricVehicleWithReadJob = this.evsSearchResultSubject.value.find(
      (ev) => ev.status === ElectricVehicleStatus.ACTIVE && ev.readJobRequestId
    );
    if (!electricVehicleWithReadJob) {
      return;
    }
    const requestInterval = 2000; // Time in milliseconds between requests

    const httpIntervalObservable = interval(requestInterval).pipe(
      switchMap((value) => {
        this.updateReadJobPendingMessage(electricVehicleWithReadJob, value);
        return this.electricVehicleService.retrieveKFISReadState(
          electricVehicleWithReadJob.vin,
          electricVehicleWithReadJob.readJobRequestId
        );
      }),
      takeWhile((response) => this.jobStatusIsReadPending(response.jobStatus), true)
    );

    this.readJobStateSub = httpIntervalObservable.subscribe((response) => {
      if (!this.jobStatusIsReadPending(response.jobStatus)) {
        this.updateEvData(electricVehicleWithReadJob, response);
      }
    });
  }

  private updateEvData(electricVehicle: ElectricVehicle, readJobResponse: ReadJobResponseDto): void {
    console.log('Updating ev data with the read-state response: ', readJobResponse);
    const evList = this.evsSearchResultSubject.value.map((ev) => {
      if (ev.readJobRequestId === readJobResponse.requestId && ev.id === electricVehicle.id) {
        if (this.jobStatusIsReadPending(readJobResponse.jobStatus)) {
          ev.pncCertificateValidity = this.getPendingPncValidityTranslation();
        } else {
          ev.pncCertificateValidity = readJobResponse.pncCertificateValidity;
        }
        ev.errorMessage = readJobResponse.errorMessage;
        ev.jobStatus = readJobResponse.jobStatus;
      }
      return ev;
    });
    this.evsSearchResultSubject.next(evList);
  }

  private jobStatusIsReadPending(jobStatus: string): boolean {
    console.log('Checking job status: ', jobStatus);
    return jobStatus && jobStatus.includes('READ_PENDING');
  }

  private unsubscribeReadJobStateSub(): void {
    if (this.readJobStateSub) {
      this.readJobStateSub.unsubscribe();
    }
  }

  private updateReadJobPendingMessage(electricVehicleWithReadJob: ElectricVehicle, currentAttempt: number): void {
    const evList = this.evsSearchResultSubject.value.map((ev) => {
      if (ev.id === electricVehicleWithReadJob.id) {
        ev.pncCertificateValidity = this.getPendingPncValidityTranslation() + '..'.repeat(currentAttempt % 5);
      }
      return ev;
    });
    this.evsSearchResultSubject.next(evList);
  }

  private getPendingPncValidityTranslation(): string {
    return this.translateService.instant('electricVehicle.pending.pncCertificateValidity');
  }
}
