import { Directive, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[matInput]'
})
export class PcsTrimMatInputValueDirective {
  constructor(@Optional() public ngControl: NgControl) {}

  @HostListener('change', ['$event.target'])
  public onInput(input: HTMLInputElement): void {
    // matInput might not be attached to any formControl (e.g. an ImportFormComponent)
    if (!this.ngControl || typeof this.ngControl.value !== 'string') {
      return;
    }
    const trimmedValue = input.value.trim();
    if (input.value !== trimmedValue) {
      this.ngControl.control.setValue(trimmedValue);
    }
  }
}
