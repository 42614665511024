<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div id="purchaseConditions">
  <div *ifAnyPerm="reqEditPermission">
    <mat-toolbar class="headerToolbar mat-elevation-z1">
      <span class="headerSpan">{{ "purchaseConditions.tariff.import.header" | translate }}</span>
      <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="importing"></mat-spinner>
    </mat-toolbar>
    <div class="mat-elevation-z1">
      <pcs-tariff-import (importEventWithArguments)="importTariffs($event)"
                         [disabled]="importing"
                         [importAllowed]="reqEditPermission |accessAllowed"></pcs-tariff-import>
    </div>
  </div>
  <mat-divider class="section-divider"></mat-divider>
  <div>
    <mat-toolbar class="headerToolbar mat-elevation-z1">
      <span class="headerSpan">{{ "purchaseConditions.header" | translate }}</span>
      <button mat-raised-button class="headerActionBtn icon-only" (click)="addNewPurchaseCondition()"
              *ifAnyPerm="reqEditPermission" [disabled]="importing">
        <mat-icon>add</mat-icon>
      </button>
      <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
    </mat-toolbar>
  </div>
  <div class="pc-table mat-elevation-z1">
    <mat-accordion>
      <mat-expansion-panel hideToggle expanded="true">
        <pcs-purchase-conditions-toggle-group
          (filterSelectionEvent)="handleFilterChange($event)"></pcs-purchase-conditions-toggle-group>

        <active-purchase-conditions-table *ngIf="selectedFilter==='ACTIVE_PURCHASE_CONDITIONS'"
                                          [data]="purchaseConditions$"
                                          [dataColumns]="purchaseConditionsTableColumns"
                                          i18nBase="purchaseConditions">
        </active-purchase-conditions-table>

        <purchase-conditions-table *ngIf="selectedFilter==='OVERVIEW'" [data]="purchaseConditions$"
                                   [dataColumns]="purchaseConditionsTableColumns"
                                   i18nBase="purchaseConditions"
                                   [actionButtons]="true"
                                   [disableEdit]="!(reqEditPermission |accessAllowed)"
                                   [disableDelete]="!(reqEditPermission |accessAllowed)"
                                   (editEvent)="onEditPurchaseCondition($event)"
                                   (readEvent)="onReadPurchaseCondition($event)"
                                   (deleteEvent)="onDeletePurchaseCondition($event)"
                                   (templateEvent)="onCreateByTemplate($event)"
                                   [disableTableBtns]="importing">
        </purchase-conditions-table>
        <mat-paginator *ngIf="selectedFilter==='OVERVIEW'"
                       [pageSizeOptions]="pageSizeOptions"
                       (page)="handlePageChange($event)" [length]="resultLength">
        </mat-paginator>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

