/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {InvoiceAdminDto} from '../../datatypes/invoices/InvoiceAdminDto';
import {InvoiceFilterDto} from '../../datatypes/invoices/InvoiceFilterDto';
import {ReimbursementRequestDto} from '../../datatypes/invoices/ReimbursementRequestDto';
import {ReimbursementBilledItemDto} from '../../datatypes/invoices/ReimbursementBilledItemDto';
import {OrderItemDto} from "../../datatypes/invoices/OrderItemDto";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public retrieveInvoices(filter: InvoiceFilterDto, offset, pageSize): Observable<InvoiceAdminDto[]> {
    return this.http.post<InvoiceAdminDto[]>(environment.invoiceEndpoint + '/search', filter, {
      headers: this.headers,
      params: {
        offset,
        pageSize
      }
    });
  }

  public retrieveTransactionsForInvoice(contractId: string, invoiceIdent: string): Observable<ReimbursementBilledItemDto[]> {
    const url = environment.invoiceEndpoint + '/' + contractId + '/billeditems';
    const options = {
      params: {
        invoiceIdent
      },
      headers: this.headers
    };
    return this.http.get<ReimbursementBilledItemDto[]>(url, options);
  }

  public startReimbursementProcess(invoiceId: number, requestDto: ReimbursementRequestDto): Observable<ReimbursementBilledItemDto[]> {
    const url = environment.invoiceEndpoint + '/' + invoiceId + '/start-reimbursement';

    return this.http.post<ReimbursementBilledItemDto[]>(url, requestDto, {
      headers: this.headers
    });
  }

  public validateReimbursementProcess(invoiceId: number, requestDto: ReimbursementRequestDto): Observable<void> {
    const url = environment.invoiceEndpoint + '/' + invoiceId + '/validate/start-reimbursement';

    return this.http.post<void>(url, requestDto, {
      headers: this.headers
    });
  }

  public searchReimbursementRequests(invoiceIdent: string): Observable<string[]> {
    const url = environment.invoiceEndpoint + '/search-reimbursement-request';
    const options = {
      params: {
        invoiceIdent
      },
      headers: this.headers
    };
    return this.http.get<string[]>(url, options);
  }

  public removeCdrFromBillrun(cdrId: number, comment: string): Observable<void> {
    const url = environment.invoiceEndpoint + '/cdr/' + cdrId;
    const options = {
      headers: this.headers,
      body: {comment}
    };
    return this.http.delete<void>(url, options);
  }

  public validateCdrRemovalFromBillrun(cdrId: number): Observable<void> {
    const url = environment.invoiceEndpoint + '/cdr/validateremoval/' + cdrId;
    const options = {
      headers: this.headers
    };
    return this.http.get<void>(url, options);
  }

  public retrieveOrderItems(contractId: string, invoiceIdent: string): Observable<OrderItemDto[]> {
    const url = environment.invoiceEndpoint + '/' + contractId + '/orderitems';
    const options = {
      headers: this.headers,
      params: {
        invoiceIdent
      },
    };
    return this.http.get<OrderItemDto[]>(url, options);
  }

  public validateOrderItemsUpdate(invoiceId: number, orderItems: OrderItemDto[]): Observable<void> {
    const url = environment.invoiceEndpoint + '/' + invoiceId + '/validate/send-for-correction';
    return this.http.post<void>(url, orderItems, {
      headers: this.headers
    });
  }
}
