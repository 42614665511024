/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Fields } from '../global/fields.enum';
import { LocationDtoAttributeName } from '../datatypes/location/location-dto-attribute-name.enum';
import { ChargepointDtoAttributeName } from '../datatypes/location/chargepoint/chargepoint-attribute-name.enum';

export class TableConfigurations {
  public static readonly fieldLimit: Map<string, number> = new Map([[Fields.COMMENT, 27]]);

  public static readonly tooltipEligibleFields = [Fields.COMMENT];

  /*
    Location Level:
      Name, street, house number, city, postal code, country code, hotline, latitude, longitude
  */
  public static readonly editableLocationAttributes: string[] = [
    LocationDtoAttributeName.NAME,
    LocationDtoAttributeName.STREET,
    LocationDtoAttributeName.HOUSE_NO,
    LocationDtoAttributeName.CITY,
    LocationDtoAttributeName.POSTAL_CODE,
    LocationDtoAttributeName.COUNTRY_CODE,
    LocationDtoAttributeName.HOTLINE,
    LocationDtoAttributeName.LATITUDE,
    LocationDtoAttributeName.ACCESS_LATITUDE,
    LocationDtoAttributeName.LONGITUDE,
    LocationDtoAttributeName.ACCESS_LONGITUDE,
    LocationDtoAttributeName.GEO_POSITION_STATUS,
    LocationDtoAttributeName.ADMIN_COMMENTS,
    LocationDtoAttributeName.STATE
  ];

  public static readonly singleColumnLocAttributes: string[] = [LocationDtoAttributeName.ADMIN_COMMENTS];

  public static readonly locAttributesToHide: string[] = [];

  /*
    Charge point Level;
    floor, visibilityAttribute, charge point class, accessibility, comments, (freebie charging), plug type,
    the authentication mode, Adhoc_Capable and max power.
   */
  public static readonly editableChargepointAttributes: string[] = [
    ChargepointDtoAttributeName.FLOOR,
    ChargepointDtoAttributeName.VISIBILITY,
    ChargepointDtoAttributeName.CHARGEPOINT_CLASS,
    ChargepointDtoAttributeName.ACCESS,
    ChargepointDtoAttributeName.FREEBIE_CHARGING,
    ChargepointDtoAttributeName.ADHOC_CAPABLE,
    ChargepointDtoAttributeName.PLUGS,
    ChargepointDtoAttributeName.AUTH_MODES,
    ChargepointDtoAttributeName.ADMIN_COMMENTS,
    ChargepointDtoAttributeName.MAX_POWER,
    ChargepointDtoAttributeName.CHARGING_CATEGORY
  ];

  public static readonly cpAttributesToHide: string[] = [
    ChargepointDtoAttributeName.EN_ADDITIONAL_INFORMATION,
    ChargepointDtoAttributeName.EVSE_ID
  ];

  public static readonly platformCommonAttributes: string[] = [
    ChargepointDtoAttributeName.ADMIN_COMMENTS,
    LocationDtoAttributeName.ADMIN_COMMENTS,
    ChargepointDtoAttributeName.ADMIN_LAST_UPDATED_DATE,
    LocationDtoAttributeName.ADMIN_LAST_UPDATED_DATE,
    ChargepointDtoAttributeName.ADMIN_LAST_UPDATED_BY,
    LocationDtoAttributeName.ADMIN_LAST_UPDATED_BY
  ];
}
