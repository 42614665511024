<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-form-field appearance="outline" [formGroup]="parentFormGroup">
  <mat-label>{{'purchaseConditions.cpo' | translate}}</mat-label>
  <input type="text" matInput formControlName="cpo" required [matAutocomplete]="autocompleteCpo">
  <mat-autocomplete #autocompleteCpo [displayWith]="cpoDisplayText">
    <mat-option *ngFor="let cpo of filteredCpoList | async" [value]="cpo">
      {{cpo.cpoCode}} [{{cpo.name}}]
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
