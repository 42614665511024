/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {GroupUser} from './group-user';

export const USER_GROUP_DATA_TABLE_COLUMNS = ['name'];

export interface UserGroup {
  id: number;
  name: string;
  rights: string[];
  users: GroupUser[];
  hasActiveChangeRequest?: boolean;
}
