/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {TaxRecord} from '../datatypes/taxrecord';
import {TaxService} from '../services/http/tax.service';
import {BehaviorSubject, firstValueFrom, of} from 'rxjs';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {PermissionAware} from '../permission-aware';
import {AccessRights} from '../datatypes/access-rights.enum';
import {Message} from '../datatypes/message';
import {EditTaxComponent} from './edit-tax/edit-tax.component';
import {MatDialog} from '@angular/material/dialog';
import {PurchaseConditionService} from '../services/http/purchase-condition.service';
import {catchError, finalize, tap} from "rxjs/operators";
import {WithLoadingSpinner} from "pcs-commons/components";

@Component({
  selector: 'pcs-my-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.css']
})
export class TaxesComponent extends AbstractNotificationHandler implements OnInit, PermissionAware {
  public readonly reqEditPermission: AccessRights[] = [AccessRights.PRODUCT_EDIT_WEB];

  public currencies: string[];
  public dataColumns = TaxRecord.dataColumns;
  public taxesResultSubject = new BehaviorSubject<TaxRecord[]>([]);
  public taxesResult$ = this.taxesResultSubject.asObservable();

  constructor(
    private taxService: TaxService,
    private purchaseConditionService: PurchaseConditionService,
    private dialog: MatDialog) {
    super();
  }

  @WithLoadingSpinner()
  public async getTaxes(): Promise<void> {
    const taxData = await firstValueFrom((this.taxService.getTaxRecords()));
    this.taxesResultSubject.next(taxData.data);
  }

  @WithLoadingSpinner()
  public async getCurrencies(): Promise<void> {
    const currencies = await firstValueFrom(this.purchaseConditionService.getCurrencies());
    this.currencies = currencies.map(currency => currency.name)
  }

  public async ngOnInit(): Promise<void> {
    await this.getTaxes();
    await this.getCurrencies();
  }

  public onEditTax(taxesToEdit: TaxRecord[]): void {
    const dialogRef = this.dialog.open(
      EditTaxComponent,
      {
        width: '1000px',
        data: {taxesToEdit: taxesToEdit, currencyList: this.currencies, country: taxesToEdit[0].country}
      }
    );
    dialogRef.afterClosed().subscribe(
      async isSuccess => {
        if (isSuccess) {
          const msg = new Message();
          msg.message = 'taxes.edit.successful';
          msg.param0 = taxesToEdit[0].country;
          this.showInfo(msg);
          await this.getTaxes();
        }
      });
  }
}
