/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {BasicFeeTableColumns} from '../../datatypes/basicfee-record';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'pcs-basic-fee-table',
  templateUrl: './basic-fee-table.component.html',
  styleUrls: [
    '../price-table-styles.css',
    '../data-table.component.css',
    '../action-column-style.css',
    './basic-fee-table.component.css']
})
export class BasicFeeTableComponent extends OptimizedTableBaseComponent {
  public readonly COUNTRY_COLUMN = BasicFeeTableColumns.COUNTRY;
  public readonly VALID_UNTIL = BasicFeeTableColumns.VALID_UNTIL;
  public readonly VALID_FROM = BasicFeeTableColumns.VALID_FROM;
  public readonly columnsRequiredTranslation: string[] = [this.COUNTRY_COLUMN, this.VALID_FROM, this.VALID_UNTIL];

  constructor(renderer: Renderer2, private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public requiresTranslation(col: string): boolean {
    return this.columnsRequiredTranslation.includes(col);
  }

  public getDateTimeTranslation(value: string): string {
    return this.translateHelper.translateDateTimeUTCSafe(value);
  }
}
