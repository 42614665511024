<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="scrollable-table-container">
  <mat-table [dataSource]="tableDataSource" class="padding-lr-24">
    <ng-container *ngFor="let col of dataColumns; let columnIndex = index">

      <ng-container *ngIf="!colsWithCustomColDef.includes(col)" matColumnDef="{{ col }}">
        <mat-header-cell
          *matHeaderCellDef
          [styledColumn]="col"
          [ngClass]="[userLoginColumn, queryColumn].includes(col) ? 'filter-column-header-cell' : ''"
        >
          {{ getI18nBase(col) | translate }}
          <pcs-input-column-filter
            *ngIf="[userLoginColumn, queryColumn].includes(col)"
            [styledColumn]="col"
            (handleInputChange)="handleFilterValueChange(col, $event)"
          />
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [styledColumn]="col" class="center queryHistoryTableCell">
          <div>{{ element[col] }}</div>
        </mat-cell>
      </ng-container>
    </ng-container>


    <ng-container matColumnDef="{{ timestampColumn }}">
      <mat-header-cell *matHeaderCellDef class="center timestamp-cell filter-column-header-cell">
        {{ getI18nBase(timestampColumn) | translate }}
        <pcs-date-range-column-filter
          class="timestamp-cell"
          (handleInputChange)="handleDateRangeValueChange($event)"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center timestamp-cell queryHistoryTableCell">
        <div>{{ element.timestamp | translateDateTime: updated }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ resultCountColumn }}">
      <mat-header-cell *matHeaderCellDef class="center result-count-cell">
        {{ getI18nBase(resultCountColumn) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center result-count-cell queryHistoryTableCell">
        <div>{{ element.resultCount }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ successfulColumn }}">
      <mat-header-cell *matHeaderCellDef class="center successful-cell filter-column-header-cell">
        {{ getI18nBase(successfulColumn) | translate }}
        <pcs-icon-column-filter
          class="successful-cell"
          [options]="successfulColumnValues"
          (handleSelectChange)="handleFilterValueChange(successfulColumn, $event)"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center successful-cell queryHistoryTableCell">
        <mat-icon *ngIf="element.successful" matTooltip="{{
          'query-history.status.true'| translate
          }}">done
        </mat-icon>
        <mat-icon *ngIf="!element.successful" matTooltip="{{
          'query-history.status.false'| translate
          }}">close
        </mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>

  </mat-table>

</div>
