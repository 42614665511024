import {Component, Renderer2} from '@angular/core';
import {TranslationHelperService} from "pcs-commons/http";
import {PaginatedTableBaseComponent} from "pcs-commons/data-tables";

@Component({
  selector: 'pcs-enrollment-ea-ec-table',
  templateUrl: './enrollment-ea-ec-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css', './enrollment-ea-ec-table.component.css']
})
export class EnrollmentEaEcTableComponent extends PaginatedTableBaseComponent {
  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public translateDate(date: string): string {
    return this.translateHelper.translateDateTimeSafe(date);
  }
}
