<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-form-field [appearance]="appearance? appearance: 'outline'" *ngIf="inputControl && label && optionList"
                [class.fullWidth]="fullWidth" >
  <mat-label>{{label | translate}}</mat-label>
  <input type="text" matInput [formControl]="inputControl" [matAutocomplete]="auto" [required]="required">
    <mat-autocomplete #auto autoActiveFirstOption [displayWith]="getDisplayText.bind(this)">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{getDisplayText(option)}}
      </mat-option>
    </mat-autocomplete>
  <mat-error *ngIf="inputControl.invalid">
    {{inputControl | validationMessage | async}}
  </mat-error>
</mat-form-field>
