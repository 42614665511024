/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Identifiable} from 'pcs-commons/datatypes';

export class PurchasePriceComponent extends Identifiable {
  public type: string;
  public price: number;
  public stepSize: number;
}

export enum PurchasePriceComponentAttributes {
  TYPE = 'type',
  PRICE = 'price',
  STEP_SIZE = 'stepSize'
}
