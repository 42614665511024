/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {FleetAddress} from './fleet-address';

export class FleetAddressList {
  public billingAddress: FleetAddress;
  public shippingAddress: FleetAddress;
  public useBillingAsShippingAddress: boolean;
}

export enum FleetAddressListAttributes {
  BILLING_ADDRESS = 'billingAddress',
  SHIPPING_ADDRESS = 'shippingAddress',
  USE_BILLING_AS_SHIPPING_ADDRESS = 'useBillingAsShippingAddress'
}
