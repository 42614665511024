/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Operator } from '../datatypes/operator';
import { ChangeRequestDto } from '../datatypes/audit-flow/change-request-dto';
import { CDRBillingDto } from '../datatypes/CDRBillingDto';

@Injectable()
export class ChangeRequestSharedDataService {
  private changeRequestDataSource = new BehaviorSubject<ChangeRequestDto>(null);
  private currentChangeRequestData$ = this.changeRequestDataSource.asObservable();

  private cpoDataSource = new BehaviorSubject<Operator>(null);
  private currentCpoData$ = this.cpoDataSource.asObservable();

  private cdrDataSource = new BehaviorSubject<CDRBillingDto>(null);
  private currentCdrData$ = this.cdrDataSource.asObservable();

  public updateCurrentCpo(newCpo: Operator): void {
    console.log('updating cpo: ', newCpo);
    this.cpoDataSource.next(newCpo);
  }

  public updateChangeRequestData(changeRequest: ChangeRequestDto): void {
    console.log('updating changeRequest data: ', changeRequest);
    this.changeRequestDataSource.next(changeRequest);
  }

  public updateCurrentCdr(newCdr: CDRBillingDto): void {
    console.log('updating cdr: ', newCdr);
    this.cdrDataSource.next(newCdr);
  }

  public clearChangeRequestSharedDataService(): void {
    this.updateChangeRequestData(null);
    this.updateCurrentCpo(null);
  }

  public getCurrentCpoObservable(): Observable<Operator> {
    return this.currentCpoData$;
  }

  public getCurrentChangeRequestObservable(): Observable<ChangeRequestDto> {
    return this.currentChangeRequestData$;
  }

  public getCurrentCdrObservable(): Observable<CDRBillingDto> {
    return this.currentCdrData$;
  }
}
