/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Operator } from '../datatypes/operator';
import { ChargepointService } from './http/chargepoint.service';
import { firstValueFrom } from 'rxjs';
import { SharedDataService } from './shared-data.service';
import { inject } from '@angular/core';

export async function operatorResolver(): Promise<void> {
  await getOperators(inject(ChargepointService), inject(SharedDataService));
}

async function getOperators(chargepointService: ChargepointService, dataService: SharedDataService): Promise<void> {
  let fetchMore = true;
  let offset = 0;
  const pageSize = 500;
  let operatorList: Operator[] = [];
  while (fetchMore) {
    const operators = await firstValueFrom(chargepointService.getOperators(offset, pageSize + 1));
    fetchMore = operators.length > pageSize;
    offset += pageSize;
    operators.splice(operators.length - 1, 1);
    operatorList = operatorList.concat(operators);
  }
  dataService.updateCurrentCpoList(operatorList);
}
