/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, ElementRef, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserDataSourceService} from "../services/user-data-source.service";
import {AccessRights} from "../datatypes/access-rights.enum";
import {Role} from "../datatypes/role.enum";
import {Subscription} from "rxjs";
import {UserAuthDetails} from "../datatypes/user-auth-details";

@Directive({
  selector: '[adminOnly]'
})
export class AdminOnlyDirective implements OnDestroy {

  private toUnsub: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private el: ElementRef,
    private userDataSource: UserDataSourceService) {
    this.toUnsub.push(this.userDataSource.currentUserAuthDetails$.subscribe(authDetails => this.checkPermission(authDetails)))
  }

  public ngOnDestroy(): void {
    this.toUnsub.forEach(s => s.unsubscribe());
  }


  protected checkPermission(authDetails: UserAuthDetails): void {
    console.log('checking if user belong to admin group');
    if (!authDetails || !authDetails.rights || !this.hasAdminPermission(authDetails.rights)) {
      console.log('user does not belong to admin group! \nHiding element: ', this.el ? this.el : this.templateRef);
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  public hasAdminPermission(currentUserRights: AccessRights[]): boolean {
    return Role.PCS_ADMIN === this.userDataSource.currentUserRole &&
      (currentUserRights.includes(AccessRights.ADMIN_WEB) || Object.values(AccessRights).every(elm => currentUserRights.includes(elm)));
  }
}
