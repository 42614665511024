<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-accordion>
  <mat-expansion-panel #filterExpansionPanel (closed)="onExpansionPanelClose()"
                       [expanded]="true">
    <mat-expansion-panel-header>
      <div class="expansion-panel-header">
        <span class="headerSpan headerToolbarSub">{{'chargepoints.filter.header' | translate}}</span>
        <filter-toggle-group (filterSelectionEvent)="handleFilterChange($event)"></filter-toggle-group>
        <mat-spinner *ngIf="loading" [diameter]="30" [strokeWidth]="5" class="toolbarSpinner"></mat-spinner>
      </div>
    </mat-expansion-panel-header>
    <chargepoint-filter-form *ngIf="displayEvent$ && currentFilterType === 'DEFAULT'"
                             [displayTrigger$]="displayEvent$"></chargepoint-filter-form>
    <unused-chargepoint-filter-form *ngIf="displayEvent$ && currentFilterType === 'UNUSED_CHARGEPOINTS'"
                                    [displayTrigger$]="displayEvent$"></unused-chargepoint-filter-form>
    <mat-action-row>
      <button (click)="onDisplayChargepoints()" class="normalButton" mat-stroked-button okAction>
        <span class="filter-button-span">{{ "chargepoints.button.filter" | translate }}</span>
        <i aria-hidden="true" class="fa fa-refresh"></i>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>
