/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export class AddressOverview {
  public ciamId: string;
  public email: string;
  public invoiceLanguage: string;
  public vatId: string;
  public company: string;
  public salutation: string;
  public name: string;
  public coName: string;
  public address: string;
  public poBox: string;
  public supplement: string;
}
