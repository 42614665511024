<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="changeRequest">
  <div class="changeRequest-info-description">
  <span>
    {{
    'changeRequest.info.description'| translate :{param0: changeRequest.comment}
    }}
  </span>
    <pcs-change-request-action-description [changeRequest]="changeRequest"></pcs-change-request-action-description>
  </div>
  <div>
    <pcs-json-diff [before]="changeRequest.originalValue" [after]="changeRequest.updatedValue"></pcs-json-diff>
  </div>

  <div class="changeRequest-info-reviewer">
        <span>{{
          'changeRequest.info.reviewer'| translate :{param0: getReviewers()}
          }}</span>
  </div>
</div>
