/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { NavMenu } from './datatypes/nav-menu';
import { BaseUrls } from './datatypes/base-urls.enum';

export class NavigationMenuStore {
  public static readonly INBOX = new NavMenu('INBOX', 'global.link.inbox', BaseUrls.INBOX, 'mail', null);
  public static readonly PRODUCTS = new NavMenu('PRODUCTS', 'global.link.products', BaseUrls.PRODUCTS, null, 'fa-product-hunt');
  public static readonly TAXES = new NavMenu('TAXES', 'global.link.taxes', BaseUrls.TAXES, null, 'fa-money');
  public static readonly CHARGEPOINTS = new NavMenu(
    'CHARGEPOINTS',
    'global.link.chargepoints',
    BaseUrls.CHARGEPOINTS,
    null,
    'fa-plug'
  );
  public static readonly CHARGEPOINT_ATTRIBUTE_IMPORT = new NavMenu(
    'CHARGEPOINT_ATTRIBUTE_IMPORT',
    'global.link.chargepointAttributeImport',
    BaseUrls.CHARGEPOINT_ATTRIBUTE_IMPORT,
    null,
    'fa-tag'
  );
  public static readonly OPERATORS = new NavMenu('OPERATORS', 'global.link.operators', BaseUrls.OPERATORS, null, 'fa-bolt');
  public static readonly CURRENCY_FACTORS = new NavMenu(
    'CURRENCY_FACTORS',
    'global.link.currencyFactors',
    BaseUrls.CURRENCY_FACTOR_OVERVIEW,
    'calculate',
    null
  );
  public static readonly PURCHASE_CONDITIONS = new NavMenu(
    'PURCHASE_CONDITIONS',
    'global.link.purchaseConditions',
    BaseUrls.PURCHASE_CONDITION_OVERVIEW,
    'account_balance_wallet',
    null
  );
  public static readonly CARD_MANAGEMENT = new NavMenu(
    'CARD_MANAGEMENT',
    'global.link.cardManagement',
    BaseUrls.CARD_MANAGEMENT,
    'branding_watermark',
    null
  );
  public static readonly FLEETS = new NavMenu('FLEETS', 'global.link.fleets', BaseUrls.FLEETS, null, 'fa-car');
  public static readonly STATISTICS = new NavMenu(
    'STATISTICS',
    'global.link.statistic',
    BaseUrls.STATISTICS,
    null,
    'fa-bar-chart'
  );
  public static readonly OCPI_PARTNERS = new NavMenu(
    'OCPI_PARTNERS',
    'global.link.ocpiPartners',
    BaseUrls.OCPI_PARTNERS,
    'account_tree',
    null
  );
  public static readonly DATABASE = new NavMenu('DATABASE', 'global.link.database', BaseUrls.DATABASE, 'layers', null);
  public static readonly CUSTOMER_INFO = new NavMenu(
    'CUSTOMER_INFO',
    'global.link.customerinfo',
    BaseUrls.CUSTOMER_INFO,
    'list',
    null
  );
  public static readonly USER_MANAGEMENT = new NavMenu(
    'USER_MANAGEMENT',
    'global.link.usermanagement',
    BaseUrls.USER_MANAGEMENT,
    'people',
    null
  );
  public static readonly CONFIGURATIONS = new NavMenu(
    'CONFIGURATIONS',
    'global.link.configurations',
    BaseUrls.CONFIGURATIONS,
    null,
    'fa-cogs'
  );
  public static readonly CDR_CLEARING_POOL = new NavMenu(
    'CDR_CLEARING_POOL',
    'global.link.cdrClearingPool',
    BaseUrls.CDR_CLEARING_POOL,
    'fact_check',
    null
  );
  public static readonly INVOICES = new NavMenu('INVOICES', 'global.link.invoices', BaseUrls.INVOICES, 'attach_money', null);
  public static readonly CDR_REVOCATION = new NavMenu(
    'CDR_REVOCATION',
    'global.link.cdr-revocation',
    BaseUrls.CDR_REVOCATION,
    'money_off_csred',
    null
  );
  public static readonly ELECTRIC_VEHICLES = new NavMenu(
    'ELECTRIC_VEHICLES',
    'global.link.electricVehicles',
    BaseUrls.ELECTRIC_VEHICLES,
    'electric_car',
    null
  );
  public static readonly IONITY_CONTRACT_DATA = new NavMenu(
    'IONITY_CONTRACT_DATA',
    'global.link.ionityContractData',
    BaseUrls.IONITY_CONTRACT_DATA,
    'article',
    null
  );
  public static readonly PLAN_HISTORY = new NavMenu(
    'PLAN_HISTORY',
    'global.link.contractHistory',
    BaseUrls.PLAN_HISTORY,
    null,
    'fa-map-marker'
  );
  public static readonly CHANGE_REQUESTS_OVERVIEW = new NavMenu(
    'CHANGE_REQUESTS_OVERVIEW',
    'global.link.changeRequests',
    BaseUrls.CHANGE_REQUESTS_OVERVIEW,
    'restore',
    null
  );
  public static readonly ENROLLMENT_OVERVIEW = new NavMenu(
    'ENROLLMENT_OVERVIEW',
    'global.link.enrollmentOverview',
    BaseUrls.ENROLLMENT_OVERVIEW,
    'description',
    null
  );
  public static readonly QUERY_HISTORY_OVERVIEW = new NavMenu(
    'QUERY_HISTORY_OVERVIEW',
    'global.link.queryHistoryOverview',
    BaseUrls.QUERY_HISTORY_OVERVIEW,
    'storage',
    null
  );

  // please keep the order! the tabs will be shown in the same order in the ui
  private static readonly NAVIGATION_MENU_LIST: NavMenu[] = [
    NavigationMenuStore.INBOX,
    NavigationMenuStore.PRODUCTS,
    NavigationMenuStore.TAXES,
    NavigationMenuStore.CHARGEPOINTS,
    NavigationMenuStore.CHARGEPOINT_ATTRIBUTE_IMPORT,
    NavigationMenuStore.OPERATORS,
    NavigationMenuStore.CURRENCY_FACTORS,
    NavigationMenuStore.PURCHASE_CONDITIONS,
    NavigationMenuStore.CARD_MANAGEMENT,
    NavigationMenuStore.STATISTICS,
    NavigationMenuStore.FLEETS,
    NavigationMenuStore.OCPI_PARTNERS,
    NavigationMenuStore.DATABASE,
    NavigationMenuStore.CDR_CLEARING_POOL,
    NavigationMenuStore.CDR_REVOCATION,
    NavigationMenuStore.INVOICES,
    NavigationMenuStore.ELECTRIC_VEHICLES,
    NavigationMenuStore.CUSTOMER_INFO,
    NavigationMenuStore.ENROLLMENT_OVERVIEW,
    NavigationMenuStore.CHANGE_REQUESTS_OVERVIEW,
    NavigationMenuStore.USER_MANAGEMENT,
    NavigationMenuStore.CONFIGURATIONS,
    NavigationMenuStore.IONITY_CONTRACT_DATA,
    NavigationMenuStore.PLAN_HISTORY,
    NavigationMenuStore.QUERY_HISTORY_OVERVIEW
  ];

  public static getAllNavMenus(): NavMenu[] {
    return this.NAVIGATION_MENU_LIST;
  }
}
