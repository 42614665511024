<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div id="changeRequests">

  <mat-toolbar class="headerToolbar mat-elevation-z1">
    <span class="headerSpan">{{'changeRequest.list' | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
  <div class="section-container mat-elevation-z1">
    <filterable-table [data]="changeRequests$"
                      [actionButtons]="true"
                      [readonly]="true"
                      [dataColumns]="dataColumns"
                      [selectColumnConfigs]="selectColumnConfigs"
                      [translateColumns]="translateColumns"
                      [nonSearchableColumns]="nonSearchableColumns"
                      [dateColumns]="dateColumns"
                      [i18nBase]="'changeRequest.tableHeader'"
                      (readEvent)="navigateToDetailsView($event)"
                      (filterEvent)="onFilterChange($event)"
                      class="padding-lr-24">
    </filterable-table>
    <mat-paginator [pageSizeOptions]="pageSizeOptions"
                   (page)="handlePageChange($event)" [length]="resultLength">
    </mat-paginator>
  </div>
</div>
