/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnInit } from '@angular/core';
import { ImportFormBaseComponent } from '../../import-form/import-form-base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Operator } from '../../datatypes/operator';
import { CpoValidatorService } from '../../validation/cpo-validator.service';
import { FormUtils } from 'pcs-commons/utils';

@Component({
  selector: 'pcs-tariff-import',
  templateUrl: './tariff-import.component.html',
  styleUrls: ['../../import-form/import-form.component.css', './tariff-import.component.css']
})
export class TariffImportComponent extends ImportFormBaseComponent implements OnInit {
  public tariffImportForm: FormGroup;
  public loading: boolean;
  public cpoForImport: Operator;
  public cpo: FormControl;
  public validFrom: FormControl;

  constructor(
    private cpoValidator: CpoValidatorService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.tariffImportForm = this.formBuilder.group({});
  }

  public ngOnInit(): void {
    this.cpo = new FormControl(null, [Validators.required, this.cpoValidator.validate.bind(this.cpoValidator)]);
    this.tariffImportForm.addControl('cpo', this.cpo);
    this.validFrom = new FormControl(new Date(), [Validators.required]);
    this.tariffImportForm.addControl('validFrom', this.validFrom);
  }

  public handleImport(): void {
    this.cpoForImport = new Operator();
    this.cpoForImport = FormUtils.getValue(this.tariffImportForm, 'cpo');
    super.handleImportWithArguments(this.cpoForImport.id, this.validFrom.value);
  }
}
