/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {HttpParams, HttpRequest} from '@angular/common/http';
import {HttpParamEncoder} from './http-param-encoder';

export class HttpRequestHelper {
  public static setHttpParamEncoder(request: HttpRequest<any>): HttpRequest<any> {
    if (!request.params) {
      return request;
    }
    console.log('Setting custom urls param encoder');

    const existingParams = request.params;
    let paramsWithCustomEncoder = new HttpParams({encoder: new HttpParamEncoder()});
    paramsWithCustomEncoder = this.cloneParams(existingParams, paramsWithCustomEncoder);

    return request.clone({params: paramsWithCustomEncoder});
  }

  private static cloneParams(from: any, to: HttpParams): HttpParams {
    if (from instanceof HttpParams) {
      from.keys().forEach(key => {
        to = to.set(key, from.get(key));
      });
    } else {
      Object.keys(from).forEach(key => {
        to = to.set(key, from[key]);
      });
    }
    return to;
  }
}
