<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-grid-list cols="3" rowHeight="50px">
  <mat-grid-tile colspan="2" *ngIf="isLoggedIn">
    <button mat-icon-button class="user-button" [matMenuTriggerFor]="userMenu">
      <mat-icon>person</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu">
      <div mat-menu-item>
        <mat-icon>person</mat-icon>
        <span>{{currentLogin}}</span>
      </div>
      <button *adminOnly mat-menu-item class="menu-button" (click)="onCloudWatchButtonClick()">
        <mat-icon>travel_explore</mat-icon>
        <span>{{'user.cloudWatch' | translate}}</span>
      </button>
      <button mat-menu-item class="menu-button" [matMenuTriggerFor]="mfaChoices">
        <mat-icon>devices</mat-icon>
        <span>{{'mfa.configure' | translate}}</span>
      </button>
      <button mat-menu-item class="menu-button" (click)="openPasswordChangeDialog()">
        <mat-icon>vpn_key</mat-icon>
        <span>{{'user.changePassword' | translate}}</span>
      </button>
      <button mat-menu-item class="menu-button" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>logout</span>
      </button>
    </mat-menu>
    <mat-menu #mfaChoices class="{{getMFAMenuClass()}}">
      <button mat-menu-item class="menu-button"
              (click)="associateTotpMFA()">{{'mfa.reAssociateTotp' | translate}}</button>
      <mat-radio-group mat-menu-item [formControl]="preferredMFA" class="menu-radio-group"
                       (change)="handlePreferredMFASelectionChange($event)">
        <mat-radio-button *ngFor="let mfaType of mfaTypes"
                          value="{{mfaType}}">{{('mfa.prefer.' + mfaType) | translate}}</mat-radio-button>
      </mat-radio-group>
    </mat-menu>
  </mat-grid-tile>
  <mat-grid-tile colspan="2" *ngIf="!isLoggedIn"></mat-grid-tile>
  <mat-grid-tile colspan="1">
    <mat-select  class="language-select" [formControl]="languageCtrl" [hideSingleSelectionIndicator]="true">
      <mat-option value="en">en</mat-option>
      <mat-option value="de">de</mat-option>
    </mat-select>
  </mat-grid-tile>
</mat-grid-list>
