/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component} from '@angular/core';
import {AbstractNotificationHandler} from "pcs-commons/notification";
import {Observable} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import {InputValidationService} from "../services/input-validation.service";
import {IonityDataService} from "../services/http/ionity-data.service";
import {IonityCharmDataDto} from "../datatypes/ionity-contract-data/ionity-charm-data-dto";
import {
  IonityCharmContractDataDialogComponent
} from "./ionity-charm-contract-data-dialog/ionity-charm-contract-data-dialog.component";

@Component({
  selector: 'pcs-ionity-contract-data',
  templateUrl: './ionity-contract-data.component.html',
  styleUrls: ['./ionity-contract-data.component.css']
})
export class IonityContractDataComponent extends AbstractNotificationHandler {
  public contractId: FormControl;
  public loading = false;

  constructor(
    private inputValidationService: InputValidationService,
    private ionityDataService: IonityDataService,
    private dialog: MatDialog
  ) {
    super();
    this.contractId = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  }

  public getError(fc: FormControl): Observable<any> {
    return this.inputValidationService.getError(fc);
  }

  public fetchData(): void {
    if (!this.contractId.valid) {
      return;
    }
    this.loading = true;
    const contractIdValue = this.contractId.value;
    this.ionityDataService.retrieveDataForContract(contractIdValue).subscribe({
      next: (data) => {
        this.loading = false;
        this.showContractData(data, contractIdValue);
      },
      error: () => this.loading = false
    });
  }

  private showContractData(data: IonityCharmDataDto, contractId: string): void {
    const dialogRef = this.dialog.open(IonityCharmContractDataDialogComponent,
      {
        width: '750px',
        data: {
          dataToShow: data,
          contractId: contractId
        }
      });
  }
}
