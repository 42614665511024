<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="(data | async)?.length" class="scrollable-table-container">
  <mat-table #table [dataSource]="tableDataSource" matSort>
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{getI18nBase(col) | translate}}
        </mat-header-cell>
        <mat-cell [styledColumn]="col" *matCellDef="let element">
          <ng-container *ngIf="col !== 'lastUpdated'">{{element[col]}}</ng-container>
          <ng-container *ngIf="col === 'lastUpdated'">{{getDateTimeTranslation(element[col])}}</ng-container>
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>


