<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div editKeyEvents>
  <div *ngIf="!viewResponse">
    <div id="actionDiv" *ngIf="isEditMode">
      <fleet-actions [fleetId]="fleet?.fleetId" [fleetStatus]="fleet?.status" (deleteEvent)="onDelete()"
                     (deactivateEvent)="onDeactivate()" (activateEvent)="onActivate()"></fleet-actions>
    </div>
    <div *ngIf="isCreateMode" class="createFleetHeader">
      <mat-toolbar class="headerToolbar">
        <span class="headerSpan">{{ 'fleets.header.create.fleet' | translate }}</span>
        <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
      </mat-toolbar>
    </div>

    <div *ngIf="basicInfoForm" id="basicInfo">
      <mat-toolbar style="font-size: 20px;">
        <span class="headerSpan">{{ 'fleet.basicinfo' | translate }}</span>
        <button id="editBasicInfo" mat-raised-button class="headerActionBtn icon-only" *ngIf="basicInfoForm?.disabled"
                (click)="toggleBasicInfoEdit()">
          <mat-icon> edit</mat-icon>
        </button>
        <div id="saveBasicInfo" *ngIf="basicInfoForm?.enabled && isEditMode">
          <button okAction mat-raised-button class="headerActionBtn icon-only"
                  (click)="onSaveBasicInfo()" [disabled]="basicInfoForm.invalid || basicInfoForm.pristine">
            <mat-icon>done</mat-icon>
          </button>
          <button cancelAction mat-raised-button class="headerActionBtn icon-only"
                  (click)="onCancelSaveBasicInfo()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <form [formGroup]="basicInfoForm" style="display: block; flex-direction: row;" *ngIf="fleet">
        <mat-form-field appearance="outline" class="infoFormField">
          <mat-label>{{ "fleets.label.name" | translate }}</mat-label>
          <input matInput required formControlName="fleetName">
          <mat-error *ngIf="fleetName.invalid">{{ fleetName | validationMessage | async }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="infoFormField">
          <mat-label>{{ "fleets.label.fleetId" | translate }}</mat-label>
          <input matInput required formControlName="fleetId">
          <mat-error *ngIf="fleetId.invalid">{{ fleetId | validationMessage | async }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="infoFormField">
          <mat-label>{{ 'fleets.label.fleetType'| translate }}</mat-label>
          <mat-select formControlName="fleetType" required>
            <mat-option *ngFor="let type of fleetTypes"
                        [value]="type">{{ 'global.fleetType.' + type | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="fleetType.invalid">{{ fleetType | validationMessage | async }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="infoFormField">
          <mat-label>{{ "fleets.label.sapDebtorNumber" | translate }}</mat-label>
          <input matInput required formControlName="sapDebtorNumber">
          <mat-error *ngIf="sapDebtorNumber.invalid">{{ sapDebtorNumber | validationMessage | async }}</mat-error>
        </mat-form-field>

        <mat-checkbox class="doNotSendToFE-checkbox" formControlName="doNotSendToFinanceEnabler">
          <mat-label>{{ "fleet.doNotSendToFinanceEnabler" | translate }}</mat-label>
        </mat-checkbox>
      </form>
    </div>


    <div id="productInfo" class="mat-elevation-z1">
      <mat-toolbar style="font-size: 20px;" *ngIf="fleet">
        <span class="headerSpan">{{ 'fleet.product' | translate }}</span>
        <button mat-raised-button class="headerActionBtn icon-only" (click)="validateAndToggleProductEditPanel()"
                [disabled]="productToEdit">
          <mat-icon> playlist_add</mat-icon>
        </button>
      </mat-toolbar>
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="showProductEditPanel" style="border-radius: 0;">
        <pcs-fleet-product-edit (productSaveEvent)="onSaveProduct($event)"
                                *ngIf="showProductEditPanel && isEditMode"
                                [productToEdit]="productToEdit"
                                [productsNotInFleet]="getProductsNotInFleet()"></pcs-fleet-product-edit>
        <pcs-fleet-product-edit (productSaveEvent)="addProductInput($event)"
                                *ngIf="showProductEditPanel && isCreateMode"
                                [productToEdit]="productToEdit"
                                [productsNotInFleet]="getProductsNotInFleet()"></pcs-fleet-product-edit>
      </mat-expansion-panel>
      <pcs-quota-usage-table (deleteEvent)="onDeleteProduct($event)"
                             (editEvent)="enableProductForEdit($event)"
                             *ngIf="fleet && isEditMode"
                             [accumulatedStats]="accumulatedProductStats"
                             [actionButtons]="true"
                             [columnGroupMap]="productTableColumnMap"
                             [data]="currentProducts$"
                             [i18nBase]="'fleet.product'">
      </pcs-quota-usage-table>
      <div class="fleetProductTable" *ngIf="fleet  && isCreateMode">
        <data-table *ngIf="fleet"
                    class="padding-lr-24"
                    [i18nBase]="'fleet.product'"
                    [data]="currentProducts$" [dataColumns]="productTableColumns" [actionButtons]="true"
                    (editEvent)="enableProductForEdit($event)" (deleteEvent)="removeProduct($event)"></data-table>
      </div>
    </div>

    <mat-divider style="height: 20px;" *ngIf="fleet"></mat-divider>

    <div id="managerInfo" class="mat-elevation-z1">
      <mat-toolbar style="font-size: 20px;" *ngIf="fleet">
        <span class="headerSpan">{{ 'fleet.manager' | translate }}</span>
        <button mat-raised-button class="headerActionBtn icon-only" (click)="addManagerDialog()">
          <mat-icon> person_add</mat-icon>
        </button>
      </mat-toolbar>
      <data-table *ngIf="fleet"
                  class="padding-lr-24"
                  [i18nBase]="'fleet.manager'"
                  [data]="currentManagers$" [dataColumns]="fleetManagerTableColumns" [actionButtons]="true"
                  [disableEdit]="true" (deleteEvent)="handleManagerDelete($event)"></data-table>
    </div>
    <mat-divider class="section-divider-small"></mat-divider>

    <div id="addresses" class="mat-elevation-z1">
      <mat-toolbar style="font-size: 20px;" *ngIf="fleet">
        <span class="headerSpan">{{ 'fleet.Addresses' | translate }}</span>
        <edit-handler #addressEditHandler *ngIf="isEditMode && addressListForm" [forForm]="addressListForm"
                      (editEvent)="onEditAddress()" (cancelEvent)="onCancelAddressEdit()"
                      (saveEvent)="onSaveAddressEdit()">
        </edit-handler>
      </mat-toolbar>
      <div *ngIf="addressListForm?.enabled || this.currentFleetAddresses?.billingAddress">
        <mat-checkbox class="address-checkbox" *ngIf="useBillingAsShippingAddress"
                      [formControl]="useBillingAsShippingAddress">{{ 'address.useBillingAsShippingAddress' | translate }}
        </mat-checkbox>
        <mat-tab-group mat-align-tabs="start" [mat-stretch-tabs]="false">
          <mat-tab label="{{'fleet.billingAddress' | translate}}">
            <fleet-address-overview *ngIf="!addressListForm || addressListForm.disabled"
                                    [address]="currBillingAddressOverview$ | async">
            </fleet-address-overview>
            <div class="address-editor" *ngIf="addressListForm?.enabled">
              <fleet-address-editor [addressType]="billingAddType"
                                    [address]="currentFleetAddresses?.billingAddress"
                                    (formInitEvent)="onAddressFormInit($event, billingAddType)">
              </fleet-address-editor>
            </div>
          </mat-tab>
          <mat-tab label="{{'fleet.shippingAddress' | translate}}"
                   *ngIf="!useBillingAsShippingAddress?.value">
            <fleet-address-overview *ngIf="!addressListForm || addressListForm.disabled"
                                    [address]="currShippingAddressOverview$ | async">
            </fleet-address-overview>
            <div class="address-editor" *ngIf="addressListForm?.enabled">
              <fleet-address-editor [addressType]="shippingAddType"
                                    [address]="currentFleetAddresses?.shippingAddress"
                                    (formInitEvent)="onAddressFormInit($event, shippingAddType)">
              </fleet-address-editor>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <mat-divider class="section-divider-small" *ngIf="fleet"></mat-divider>
    <div class="action-buttons-bottom">
      <button *ngIf="isCreateMode" mat-raised-button id="addFleetBtn" class="bottom-button normalButton addFleetBtn"
              (click)="createFleet()"
              [disabled]="createFleetNotAllowed()">
        <mat-icon class="button-icon">add</mat-icon>
        <span>{{ "fleets.button.create.fleet" | translate }}</span>
      </button>
      <button mat-raised-button class="bottom-button normalButton" (click)="onBack()">
        <mat-icon class="button-icon">arrow_back</mat-icon>
        <span>{{ 'button.back' | translate }}</span>
      </button>
    </div>
  </div>
  <div *ngIf="viewResponse">
    <fleet-create-response [users]="fleetCreateResponse.userCredentials"
                           [fleetId]="fleetCreateResponse.fleet.fleetId"></fleet-create-response>
  </div>
</div>
