<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <div class="content-div">
    <mat-icon class="warningIcon"> notification_important</mat-icon>
    <div class="warningText">{{message | translate}}</div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button *ngIf="!associationRequired" mat-stroked-button id="actionBtn" class="dialogueButton"
          [mat-dialog-close]="true">
    {{ 'mfa.set.totp.preferred' | translate}}
  </button>
  <button mat-stroked-button id="associateBtn" class="dialogueButton" (click)="toAssociateTotp()"
          [mat-dialog-close]="false">
    {{ (associationRequired ? 'mfa.associateTotp' : 'mfa.reAssociateTotp') | translate}}
  </button>
  <button mat-stroked-button id="cancelBtn" class="dialogueButton" [mat-dialog-close]="false">
    {{'button.cancel' | translate}}
  </button>
</mat-dialog-actions>
