/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {CardStatus} from './card-status.enum';

export class ChargingCard {
  id: number;
  contractId: string;
  uid: string;
  vin: string;
  serial: string;
  status: CardStatus;
  contractValidForCardReorder: boolean;
  lastUpdated: string;
}

export enum ChargingCardColumns {
  CONTRACT_ID = 'contractId',
  UID = 'uid',
  VIN = 'vin',
  SERIAL = 'serial',
  STATUS = 'status',
  LAST_UPDATED = 'lastUpdated'
}
