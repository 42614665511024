/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Output, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {SelfInformationDto} from '../../datatypes/self-information-dto';
import {DateTime} from "luxon";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'pcs-customerinfo-table',
  templateUrl: './customerinfo-table.component.html',
  styleUrls: [
    '../scrollable-table/scrollable-table.component.css',
    '../action-column-style.css',
    './customerinfo-table.component.css'
  ]
})
export class CustomerinfoTableComponent extends OptimizedTableBaseComponent {

  @Output() public downloadCustomerinfoEvent = new EventEmitter<SelfInformationDto>();

  constructor(renderer: Renderer2,
              public translateService: TranslateService) {
    super(renderer);
  }

  public translateDate(dateToTranslateAsIsoString: string | null): string {
    if (dateToTranslateAsIsoString) {
      const dateTime = DateTime.fromISO(dateToTranslateAsIsoString, {zone: 'utc'});
      if (dateTime?.isValid) {
        const date = dateTime.toFormat(this.translateService.instant('global.dateTimeFormat'));
        return date;
      }
      return "invalid";
    }
    return "";
  }

  public getColumnClass(col: string): string {
    return col + '-column';
  }

  public onDownloadCustomerinfo(customerinfo: SelfInformationDto): void {
    this.downloadCustomerinfoEvent.emit(customerinfo);
  }
}
