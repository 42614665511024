/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StringIdentifiable} from '../../datatypes/string-identifiable';
import {DragDropEventData} from '../../datatypes/drag-drop-event-data';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';

@Component({
  selector: 'platform-list-card',
  templateUrl: './platform-list-card.component.html',
  styleUrls: ['./platform-list-card.component.css']
})
export class PlatformListCardComponent implements PermissionAware {
  @Input() titleKey: string;
  @Input() platformList: StringIdentifiable[];
  @Output() itemMoveEvent = new EventEmitter<DragDropEventData>();
  readonly reqEditPermission: AccessRights[] = [AccessRights.CPO_AND_PARTNER_EDIT_WEB];

  constructor() {
  }

  handleListItemMove(eventData: DragDropEventData) {
    this.itemMoveEvent.emit(eventData);
  }


}
