/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Platform} from './platform.enum';
import {Identifiable} from 'pcs-commons/datatypes';

export class CDRClearing extends Identifiable {
  public lastTry: string;
  public sessionId: string;
  public platform: Platform;
  public cpoId: string;
  public errorMessage: string;
  public clearable: boolean;
}

export enum CDRClearingColumns {
  SESSION_ID = 'sessionId',
  PLATFORM = 'platform',
  CPO_ID = 'cpoId',
  ERROR_MESSAGE = 'errorMessage',
  CLEARABLE = 'clearable'
}
