<div>
  <mat-form-field class="date-range-filter-date-picker">
    <mat-date-range-input [rangePicker]="picker" [formGroup]="dateRangeFormGroup">
      <input matStartDate [placeholder]="getDatePlaceholder()" [formControl]="fromDate">
      <input matEndDate [placeholder]="getDatePlaceholder()" [formControl]="toDate">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker" class="date-range-column-filter-toggle"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>
