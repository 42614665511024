/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelper} from '../../utils/form-helper';

@Component({
  selector: 'pcs-cdrs-for-revocation-filter',
  templateUrl: './cdrs-for-revocation-filter.component.html',
  styleUrls: ['./cdrs-for-revocation-filter.component.css']
})
export class CdrsForRevocationFilterComponent implements OnInit {
  filterFormGroup: FormGroup;
  sessionId: FormControl;
  contractId: FormControl;

  @Output() filterEvent = new EventEmitter<{ sessionId: string, contractId: string }>();

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.sessionId = new FormControl('', Validators.maxLength(100));
    this.contractId = new FormControl('', Validators.maxLength(100));
    this.filterFormGroup = this.formBuilder.group({
      sessionId: this.sessionId,
      contractId: this.contractId
    });
  }

  triggerFetchCdrs(): void {
    this.filterEvent.emit({
      sessionId: FormHelper.getStringValue(this.filterFormGroup, 'sessionId'),
      contractId: FormHelper.getStringValue(this.filterFormGroup, 'contractId')
    });
  }
}
