/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export enum VinCheckResultColumns {
  ROW = 'row',
  VIN = 'vin',
  HAS_ACTIVE_CONTRACT = 'hasActiveContract'
}

export class VinCheckResult {
  public static readonly excelHeaders: string[] = [VinCheckResultColumns.VIN, VinCheckResultColumns.HAS_ACTIVE_CONTRACT];
  public row: number;
  public vin: string;
  public hasActiveContract: boolean;
}
