/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, OnDestroy, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ChargepointFilterType} from '../../datatypes/chargepoint-filter-type.enum';
import {ChargepointFilterEventService} from '../../services/chargepoint-filter-event.service';

@Component({
  selector: 'chargepoint-filter',
  templateUrl: './chargepoint-filter.component.html',
  styleUrls: ['./chargepoint-filter.component.css']
})
export class ChargepointFilterComponent implements OnDestroy {

  @ViewChild('filterExpansionPanel') public filterExpansionPanel;
  @Input() public loading;
  public currentFilterType: string = ChargepointFilterType.DEFAULT;
  private toggleButtonClicked = false;
  private triggerDisplay = new BehaviorSubject<boolean>(false);
  public displayEvent$ = this.triggerDisplay.asObservable();

  constructor(private filterEventService: ChargepointFilterEventService) {
  }

  public ngOnDestroy(): void {
    this.triggerDisplay.complete();
  }

  protected onDisplayChargepoints(): void {
    console.log('triggering display chargepoint event');
    this.triggerDisplay.next(true);
  }

  protected handleFilterChange(filter: string): void {
    this.toggleButtonClicked = true;
    this.currentFilterType = filter;
    this.triggerDisplay.next(false);
    console.log('selected filter: ', this.currentFilterType);
    this.filterEventService.updateCurrentFilterType(ChargepointFilterType[filter]);
  }

  protected onExpansionPanelClose(): void {
    console.log('toggle button clicked: ', this.toggleButtonClicked);
    console.log('closing expansion panel, expanded val: ', this.filterExpansionPanel.expanded);
    if (this.toggleButtonClicked) {
      this.filterExpansionPanel.expanded = true;
    }
    this.toggleButtonClicked = false;
  }
}
