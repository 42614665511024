/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export class OcpiPartnerInitializationData {
  public url: string;
  public token: string;
  public partyId: string;
  public countryCode: string;
  public platformName: string;
  public authCheckLocation: boolean;
  public pullStaticDataOption: string;
  public requestAuthorizationId: boolean;
  public allowOfflineAuthentication: boolean;
  public certCn: string;
}
