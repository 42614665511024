<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="div-container">
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'orderDateTime' && col !== 'invoiceDateTime' && col !== 'invoiceType' && col !== 'status' && col !== 'syncStatus'">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'orderDateTime' || col === 'invoiceDateTime'">{{translateDate(element[col])}}</ng-container>
            <ng-container
              *ngIf="col === 'invoiceType' && element[col]">{{'invoice.invoiceType.' + element[col] | translate}}</ng-container>
            <ng-container
              *ngIf="col === 'status' && element[col]">{{'invoice.status.' + element[col] | translate}}</ng-container>
            <ng-container
              *ngIf="col === 'syncStatus' && element[col]">{{'invoice.syncStatus.' + element[col] | translate}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>

      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="invoicesActionCell actionCell center details-column"> {{'invoices.tableHeader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="invoicesActionCell actionCell center details-column">
          <button mat-mini-fab class="edit tableActionBtn" (click)="editElement(element)"
                  *ngIf="!disableEdit || !invalidElementForDetails(element)" matTooltip="{{'invoice.tooltip.reimbursement' | translate}}">
            <svg-icon src="/assets/images/ic_detail2.svg"></svg-icon>
          </button>
          <button mat-mini-fab class="edit tableActionBtn" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="searchElement(element)" *ngIf="!disableEdit" matTooltip="{{'invoice.tooltip.orderitems' | translate}}">
            <mat-icon> edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>
