/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {QueryDto} from '../../datatypes/QueryDto';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {DatabaseService} from '../../services/http/database.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {AddQueryComponent} from '../add-query/add-query.component';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../../utils/utils';
import {SharedDataService} from '../../services/shared-data.service';
import {DeleteDialogComponent} from '../../dialog/delete-dialog/delete-dialog.component';
import {Message} from '../../datatypes/message';
import {CodeModel} from "@ngstack/code-editor";

@Component({
  selector: 'pcs-query-input',
  templateUrl: './query-input.component.html',
  styleUrls: ['./query-input.component.css']
})
export class QueryInputComponent extends AbstractNotificationHandler implements OnInit, PermissionAware, OnDestroy {
  public readonly reqEditPermission = [AccessRights.DATABASE_EDIT_WEB];
  public toUnsubscribe: Subscription[] = [];
  public queryFormGroup: FormGroup;
  public querySelection: FormControl;
  public allQueries$: Observable<QueryDto[]>;
  public allQueries: QueryDto[];

  public queryOnProcess: QueryDto;

  @Input() public resultsAvailable: boolean;

  @Output() public queryEvent = new EventEmitter<QueryDto>();
  @Output() public downloadEvent = new EventEmitter<void>();


  private editorCodeModelUri = 'query-input.ts';
  private editorCodeModelLanguage = 'sql';
  private codeModelSource = new BehaviorSubject<CodeModel>({
    language: this.editorCodeModelLanguage,
    uri: this.editorCodeModelUri,
    value: ''
  });
  public codeModel$ = this.codeModelSource.asObservable();

  constructor(
    private formBuilder: FormBuilder,
    private databaseService: DatabaseService,
    private dataService: SharedDataService,
    private dialog: MatDialog) {
    super();

  }

  public ngOnInit(): void {
    this.allQueries$ = this.dataService.allQueries$;
    this.toUnsubscribe.push(this.dataService.queryToEdit$.subscribe((query) => this.updateQuerySelected(query)));
    this.toUnsubscribe.push(this.dataService.allQueries$.subscribe((queries) => this.allQueries = queries));
    this.retrieveQueries();
    this.dataService.updateCurrentQueryOnEdit(new QueryDto());
    this.defineFormControls();

  }

  private retrieveQueries(): void {
    this.databaseService.retrieveSavedQueries().subscribe(
      (queryList) => {
        Utils.sortArrayByStringProperty(queryList, 'name');
        this.dataService.updateDatabaseQueries(queryList);
      }
    );
  }

  public defineFormControls(): void {
    this.querySelection = new FormControl('');
    this.toUnsubscribe.push(this.querySelection.valueChanges.subscribe((queryName) => this.onQuerySelected(queryName)));
    this.queryFormGroup = this.formBuilder.group({
      querySelection: this.querySelection
    });
  }

  public triggerQueryExecution(): void {
    if (this.queryOnProcess.query) {
      this.queryEvent.emit(this.queryOnProcess);
    }
  }

  public triggerResultDownload(): void {
    this.downloadEvent.emit();
  }

  public onEditQuery(): void {
    this.openEditQueryDialog();
  }

  private openEditQueryDialog(): void {
    const queryDto: QueryDto = {...this.queryOnProcess};
    const dialogRef = this.dialog.open(AddQueryComponent, {width: '600px', data: queryDto});

    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result.success) {
          const msg = new Message();
          msg.message = result.message;
          this.showInfo(msg);
        }
      });
  }


  public onDeleteQuery(): void {
    const queryToDelete: QueryDto = this.queryOnProcess;
    if (!queryToDelete.name) {
      this.dataService.updateCurrentQueryOnEdit(new QueryDto());
      return;
    }
    console.log('delete query: ' + queryToDelete);
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: Utils.deleteQueryConfirmMessage(queryToDelete.name)
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.doDeleteQuery(queryToDelete);
      }
    });
  }

  private doDeleteQuery(queryToDelete: QueryDto): void {
    this.databaseService.deleteQuery(queryToDelete).subscribe(
      (queryList) => {
        Utils.sortArrayByStringProperty(queryList, 'name');
        this.dataService.updateCurrentQueryOnEdit(new QueryDto());
        this.dataService.updateDatabaseQueries(queryList);
        const msg = new Message();
        msg.message = 'database.query.deleted';
        this.showInfo(msg);
      }
    );
  }

  public isQuerySelected(): boolean {
    return Boolean(this.queryOnProcess.name);
  }

  public onQuerySelected(queryName: string): void {
    let selectedQuery: QueryDto;
    if (queryName) {
      this.allQueries.forEach((query) => {
        if (queryName === query.name) {
          selectedQuery = {...query};
        }
      });
    } else {
      selectedQuery = new QueryDto();
      selectedQuery.query = this.queryOnProcess.query;
    }
    if (this.queryOnProcess !== selectedQuery) {
      this.dataService.updateCurrentQueryOnEdit(selectedQuery);
    }
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
    this.codeModelSource.complete();
  }

  public updateQuerySelected(query: QueryDto): void {
    this.queryOnProcess = query;
    this.updateQueryInEditor(this.queryOnProcess.query);
    if (this.querySelection && this.querySelection.value !== this.queryOnProcess.name) {
      this.querySelection.setValue(this.queryOnProcess.name);
    }
  }

  private updateQueryInEditor(value: string): void {
    value = value ? value : '';
    if (this.codeModelSource.getValue().value === value) {
      return;
    }
    console.log("Updating query on edit with value: ", value);
    this.codeModelSource.next({
      language: this.editorCodeModelLanguage,
      uri: this.editorCodeModelUri,
      value: value
    })
  }

  public onQueryInputChange(newValue: string): void {
    const updatedQuery = {...this.queryOnProcess};
    updatedQuery.query = newValue;
    this.dataService.updateCurrentQueryOnEdit(updatedQuery);
  }
}
