/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewInit, Component, Renderer2, ViewChild} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {MatPaginator} from '@angular/material/paginator';
import {Parameters} from '../../global/parameters';

@Component({
  selector: 'tariff-import-result-table',
  templateUrl: './tariff-import-result-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css',
    '../data-table.component.css',
    './tariff-import-result-table.component.css']
})
export class TariffImportResultTableComponent extends OptimizedTableBaseComponent implements AfterViewInit {

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  public ngAfterViewInit() {
    this.tableDataSource.paginator = this.paginator;
  }
}

