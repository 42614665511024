/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {CurrencyFactorService} from '../../services/http/currency-factor.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';
import {ValidationMessagePipe} from 'pcs-commons/validation';
import {DateRangeValidatorService} from '../../validation/date-range-validator.service';
import {TranslationHelperService} from 'pcs-commons/http';
import {CurrencyFactorsDialog} from '../currency-factors-dialog';

@Component({
  selector: 'pcs-edit-currency-factors',
  templateUrl: './edit-currency-factors.component.html',
  styleUrls: ['../../dialog/dialog-common-styles.css', './edit-currency-factors.component.css']
})
export class EditCurrencyFactorsComponent extends CurrencyFactorsDialog {

  constructor(
    currencyFactorService: CurrencyFactorService,
    dialogRef: MatDialogRef<EditCurrencyFactorsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    formBuilder: FormBuilder,
    validationPipe: ValidationMessagePipe,
    dateRangeValidator: DateRangeValidatorService,
    translationHelper: TranslationHelperService) {
    super(formBuilder, validationPipe, dateRangeValidator, translationHelper,
      dialogRef, currencyFactorService);
    this.currency = data.currency;
    this.currencyFactors = data.currencyFactorsToEdit;
    this.setValidUntilInitialEditable();
  }
}
