<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="scrollable-table-container">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <mat-header-cell *matHeaderCellDef class="{{col}}-cell">{{ getI18nBase(col) | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element " class="{{col}}-cell">
          <ng-container
            *ngIf="canBeTranslated(col)"> {{ element[col] | ocpiPartnerTranslate:col | translate }}
          </ng-container>
          <ng-container *ngIf="col==='platform'">{{ element[col]?.name }}</ng-container>
          <ng-container *ngIf="isDate(col)">{{ getDateTimeTranslation(element[col]) }}</ng-container>
          <ng-container *ngIf="isBoolean(col)">
            <mat-icon *ngIf="element[col]===true">check</mat-icon>
          </ng-container>
        </mat-cell>
      </ng-container>
    </div>
    <ng-container matColumnDef="tableAction" stickyEnd="true">
      <mat-header-cell *matHeaderCellDef class="actionCell"> {{ 'fleet.tableheader.action' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="actionCell">
        <mat-grid-list class="action-button-grid" cols="3">
          <mat-grid-tile colspan="1">
            <button (click)="onPushRfidTokensToPartner(element)" *ngIf="isPartnerActive(element)"
                    [disabled]="!canPushTokens(element)"
                    class="tableActionBtn"
                    mat-mini-fab matTooltip="{{'ocpi.partner.initiate.pushRfidTokens.tooltip' | translate}}">
              <mat-icon>send</mat-icon>
            </button>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <button (click)="onActivate(element)" *ngIf="activationPossible(element)" class="tableActionBtn"
                    mat-mini-fab matTooltip="{{'ocpi.partner.activate'|translate}}">
              <mat-icon> check_circle_outline</mat-icon>
            </button>
            <div *ifAnyPerm="reqPermForJobStart">
              <button (click)="onUpdateLocationDataForPartner(element)" *ngIf="isPartnerActive(element)"
                      [class.spinner]="isSyncRunning(element)"
                      [disabled]="isSyncRunning(element)"
                      class="tableActionBtn"
                      mat-mini-fab matTooltip="{{'ocpi.partner.initiate.sync.tooltip' | translate}}">
                <mat-icon>sync</mat-icon>
              </button>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <button (click)="editElement(element)" class="tableActionBtn"
                    mat-mini-fab>
              <mat-icon> edit</mat-icon>
            </button>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>
