<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="header-toolbar">
    <span class="headerSpan">{{'usermanagement.users' | translate }}</span>
    <button (click)="onCreateUser()" *ifAnyPerm="reqEditPermission" class="headerActionBtn icon-only" mat-raised-button>
      <mat-icon> add</mat-icon>
    </button>
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
  </mat-toolbar>
  <pcs-user-table (deleteEvent)="onUserDelete($event)"
                  (editEvent)="onUserEdit($event)"
                  (resultAcknowledgeEvent)="onResultAcknowledgement()"
                  [actionButtons]="reqEditPermission | accessAllowed"
                  [actionResult$]="actionResult$"
                  [availableGroups]="availableGroups"
                  [dataColumns]="userTableColumns"
                  [data]="userList$"
                  actionButtons="true">
  </pcs-user-table>
</div>
