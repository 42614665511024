/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, AfterViewInit, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Message} from '../../datatypes/message';
import {FocusMonitor} from '@angular/cdk/a11y';
import {DialogData} from '../../datatypes/dialog-data';

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements AfterViewInit {
  public message: Message;
  public actionKey: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private focusMonitor: FocusMonitor) {
    console.log('msg: ', data.message, 'actionKey: ', data.actionKey);
    this.message = data.message;
    this.actionKey = data.actionKey;
  }

  public ngAfterViewInit(): void {
    this.focusMonitor.stopMonitoring(document.getElementById('actionBtn'));
    this.focusMonitor.stopMonitoring(document.getElementById('cancelBtn'));
  }
}
