/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
/**
 * the order is important, as the validation check will be done according to the order
 */
export enum ValidationType {
  EMAIL = 'email',
  MIN_LENGTH = 'minlength',
  MAX_LENGTH = 'maxlength',
  PATTERN = 'pattern',
  NO_UPPERCASE = 'noUppercase',
  MIN_VALUE = 'min',
  MAX_VALUE = 'max',
  DATE_INVALID = 'matDatepickerParse',
  DATE_IN_PAST = 'matDatepickerMin',
  NAME_NOT_UNIQUE = 'notUniqueGroupName',
  REQUIRED = 'required',
  URL = 'url',
  TAX_RATE = 'taxRate',
  INVALID_MIN_MAX_COMBINATION = 'InvalidMinMaxCombination',
  DATE_RANGE_INVALID = 'invalidDateRange',
  POSITIVE_INTEGER = 'positiveInteger',
  PASSWORD_MISMATCH = 'passwordMismatch',
  NO_WHITESPACE = 'whitespace'
}
