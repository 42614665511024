/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {PurchaseConditionElement} from './purchase-condition-element';
import {Identifiable} from 'pcs-commons/datatypes';

export class PurchaseConditionOverviewElement extends Identifiable {
  public static readonly dataColumns: string[] = ['cpoid', 'cpoName', 'platform', 'country', 'evseIds', 'tariffId', 'status', 'validFrom', 'validUntil', 'duration'];
  public static readonly dataColumnsActiveTable: string[] = ['cpoid', 'cpoName', 'platform', 'country', 'evseIds', 'tariffId', 'status', 'validFrom', 'validUntil', 'duration', 'maxPrice', 'currency'];

  public cpoid: string;
  public cpoCode: string;
  public cpoName: string;
  public platform: string;
  public country: string;
  public tariffId: string;
  public evseIds: string[];
  public status: string;
  public validFrom: string;
  public validUntil: string;
  public duration: number;
  public maxPrice: number;
  public currency: string;
  public elements: PurchaseConditionElement[];
}
