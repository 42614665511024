<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div id="change-request-info">
  <span> {{'changeRequest.info'|translate}}</span>
  <div class="change-request-info-box">
    <div *ngIf="isCPOStatusChange()">
      <pcs-change-request-info-cpo [changeRequest]="changeRequest"></pcs-change-request-info-cpo>
    </div>
    <div *ngIf="isCDRBillrunRevocation()">
      <pcs-change-request-info-common [changeRequest]="changeRequest" [params]="getParams()"></pcs-change-request-info-common>
    </div>
    <div *ngIf="canOnlyBeShownAsJsonDiff()">
      <pcs-change-request-info-json [changeRequest]="changeRequest"></pcs-change-request-info-json>
    </div>
    <div *ngIf="isReimbursement()">
      <pcs-change-request-info-as-table [changeRequest]="changeRequest" [params]="getParams()"></pcs-change-request-info-as-table>
    </div>
  </div>
</div>

