/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {PriceColumnPipeBase} from './price-column-pipe-base';
import {SharedDataService} from '../services/shared-data.service';
import {ChargepointClassPriceColumn} from '../chargepoint-class-price';
import {PriceUtils} from '../utils/price-utils';
import {Utils} from '../utils/utils';
import {FormUtils} from 'pcs-commons/utils';
import {EditCountryPriceFormManager} from '../products/product-details/edit-country-price/edit-country-price-form-manager.abstract';
import {CountryPriceRecordColumns} from '../country-price-record';

@Pipe({
  name: 'editableClassPriceColumnValue',
  pure: false
})
export class EditableClassPriceColumnValuePipe extends PriceColumnPipeBase implements PipeTransform {
  constructor(dataSource: SharedDataService) {
    super(dataSource);
  }

  public transform(formGroup: FormGroup, column: string, country: string, cpClass: string): string | null {
    if (!formGroup) {
      return null;
    }

    switch (column) {
      case ChargepointClassPriceColumn.PRICE_PER_MIN_NETTO:
        return this.getPriceForColumn(formGroup, Utils.calcClassPriceFormControlName(cpClass, EditCountryPriceFormManager.MIN), country);
      case ChargepointClassPriceColumn.PRICE_PER_KWH_NETTO:
        return this.getPriceForColumn(formGroup, Utils.calcClassPriceFormControlName(cpClass, EditCountryPriceFormManager.KWH), country);
      default:
        return null;
    }
  }

  private getPriceForColumn(formGroup: FormGroup, fcName: string, country: string): string {
    const pricePerUnit: number = FormUtils.getNullableNumber(formGroup, fcName);
    const vatValue = FormUtils.getNullableNumber(formGroup, CountryPriceRecordColumns.TAX);
    return PriceUtils.getNettoPrice(
      pricePerUnit, vatValue !== null ? vatValue / 100 : null
    );
  }
}
