/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {ProductQuota, ProductQuotaColumns} from '../../datatypes/product-quota';
import {MultiHeaderTableBaseComponent} from '../table-base/multi-header-table-base.component';

@Component({
  selector: 'pcs-quota-usage-table',
  templateUrl: './quota-usage-table.component.html',
  styleUrls: [
    '../action-column-style.css',
    './quota-usage-table.component.css',
    '../scrollable-table/scrollable-table.component.css']
})
export class QuotaUsageTableComponent extends MultiHeaderTableBaseComponent {
  @Input() public accumulatedStats: ProductQuota;
  public productCode: string = ProductQuotaColumns.PRODUCT_CODE;

  constructor(renderer: Renderer2) {
    super(renderer);
  }
}
