/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Identifiable } from 'pcs-commons/datatypes';
import { DateTime } from 'luxon';

export class BasicFeeRecord extends Identifiable {
  public country: string;
  public basicFee: number;
  public defaultPriceMin: number;
  public defaultPriceKwh: number;
  public currency: string;
  public validFrom: string;
  public validUntil: string;
}

export interface BasicFeeFormRecord {
  id: number;
  validFromDate: DateTime;
  validFromTime: string;
  validUntilDate: DateTime;
  validUntilTime: string;
  basicFee: number;
  defaultPriceMin: number | string;
  defaultPriceKwh: number | string;
}

export enum BasicFeeTableColumns {
  COUNTRY = 'country',
  CURRENCY = 'currency',
  TAX = 'tax',
  VALID_FROM = 'validFrom',
  VALID_UNTIL = 'validUntil',
  BASIC_FEE = 'basicFee',
  BASIC_FEE_NETTO = 'basicFeeNetto',
  DEFAULT_PRICE_MIN = 'defaultPriceMin',
  DEFAULT_PRICE_MIN_NETTO = 'defaultPriceMinNetto',
  DEFAULT_PRICE_KWH = 'defaultPriceKwh',
  DEFAULT_PRICE_KWH_NETTO = 'defaultPriceKwhNetto',
  VALID_FROM_DATE = 'validFromDate',
  VALID_FROM_TIME = 'validFromTime',
  VALID_UNTIL_DATE = 'validUntilDate',
  VALID_UNTIL_TIME = 'validUntilTime'
}
