/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, ElementRef, HostListener} from '@angular/core';
import {KeyCodeEnum} from '../utils/KeyCode.enum';

/**
 * Makes element to listen to ESC and ENTER events invoking the respective actions inside the element.
 * Elements to be invoked are found attributes assigned. Action invoked is (click).
 *   ESC          -> cancelAction
 *   Enter        -> okAction
 *   ESC | Enter  -> okCancelAction
 * Note: avoid defining okAction and okCancelAction inside the same annotated element
 * (same with cancelAction and okCancelAction, you will not known which of the elements is invoked)
 *
 * Example:
 *  <div editKeyEvents>
 *     ...
 *     <button cancelAction mat-mini-fab> (click)="doCancelEdit()">Cancel</button>
 *  </div>
 */
@Directive({
  selector: '[editKeyEvents]'
})
export class EditKeyEventsDirective {

  constructor(private el: ElementRef) {
  }

  @HostListener('document:keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    const active = document.activeElement;

    if (event.key === KeyCodeEnum.ESC) {
      // don't handle ESC for select boxes as they handle the event internally
      if (active && ['SELECT', 'MAT-SELECT'].includes(active.tagName)) {
        return;
      }
      this.invoke('[cancelAction], [okCancelAction]');
    }
    if (event.key === KeyCodeEnum.ENTER) {
      // don't handle ENTER for textareas and select boxes as they handle the event internally
      if (active && ['BUTTON', 'SELECT', 'MAT-SELECT'].includes(active.tagName)) {
        return;
      }

      if (active && active.tagName === 'TEXTAREA' && !event.ctrlKey) { // for textarea, ctrl + enter should execute the action
        return;
      }

      this.invoke('[okAction], [okCancelAction]');
    }
  }

  private invoke(idSelector: string): boolean {
    const action = this.el.nativeElement.querySelector(idSelector);
    console.log('%s is %s', idSelector, action);
    if (action) {
      if (!action.disabled) {
        action.click();
        return true;
      }
    } else {
      console.log('Missing element to invoke. Selector %s has no result', idSelector);
    }
    return false;
  }
}
