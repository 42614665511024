/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {BreakpointObserver} from '@angular/cdk/layout';

/**
 this directive can only be applied to <ng-template>,
 please wrap your component inside a <ng-template> and apply there
 **/
@Directive({
  selector: '[hideAt]'
})
export class HideAtDirective implements OnInit, OnDestroy {
  @Input() hideAt;
  private destroy$ = new Subject();

  constructor(
    private breakpoint: BreakpointObserver,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  public ngOnInit(): void {
    const hide$ = this.breakpoint
      .observe(`(min-width: ${this.hideAt}px)`)
      .pipe(
        takeUntil(this.destroy$),
        map(({matches}) => matches)
      );

    hide$.subscribe(hide => {
      if (hide) {
        this.viewContainer.clear();
      } else {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }

}
