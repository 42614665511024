/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CurrencyFactor} from '../datatypes/currency-factor';
import {ValidatorList, ValidationMessagePipe} from 'pcs-commons/validation';
import {DateUtils} from 'pcs-commons/utils';
import {Utils} from '../utils/utils';
import {FormUtils} from 'pcs-commons/utils';
import {BehaviorSubject, Observable} from 'rxjs';
import {DateRangeValidatorService} from '../validation/date-range-validator.service';
import {Directive, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {CurrencyFactorService} from '../services/http/currency-factor.service';
import {TranslationHelperService} from 'pcs-commons/http';
import {combineDateAndTime} from "../validation/date-range-utils";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CurrencyFactorsDialog implements OnInit {
  private currencyFactorsToEditSource = new BehaviorSubject<CurrencyFactor[]>([]);
  public currencyFactorsToEdit$ = this.currencyFactorsToEditSource.asObservable();

  public editCurrencyFactorForm: FormGroup;

  public dataColumns = CurrencyFactor.dataColumnsEditTable;

  public currencyFactors: CurrencyFactor[];

  public currency: string;

  public loading = false;

  protected constructor(
    private formBuilder: FormBuilder,
    private validationPipe: ValidationMessagePipe,
    private dateRangeValidator: DateRangeValidatorService,
    private translationHelper: TranslationHelperService,
    private dialogRef: MatDialogRef<CurrencyFactorsDialog>,
    protected currencyFactorService: CurrencyFactorService) {
    this.currencyFactors = [];
  }

  public ngOnInit(): void {
    this.editCurrencyFactorForm = this.formBuilder.group({},
      {validators: [this.dateRangeValidator.validate.bind(this.dateRangeValidator)]}
    );
    this.defineCurrencyFactorsForm();
  }

  protected defineCurrencyFactorsForm(): void {
    if (!this.editCurrencyFactorForm) {
      this.editCurrencyFactorForm = this.formBuilder.group(
        {},
        {validators: [this.dateRangeValidator.validate.bind(this.dateRangeValidator)]});
    }
    Object.keys(this.editCurrencyFactorForm.controls).forEach((name) => this.editCurrencyFactorForm.removeControl(name));

    if (this.currencyFactors !== undefined) {
      this.currencyFactors.forEach((record, index) => {
        this.addRow(record, index);
      });
    }
    this.currencyFactorsToEditSource.next(this.currencyFactors);
  }

  private addRow(record: CurrencyFactor, index: number): void {
    const currencyFactorsFormControls = new Map<string, FormControl>();
    record.index = index;

    const description = new FormControl(record.description);
    const value = new FormControl(record.value, ValidatorList.POSITIVE_DECIMAL_REQUIRED);
    const reverseValue = new FormControl(record.reverseValue, ValidatorList.POSITIVE_DECIMAL_REQUIRED);
    const disable = !record.added && !DateUtils.dateTimeIsInFuture(record.validFrom);
    const validFrom = DateUtils.convertToDateTimeWithUTC(record.validFrom);
    const validFromDate = new FormControl(validFrom?.startOf("day"));
    const validFromTime = new FormControl(validFrom?.toFormat('HH:mm:ss'));
    const validFromDisplayValue = new FormControl(validFrom?.toLocaleString());
    const validUntil = DateUtils.convertToDateTimeWithUTC(record.validUntil);
    const validUntilDate = new FormControl(validUntil?.startOf("day"));
    const validUntilTime = new FormControl(validUntil?.toFormat('HH:mm:ss'));
    const validUntilDisplayValue = new FormControl(validUntil?.toLocaleString());

    if (disable) {
      value.disable();
      reverseValue.disable();
      validFromDate.disable();
      validFromTime.disable();
      validFromDisplayValue.disable();
    }

    if (!record.added && !record.validUntilInitialEditable && record.validUntil !== null
      && !DateUtils.dateTimeIsInFuture(record.validUntil)) {
      validUntilDate.disable();
      validUntilTime.disable();
      validUntilDisplayValue.disable();
    }

    currencyFactorsFormControls.set('description-' + index, description);
    currencyFactorsFormControls.set('value-' + index, value);
    currencyFactorsFormControls.set('reverseValue-' + index, reverseValue);
    currencyFactorsFormControls.set('validFromDate-' + index, validFromDate);
    currencyFactorsFormControls.set('validFromTime-' + index, validFromTime);
    currencyFactorsFormControls.set('validUntilDate-' + index, validUntilDate);
    currencyFactorsFormControls.set('validUntilTime-' + index, validUntilTime);
    currencyFactorsFormControls.set('DisplayValueValidFrom-' + index, validFromDisplayValue);
    currencyFactorsFormControls.set('DisplayValueValidUntil-' + index, validUntilDisplayValue);
    currencyFactorsFormControls.forEach((fc, name) => this.editCurrencyFactorForm.addControl(name, fc));
  }

  protected formattedCurrencyFactorsToEdit(): CurrencyFactor[] {
    const currencyFactors = [...this.currencyFactors];
    currencyFactors.forEach((record, index) => {
      record.description = FormUtils.getStringValue(this.editCurrencyFactorForm, `description-${index}`);
      record.value = FormUtils.getNullableNumber(this.editCurrencyFactorForm, `value-${index}`);
      record.reverseValue = FormUtils.getNullableNumber(this.editCurrencyFactorForm, `reverseValue-${index}`);

      record.validFrom = combineDateAndTime(`validFromDate-${index}`, this.editCurrencyFactorForm.getRawValue(), 0)?.toISO();
      record.validUntil = combineDateAndTime(`validUntilDate-${index}`, this.editCurrencyFactorForm.getRawValue(), 999)?.toISO();
    });
    return currencyFactors;
  }

  public deleteRecord(recordToDelete: CurrencyFactor): void {
    this.currencyFactors = this.formattedCurrencyFactorsToEdit();
    Utils.removeArrayItem(this.currencyFactors, recordToDelete);
    this.defineCurrencyFactorsForm();
  }

  public addRecord(): void {
    const newCurrencyFactor = new CurrencyFactor();
    newCurrencyFactor.currency = this.currency;
    newCurrencyFactor.added = true;
    this.currencyFactors = this.formattedCurrencyFactorsToEdit();
    Utils.addArrayItem(this.currencyFactors, newCurrencyFactor);
    this.addRow(newCurrencyFactor, this.currencyFactors.length-1);
    this.currencyFactorsToEditSource.next(this.currencyFactors);
  }

  public getError(fg: FormGroup): Observable<any> {
    return this.validationPipe.transform(fg);
  }

  public getErrorFC(control: FormControl): Observable<any> {
    return this.validationPipe.transform(control);
  }

  public allInputsValid(): boolean {
    return this.editCurrencyFactorForm.valid;
  }

  public showDateRangeError(): boolean {
    return this.editCurrencyFactorForm.invalid && this.editCurrencyFactorForm.hasError('invalidDateRange');
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onSave(): void {
    this.loading = true;
    const formattedCurrencyFactors = this.formattedCurrencyFactorsToEdit();
    this.currencyFactorService.update(formattedCurrencyFactors)
      .subscribe({
        next: () => {
          this.loading = false;
          this.dialogRef.close(true);
        },
        error: (error) => this.loading = false
      });
  }

  protected setValidUntilInitialEditable(): void {
    this.currencyFactors.forEach((currencyFactor) => {
        if (currencyFactor.validUntil === null || DateUtils.dateTimeIsInFuture(currencyFactor.validUntil)) {
          currencyFactor.validUntilInitialEditable = true;
        }
      }
    );
  }
}
