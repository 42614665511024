<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>{{'purchaseCondition.element.title' | translate}}</h2>
  <div>
    <mat-toolbar class="dialog-section-toolbar">{{'purchaseCondition.element.restrictions' | translate}}</mat-toolbar>
    <mat-grid-list cols="2" rowHeight="100px">
      <form class="dialog-form" *ngIf="timeDataForm" [formGroup]="timeDataForm">
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>{{'purchaseCondition.element.startTime'| translate}}</mat-label>
            <input matInput formControlName="startTime" type="time" step="1">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>{{'purchaseCondition.element.endTime'| translate}}</mat-label>
            <input matInput formControlName="endTime" type="time" step="1">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{'purchaseCondition.element.startDate'| translate}}</mat-label>
            <input matInput formControlName="startDate" placeholder="{{'global.dateFormat' | translate | lowercase}}"
                   [matDatepicker]="startDatePicker" [min]="minStartDate" [max]="maxStartDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{'purchaseCondition.element.endDate'| translate}}</mat-label>
            <input matInput formControlName="endDate" placeholder="{{'global.dateFormat' | translate | lowercase}}"
                   [matDatepicker]="endDatePicker" [min]="minEndDate" [max]="maxEndDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
        </mat-grid-tile>
      </form>
      <form class="dialog-form" *ngIf="consumptionDataForm" [formGroup]="consumptionDataForm">
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>{{'purchaseCondition.element.minKwhConsumption'| translate}}</mat-label>
            <input matInput formControlName="minKwhConsumption">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>{{'purchaseCondition.element.maxKwhConsumption'| translate}}</mat-label>
            <input matInput formControlName="maxKwhConsumption" [errorStateMatcher]="crossFieldErrorMatcher">
            <mat-error *ngIf="maxKwhConsumption.invalid || consumptionDataForm.errors">
              {{ consumptionDataForm | validationMessage | async}}
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
      </form>
      <form class="dialog-form" *ngIf="durationDataForm" [formGroup]="durationDataForm">
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>{{'purchaseCondition.element.minDuration'| translate}}</mat-label>
            <input matInput formControlName="minDuration">
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>{{'purchaseCondition.element.maxDuration'| translate}}</mat-label>
            <input matInput formControlName="maxDuration" [errorStateMatcher]="crossFieldErrorMatcher">
            <mat-error *ngIf="maxDuration.invalid || durationDataForm.errors">
              {{durationDataForm | validationMessage | async}}
            </mat-error>
          </mat-form-field>
        </mat-grid-tile>
      </form>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{'purchaseCondition.element.powerClasses'| translate}}</mat-label>
          <mat-select [formControl]="powerClasses" multiple>
            <mat-optgroup label="AC">
              <mat-option *ngFor="let pClass of acPowerClassList"
                          [value]="pClass.name">{{'global.chargeClass.' + pClass.name | translate}}</mat-option>
            </mat-optgroup>
            <mat-optgroup label="DC">
              <mat-option *ngFor="let pClass of dcPowerClassList"
                          [value]="pClass.name">{{'global.chargeClass.' + pClass.name | translate}}</mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{'purchaseCondition.element.daysOfWeek'| translate}}</mat-label>
          <mat-select [formControl]="daysOfWeek" multiple>
            <mat-option *ngFor="let day of weekDaysList"
                        [value]="day">{{'message.day.' + day | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-toolbar class="dialog-section-toolbar" [ngClass]="{'error' : pricesForm.touched && pricesForm.invalid}">
      {{'purchaseCondition.element.priceComponents' | translate}} *
    </mat-toolbar>
    <price-component-editable-table *ngIf="pricesForm"
                                    [data]="observableOfPricesTypes()"
                                    [dataColumns]="purchasePriceComponentColumns"
                                    [pricesForm]="pricesForm"
                                    [i18nBase]="'purchaseCondition.element'">
    </price-component-editable-table>
    <mat-dialog-actions>
      <button okAction mat-raised-button class="dialogueButton dialogueButtonRight"
              [disabled]="!allFormsValid()"
              (click)="onSave()">
        {{'button.save' | translate}}
      </button>
      <button cancelAction mat-raised-button class="dialogueButton"
              (click)="onCancel()">
        {{'button.cancel' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
