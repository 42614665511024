<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div>
  <mat-toolbar class="headerToolbar mat-elevation-z1">
        <span class="headerSpan">{{'chargepoint.details.locationGroup.header' | translate }}
          : {{currLocationGroupId}}</span>
  </mat-toolbar>
  <pcs-location-attributes [location]="locationData" [sortedPlatformList]="sortedPlatformList"
                           [locationGroupId]="currLocationGroupId"
                           (editEvent)="storeManualData($event)">
  </pcs-location-attributes>

  <mat-expansion-panel class="map-container" [expanded]="true" hideToggle>
    <div class="map-header">
      <span class="map-header-span" *ngIf="coordinatePresent">
        <span class="dot"></span>
        {{'map.coordinates.cpo' | translate }}
      </span>
      <span class="map-header-span-right" *ngIf="addressCoordinatePresent">
        <span class="dot dot-purple"></span>
        {{'map.coordinates.address' | translate }}
      </span>
      <span class="map-header-span-right" *ngIf="userPositionDataPresent">
        <span class="dot dot-yellow"></span>
        {{'map.coordinates.userPositions' | translate }}
      </span>
    </div>
    <google-map [options]="options" width="100%" #map>
      <map-marker *ngFor="let marker of markers"
                  [position]="marker.getPosition()"
                  [options]="markerOptions" [title]="marker.getTitle()" [label]="marker.getLabel()"
                  [icon]="marker.getIcon()"></map-marker>
    </google-map>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="locationData" class="expansion-panel-tile" [expanded]="true" hideToggle>
    <div class="expansion-table-header">
      <span class="headerSpan headerToolbarSub">{{'chargepoint.chargepointsdetails.edit.header' | translate }}</span>
    </div>
    <mat-tab-group dynamicHeight *ngIf="chargepoints" [selectedIndex]="selectedTab.value"
                   (selectedTabChange)="handleTabChange($event)"
                   [mat-stretch-tabs]="false">
      <mat-tab *ngFor="let chargepoint of chargepoints; let index = index">
        <ng-template mat-tab-label>
          <span>{{chargepoint.evseId}}</span>
          <div class="action-button-div">
            <button id="editChargepoint" mat-icon-button class="action-icon-button"
                    *ngIf="!cpInEditMode(chargepoint) && (reqEditPermission | accessAllowed)"
                    (click)="triggerDataEditForChargepoint(chargepoint)">
              <mat-icon> edit</mat-icon>
            </button>
            <div id="saveChargepoint" *ngIf="cpInEditMode(chargepoint)">
              <button mat-icon-button class="action-icon-button"
                      (click)="triggerChargepointDataSave()">
                <mat-icon> save</mat-icon>
              </button>
              <button mat-icon-button class="action-icon-button"
                      (click)="triggerChargepointEditCancel()">
                <mat-icon> close</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
        <pcs-chargepoint-details
          [chargepoint]="chargepoint" [sortedPlatformList]="sortedPlatformList"
          [chargepointGroupId]="chargepoint.id"
          (editEvent)="storeManualData($event)"></pcs-chargepoint-details>
      </mat-tab>
    </mat-tab-group>
  </mat-expansion-panel>
</div>
