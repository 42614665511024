<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<button mat-raised-button class="headerActionBtn icon-only" *ngIf="!isEditMode"
        (click)="enableEdit()">
  <mat-icon> edit</mat-icon>
</button>
<div *ngIf="isEditMode">
  <button mat-raised-button class="headerActionBtn icon-only"
          (click)="save()" [disabled]="saveNotApplicable()">
    <mat-icon> done</mat-icon>
  </button>
  <button mat-raised-button class="headerActionBtn icon-only"
          (click)="cancel()">
    <mat-icon> close</mat-icon>
  </button>
</div>
