<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div>
  <div class="mat-elevation-z1">
    <mat-toolbar class="headerToolbar">
      <span *ngIf="mode === 'CREATE'">{{ 'product-detail.title.productCreate'|translate }}</span>
      <span
        *ngIf="mode !== 'CREATE' && currProductData"> {{ 'product-detail.title.productView'|translate:currProductData }}
      </span>
      <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
    </mat-toolbar>
  </div>
  <form class="product-info-form" *ngIf="productInfoForm" [formGroup]="productInfoForm">
    <mat-grid-list cols="2" rowHeight="110px">
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'global.label.product.name' | translate }}</mat-label>
          <input matInput required formControlName="name">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{ "global.label.product.status" | translate }}</mat-label>
          <input matInput formControlName="status">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'product-detail.label.product.term' | translate }}</mat-label>
          <input matInput required formControlName="term">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'global.label.product.code' | translate }}</mat-label>
          <input matInput formControlName="code">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'product-detail.label.product.channel' | translate }}</mat-label>
          <mat-select required formControlName="channel">
            <mat-option *ngFor="let channel of channelList"
                        [value]="channel">{{ 'product.channel.' + channel | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'global.label.product.serviceId' | translate }}</mat-label>
          <input matInput formControlName="serviceId" [required]="isServiceIdRequired()">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
  <mat-tab-group dynamicHeight [mat-stretch-tabs]="false" class="price-info mat-elevation-z1">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'product-detail.tabPanel.basicFees' | translate }}</span>
        <button mat-raised-button class="tab-action-btn  icon-only" (click)="onConfigureNewBasicFee()"
                *ngIf="isCreateOrEditMode() && (reqEditPermission | accessAllowed)">
          <mat-icon> add</mat-icon>
        </button>
      </ng-template>
      <pcs-basic-fee-table (deleteEvent)="onDeleteBasicFee($event)"
                           (editEvent)="onEditBasicFee($event)"
                           *ngIf="currProductData && currProductData.basicFees && taxData"
                           [actionButtons]="priceAndPlanEditAllowed()"
                           [dataColumns]="basicFeeTableColumns"
                           [data]="basicFees$"
                           [i18nBase]="'product-detail.label'">
      </pcs-basic-fee-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'product-detail.tabPanel.prices' | translate }}</span>
        <button mat-raised-button class="tab-action-btn  icon-only" (click)="onConfigureNewCountryPrice()"
                *ngIf="isCreateOrEditMode() && (reqEditPermission | accessAllowed)">
          <mat-icon> add</mat-icon>
        </button>
      </ng-template>
      <country-prices-table *ngIf="currProductData && currProductData.prices && taxData"
                            [data]="countryPrices$"
                            [dataColumns]="countryPriceTableColumns"
                            [i18nBase]="'product-detail.label'"
                            [actionButtons]="priceAndPlanEditAllowed()"
                            (editEvent)="onEditCountryPrice($event)"
                            (deleteEvent)="onDeleteCountryPrice($event)">
      </country-prices-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'product-detail.tabPanel.plans' | translate }}</span>
        <button (click)="createNewPlan()" *ngIf="isCreateOrEditMode() && (reqEditPermission | accessAllowed)"
                class="tab-action-btn  icon-only"
                mat-raised-button>
          <mat-icon> add</mat-icon>
        </button>
      </ng-template>
      <pcs-plan-table (deleteEvent)="onDeletePlan($event)"
                      (editEvent)="onEditPlan($event)"
                      [dataColumns]="planTableColumns"
                      [i18nBase]="'product-detail.label'"
                      [data]="plans$"
                      [actionButtons]="priceAndPlanEditAllowed()">
      </pcs-plan-table>
    </mat-tab>
  </mat-tab-group>

  <div class="action-buttons-bottom">
    <button mat-raised-button class="dialogueButton dialogueButtonRight"
            *ngIf="isCreateOrEditMode() && (reqEditPermission | accessAllowed)"
            [disabled]="!saveAllowed()"
            (click)="save()">
      <mat-icon class="button-icon">save</mat-icon>
      <span>{{ 'product-detail.button.save' | translate }}</span>
    </button>
    <button mat-raised-button class="dialogueButton"
            *ngIf="isCreateOrEditMode() && currProductData && currProductData.status==='DRAFT' && currProductData.id !== undefined && (reqEditPermission | accessAllowed)"
            (click)="activate()">
      <mat-icon class="button-icon">play_arrow</mat-icon>
      <span>{{ 'product-detail.button.activate' | translate }}</span>
    </button>
    <button mat-raised-button class="deactivate negative-action"
            *ngIf="isCreateOrEditMode() && currProductData && currProductData.status!=='DORMANT' && currProductData.id !== undefined && (reqEditPermission | accessAllowed)"
            (click)="deactivate()">
      <mat-icon class="button-icon">block</mat-icon>
      <span>{{ 'product-detail.button.deactivate' | translate }}</span>
    </button>
    <button mat-raised-button class="dialogueButton" (click)="goBack()">
      <mat-icon class="button-icon">arrow_back</mat-icon>
      <span>{{ 'product-detail.button.back' | translate }}</span>
    </button>
  </div>
</div>
