/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {WorkSheetDto} from './work-sheet-dto';
import {DateUtils} from 'pcs-commons/utils';
import {StatisticService} from './statistic.service';
import FileSaver from "file-saver";
import {StatisticDownloadStatusService} from "./statistic-download-status.service";
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class StatisticCsvDownloaderService {

  constructor(private statisticService: StatisticService, public statusService: StatisticDownloadStatusService) {
  }

  public async getStatisticV2(pageSize: number, selectedQuery: string): Promise<void> {
    this.statusService.start(selectedQuery);
    try {
      await this.doExportStatistics(selectedQuery, pageSize);
    } catch (exception) {
      console.error("An exception occurred", exception);
    } finally {
      this.statusService.stop();
    }
  }

  private async doExportStatistics(selectedQuery: string, pageSize: number): Promise<void> {
    const dataProvider = (currentPage: number): Observable<WorkSheetDto[]> => this.statisticService.getStatisticsV2(
      selectedQuery, null, null, currentPage * pageSize, pageSize + 1);

    const csvChunks = await this.readDataAndConvertThemToCSVChunks(pageSize, dataProvider, selectedQuery);
    const csv = csvChunks.join("\n");
    this.storeCsvFile(csv, selectedQuery);
  }

  private async readDataAndConvertThemToCSVChunks(pageSize: number, observableToCall: (currentPage: number) => Observable<WorkSheetDto[]>,
                                                  selectedQuery: string): Promise<Array<string>> {
    let currPage = 0;
    const csvChunks = new Array<string>();
    let hasMoreData = false;
    do {
      console.log(`fetching data for ${selectedQuery}. Current page: ${currPage}, chunk size: ${pageSize}`);
      const workSheetDtos = await firstValueFrom(observableToCall(currPage));

      const wsDto = workSheetDtos[0];
      this.addTitleRowIfNecessary(csvChunks, wsDto);
      console.log('processing data for sheet: ', wsDto);
      hasMoreData = this.appendDataToWorksheet(wsDto, pageSize, csvChunks);
      ++currPage;
      this.statusService.tick();
    } while (hasMoreData);

    return csvChunks;
  }

  private addTitleRowIfNecessary(csvChunks: string[], wsDto: WorkSheetDto): void {
    if (csvChunks.length === 0) {
      const titleRow = wsDto.metaData.map(meta => meta.columnName).join(';');
      csvChunks.push(titleRow);
    }
  }

  private appendDataToWorksheet(wsDto: WorkSheetDto, pageSize: number, csvChunks: Array<string>): boolean {
    const sheetData = wsDto.data;

    const sheetHasMore = sheetData.length > pageSize;
    // if sheet has more data than page size, we remove the last entry as we originally asked for one extra data
    if (sheetHasMore) {
      sheetData.pop();
    }
    const page = sheetData
      .map(row => Object.values(row)
        .map(s => this.encodeSpecialCharacters(s))
        .join(";"))
      .join("\n");
    csvChunks.push(page);

    return sheetHasMore;
  }

  private encodeSpecialCharacters(s: unknown): string {
    if (!s) {
      return s as string;
    }
    let cell = s as string;
    ['\r\n', '\n', '\r'].forEach(crlf => {
      while (cell.includes(crlf)) {
        cell = cell.replace(crlf, ' ');
      }
    });
    if (cell.includes(";")) {
      cell = '"' + cell + '"';
    }
    return cell;
  }

  private storeCsvFile(csv: string, query: string): void {
    const fileName = `${query}-${DateUtils.toBackendDate(DateTime.now())}.csv`;
    console.log('finished fetching data! Storing in CSV file: ', fileName);
    const csvBlob = new Blob([csv], {type: "text/csv"});
    FileSaver.saveAs(csvBlob, fileName);
  }
}
