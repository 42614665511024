/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {AccessRights} from '../datatypes/access-rights.enum';

@Pipe({
  name: 'permissionTranslationKey'
})
export class PermissionTranslationKeyPipe implements PipeTransform {
  private static readonly READ = 'READ_WEB';
  private static readonly EDIT = 'EDIT_WEB';
  private static readonly TRANSLATION_KEY_READ = 'usermanagement.groups.permissions.read';
  private static readonly TRANSLATION_KEY_EDIT = 'usermanagement.groups.permissions.edit';

  public transform(value: AccessRights): any {
    console.log('translating permission: ', value);
    if (value.toString().endsWith(PermissionTranslationKeyPipe.READ)) {
      return PermissionTranslationKeyPipe.TRANSLATION_KEY_READ;
    } else if (value.toString().endsWith(PermissionTranslationKeyPipe.EDIT)) {
      return PermissionTranslationKeyPipe.TRANSLATION_KEY_EDIT;
    }
    return value;
  }

}
