<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="notification-container">
  <div class="icon-section">
    <mat-icon *ngIf="data.type==='ERROR'" class="error">error</mat-icon>
    <mat-icon *ngIf="data.type==='INFO'" class="info">info</mat-icon>
    <mat-icon *ngIf="data.type==='WARN'" class="warn">warning</mat-icon>
  </div>
  <div class="msg-section" [ngClass]="data.type === 'ERROR' ? 'error-msg': 'info-msg'">
    <span>{{ data.message.message | translate:data.message }}</span>
  </div>
  <div class="action-section">
    <button mat-icon-button class="snackbar-close-button" [ngClass]="data.type === 'ERROR' ? 'error-msg': 'info-msg'" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
