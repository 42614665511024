/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Action} from '../../../datatypes/action.enum';
import {ActivatedRoute} from '@angular/router';
import {SharedDataService} from '../../../services/shared-data.service';
import {UserGroupFormManager} from './user-group-form-manager.abstract';
import {Observable, Subscription} from 'rxjs';
import {FormBuilder, FormControl} from '@angular/forms';
import {UserGroupService} from '../../../services/http/user-group.service';
import {Parameters} from '../../../global/parameters';
import {WebConfigRouterService} from '../../../services/web-config-router.service';
import {UniqueGroupNameValidator} from '../../../validation/unique-group-name-validator.service';
import {ValidationMessagePipe} from 'pcs-commons/validation';
import {BaseUrls} from '../../../datatypes/base-urls.enum';
import {TranslateService} from '@ngx-translate/core';
import {WithLoadingSpinner} from "pcs-commons/components";
import {AuditService} from "../../../services/audit.service";
import {ChangeRequestAction} from "../../../datatypes/audit-flow/change-request-action";
import {UserGroup} from "../../../datatypes/user-group";
import {take} from "rxjs/operators";

@Component({
  selector: 'pcs-edit-user-group',
  templateUrl: './edit-user-group.component.html',
  styleUrls: ['./edit-user-group.component.css']
})
export class EditUserGroupComponent extends UserGroupFormManager implements OnInit, OnDestroy {

  public toUnsubscribe: Subscription[] = [];
  public nameMaxLength = Parameters.GROUP_NAME_MAX_LENGTH;
  /** triggers rendering when the user changes their language settings */
  public updated = new Date();
  private originalGroup!: UserGroup;
  public changeRequestDialogOpen = false;

  constructor(
    private router: WebConfigRouterService,
    formBuilder: FormBuilder,
    uniqueGroupNameValidator: UniqueGroupNameValidator,
    private userGroupService: UserGroupService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private dataService: SharedDataService,
    private validationPipe: ValidationMessagePipe,
    private auditService: AuditService) {
    super(formBuilder, uniqueGroupNameValidator);
  }

  public ngOnInit(): void {
    console.log('initiating user group page');
    this.determineMode();
    this.defineFormGroup();
    if (this.isReadOrEditMode()) {
      this.setupGroupData();
    }
    this.translateService.onLangChange.subscribe(() => this.updated = new Date());
  }

  private determineMode(): void {
    const mode = this.route.snapshot.queryParamMap.get('mode');
    switch (mode) {
      case Action.CREATE:
        this.mode = Action.CREATE;
        break;
      case Action.EDIT:
        this.mode = Action.EDIT;
        break;
      default:
        this.mode = Action.READ;
    }
    if (Action.CREATE !== mode) {
      this.selectedGroupName = this.route.snapshot.paramMap.get('groupName');
      console.log('selected group: ', this.selectedGroupName);
    }
    console.log('user group page mode:  ', this.mode);
  }

  private setupGroupData(): void {
    this.dataService.currentGroupToEdit$
      .pipe(take(1))
      .subscribe(group => this.originalGroup = JSON.parse(JSON.stringify(group)));
    this.toUnsubscribe.push(this.dataService.currentGroupToEdit$.subscribe((gr) => {
      this.groupOnProcess = gr;
      console.log('got group info for read/edit: ', this.groupOnProcess);
      this.updateFormValuesForGroupData();
    }));
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((s) => s.unsubscribe());
    this.dataService.updateCurrentUserGroupOnEdit(null);
  }

  public getCloseButtonText(): string {
    return Action.READ === this.mode || (Action.EDIT === this.mode && this.userGroupForm.pristine)
      ? 'button.close' : 'button.cancel';
  }

  public async onCancel(): Promise<void> {
    await this.router.navigate([BaseUrls.USER_MANAGEMENT]);
  }

  public isSaveValid(): boolean {
    return Action.EDIT === this.mode ?
      !this.userGroupForm.pristine && this.userGroupForm.valid : this.userGroupForm.valid;
  }

  @WithLoadingSpinner()
  public async onSave(): Promise<void> {
    if (!this.changeRequestDialogOpen) {
      try {
        this.changeRequestDialogOpen = true;
        if (Action.EDIT === this.mode) {
          await this.updateUserGroup();
        } else {
          await this.createNewUserGroup();
        }
      } finally {
        // without the timeout, both cancel buttons are clicked when hitting the ESC key,
        // so the user would close two dialogs instead of closing only the
        // change request popup
        setTimeout(() => this.changeRequestDialogOpen = false, 50);
      }
    }
  }

  @WithLoadingSpinner()
  private async updateUserGroup(): Promise<void> {
    console.log('updating user group: ', this.groupOnProcess);
    const editedGroup = this.prepareUserGroupData();
    delete editedGroup.hasActiveChangeRequest // we do not want this field to appear in the change request
    delete this.originalGroup.hasActiveChangeRequest // we do not want this field to appear in the change request
    if (await this.auditService.initiateChangeRequestForUserGroup(
      ChangeRequestAction.MODIFY,
      this.originalGroup,
      editedGroup
    )) {
      await this.router.navigate([BaseUrls.USER_MANAGEMENT]);
    }
  }

  @WithLoadingSpinner()
  private async createNewUserGroup(): Promise<void> {
    console.log('creating new group');
    const newUserGroup = this.prepareUserGroupData();
    delete newUserGroup.hasActiveChangeRequest // we do not want this field to appear in the change request
    if (await this.auditService.initiateChangeRequestForUserGroup(
      ChangeRequestAction.CREATE,
      {} as UserGroup,
      newUserGroup)) {
      await this.router.navigate([BaseUrls.USER_MANAGEMENT]);
    }
  }

  public getError(fc: FormControl): Observable<any> {
    return this.validationPipe.transform(fc);
  }
}
