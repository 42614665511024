/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {AccessRights} from '../datatypes/access-rights.enum';
import {MatDialog} from '@angular/material/dialog';
import {CurrencyFactorService} from '../services/http/currency-factor.service';
import {Message} from '../datatypes/message';
import {CurrencyFactor} from '../datatypes/currency-factor';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {BehaviorSubject, catchError, finalize, map, of, Subscription, tap} from 'rxjs';
import {AddCurrencyFactorComponent} from './add-currency-factor/add-currency-factor.component';
import {EditCurrencyFactorsComponent} from './edit-currency-factors/edit-currency-factors.component';
import {PurchaseConditionService} from '../services/http/purchase-condition.service';
import {Parameters} from '../global/parameters';

@Component({
  selector: 'app-currency-factors',
  templateUrl: './currency-factors.component.html',
  styleUrls: ['./currency-factors.component.css']
})
export class CurrencyFactorsComponent extends AbstractNotificationHandler implements OnInit, OnDestroy {
  readonly reqEditPermission = [AccessRights.PURCHASE_EDIT_WEB];
  loading = false;

  pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  resultLength = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private currencyFactorsSource = new BehaviorSubject<CurrencyFactor[]>([]);
  currencyFactors$ = this.currencyFactorsSource.asObservable();

  dataColumns = CurrencyFactor.dataColumns;
  selectedPageSize = 10;
  toUnsubscribe: Subscription[] = [];

  constructor(
    private currencyFactorService: CurrencyFactorService,
    private purchaseConditionService: PurchaseConditionService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    this.retrieveCurrencyFactors(0, this.pageSizeOptions[0]);
  }

  private retrieveCurrencyFactors(pageIndex: number, pageSize: number): void {
    const offset = pageIndex * pageSize;
    // send one more so see if we have more records than requested
    this.loading = true;

    this.currencyFactorService.retrieveCurrencyFactors('', offset, pageSize + 1).pipe(
      map(currencyFactors => [...currencyFactors].sort((a,b) => this.compareForSorting(a, b))),
      tap(currencyFactors => this.handleResult(pageIndex, pageSize, currencyFactors)),
      catchError(() => of(null)),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  private handleResult(pageIndex: number, pageSize: number, currencyFactors: CurrencyFactor[]): void {
    this.resultLength = pageIndex * pageSize + currencyFactors.length;

    // remove extra element before showing
    if (currencyFactors.length > pageSize) {
      currencyFactors.splice(currencyFactors.length - 1, 1);
    }

    this.currencyFactorsSource.next(currencyFactors);

    if (currencyFactors.length <= 0) {
      const msg = new Message();
      msg.message = 'currencyfactors.noRecordsFound';
      this.showInfo(msg);
    }
  }

  public onEditCurrencyFactor(currencyFactor: CurrencyFactor): void {
    this.loading = true;
    const currency = currencyFactor.currency;
    this.currencyFactorService.retrieveCurrencyFactors(currency, 0, '').subscribe(
      {
        next: currencyFactorsToEdit => {
          currencyFactorsToEdit.sort((a,b) => this.compareForSorting(a, b));
          console.log(currencyFactorsToEdit);
          this.loading = false;
          const dialogRef = this.dialog.open(EditCurrencyFactorsComponent, {
            width: '1200px',
            data: {currency: currencyFactor.currency, currencyFactorsToEdit}
          });
          this.toUnsubscribe.push(dialogRef.afterClosed().subscribe((success) => {
            if (success) {
              this.retrieveCurrencyFactors(0, this.selectedPageSize);
            }
          }));
        },
        error: () => this.loading = false
      });
  }

  onConfigureNewCurrencyFactor() {
    this.loading = true;
    this.purchaseConditionService.getCurrencies().subscribe({
      next: currencies => {
        this.loading = false;
        const currencyNames = currencies.map(currency => currency.name);
        const dialogRef = this.dialog.open(AddCurrencyFactorComponent, {
          width: '1200px',
          data: {currencies: currencyNames}
        });
        this.toUnsubscribe.push(dialogRef.afterClosed().subscribe((success) => {
          if (success) {
            this.retrieveCurrencyFactors(0, this.selectedPageSize);
          }
        }));
      },
      error: () => this.loading = false
    });
  }

  handlePageChange(pageEvent: PageEvent) {
    this.selectedPageSize = pageEvent.pageSize;
    this.retrieveCurrencyFactors(pageEvent.pageIndex, pageEvent.pageSize);
  }

  ngOnDestroy(): void {
    this.toUnsubscribe.forEach(sub => sub.unsubscribe());
  }

  private compareForSorting(a: CurrencyFactor, b: CurrencyFactor): number {
    if (a.currency !== b.currency) {
      return a.currency.localeCompare(b.currency);
    }
    if (!a.validFrom) return -1;
    if (!b.validFrom) return 1;
    return a.validFrom?.localeCompare(b.validFrom);
  }
}
