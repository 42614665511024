/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {TaxData} from '../datatypes/taxdata';
import {Utils} from './utils';
import {TaxRecord} from '../datatypes/taxrecord';
import {DateUtils} from 'pcs-commons/utils';
import {FormControl} from '@angular/forms';

export class PriceUtils {

  public static toPercent(taxRate: number): number {
    return Math.round(10000 * taxRate) / 100;
  }

  public static getNettoPrice(price: number, vat: number): string {
    if (Utils.objectNotEmpty(price)) {
      if (!isNaN(price)) {
        if (price === 0) {
          return (price).toFixed(2);
        }
        if (!isNaN(vat)) {
          return (price / (1 + vat)).toFixed(2);
        }
      }
    }
    return '';
  }

  public static formatPrice(price: number): string {
    if (Utils.objectNotEmpty(price)) {
      if (!isNaN(price)) {
        return price.toFixed(2);
      }
    }
    return '';
  }

  public static getTax(country: string, taxData: TaxData): number {
    let vat: number;
    if (Utils.isNotEmpty(country)) {
      if (taxData) {
        for (const tax of taxData.data) {
          if (tax.country.toUpperCase() === country) {
            vat = tax.taxRate;
          }
        }
      } else {
        console.log('Tax data not available for country: ' + country);
      }
    }
    return vat;
  }

  public static getTaxForTime(country: string, taxData: TaxData, dateTime: string, doLog?: boolean): number {
    if (doLog) {
      console.log('searching tax for country: ', country, ' taxData:', taxData, ' dateTime', dateTime);
    }
    if (!Utils.isNotEmpty(country) || !taxData) {
      return null;
    }

    const taxConfigs: TaxRecord[] = [];
    for (const element of taxData.data) {
      if (element.country.toUpperCase() === country) {
        taxConfigs.push(element);
      }
    }
    if (taxConfigs.length < 1) {
      console.log('Tax data not available for country: ' + country);
      return null;
    }
    if (!dateTime || taxConfigs.length === 1) {
      return taxConfigs[0].taxRate;
    }

    const taxForDateTime = PriceUtils.findTaxValidForTime(taxConfigs, dateTime, doLog);
    return !taxForDateTime ? taxConfigs[0].taxRate : taxForDateTime.taxRate;
  }

  private static findTaxValidForTime(taxConfigs: TaxRecord[], dateTime: string, doLog: boolean): TaxRecord {
    const luxonDateTime = DateUtils.convertToDateTimeWithUTC(dateTime);
    if (doLog) {
      console.log('searching tax for dateTime: ', dateTime, ' after moment conversion: ', luxonDateTime);
    }
    return taxConfigs.find(taxConf => {
      const validFrom = DateUtils.convertToDateTimeWithUTC(taxConf.validFrom)?.startOf("second");
      const validUntil = DateUtils.convertToDateTimeWithUTC(taxConf.validUntil)?.startOf("second");
      if (!taxConf.validFrom) {
        return luxonDateTime.startOf("second") <= validUntil;
      }
      if (!taxConf.validUntil) {
        return luxonDateTime.startOf("second") >= validFrom;
      }
      return validFrom <= luxonDateTime && luxonDateTime <= validUntil;
    });
  }

  public static updateCurrencyForCountry(newCountry: string, taxData: TaxData, fc: FormControl): void {
    let j: number;
    const taxes: TaxRecord[] = taxData.data;
    for (j = 0; j < taxes.length; j++) {
      const taxEntry = taxes[j];
      if (taxEntry.country === newCountry) {
        fc.setValue(taxEntry.currency);
        return;
      }
    }
  }

}
