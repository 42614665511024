/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {ImportAction} from './import-action.enum';

export class VehicleImportRecord {
  public static readonly tableColumns: string[] = [
    'row', 'action', 'importResult', 'make', 'model', 'version',
    'buildYear', 'vehicleClass', 'power', 'batteryCapacity', 'electricRange',
    'consumption', 'maxChargingDC', 'maxChargingAC', 'plugTypes'
  ];
  row: number;
  action: ImportAction;
  importResult: String;
  make: String;
  model: String;
  version: String;
  buildYear: String;
  vehicleClass: String;
  power: number;
  batteryCapacity: number;
  electricRange: number;
  consumption: number;
  maxChargingDC: number;
  maxChargingAC: number;
  plugTypes: String[];
}
