/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input} from '@angular/core';
import {ChangeRequestInfoManagerAbstract} from "../change-request-info-manager.abstract";

@Component({
  selector: 'pcs-change-request-info-common',
  templateUrl: './change-request-info-common.component.html',
  styleUrls: ['./change-request-info-common.component.css']
})
export class ChangeRequestInfoCommonComponent extends ChangeRequestInfoManagerAbstract {

  @Input()
  public params: string[];

  public getInfoText(): string {
    return 'changeRequest.info.text.' + this.changeRequest?.action;
  }

  public getParam(index: number): string {
    return this.params?.length > index ? this.params[index] : '';
  }
}
