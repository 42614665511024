/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Identifiable} from 'pcs-commons/datatypes';

export class CurrencyFactor extends Identifiable {
  public static readonly dataColumns = ['currency', 'description', 'value', 'reverseValue', 'validFrom', 'validUntil'];
  public static readonly dataColumnsEditTable = ['description', 'value', 'reverseValue', 'validFromDate', 'validFromTime', 'validUntilDate', 'validUntilTime'];
  public currency: string;
  public description: string;
  public value: number;
  public reverseValue: number;
  public validFrom: string;
  public validUntil: string;
  public index: number;
  public added: boolean;
  public validUntilInitialEditable: boolean;
}
