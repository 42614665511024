/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ocpiPartnerTranslate'
})
export class OcpiPartnerTranslatePipe implements PipeTransform {
  public transform(value: string, col: string): string {
    if (col && value) {
      if (col === 'country') {
        return `global.country.${value}`;
      } else if (col === 'status') {
        return `ocpi.partner.status.${value}`;
      } else if (col === 'tokenSyncStatus') {
        return `ocpi.partner.${col}.${value}`;
      } else if (col === 'pullStaticDataOption') {
        return `ocpi.partner.${col}.${value}`;
      } else if (col === 'updateLocationDataStatus') {
        return `ocpi.partner.${col}.${value}`;
      }
    }
    return value;
  }
}
