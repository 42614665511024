<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="importDiv">
  <form [formGroup]="cpAttributeImportForm">
    <mat-grid-list cols="10" rowHeight="100px">
      <mat-grid-tile colspan="6">
        <input type="file" #selectFile name="selectFile" style="visibility: hidden; display:none" accept=".xlsx"
               (change)="handleFileSelect()"/>
        <mat-form-field id="cpAttributeImportFormField" appearance="outline" class="fileNameFormField"
                        style="font-size: 13px; padding: 0;">
          <input matInput id="fileName" [readonly]="true" class="fileNameInput">
          <button *ngIf="file" matSuffix mat-icon-button (click)="clearFileInput()" class="clear-button">
            <mat-icon class="clear-button-icon">close</mat-icon>
          </button>
          <mat-hint><strong style="color: red">{{ fileValidMsg | translate :{param0: param}  }}</strong></mat-hint>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <div class="importButtonDiv">
          <button class="actionButton icon-only" id="selectFileBtn" mat-raised-button (click)="triggerAddFile()"
                  [disabled]="isImportDisabled()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <div class="importButtonDiv">
          <button class="actionButton" mat-raised-button (click)="handleImport()"
                  [disabled]="isImportDisabled()">
            {{ 'button.import' | translate }}
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>
