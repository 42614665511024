/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewChecked, AfterViewInit, Component, Renderer2, ViewChild} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Parameters} from '../../global/parameters';

@Component({
  selector: 'database-query-result-table',
  templateUrl: './database-query-result-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    './database-query-result-table.component.css',
  ]
})
export class DatabaseQueryResultTableComponent extends OptimizedTableBaseComponent implements AfterViewInit, AfterViewChecked {

  public readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  @ViewChild(MatSort) public sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public getColumnClass(col: string): string {
    return col + '-column';
  }

  public ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.paginator = this.paginator;
    // After the view is initialized adjust the width of the table
    this.setTableWidth();
  }

  public ngAfterViewChecked(): void {
    this.setTableWidth();
  }

  /**
   *  After the table is set up, adjust the width of the table, so that all columns are part of the table.
   *  Sums up the width of all header cells.
   */
  public setTableWidth(): void {
    let totalTableWidth = 0;
    document.querySelectorAll('.mat-header-cell').forEach((cell) => {
      const margin = parseInt(window.getComputedStyle(cell).marginRight, 10) || 4;
      totalTableWidth += cell.getBoundingClientRect().width + margin;
    });
    if (document.querySelector('.mat-table') as HTMLElement) {
      (document.querySelector('.mat-table') as HTMLElement).style.minWidth = `${totalTableWidth}px`;
    }
  }
}
