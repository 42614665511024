/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {ChargingCardColumns} from '../../datatypes/charging-card';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'charging-card-table',
  templateUrl: './charging-card-table.component.html',
  styleUrls: ['./charging-card-table.component.css']
})
export class ChargingCardTableComponent extends OptimizedTableBaseComponent {
  @Input() public isLoadingResults;
  public readonly LAST_UPDATED: string = ChargingCardColumns.LAST_UPDATED;
  public updated = new Date();

  constructor(
    renderer: Renderer2,
    private translateService: TranslateService) {
    super(renderer);
    translateService.onLangChange.subscribe(() => this.updated = new Date());
  }
}
