<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{ "taxes.header" | translate }}</span>
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
  </mat-toolbar>
  <app-taxes-table
    [actionButtons]="reqEditPermission | accessAllowed"
    [data]="taxesResult$"
    [dataColumns]="dataColumns"
    (editEvent)="onEditTax($event)">
  </app-taxes-table>
</div>
