/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Directive, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Product} from '../../../datatypes/product';
import {
  CountryPriceRecord,
  CountryPriceRecordColumns,
  EditCountryPriceRecordColumns
} from '../../../country-price-record';
import {ChargepointClassPrice} from '../../../chargepoint-class-price';
import {TaxData} from '../../../datatypes/taxdata';
import {ValidatorList} from 'pcs-commons/validation';
import {Utils} from '../../../utils/utils';
import {FormUtils} from 'pcs-commons/utils';
import {ChargepointClassNames} from '../../../datatypes/chargepoint-class-names.enum';
import {DateRangeValidator2Service} from '../../../validation/date-range-validator2.service';
import {DateUtils} from 'pcs-commons/utils';
import {PriceUtils} from '../../../utils/price-utils';
import {EditBasicFeeFormManager} from '../edit-basic-fee/edit-basic-fee-form.manager';
import {combineDateAndTime} from "../../../validation/date-range-utils";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class EditCountryPriceFormManager implements OnDestroy {

  public static readonly KWH = 'Kwh';
  public static readonly MIN = 'Min';
  public toUnsubscribe: Subscription[] = [];
  public dataSourceSubscription: Subscription;

  public selectedPriceTab = 0;

  public currentProduct: Product;
  public countryPriceOnProcess: CountryPriceRecord;
  public taxData: TaxData;

  public basicInfoForm: FormGroup;
  public country: FormControl;
  public currency: FormControl;

  // variables used for class price tabs
  public currCountryPrices: CountryPriceRecord[] = [];
  public currCountryPricesFormList: FormGroup[] = [];
  public parentClassPricesForm: FormGroup;

  public readonly chargepointClassPricesJson = '[' +
    '{"chargepointClass":"UNKNOWN","pricePerKwh":0,"pricePerMin":0},' +
    '{"chargepointClass":"THREE","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"ELEVEN","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"TWENTY","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"TWENTY_TWO","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"FOURTY_THREE","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"FIFTY","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"HUNDRED_FIFTY","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"THREE_HUNDRED_FIFTY","pricePerKwh":"","pricePerMin":""},' +
    '{"chargepointClass":"THREE_HUNDRED_FIFTY_ION","pricePerKwh":"","pricePerMin":""}' +
    ']';

  constructor(
    public formBuilder: FormBuilder,
    public dateRangeValidator2Service: DateRangeValidator2Service
  ) {  }

  public defineClassPricesForms(): void {
    this.parentClassPricesForm = this.formBuilder.group(
      {},
      {
        validators: [this.dateRangeValidator2Service.validate.bind(this.dateRangeValidator2Service)]
      });
    this.currCountryPricesFormList = [];
    this.currCountryPrices.forEach((price) => {
      this.defineAndPushNewFormForPrice(price);
    });
    console.log('all class prices form map: ', this.currCountryPricesFormList);
    this.refreshParentPricesForm(false);
  }

  public defineAndPushNewFormForPrice(price: CountryPriceRecord): void {
    console.log('defining class prices and date ranges  form for ', price);
    const cPriceForm = this.configureClassPricesForm(price.priceDtos);
    const validFromDate = new FormControl(DateUtils.convertToDateTimeWithUTC(price.validFrom)?.startOf("day"));
    const validFromTime = new FormControl(DateUtils.convertToDateTimeWithUTC(price.validFrom)?.toFormat("HH:mm:ss"));
    const validUntilDate = new FormControl(DateUtils.convertToDateTimeWithUTC(price.validUntil)?.startOf("day"));
    const validUntilTime = new FormControl(DateUtils.convertToDateTimeWithUTC(price.validUntil)?.toFormat("HH:mm:ss"));
    const vatValue = PriceUtils.getTaxForTime(this.country.value, this.taxData, price.validFrom ? price.validFrom : price.validUntil);
    const tax = new FormControl({value: vatValue * 100, disabled: true});
    cPriceForm.addControl(EditCountryPriceRecordColumns.VALID_FROM_DATE, validFromDate);
    cPriceForm.addControl(EditCountryPriceRecordColumns.VALID_FROM_TIME, validFromTime);
    cPriceForm.addControl(EditCountryPriceRecordColumns.VALID_UNTIL_DATE, validUntilDate);
    cPriceForm.addControl(EditCountryPriceRecordColumns.VALID_UNTIL_TIME, validUntilTime);
    cPriceForm.addControl(CountryPriceRecordColumns.TAX, tax);
    const subs = EditBasicFeeFormManager.registerDurationChangeListeners(validFromDate, validFromTime, validUntilDate, validFromTime, tax, this.country, this.taxData);
    this.toUnsubscribe.push(...subs);
    this.currCountryPricesFormList.push(cPriceForm);
  }

  public ngOnDestroy(): void {
    console.log(this.constructor.name, ': on destroy is called');
    if (this.dataSourceSubscription) {
      this.dataSourceSubscription.unsubscribe();
    }
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  public configureClassPricesForm(classPrices: ChargepointClassPrice[]): FormGroup {
    const cPriceForm = this.formBuilder.group({});

    classPrices.forEach((price) => {
      const fcKwh = new FormControl(price ? price.pricePerKwh : null, ValidatorList.PRICE_OPTIONAL);
      cPriceForm.addControl(Utils.calcClassPriceFormControlName(price.chargepointClass, EditCountryPriceFormManager.KWH), fcKwh);

      const fcMin = new FormControl(price ? price.pricePerMin : null, ValidatorList.PRICE_OPTIONAL);
      cPriceForm.addControl(Utils.calcClassPriceFormControlName(price.chargepointClass, EditCountryPriceFormManager.MIN), fcMin);
    });

    return cPriceForm;
  }

  public toCountryPriceWithAllClassPrices(cPrice: CountryPriceRecord): CountryPriceRecord {
    const clonedPrice = new CountryPriceRecord();
    if (cPrice) {
      clonedPrice.country = cPrice.country;
      clonedPrice.validUntil = cPrice.validUntil;
      clonedPrice.validFrom = cPrice.validFrom;
      clonedPrice.currency = cPrice.currency;

    }
    clonedPrice.priceDtos = this.fillPricesList(cPrice ? cPrice.priceDtos : null);
    console.log('cloned country price: ', clonedPrice);
    return clonedPrice;
  }

  public fillPricesList(existingPrices: ChargepointClassPrice[]): ChargepointClassPrice[] {
    const emptyPrices: ChargepointClassPrice[] = JSON.parse(this.chargepointClassPricesJson);
    if (existingPrices) {
      for (const price of emptyPrices) {
        for (const existingPrice of existingPrices) {
          if (price.chargepointClass === existingPrice.chargepointClass) {
            if (existingPrice.pricePerKwh >= 0) {
              price.pricePerKwh = existingPrice.pricePerKwh;
            }
            if (existingPrice.pricePerMin >= 0) {
              price.pricePerMin = existingPrice.pricePerMin;
            }
            break;
          }
        }
      }
    }
    return emptyPrices;
  }

  protected prepareCountryPrice(cPriceForm: FormGroup): CountryPriceRecord {
    console.log('classPrice form value: ', cPriceForm.value);
    const cPrice = new CountryPriceRecord();
    cPrice.country = this.country.value;
    cPrice.currency = this.currency.value;
    const validFrom = DateUtils.buildDateTimeFromSeparateFields(cPriceForm.value.validFromDate, cPriceForm.value.validFromTime, false);
    const validUntil = DateUtils.buildDateTimeFromSeparateFields(cPriceForm.value.validUntilDate, cPriceForm.value.validUntilTime, false);

    cPrice.validFrom = validFrom?.toISO();
    cPrice.validUntil = validUntil?.toISO();
    // fill class prices
    cPrice.priceDtos = [];
    Object.values(ChargepointClassNames).forEach(
      (cpClass: string) => this.prepareClassPrice(cPriceForm, cpClass, cPrice)
    );
    console.log('prepared country price: ', cPrice);
    return cPrice;
  }

  private prepareClassPrice(cPriceForm: FormGroup, cpClass: string, cPrice: CountryPriceRecord): void {
    const pricePerMin: number =
      FormUtils.getNullableNumber(cPriceForm, Utils.calcClassPriceFormControlName(cpClass, EditCountryPriceFormManager.MIN));
    const pricePerKwh: number =
      FormUtils.getNullableNumber(cPriceForm, Utils.calcClassPriceFormControlName(cpClass, EditCountryPriceFormManager.KWH));
    console.log('prices for class: ', cpClass, ' perMin: ', pricePerMin, ' perKwh: ', pricePerKwh);
    if (pricePerMin !== null || pricePerKwh !== null) {
      const classPrice = new ChargepointClassPrice();
      classPrice.chargepointClass = cpClass;
      classPrice.pricePerMin = pricePerMin;
      classPrice.pricePerKwh = pricePerKwh;
      cPrice.priceDtos.push(classPrice);
    }
  }

  public refreshParentPricesForm(clean: boolean): void {
    if (clean) {
      console.log('removing all controls from parent prices form');
      Object.keys(this.parentClassPricesForm.controls).forEach((name) => this.parentClassPricesForm.removeControl(name));
    }
    this.currCountryPricesFormList.forEach((fg, index) => {
      this.parentClassPricesForm.addControl('priceForm-' + index, fg);
    });
    console.log('controls in parent prices form: ', this.parentClassPricesForm.controls);
  }

}
