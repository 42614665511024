/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {WebConfigRouterService} from "../../services/web-config-router.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'pcs-fleet-invoices-overview',
  templateUrl: './fleet-invoices-overview.component.html',
  styleUrls: ['./fleet-invoices-overview.component.css']
})
export class FleetInvoicesOverviewComponent implements OnInit {
  public currFleetId: string;

  constructor(
    private router: WebConfigRouterService,
    private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.currFleetId = this.route.snapshot.paramMap.get('fleetId');
  }

  public onBack(): void {
    this.router.navigate(['fleet-edit', this.currFleetId]);
  }
}
