/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
export enum Role {
  USER = 'USER',
  FLEET_MANAGER = 'FLEET_MANAGER',
  PCS_ADMIN = 'PCS_ADMIN'
}
