/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Invoice} from 'pcs-commons/datatypes';
import {Observable} from 'rxjs';
import {HeaderEnabled} from "./header-enabled.abstract";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends HeaderEnabled {

  constructor(private http: HttpClient,
              @Inject('env') private env: any) {
    super();
  }

  public getInvoices(fleetId: string): Observable<Invoice[]> {
    const url = this.env.invoiceEndpoint + '/' + fleetId + '/invoices';
    const invoices = this.http.get<Invoice[]>(url, {headers: this.headers});
    return invoices;
  }

  public downloadInvoice(fleetId: string, invoiceId: number): Observable<Blob> {
    const url = this.env.invoiceEndpoint + '/' + fleetId + '/invoices/' + invoiceId;
    return this.http.get<Blob>(url, {responseType: 'blob' as 'json'});
  }

  public downloadBilledItemsFinanceEnabler(fleetId: string, invoiceId: number): Observable<Blob> {
    const url = this.env.invoiceEndpoint + '/' + fleetId + '/billeditems-finance-enabler/' + invoiceId;
    return this.http.get<Blob>(url, {responseType: 'blob' as 'json'});
  }
}
