/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractFilterFormComponent implements OnInit, OnDestroy {
  @Input() public displayTrigger$: Observable<any>;
  public triggerSubscription: Subscription;

  public filterFormGroup: FormGroup;

  constructor() {
  }

  public ngOnInit(): void {
    console.log('calling define from control');
    this.defineFromControls();
    this.triggerSubscription = this.displayTrigger$.subscribe((trigger) => {
      if (trigger) {
        this.triggerDisplayChargepoints();
      }
    });
  }

  public ngOnDestroy(): void {
    this.triggerSubscription.unsubscribe();
  }

  public abstract triggerDisplayChargepoints();

  public abstract defineFromControls();
}
