/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {PurchaseCondition} from '../../datatypes/purchase-conditions/purchase-condition';
import {Action} from '../../datatypes/action.enum';
import {FormBuilder, FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {PurchaseConditionElement} from '../../datatypes/purchase-conditions/purchase-condition-element';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ValidatorList} from 'pcs-commons/validation';
import {Utils} from '../../utils/utils';
import {FormUtils} from 'pcs-commons/utils';
import {NonZeroValidatorService} from '../../validation/non-zero-validator.service';
import {Operator} from '../../datatypes/operator';
import {PurchaseConditionStatus} from '../../datatypes/purchase-conditions/purchase-condition-status.enum';
import {DateUtils} from 'pcs-commons/utils';
import {Directive, OnDestroy} from '@angular/core';
import {pairwise} from 'rxjs/operators';
import {CpoValidatorService} from '../../validation/cpo-validator.service';
import {CurrencyValidatorService} from '../../validation/currency-validator.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../dialog/confirmation-dialog/confirmation-dialog.component';
import {DateTime} from "luxon";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class PurchaseConditionFormManager extends AbstractNotificationHandler implements OnDestroy {
  public readonly _tElemIdPrefix = 'purchaseElement';
  public readonly statusList = Object.values(PurchaseConditionStatus);
  public readonly TARIFF_ID = 'tariffId';
  public readonly EVSEIDS = 'evseIds';
  public readonly COUNTRY = 'country';

  public toUnsubscribe: Subscription[] = [];
  public toUnsubscribeValueChangeListener: Subscription[] = [];
  public currPurchaseCondition: PurchaseCondition;
  public mode: Action;
  public loading = false;

  public purchaseConditionForm: UntypedFormGroup;
  public radioButtonSelection: FormControl<string>;
  public cpo: UntypedFormControl;
  public country: FormControl<string>;
  public tariffId: FormControl<string>;
  public evseIds: FormControl<string>;
  public currency: FormControl<string>;
  public maxPrice: FormControl<number>;
  public validFrom: FormControl<string | DateTime>;
  public validUntil: FormControl<string | DateTime>;
  public status: FormControl<string>;
  public currElementsSubject = new BehaviorSubject<PurchaseConditionElement[]>([]);
  public currElements$ = this.currElementsSubject.asObservable();
  public elementsUpdated = false;

  public unixEpoch: DateTime;
  public minValidFrom: DateTime;
  public minValidUntil: DateTime;

  public cpoList: Operator[] = [];

  protected constructor(
    public dialog: MatDialog,
    public nonZeroValidator: NonZeroValidatorService,
    public cpoValidator: CpoValidatorService,
    public currencyValidator: CurrencyValidatorService,
    public formBuilder: FormBuilder) {
    super();
  }

  public defineFormControls(): void {
    console.log('defining form controls and their values. Current purchase condition: ', this.currPurchaseCondition);
    this.country = new FormControl<string>(null, ValidatorList.ALPHA2_COUNTRY);
    this.tariffId = new FormControl<string>(null, ValidatorList.STRING_DEFAULT);
    this.evseIds = new FormControl<string>(null);
    this.currency = new FormControl<string>(null, [Validators.required, this.currencyValidator.validate.bind(this.currencyValidator)]);
    const maxPriceValidators = [this.nonZeroValidator.validate.bind(this.nonZeroValidator), ...ValidatorList.PRICE_OPTIONAL];
    this.maxPrice = new FormControl<number>(null, maxPriceValidators);
    this.validFrom = new FormControl<string | DateTime>(null, [Validators.required]);
    this.validUntil = new FormControl<string | DateTime>(null);
    this.status = new FormControl<string>(null);
    this.status.disable();
    this.radioButtonSelection = new FormControl<string>(null);
    this.cpo = new UntypedFormControl(null, [Validators.required, this.cpoValidator.validate.bind(this.cpoValidator)]);

    this.purchaseConditionForm = this.formBuilder.group({
      country: this.country,
      tariffId: this.tariffId,
      evseIds: this.evseIds,
      currency: this.currency,
      maxPrice: this.maxPrice,
      validFrom: this.validFrom,
      validUntil: this.validUntil,
      status: this.status,
      cpo: this.cpo
    });
    this.defineValueChangeListeners();
  }

  private defineValueChangeListeners(): void {
    this.toUnsubscribeValueChangeListener.push(this.validFrom.valueChanges.subscribe(
      (newValidFrom) => {
        const newValidFromMoment = DateUtils.convertToDateTimeWithUTC(newValidFrom);
        console.log('updating min valid until for new validFrom value: ', newValidFromMoment);
        this.minValidUntil = !newValidFrom ? this.unixEpoch : DateUtils.nextDay(newValidFromMoment);
      }
    ));

    this.toUnsubscribeValueChangeListener.push(this.validUntil.valueChanges.pipe(pairwise()).subscribe(
      ([prevValidUntil, newValidUntil]) => {
        // check if the elements has end date after new validUntil, replace them with new ValidUntil
        const currElements = this.currElementsSubject.getValue();

        if (Utils.isEmpty(newValidUntil)
          || DateUtils.toBackendDate(newValidUntil) === DateUtils.toBackendDate(prevValidUntil)
          || currElements.length < 1) {
          return;
        }

        console.log('valid until new value: ', newValidUntil, ' previous value : ', prevValidUntil);
        const elDateNeedAdjustment = currElements.find((el) => {
          if (!this.validUntil.value || !this.validUntil.valid) {
            return false;
          }
          const newValidUntilString = DateUtils.toBackendDate(newValidUntil);
          // 1. start date exist and is after new valid until
          if (el.startDate && DateUtils.isDateAfter(el.startDate, newValidUntilString)) {
            return true;
          }
          // 2. end date exist and is after new valid until
          return el.endDate && DateUtils.isDateAfter(el.endDate, newValidUntilString);
        });

        if (!elDateNeedAdjustment) {
          return;
        }

        // inform user about the el end date adjustment
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
          {
            maxWidth: 600,
            data: {
              message: Utils.changePurchaseConValidUntilConfirmMessage(),
              actionKey: 'button.ok'
            }
          });

        dialogRef.afterClosed().subscribe((result) => {
          if (!result) {
            console.log('reverting back to : ', prevValidUntil);
            this.validUntil.setValue(DateUtils.convertToDateTimeWithUTC(prevValidUntil), {emitEvent: false});
          }
        });
      }
    ));

    this.radioButtonSelection.valueChanges.subscribe((newValue) => {
      console.log('new value for tariffEvseSelection: ', newValue);
    });
  }

  /**
   * must be called after defineFormControls()
   */
  public refreshValues(): void {
    this.toUnsubscribeValueChangeListener.forEach((sub) => sub.unsubscribe());
    console.log('refreshing form control values for: ', this.currPurchaseCondition);
    console.log('can edit details: ', this.canEditDetails());

    this.cpo.setValue(this.currPurchaseCondition.cpo ? this.currPurchaseCondition.cpo : null);

    // set either country, tariffId or evseIds
    const evseIdsAsString = Utils.arrayEmpty(this.currPurchaseCondition.evseIds) ?
      '' : this.currPurchaseCondition.evseIds.join(', ');
    if (Utils.isNotEmpty(this.currPurchaseCondition.country)) {
      this.setRadioGroupValues(this.COUNTRY, this.currPurchaseCondition.country);
    } else if (Utils.isNotEmpty(this.currPurchaseCondition.tariffId)) {
      this.setRadioGroupValues(this.TARIFF_ID, this.currPurchaseCondition.tariffId);
    } else if (Utils.isNotEmpty(evseIdsAsString)) {
      this.setRadioGroupValues(this.EVSEIDS, evseIdsAsString);
    } else {
      this.setRadioGroupValues(this.COUNTRY, '');
    }

    this.currency.setValue(this.currPurchaseCondition.currency);
    this.maxPrice.setValue(this.currPurchaseCondition.maxPrice);
    this.validFrom.setValue(this.currPurchaseCondition.validFrom);
    this.validUntil.setValue(this.currPurchaseCondition.validUntil);
    this.status.setValue(this.currPurchaseCondition.status);

    // generate table element ids
    this.currPurchaseCondition.elements.forEach((elem) => elem.tElemId = Utils.generateStringId(this._tElemIdPrefix));
    this.currElementsSubject.next(this.currPurchaseCondition.elements);

    if (!this.canEditDetails()) {
      this.purchaseConditionForm.disable();
      this.radioButtonSelection.disable();
    } else {
      this.purchaseConditionForm.enable();
      this.status.disable();
    }

    if (!this.isReadOnlyMode && PurchaseConditionStatus.INACTIVE !== this.currPurchaseCondition.status) {
      this.validUntil.disable();
      this.validFrom.disable();
    }

    this.elementsUpdated = false;
    this.purchaseConditionForm.markAsPristine();
    this.defineValueChangeListeners();
  }

  private setRadioGroupValues(selection: string, value: string): void {
    this.evseIds.setValue(null);
    this.country.setValue(null);
    this.tariffId.setValue(null);
    switch (selection) {
      case this.COUNTRY:
        this.country.setValue(value);
        break;
      case this.TARIFF_ID:
        this.tariffId.setValue(value);
        break;
      case this.EVSEIDS:
        this.evseIds.setValue(value);
    }
    this.radioButtonSelection.setValue(selection);
  }

  public saveAllowed(): boolean {
    const hasElements = this.currElementsSubject.getValue().length > 0;
    if (this.isEditMode) {
      return this.purchaseConditionForm && this.purchaseConditionForm.valid && hasElements
        && (!this.purchaseConditionForm.pristine || this.elementsUpdated);
    }
    return this.purchaseConditionForm && this.purchaseConditionForm.valid && hasElements;
  }

  public preparePurchaseCondition(): PurchaseCondition {
    const purchaseCondition = new PurchaseCondition();
    if (this.isEditMode) {
      purchaseCondition.id = this.currPurchaseCondition.id;
    }
    purchaseCondition.elements = this.currElementsSubject.getValue();
    purchaseCondition.cpo = new Operator();
    purchaseCondition.cpo = FormUtils.getValue(this.purchaseConditionForm, 'cpo');

    if (this.isTariffSelected()) {
      purchaseCondition.tariffId = FormUtils.getStringValue(this.purchaseConditionForm, this.TARIFF_ID);
    } else if (this.isCountrySelected()) {
      purchaseCondition.country = FormUtils.getStringValue(this.purchaseConditionForm, 'country');
    } else {
      const evseIds = FormUtils.getStringValue(this.purchaseConditionForm, this.EVSEIDS);
      const evseIdList: string[] = Utils.isEmpty(evseIds) ? [] : evseIds.split(',');
      evseIdList.forEach((s) => s.trim());
      purchaseCondition.evseIds = evseIdList;
    }
    purchaseCondition.currency = FormUtils.getStringValue(this.purchaseConditionForm, 'currency');
    purchaseCondition.maxPrice = FormUtils.getNullableNumber(this.purchaseConditionForm, 'maxPrice');
    purchaseCondition.status = FormUtils.getStringValue(this.purchaseConditionForm, 'status');
    purchaseCondition.validFrom = FormUtils.getValueAsBackendDate(this.purchaseConditionForm, 'validFrom');
    purchaseCondition.validUntil = FormUtils.getValueAsBackendDate(this.purchaseConditionForm, 'validUntil');
    return purchaseCondition;
  }

  public get isCreateMode(): boolean {
    return this.mode && Action.CREATE === this.mode;
  }

  public get isEditMode(): boolean {
    return this.mode && Action.EDIT === this.mode;
  }

  public get isReadOnlyMode(): boolean {
    return this.mode && Action.READ === this.mode;
  }

  public canEditDetails(): boolean {
    return this.isCreateMode ||
      this.isEditMode && this.currPurchaseCondition && PurchaseConditionStatus.DELETED !== this.currPurchaseCondition.status;
  }

  public allowElementEdit(): boolean {
    return this.isCreateMode ||
      this.isEditMode && this.currPurchaseCondition &&
      (PurchaseConditionStatus.INACTIVE === this.currPurchaseCondition.status
        || PurchaseConditionStatus.ACTIVE === this.currPurchaseCondition.status);
  }

  public allowElementRemove(): boolean {
    return this.canEditDetails();
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
    this.toUnsubscribeValueChangeListener.forEach((sub) => sub.unsubscribe());
  }

  public isTariffSelected(): boolean {
    return this.TARIFF_ID === this.radioButtonSelection.value;
  }

  public isEvseIdListSelected(): boolean {
    return this.EVSEIDS === this.radioButtonSelection.value;
  }

  public isCountrySelected(): boolean {
    return this.COUNTRY === this.radioButtonSelection.value;
  }
}
