/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
*/
import {Component} from '@angular/core';
import {ChangeRequestInfoManagerAbstract} from "../change-request-info-manager.abstract";

@Component({
  selector: 'pcs-change-request-info-json',
  templateUrl: './change-request-info-json.component.html',
  styleUrls: ['./change-request-info-json.component.css']
})
export class ChangeRequestInfoJsonComponent extends ChangeRequestInfoManagerAbstract {

}
